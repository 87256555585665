<template>
  <div class="swiper-pagination">

  </div>
</template>

<script>
export default {
  name: "BlockPagination"
}
</script>

<style scoped lang="scss">
.swiper-pagination {
  position: relative;
  left: auto !important;
  display: flex;
  justify-content: center;
  //display: flex;
  //flex-direction: row;
  //column-gap: 10px;
  //align-items: center;
  //.circle-container {
  //    cursor: pointer;
  //    filter: blur(3px);
  //    width: 8px;
  //    height: 8px;
  //    border-radius: 50%;
  //    background-color: #FDFDFD;
  //  transition: all .6s ease;
  //
  //    &:hover {
  //      background-color: var(--color-main-accent);
  //    }
  //
  //  &.active {
  //    background-color: var(--color-main-accent);
  //    width: 16px;
  //    height: 16px;
  //  }
  //
  //  &.prev-next {
  //    width: 12px;
  //    height: 12px;
  //  }
  //}
}

</style>