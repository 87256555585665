import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import VueAwesomeSwiper from "vue-awesome-swiper";
import reusedCode from "@/mixins/reusedCode";
import PrimeVue from '@/library/config';
import AnimateOnScroll from '@/library/animateonscroll';
import Vue3Lottie from "vue3-lottie";

createApp(App).use(store).use(router).use(Vue3Lottie, { name: 'LottieAnimation' }).directive('animateonscroll', AnimateOnScroll).use(PrimeVue).use(VueAwesomeSwiper).mixin(reusedCode).mount('#app')
