<template>
  <p class="main-title-container">
    <slot/>
  </p>
</template>

<script>
export default {
name: "MainTitleQuiz"
}
</script>

<style scoped lang="scss">
.main-title-container {
  line-height: 120%;
  white-space: pre-wrap;
  color: var(--color-main-light-color);
}


@media (max-width: 3024px){
  .main-title-container {
    font-size: 48px;
  }
}


@media (max-width: 1920px) {
  .main-title-container {
    font-size: 32px;
  }
}


@media (max-width: 1600px) {
  .main-title-container {
    font-size: 28px;
  }
}

@media (max-height: 800px) {
  .main-title-container {
    font-size: 24px;
  }
}

@media (max-width: 1400px) and (min-height: 801px) {
  .main-title-container {
    font-size: 28px;
  }
}

@media (max-width: 1133px) {
  .main-title-container {
    font-size: 38px;
  }
}


@media (max-width: 769px) {
  .main-title-container {
    font-size: 22px;
  }
}


@media (min-height: 600px) and (max-height: 630px){

  //8 plus
}

@media (max-width: 375px) {
  //se new
}

@media (max-width: 375px) and (min-height: 630px) {
  //x
}


@media (max-width: 320px) {
  .main-title-container {
    font-size: 16px;
  }
}

@media (min-width: 3025px) {
  .main-title-container {
    font-size: 98px;
  }
}
</style>