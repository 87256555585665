<template>
  <div class="icon-img-container" :class="{'active-hover' : activeBlur}">
    <img :src="getUrlIcons(infoIcon)" alt="icon">
  </div>
</template>

<script>
export default {
  name: "ButtonIcon",
  props: {
    infoIcon: {
      type: String,
      required: true
    },
    focusParam: {
      type: Boolean,
      required: true
    }
  },
  computed: {
    activeBlur() {
      if(this.activeMobile) {
        return true
      } else {
        return this.focusParam;
      }
    }
  }
}
</script>

<style scoped lang="scss">
.icon-img-container {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  opacity: .4;
  transition: opacity .6s ease, filter .6s ease;
  filter: blur(var(--active-blur-button));

  &.active-hover {
    filter: blur(0);
  }

  & img {
    object-fit: contain;
    width: 100%;
    height: 100%;
  }
}


@media (max-width: 3024px){
    .icon-img-container {
      max-width: 24px;
      max-height: 24px;
    }
}



@media (max-width: 1920px) {
    .icon-img-container {
      max-width: 20px;
      max-height: 20px;
    }
}

@media (max-width: 1600px) {

    .icon-img-container {
      max-width: 18px;
      max-height: 18px;
    }
}
@media (max-height: 800px) {
    .icon-img-container {
      max-width: 14px;
      max-height: 14px;
    }
}

@media (max-width: 1400px) and (min-height: 801px) {
    .icon-img-container {
      max-width: 18px;
      max-height: 18px;
    }
}

@media (max-width: 1133px) {
    .icon-img-container {
      max-width: 14px;
      max-height: 14px;
    }
}

@media (min-width: 3025px) {
    .icon-img-container {
      max-width: 32px;
      max-height: 32px;
    }
}
</style>