import router from "@/router";

export const quizModules = {
    namespaced: true,
    state:() => ( {

        resultInfo: [
            {
                id: 0,
                nameBrand: 'Coca-Cola',
                imgBrand: ['coca-cola.svg'],
                description: 'Дитя - это позитивная личность с оптимистичным взглядом на жизнь. Он стремится вернуться к природе и естественности, излучает жизнелюбие и оптимизм. Верит в то, что жизнь всегда будет простой и беззаботной. В конечном счете он хочет, чтобы все вокруг были счастливы. Он являет собой воплощение чистоты и добродетели. Он вызывает в памяти детские годы чудесные, свободные от бремени и цинизма взрослой жизни.',
                wish: 'Обрести счастье',
                weakness: 'Наивный, отрицает действительность, подавляет неприятные переживания',
                fear: 'Навлечь на себя чей-либо гнев',
                character: 'Жизнелюбивый, доверчивый, невинный, непосредственный, имеет чистые помыслы',
                application: 'Архетип Дитя лучше всего подходит для брендов, которые связаны с добром, нравственностью, простотой, ностальгией или детством. А также компаний, предоставляющих продукты и услуги, связанные с чистотой и здоровьем.\n\nДля архетипа Дитя свойственно живое общение, поэтому уместным будет активное присутствие бренда в социальных сетях. Контент должен быть легким в усвоении, интересным и привлекательным. Проведение интерактивов с подписчиками, конкурсов и акций поможет укрепить взаимодействие с аудиторией и дать возможность лучше узнать о том, что они предпочитают.\n\nВ качестве мероприятий бренда могут выступать музыкальные фестивали, городские праздники, конкурсы талантов. \nДизайн должен отражать жизнелюбие и непосредственность архетипа. Яркий, простой и завлекающий визуал с дружественным и читаемым шрифтом. Необходимо избегать слишком формальных стилей и предпочтительно использовать более игривую коммуникацию',
                typeName: 'Дитя',
                slug: 'ditya',
                counter: 0
            },
            {
                id: 1,
                slug: 'iskatel',
                nameBrand: 'Starbucks',
                imgBrand: ['starbucks.svg'],
                description: 'Основное желание Искателя — изучить мир. Он постоянно странствует, расширяя границы в надежде открыть новые горизонты. Этот архетип характеризуется жаждой свободы, и стремится всегда утвердить свою независимость.\n\nИскателя тенят новым открытиям и приключениям, чувствует себя комфортно в суровой или опасной среде. Он не хочет замыкаться в себе и чувствует, что на него давят ограничения общества. \n\nИскатель вдохновляет людей осмыслить свою жизнь, поставить перед собой вопросы и найти на них ответы. Бренд с архетипом Искатель поощряет свободу мысли, жажду знания и стремление к самосовершенствованию.',
                wish: 'Свобода',
                weakness: 'Отчужденность, сильно отдаляется от общества, может ничего не найти в путешествии',
                fear: 'Оказаться в ловушке, потерять себя, уйти в небыте',
                character: 'Независимый, целе',
                application: 'Для этого архетипа отлично подойдут продукты или услуги, которые помогают клиентам расширить свои горизонты, развиться личностно или найти новые области интересов. \n' +
                    '\n' +
                    'Акцентируйте свою экспертизу и уникальный подход к изучению и дифференцированию информации, предлагая клиентам уникальную возможность обрести новое знание и опыт.\n' +
                    '\n' +
                    'Одним из удачных решений может стать создание платформы для общения и обмена знаниями между клиентами, создавая сообщество, где люди могут делиться своими исследованиями и открытиями.\n' +
                    '\n' +
                    'Компания-Искатель призывает свою аудиторию к осознанному поиску знания и истин, вдохновляет на исследование новых горизонтов и постоянное развитие. \n' +
                    '\n' +
                    'Чтобы найти отклик у аудитории, бренд может воспевать природу и пробуждать жажду приключений. Стоит избегать обыденности и скуки, ведь Искателю нужны яркие впечатления: яхтинг, хайкинг, рафтинг, полеты на параплане и другие способы активно исследовать мир.',
                typeName: 'Искатель',
                counter: 0
            },
            {
                id: 2,
                slug: 'mudrets',
                nameBrand: 'NASA',
                imgBrand: ['nasa.svg'],
                description: 'Мудрец — сосредоточение разума, мудрости и интеллекта. Им движет желание познать истину и поделиться этим знанием с другими. Процесс познания для Мудреца заключается в кропотливых исследованиях, рациональном мышлении, философских беседах и постоянном совершенствовании своих навыков.\n' +
                    '\n' +
                    'Мудрец также знает, что образование не ограничивается учебными предметами и академическими знаниями. Ему важны и моральные ценности, духовность, этика и эстетика. Он верит, что познание должно помочь нам стать лучше и улучшать мир вокруг нас.',
                wish: 'Найти истину и понять этот мир',
                weakness: 'Может вечно изучать вопрос, так и не принять решение, догматизм, гордыня, оторванность от действительности',
                fear: 'Показаться невежественным, стать обманутым',
                character: 'Мудрый, интеллектуальный, вдумчивый, эрудированный, компетентный, интеллигентный, духовный, философ, эксперт',
                application: 'Архетип Мудрец подходит компаниям в сфере образования, научной, исследовательской, технологичной деятельности. Представителям интеллектуальной и культурной среды, а также информационным ресурсам. \n' +
                    '\n' +
                    'Маркетинг «мудрых» брендов скорее возвышенный и ненавязчивый, с некоторым налетом благородства. \n' +
                    '\n' +
                    'Демонстрируйте экспертность и компетенции через публикации в уважаемых изданиях, участие в подкастах и интервью. \n' +
                    '\n' +
                    'Создайте контент, который пропитан знаниями и экспертизой вашей компании. Предлагайте информацию, которая будет нести пользу и просвещение вашей аудитории. Разработайте информационные материалы и ресурсы, которые будут вдохновлять и помогать клиентам становиться более эрудированными. \n' +
                    '\n' +
                    'Общайтесь с вашей аудиторией с уверенностью и глубиной. Используйте формат размышлений и спокойный тон.\n' +
                    '\n' +
                    'Визуал в сдержанной цветовой гамме: серый, бежевый, белый, светло-голубой. Или спокойные и глубокие оттенки: такие как темно-синий или пурпурный. Они ассоциируются с мудростью, стабильностью и глубиной знаний.\n' +
                    '\n' +
                    'Используйте классические и элегантные шрифты, которые придают стиль и утонченность. Это поможет создать впечатление академического авторитета.',
                typeName: 'Мудрец',
                counter: 0
            },
            {
                id: 3,
                slug: 'buntar',
                nameBrand: 'Harley Davidson, Тинькофф',
                imgBrand: ['harley-davidson.svg', 'Тинькофф.svg'],
                description: 'Бунтарь не вписывается в общество и в глубине души не принимает общие ценности. Он нарушает правила и идет против системы, открыто противопоставляя себя обществу. Стремится к отчуждению, и хочет, чтобы его боялись, предпочитает обитать в нелюдимых закоулках. Бунтарь воплощает теневые черты культуры – то есть состоит из качеств, которые общество презирает и осуждает. ',
                wish: 'Освободиться, отомстить или совершить переворот',
                weakness: 'Наглость, неуважение, жестокость, может перейти на темную сторону и стать преступником',
                fear: 'Показаться невежественным, стать обманутым',
                character: 'Неистовый, свободолюбивый, мятежный, выходящий за рамки, остро чувствующий ограничения общества, предвестник перемен, революционер',
                application: 'Архетип Бунтарь выбирают бренды, которые имеют революционный продукт, производит технику или видеоигры, связанные с мощью и разрушением, например, бульдозеры, мотоциклы, различные видеоигры-шутеры, алкоголь и табак. А также те бренды, которые поддерживают покупателей, чувствующих себя не такими, как все.\n' +
                    '\n' +
                    'Этот архетип помогает выпустить накопившееся напряжение, что особенно важно для подростковой аудитории.\n' +
                    '\n' +
                    'В коммуникации зачастую используются агрессивные и брутальные элементы, эстетика различных субкультур, изображение черепов и образ смерти. \n' +
                    '\n' +
                    'В то же время, архетип Бунтаря может быть удачно использован в любых сферах, в случае, если бренд выходит на сложившийся рынок с совершенно революционным продуктом.',
                typeName: 'Бунтарь',
                counter: 0
            },
            {
                id: 4,
                slug: 'mag',
                nameBrand: 'Google, Apple',
                imgBrand: ['google.svg', 'apple.svg'],
                description: 'Маг использует своё понимание скрытой работы Вселенной, чтобы воплотить мечты в реальность. Маг - глубокий мыслитель и надёжный советчик.\n' +
                    '\n' +
                    'Он являет собой символ мудрости, знания и волшебства, стремится к познанию глубинных истин и расширению своего сознания.\n' +
                    '\n' +
                    'Он обладает особым даром и с его помощью улучшает мир и жизнь людей. Считает, что границы находятся только в голове, — все возможно, стоит лишь пожелать. Маг помогает измениться внешне или внутренне. \n',
                wish: 'Раскрыть истинную сущность и тайны мира.',
                weakness: 'Рискованность, манипулирование, хитрость.',
                fear: 'Причинить ущерб и неприятности своими действиями',
                character: 'Интеллектуальный, динамичный, харизматичный, умный, имеет видение жизни и интуицию, воображение, идеалист, умеет находить решение, которое устроит всех, таинственный, загадочный.',
                application: 'Архетип Маг может быть использован в бренде компании, которая стремится к инновациям, исследованиям и передовым технологиям или обращается к потребностям духовного познания мира.\n' +
                    '\n' +
                    'Атрибутами бренда могут быть экспертность в своей области, научность, изобретательность, технологичность, трансформация, преображение. \n' +
                    '\n' +
                    'В любом случае стоит подчеркивать способность преобразовывать и улучшать жизнь своих клиентов, демонстрировать магические результаты – будь то мгновенные денежные переводы по всему миру или волшебное преображение от нанесения крема.\n' +
                    '\n' +
                    'Хорошим приемом для транслирования архетипа Мага, может быть выбран путь от обратного, когда компания предлагает представить мир без своего продукта или услуги. \n' +
                    '\n' +
                    'В визуальной коммуникации могут быть использованы символы энергии природы, таких как огонь, воздух, вода и земля. Может присутствовать атрибут книги, пергамента, свитка или магических артефактов: волшебные посохи, зелья, амулеты или древние символы.',
                typeName: 'Маг',
                counter: 0
            },
            {
                id: 5,
                slug: 'geroy',
                nameBrand: 'Nike',
                imgBrand: ['nike.svg'],
                description: 'Герой - это спаситель и искупитель общества. Отличаясь мужеством, самопожертвованием и достижениями, Герой стремится к личностной трансформации в своём стремлении одержать победу над невзгодами.\n' +
                    '\n' +
                    'Он отличается силой, храбростью и решительностью, способностью справиться с любыми трудностями и врагами. Он может быть воином, защитником, предводителем или просто тем, кто противостоит злу и несправедливости.\n' +
                    '\n' +
                    'Герой часто становится символом надежды и вдохновения для других людей. Его победы и достижения стимулируют других на подвиги, побуждают их бороться за справедливость и свободу.',
                wish: 'Преодолеть трудности и проявить себя',
                weakness: 'Высокомерие, вечный поиск врагов, неумение успокоиться',
                fear: 'Стать уязвимым и слабым',
                character: 'Сильный, волевой, справедливый, отважный, честный, способен жертвовать своими интересами ради блага других.',
                application: 'Чтобы выразить архетип Героя в бренде компании нужно создать впечатление смелости и стойкости. Для этого можно использовать яркие и насыщенные цвета, смелые шрифты и узнаваемые графические формы. \n' +
                    '\n' +
                    'Позиционируйте компанию как надежного партнера, готового помочь клиентам преодолеть сложности и достичь успеха. \n' +
                    '\n' +
                    'Так как именно архетип Героя тесно связан с легендами и мифами, будет уместно рассказывать историю бренда. Используйте наглядные примеры побед, смелые и вдохновляющие истории своих клиентов. \n' +
                    '\n' +
                    'Добавляйте аллегории, которые помогут связать вашу компанию с героическими качествами. \n' +
                    '\n' +
                    'Важно, чтобы рассказ подтверждался фактами, поэтому будьте готовы яро защищать и помогать клиентам на практике.',
                typeName: 'Герой',
                counter: 0
            },
            {
                id: 6,
                slug: 'pravitel',
                nameBrand: 'Rolls-Royce',
                imgBrand: ['rolls-royce.svg'],
                description: 'Лидер, обладающий силой и властью. Этот архетип отличается стремлением к контролю и управлению, его основное желание - установить порядок и стабильность на благо общества.\n' +
                    '\n' +
                    'Он часто распознается по своему авторитетному и властному облику, а также рациональному мышлению и принятию жестких решений для достижения поставленных целей.',
                wish: 'Управлять.',
                weakness: 'Неумение делегировать, тотальный контроль, может быть тираничным.',
                fear: 'Хаос, быть свергнутым.',
                character: 'Квалифицированный, действительный, эффективный, продуктивный, уверенный, ответственный, управленец, организатор.',
                application: 'Архетип Правителя может быть эффективно применен в брендинге юридической компании, чтобы создать образ авторитетности, надежности и стабильности.\n' +
                    '\n' +
                    '• Логотип и дизайн: элегантный дизайн, который выражает власть и стабильность. Использование прямых, четких линий, сдержанных цветов, таких как синий, черный или золотой, и стилизованных элементов, отсылающих к символам власти.\n' +
                    '\n' +
                    '• Позиционирование: подчеркивание авторитета и экспертизы, высоких стандартов, наличие дипломов, сертификатов, наград и статусов.\n' +
                    '\n' +
                    'Чтобы нивелировать слабости архетипа Правитель, стоит участвовать в благотворительности и социальных проектах.\n' +
                    '\n' +
                    'Успешное использование архетипа Правителя в бренде компании поможет придать ему авторитет и надежность, привлекая клиентов, которые ценят стабильность и качество.\n',
                typeName: 'Правитель',
                counter: 0
            },
            {
                id: 7,
                slug: 'tvorets',
                nameBrand: 'Lego, Adobe',
                imgBrand: ['adobe.svg','lego.svg'],
                description: 'Воплощение творческой и инновационной личности, стремится к постоянному развитию и совершенствованию своих произведений. Он может считать себя ценителем свободы, однако для него свобода это скорее желание почувствовать себя Богом, создавая то, на что никто иной не способен. Он отличается оригинальностью и интеллектуальностью, способностью воплощать новые идеи в жизнь.\n' +
                    '\n' +
                    'Творец обладает глубоким пониманием современных тенденций. Открыт для новых идей, готов экспериментировать и рисковать, чтобы достичь прогресса и инноваций.\n' +
                    '\n' +
                    'В глубине души любой творец мечтает создать что-то великое, чтобы обрести бессмертие через свое творение. \n' +
                    '\n' +
                    'Творцы тяжело переживают критику со стороны, так как им достаточно самокритики – они зачастую считают, что их творения не совершенны.',
                wish: 'Создать что-то вечное',
                weakness: 'Непрактичность, бесконечная доработка, создание не того, что задумал.',
                fear: 'Мыслить и действовать как все',
                character: 'Креативный, удивляющий, вдохновляющий на творчество, новатор',
                application: 'Бренд, основанный на архетипе Творца, обычно предлагает инновационные решения и продукты, которые удивляют своей уникальностью. Чтобы отстроиться от конкурентов, эффективным решением будет демонстрировать креативный подход, небанальные решения во всех аспектах коммуникации. Подчеркнуть, что бренд ставит перед собой задачу создавать что-то новое и оригинальное.\n' +
                    '\n' +
                    'Зачастую Творец вдохновляет и стремится помочь клиентам раскрыть свой творческий потенциал. Уместным будет акцентировать внимание на процессе творчества, демонстрируя, как именно бренд создает свои продукты или услуги.\n' +
                    '\n' +
                    'Визуальная и сенсорная составляющая крайне важна для Творца, поэтому стоит уделить особе внимание не только необычной цветовой палитре, но и формам, текстурам и возможно даже ароматам, сопровождающим бренд. \n' +
                    '\n' +
                    'Важно помнить, что для выражения архетипа творца в позиционировании бренда необходимо быть последовательным и соответствовать этому образу во всех аспектах, включая элементы корпоративной культуры, способствующие созданию атмосферы свободного самовыражения.',
                typeName: 'Творец',
                counter: 0
            },
            {
                id: 8,
                slug: 'sluzhitel',
                nameBrand: 'Johnson&Johnson',
                imgBrand: ['johnson.svg'],
                description: 'Служителем движет желание помогать и спасать окружающих. \n' +
                    '\n' +
                    'Он опасается нестабильности и трудностей, но не столько для себя лично, сколько для других людей.  Он считает своей обязанностью бескорыстно заботиться и ухаживает. \n' +
                    '\n' +
                    'Заботливый стремится поддержать всех, кому это необходимо, для него свойственно самопожертвование, щедрость, эмпатия и сочувствие.',
                wish: 'Уберечь людей от вреда, помогать',
                weakness: 'Неумение отказывать, навязчивость, приносит себя в жертву и настойчиво требует отдачи, мученичество.',
                fear: 'Получить эгоизм и неблагодарность.',
                character: 'Щедрый, сострадающий, эмпатичный, заботливый, альтруист.',
                application: 'Архетип Служитель отлично подходит для брендов, которые хотят построить доверительные и долгосрочные отношения с клиентом, предлагая услуги и продукты, заботящиеся о потребителе. \n' +
                    '\n' +
                    'Бренд может быть представлен как надежный компетентный помощник, на которого можно положиться в любой ситуации, который готов ответить на любой вопрос и решить любую проблему.\n' +
                    '\n' +
                    'Чтобы реализовывать эту стратегию важно обратить внимание на отличный клиентский сервис, доступный 24/7 и хранящий всю историю коммуникаций с клиентом.\n' +
                    '\n' +
                    'Удачным шагом может быть демонстрация социальной ответственности.  Поддерживайте благотворительные организации или инициируйте собственные добрые дела, чтобы укрепить связь с целевой аудиторией.\n' +
                    '\n' +
                    'Подчеркните свою приверженность определенным местным сообществам, активно участвовать в развитии и поддержке местных инициатив.\n' +
                    '\n' +
                    'В дизайне стоит выбирать мякгие линии и текстуры, теплые, глубокие оттенки, создающие атмосферу доверия.',
                typeName: 'Служитель',
                counter: 0
            },
            {
                id: 9,
                slug: 'shut',
                nameBrand: 'TikTok, Old Spice',
                imgBrand: ['old-spice.svg','tik-tok.svg'],
                description: 'Шут обожает жизнь и общение и выступает за то, чтобы никто никому не указывал. Шут помогает смириться с нелепостью этого мира, ведь Шут, с одной стороны, умеет разрядить обстановку, а с другой – с радостью нарушает правила. Шуту важно созвать людей и устроить представление.',
                wish: 'Наслаждаться жизнью здесь и сейчас.',
                weakness: 'Наглость, безрассудство, ничего не воспринимает всерьез, может прожить жизнь впустую',
                fear: 'Стать скучным и обыденным.',
                character: 'Изобретательный, оригинальный, острый на язык, сатирический, озорной.',
                application: 'Архетип Шута помогает потребителю разнообразить то, что является скучным. \n' +
                    '\n' +
                    'И поскольку Шут обожает общение, то чаще всего этот архетип используют в товарах и услугах массового спроса. Например, шутовские бренды предлагают позабыть о последствиях безответственного или нездорового поведения, превращая вредное в полезное - с помощью юмора. \n' +
                    '\n' +
                    'Он отлично подходит бренду, желающему противопоставить себя уже существующему успешному конкуренту, высмеивая его напыщенность. Как когда-то шуты высмеивали «королей». \n' +
                    '\n' +
                    'Или даже может предложить посмеяться над чем-то печальным. \n' +
                    '\n' +
                    'В визуале Шута непременно присутствуют яркие и оригинальные цвета и формы, коллажи и нереалистичные ситуации. Уместно применить игру слов и отсылки к популярным мемам.',
                typeName: 'Шут',
                counter: 0
            },
            {
                id: 10,
                slug: 'lyubovnik',
                nameBrand: 'Chanel, Tiffany&Co',
                imgBrand: ['chanel.svg', 'tiffany.svg'],
                description: 'Архетип любовника отвечает за всевозможные виды любви – начиная от любви родительской, продолжая дружеской и духовной, и, конечно, романтической. Любовнику важно знать, что он особенный, единственный и неповторимый. Поэтому ему важно выглядеть красиво и привлекательно, он хочет вызывать влечение, дарить любовь и выражать привязанность. В своем стремлении он готов идти на любые подвиги и жертвы. Любовник желает качественно наслаждаться жизнью. Кажется, без этого архетипа, в разном своем проявлении, не обходится ни одно художественное произведение. ',
                wish: 'Добиться близости и испытывать чувственное наслаждение.',
                weakness: 'Одержимость, ревность, неуверенность в себе.',
                fear: 'Остаться в одиночестве, не найти подходящего человека, не заслужить любви.',
                character: 'Эмоциональный, пылкий, влюбчивый, наполнен жизненной силой, признательный, соблазнительный',
                application: 'Архетип Любовник, несомненно, подходит компаниям, связанным с индустрией моды, красоты и получение различных чувственных опытов – таких как гастрономия, алкоголь или спортивные автомобили. А также тем компаниям, чьи продукты и услуги нацелены на создание близкой связи между людьми (духовной или телесной). Архетип пробуждает в человеке тягу к прекрасному, поэтому для создания бренда важно уделить внимание эстетике и передать чувственность. \n' +
                    '\n' +
                    'Стоит сделать акцент на индивидуальность, эмоциональную связь и притяжение, дать покупателям почувствовать себя особенными и важными. \n' +
                    '\n' +
                    'Зачастую в коммуникации брендов-любовников используют яркие сексуальные образы, даже в продвижении товаров, не связанных напрямую с романтическими отношениями.',
                typeName: 'Любовник',
                counter: 0
            },
            {
                id: 11,
                slug: 'slavnyiy-malyiy',
                nameBrand: 'IKEA',
                imgBrand: ['IKEA.svg'],
                description: 'Славный малый – сосредоточение добродетелей обычного человека. Он убежден: все люди, равно как и я – ценны. Быть «своим» это лучший триггер для архетипа и когда он достигает этого, то хочет насладиться спокойствием и постоянством. Архетип Славного малого проникнут естественностью, поскольку стремиться всех уравнять. Он не только болеет за «своих», но и за того, кто находится в заведомо невыгодном положении. Он не терпит никакого притворства, равно как и желания жить в роскоши.',
                wish: 'Держаться окружающих.',
                weakness: 'Может переступить через себя ради признания общества, а в итоге получит лишь видимость поддержки.',
                fear: 'Выделяться, зазнаться и лишиться признания, стать изгоем.',
                character: 'Здравомыслящий, умеет сопереживать, непритязательный, любит все простое и настоящее, искренний, обыватель.',
                application: 'Архетип Славный Малый идеально подходит для брендинга компаний, которые производят товары и услуги повседневного спроса в экономичном и массовом сегменте. Обычно они связаны с детьми, семьями и домашними животными. Этот архетип помогает людям испытать чувство принадлежности к комьюнити, создать эмоциональную привязанность и доверие. \n' +
                    '\n' +
                    'Визуал в духе «Славный малый» формируется за счет простых, понятных и непритязательных форм и символов. Разумно будет выбрать достаточно яркую цветовую гамму, чтобы выделяться среди обилия конкурентов в этом сегменте. \n' +
                    '\n' +
                    'Коммуникация должна нести идею поддержки, сплоченности, народности и признания важности каждого. Стоит избегать назидательного и поучительного тона, а вот применение самоуничижительного юмора, будет вполне оправдано, и создаст ощущение «настоящего» и «своего» бренда. Используйте социальные сети и службы клиентского сервиса, чтобы выстроить двустороннюю коммуникацию с клиентами. Они должны почувствовать компанию частью сообщества, такой же как они сами. ',
                typeName: 'Славный малый',
                counter: 0
            }
        ],
        activeQuestion: -1,
        activeResult: false,

        resultQuizAnswer: [
            {
                id: 0,
                answer: -1
            },
            {
                id: 1,
                answer: -1
            },
            {
                id: 2,
                answer: -1
            },
            {
                id: 3,
                answer: -1
            },
            {
                id: 4,
                answer: -1
            },
            {
                id: 5,
                answer: -1
            },
            {
                id: 6,
                answer: -1
            }
        ],
        quizInfo: [
            {
                id: 0,
                activeAnswer: false,
                mainTitle: 'К какому ценовому сегменту относится ваш продукт или услуга?',
                price: 5,
                quizItem: [

                    {
                        id: 0,
                        active: false,
                        typeResult: [2,4,6,7,10],
                        text: 'Премиальный'
                    },
                    {
                        id: 1,
                        active: false,
                        typeResult: [1,2,3,4,5,6,7,10],
                        text: 'Средний высокий'
                    },
                    {
                        id: 2,
                        active: false,
                        typeResult: [1,3,4,5,8,9,11],
                        text: 'Средний низкий'
                    },
                    {
                        id: 3,
                        active: false,
                        typeResult: [0,3,8,9,11],
                        text: 'Экономичный'
                    }
                ]
            },
            {
                id: 1,
                price: 10,
                activeAnswer: false,
                mainTitle: 'В чем главная черта характера вашего бренда?',
                quizItem: [
                    {
                        id: 0,
                        active: false,
                        typeResult: [0],
                        text: 'Чистота помыслов и доброта'
                    },
                    {
                        id: 1,
                        active: false,
                        typeResult: [1],
                        text: 'Свобода'
                    },
                    {
                        id: 2,
                        active: false,
                        typeResult: [2],
                        text: 'Мудрость'
                    },
                    {
                        id: 3,
                        active: false,
                        typeResult: [3],
                        text: 'Инициация перемен'
                    },
                    {
                        id: 4,
                        active: false,
                        typeResult: [4],
                        text: 'Глобальное видение'
                    },
                    {
                        id: 5,
                        active: false,
                        typeResult: [5],
                        text: 'Честь и мужество'
                    },
                    {
                        id: 6,
                        active: false,
                        typeResult: [7],
                        text: 'Креативность'
                    },
                    {
                        id: 7,
                        active: false,
                        typeResult: [8],
                        text: 'Забота и сострадание'
                    },
                    {
                        id: 8,
                        active: false,
                        typeResult: [9],
                        text: 'Юмор и оригинальность'
                    },
                    {
                        id: 9,
                        active: false,
                        typeResult: [10],
                        text: 'Чувственность'
                    },
                    {
                        id: 10,
                        active: false,
                        typeResult: [11],
                        text: 'Верность'
                    },
                ]
            },
            {
                id: 2,
                price: 10,
                activeAnswer: false,
                mainTitle: 'Кем ваша компания хочет быть для клиента?',
                quizItem: [
                    {
                        id: 0,
                        active: false,
                        typeResult: [11],
                        text: 'Лучшим другом'
                    },
                    {
                        id: 1,
                        active: false,
                        typeResult: [2],
                        text: 'Экспертом'
                    },
                    {
                        id: 2,
                        active: false,
                        typeResult: [5],
                        text: 'Героем'
                    },
                    {
                        id: 3,
                        active: false,
                        typeResult: [7],
                        text: 'Вдохновением'
                    },
                    {
                        id: 4,
                        active: false,
                        typeResult: [3],
                        text: 'Революцией'
                    },
                    {
                        id: 5,
                        active: false,
                        typeResult: [1],
                        text: 'Исследователем'
                    },
                    {
                        id: 6,
                        active: false,
                        typeResult: [8],
                        text: 'Помощником'
                    },
                    {
                        id: 7,
                        active: false,
                        typeResult: [6],
                        text: 'Наставником'
                    },
                    {
                        id: 8,
                        active: false,
                        typeResult: [0],
                        text: 'Ностальгией'
                    },
                    {
                        id: 9,
                        active: false,
                        typeResult: [10],
                        text: 'Второй половинкой'
                    },
                    {
                        id: 10,
                        active: false,
                        typeResult: [4],
                        text: 'Чудом'
                    },
                    {
                        id: 11,
                        active: false,
                        typeResult: [9],
                        text: 'Развлечением'
                    },
                ]
            },
            {
                id: 3,
                price: 30,
                activeAnswer: false,
                mainTitle: 'Ваша компания помогает людям...',
                quizItem: [
                    {
                        id: 0,
                        active: false,
                        typeResult: [0,1,2],
                        text: 'Обрести счастье'
                    },
                    {
                        id: 1,
                        active: false,
                        typeResult: [3,4,5],
                        text: 'Добиваться цели'
                    },
                    {
                        id: 2,
                        active: false,
                        typeResult: [6,7,8],
                        text: 'Чувствовать себя в безопасности'
                    },
                    {
                        id: 3,
                        active: false,
                        typeResult: [9,10,11],
                        text: 'Иметь любовь или привязанность'
                    }
                ]
            },
            {
                id: 4,
                price: 20,
                activeAnswer: false,
                mainTitle: 'Какой базовый мотив движет брендом?',
                quizItem: [
                    {
                        id: 0,
                        active: false,
                        typeResult: [0,1,2],
                        text: 'Поиск лучшего мира'
                    },
                    {
                        id: 1,
                        active: false,
                        typeResult: [3,4,5],
                        text: 'Оставить после себя наследие'
                    },
                    {
                        id: 2,
                        active: false,
                        typeResult: [9,10,11],
                        text: 'Быть в гармонии с миром'
                    },
                    {
                        id: 3,
                        active: false,
                        typeResult: [6,7,8],
                        text: 'Структурировать и улучшить мир'
                    }
                ]
            },
            {
                id: 5,
                price: 17,
                activeAnswer: false,
                mainTitle: 'Какая цель у вашего бренда?',
                quizItem: [
                    {
                        id: 0,
                        active: false,
                        typeResult: [0],
                        text: 'Быть счастливым'
                    },
                    {
                        id: 1,
                        active: false,
                        typeResult: [1],
                        text: 'Открывать новое'
                    },
                    {
                        id: 2,
                        active: false,
                        typeResult: [2],
                        text: 'Понять мир'
                    },
                    {
                        id: 3,
                        active: false,
                        typeResult: [3],
                        text: 'Нарушать правила'
                    },
                    {
                        id: 4,
                        active: false,
                        typeResult: [4],
                        text: 'Воплощать мечты в жизнь'
                    },
                    {
                        id: 5,
                        active: false,
                        typeResult: [5],
                        text: 'Улучшить мир'
                    },
                    {
                        id: 6,
                        active: false,
                        typeResult: [6],
                        text: 'Создать процветание и успех'
                    },
                    {
                        id: 7,
                        active: false,
                        typeResult: [7],
                        text: 'Реализовать творческие идеи'
                    },
                    {
                        id: 8,
                        active: false,
                        typeResult: [8],
                        text: 'Помогать людям'
                    },
                    {
                        id: 9,
                        active: false,
                        typeResult: [9],
                        text: 'Нести радость'
                    },
                    {
                        id: 10,
                        active: false,
                        typeResult: [10],
                        text: 'Построить близкие отношения'
                    },
                    {
                        id: 11,
                        active: false,
                        typeResult: [11],
                        text: 'Быть частью социума'
                    },
                ]
            },    {
                id: 6,
                price: 10,
                activeAnswer: false,
                mainTitle: 'Чего больше всего боится ваш бренд?',
                quizItem: [
                    {
                        id: 0,
                        active: false,
                        typeResult: [0],
                        text: 'Навлечь на себя гнев'
                    },
                    {
                        id: 1,
                        active: false,
                        typeResult: [1],
                        text: 'Оказаться в ловушке'
                    },
                    {
                        id: 2,
                        active: false,
                        typeResult: [2],
                        text: 'Показаться невежественным'
                    },
                    {
                        id: 3,
                        active: false,
                        typeResult: [3],
                        text: 'Быть беспомощным'
                    },
                    {
                        id: 4,
                        active: false,
                        typeResult: [4],
                        text: 'Неожиданные негативные\n' +
                            'последствия своих действий'
                    },
                    {
                        id: 5,
                        active: false,
                        typeResult: [5],
                        text: 'Стать уязвимым, струсить'
                    },
                    {
                        id: 6,
                        active: false,
                        typeResult: [6],
                        text: 'Беспорядок и бунт'
                    },
                    {
                        id: 7,
                        active: false,
                        typeResult: [7],
                        text: 'Быть как все'
                    },
                    {
                        id: 8,
                        active: false,
                        typeResult: [8],
                        text: 'Неблагодарность'
                    },
                    {
                        id: 9,
                        active: false,
                        typeResult: [9],
                        text: 'Заскучать или наскучить'
                    },
                    {
                        id: 10,
                        active: false,
                        typeResult: [10],
                        text: 'Остаться в одиночестве'
                    },
                    {
                        id: 11,
                        active: false,
                        typeResult: [11],
                        text: 'Стать изгоем'
                    },
                ]
            }
        ]
    }),
    mutations: {
        SET_RESULT_QUIZ_ANSWER: (state,payload) => {
            state.resultQuizAnswer[state.activeQuestion].answer = payload;
        },

        SET_QUIZ_INFO_ACTIVE: (state,payload) => {
            state.quizInfo[state.activeQuestion].quizItem[state.resultQuizAnswer[state.activeQuestion].answer].active = payload;
        },

        SET_ACTIVE_ANSWER: (state,payload) => {
            state.quizInfo[state.activeQuestion].activeAnswer = payload
        },


        SET_ACTIVE_QUESTION: (state, payload) => {
            state.activeQuestion = payload
        },

        SET_ID_RESULT: (state, payload) => {
            if(payload === true) {
                for (let i = 0; i < 7; i++) {
                    state.quizInfo[i].quizItem.forEach(item => {
                        if(state.resultQuizAnswer[i].answer === item.id) {
                            state.quizInfo[i].quizItem[item.id].typeResult.forEach(itemType => {
                                state.resultInfo[itemType].counter += state.quizInfo[i].price;
                            })
                        }
                    })
                }

                let max = state.resultInfo[0].counter;
                let slug = 'ditya';
                for (let i = 1; i < state.resultInfo.length; i++) {
                    if (state.resultInfo[i].counter > max) {
                        max = state.resultInfo[i].counter;
                        slug = state.resultInfo[i].slug
                    }
                }
                state.slugResult = slug
                if(slug !== undefined) {
                    state.resultQuizAnswer.forEach(item => {
                        item.answer = -1
                    })
                    state.quizInfo.forEach(item => {
                        item.activeAnswer = false
                        item.quizItem.forEach(item => {
                            item.active = false
                        })
                    })
                    router.push({name: 'quiz-result', params: {result: slug}})
                }
            }
        },
    },
    actions:  {

        RESULT_QUIZ_ANSWER: (context, payload) => {
            context.commit('SET_RESULT_QUIZ_ANSWER', payload)
        },

        QUIZ_INFO_ACTIVE: (context, payload) => {
            context.commit('SET_QUIZ_INFO_ACTIVE', payload)
        },
        
        ACTIVE_ANSWER: (context, payload) => {
            context.commit('SET_ACTIVE_ANSWER', payload)
        },

        ACTIVE_QUESTION: (context, payload) => {
            context.commit('SET_ACTIVE_QUESTION', payload)
        },

        ID_RESULT: (context, payload) => {
            context.commit('SET_ID_RESULT', payload)
        }

    }
}