<template>
    <main class="main-wrapper">
      <start-screen/>
      <!--    <video-screen/>-->
      <about-screen/>
      <project-screen id="section-0"/>
      <service-screen id="section-1"/>
      <team-screen id="section-2"/>
      <news-screen id="section-3"/>
      <partners-screen id="section-4"/>
      <visioners-screen />
      <contacts-screen id="section-5"/>
    </main>
</template>

<script>
import StartScreen from "@/components/features/main-page/StartScreen.vue";
import VideoScreen from "@/components/features/main-page/VideoScreen.vue";
import AboutScreen from "@/components/features/main-page/AboutScreen.vue";
import ProjectScreen from "@/components/features/main-page/ProjectScreen.vue";
import ServiceScreen from "@/components/features/main-page/ServiceScreen.vue";
import TeamScreen from "@/components/features/main-page/TeamScreen.vue";
import NewsScreen from "@/components/features/main-page/NewsScreen.vue";
import PartnersScreen from "@/components/features/main-page/PartnersScreen.vue";
import ContactsScreen from "@/components/features/main-page/ContactsScreen.vue";
import VisionersScreen from "@/components/features/main-page/VisionersScreen.vue";

export default {
  name: "main-page",
  components: {
    VisionersScreen,
    ContactsScreen,
    PartnersScreen,
    NewsScreen, TeamScreen, ServiceScreen, ProjectScreen, AboutScreen, VideoScreen, StartScreen},

}
</script>

<style scoped lang="scss">
 main {
   display: flex;
   flex-direction: column;
   position: relative;
 }

</style>