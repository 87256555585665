<template>
  <header class="main-header-container" v-if="!activeMobile && !activeFixed">
    <menu-item :menu-info="menuInfo.slice(0,3)"
               :active-index="activeIndex"
               @onItemChanged="onItemChanged"
               @scrollTo="changePage"
               :offset="256"/>
    <div class="logo-container" @click="scrollToTop">
      <img :src="logoImg" alt="logo">
    </div>
    <menu-item :menu-info="menuInfo.slice(3,6)"
               :active-index="activeIndex"
               @onItemChanged="onItemChanged"
               @scrollTo="changePage"
               :offset="256"/>
  </header>

  <header-fixed v-else-if="!activeMobile && activeFixed"
                :active="active"
                :active-index="activeIndex"
                @onItemChanged="onItemChanged"
                @scrollTo="changePage"
                :menu-info="menuInfo"/>

  <header class="main-header-container" v-else>
    <div class="logo-container" @click="scrollToTopMobile">
      <img :src="getUrlLogo(logoImg)" alt="logo">
    </div>

    <transition name="fade-opacity-lazy" mode="out-in">
      <div class="burger-button-container" v-if="$route.name !== 'quiz-loading'"
           :class="{'active-burger' : !activeMenu}"
           @click="changeStateMenu">
        <transition name="fade-rotate">
          <img v-if="activeMenu" :src="getUrlIcons('cross-hover.svg')" alt="icons">
          <img v-else  :src="getUrlIcons('menu-burger-icon.svg')" alt="icons">
        </transition>
      </div>

      <div class="burger-text-container" v-else>
        <main-paragraph>
          <transition name="fade-opacity-lazy" mode="out-in">
                <span style="position: relative" :key="$route.params.id">
                  {{ Number($route.params.id) + 1 }}
                </span>
          </transition>
          {{ textQuestion }}
        </main-paragraph>
      </div>
    </transition>


    <transition name="fade-translate-x-card-revert">
      <div class="menu-burger-container" v-show="activeMenu">

        <menu-item :menu-info="menuInfo" @onItemChanged="onItemChanged" @scrollTo="changePageMobile"/>

        <contact-mobile/>
      </div>
    </transition>
  </header>
</template>

<script>
import MainParagraph from "@/components/shared/text/MainParagraph.vue";
import ButtonWithArrow from "@/components/shared/ButtonWithArrow.vue";
import ContactMobile from "@/components/entities/ContactMobile.vue";
import MenuItem from "@/components/entities/MenuItem.vue";
import HeaderFixed from "@/components/entities/HeaderFixed.vue";

export default {
  name: "MainHeader",
  components: {HeaderFixed, MenuItem, ContactMobile, ButtonWithArrow, MainParagraph},
  props: {
    activeFixed: {
      type: Boolean
    },
    active: {
      type: Boolean
    }
  },
  data() {
    return {
      textQuestion: 'вопрос',
      activeIndex: -1,
      activeMenu: false,
      logoImg: 'look.svg',
      menuInfo: [
        {
          id: 0,
          text: 'Проекты',
          rout: '/'
        },
        {
          id: 1,
          text: 'Услуги',
          rout: '/'
        },
        {
          id: 2,
          text: 'Лидеры',
          rout: '/'
        },
        {
          id: 3,
          text: 'Новости',
          rout: '/'
        },
        {
          id: 4,
          text: 'Партнеры',
          rout: '/'
        },
        {
          id: 5,
          text: 'Контакты',
          rout: '/'
        }
      ],
      prevTimer: null
    }
  },
  methods: {
    changeStateMenu() {
      this.activeMenu = !this.activeMenu
      this.$emit('changeStateMenu', this.activeMenu)
    },

    changePage(id) {
      if(this.$route.name === 'home') {
        this.scrollTo(id);
      } else {
         this.checkScrollY();
          this.$emit('scrollTo')
          setTimeout(() => {
            this.$router.push('/').then(r => {
              setTimeout(() => {
                this.scrollTo(id);
              }, 750)
            })
          }, 500)
      }
    },

    checkScrollY() {
      if(window.scrollY !== 0) {
        window.scrollTo({
          top: 0,
          behavior: "smooth",
        });
      }
    },

    changePageMobile(id) {
      if(this.$route.name === 'home') {
        this.changeStateMenu();
        this.scrollToMobile(id, 0);
      } else {
        this.$router.push('/')
        setTimeout(() => {
          this.scrollToMobile(id, 100);
        }, 100)
      }
    },

    scrollTo(id) {
      let parent = document.querySelector('.main-wrapper');
        parent.querySelector('#section-' + id).scrollIntoView({
          behavior: 'smooth',
        });
    },

    scrollToMobile(id, timer) {
      setTimeout(() => {
        let parent = document.querySelector('.main-wrapper');
        let block = parent.querySelector('#section-' + id)
        window.scrollTo({
          top: block?.offsetTop - 56,
        })
        if(timer !== 0) {
          this.changeStateMenu();
        }
      }, timer)

    },

    scrollToTopMobile() {
      if(this.activeMenu) {
        this.activeMenu = false
        if(this.$route.name === 'home') {
          window.scrollTo({
            top: 0,
          });
        } else {
          this.$router.push('/')
        }
      } else {
        this.scrollToTop()
      }
    },


    onItemChanged(event, currentItem) {
      if(currentItem !== undefined) {
        this.activeIndex = Number(currentItem.href.toString().slice(-1))
      } else {
        this.activeIndex = -1
      }
    },
  }
}
</script>

<style scoped lang="scss">



  .main-header-container {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    width: fit-content;
    background: rgba(var(--color-rgba-dark), 0.20);

    &.active-fixed {
      z-index: 900;
      position: fixed;
      background: rgba(var(--color-rgba-dark), 0.40);
    }

    .scrollactive-item {
      &.active {
        .main-paragraph-container {
          opacity: 1;
        }
      }
    }


    .logo-container {
      cursor: pointer;
      width: 100%;
      height: 100%;

      & img {
        width: 100%;
        height: 100%;
      }
    }
  }


  @media (max-width: 3024px) and (min-width: 1921px){
    .main-header-container {
      column-gap: 56px;
      padding: 26px 48px;
      border-radius: 20px;

      &.active-fixed {
        padding: 26px 40px;
        top: 42px;
        left: 42px;
      }

      .menu-content {
        column-gap: 56px;
      }

      .main-paragraph-container {
        font-size: 26px;
      }

      .logo-container {
        width: 140px;
        height: 48px;
      }
    }
  }


  @media (max-width: 1920px) {
    .main-header-container {
      column-gap: 50px;
      padding: 22px 48px;
      border-radius: 16px;
      .menu-content {
        column-gap: 50px;
      }
      &.active-fixed {
        padding: 22px 32px;
        top: 40px;
        left: 40px;
      }
      .logo-container {
        width: 102px;
        height: 34px;
      }
    }
  }


  @media (max-width: 1600px) {
    .main-header-container {
      column-gap: 48px;
      padding: 18px 32px;
      border-radius: 12px;

      &.active-fixed {
        padding: 24px 18px;
        top: 18px;
        left: 18px;
      }

      .main-paragraph-container {
        font-size: 18px;
      }

      .menu-content {
        column-gap: 48px;
      }


      .logo-container {
        width: 77px;
        height: 26px;
      }
    }
  }

  @media (max-height: 800px) {
    .main-header-container {
      column-gap: 48px;
      padding: 16px 28px;
      border-radius: 10px;

      &.active-fixed {
        left: 24px;
        top: 24px;
      }

      .main-paragraph-container {
        font-size: 16px;
      }
      .menu-content {
        column-gap: 48px;
      }


      .logo-container {
        width: 77px;
        height: 26px;
      }
    }
  }

  @media (max-width: 1400px) and (min-height: 801px) {
    .main-header-container {
      column-gap: 48px;
      padding: 18px 32px;
      border-radius: 12px;

      .main-paragraph-container {
        font-size: 18px;
      }
      .menu-content {
        column-gap: 48px;
      }
      &.active-fixed {
        top: 40px;
      }
      .logo-container {
        width: 77px;
        height: 26px;
      }
    }
  }

  @media (max-width: 1133px) {
    .main-header-container {
      column-gap: 48px;
      padding: 16px 28px;
      border-radius: 10px;

      &.active-fixed {
        left: 24px;
        top: 24px;
      }


      .main-paragraph-container {
        font-size: 16px;
      }
      .menu-content {
        column-gap: 48px;
      }
      .logo-container {
        width: 77px;
        height: 26px;
      }
    }
  }

  @media (max-width: 769px) {
    .menu-content {
      flex-direction: column;
      width: 100%;
    }
    .main-header-container {
      z-index: 950;
      position: fixed;
      top: 0;
      padding: 18px 16px;
      height: 56px;
      width: 100%;
      justify-content: space-between;
      column-gap: 0;
      border-radius: 0;
      background: var(--color-main-dark-color);
      &.active-fixed {
        top: 0;
      }
      .logo-container {
        z-index: 951;
        position: relative;
        width: 61px;
        height: 21px;
      }

      .burger-button-container {
        width: 18px;
        height: 18px;
        position: relative;
        z-index: 951;

        & img {
          object-fit: contain;
          width: 100%;
          height: 100%;
        }
      }

      .menu-burger-container {
        position: absolute;
        width: var(--calc-width);
        height: var(--calc-height);
        top: 0;
        left: 0;
        background-color: var(--color-main-dark-color);
        padding-top: 76px;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        padding-bottom: 63px;
      }
    }
  }

  @media (max-width: 769px)  and (min-height: 720px) and (max-height: 1000px) {
    .main-header-container {
      .menu-burger-container {
        padding-bottom: 75px;
      }
    }
  }

  @media (min-height: 600px) and (max-height: 630px){

    //8 plus
  }

  @media (max-width: 375px) {
    .main-header-container {

      .menu-burger-container {
        padding-bottom: 22.5px;
      }
    }
  }

  @media (max-width: 375px) and (min-height: 630px) {
    .main-header-container {

      .info-container-contact {
        display: flex;
      }


      .menu-burger-container {
        padding-bottom: 50px;
      }
    }
  }


  @media (max-width: 320px) {
    .main-header-container {
      .menu-burger-container {
        padding-top: 56px;
      }
    }
  }

  @media (min-width: 3025px) {
    .main-header-container {
      column-gap: 72px;
      padding: 40px 72px;
      border-radius: 32px;

      &.active-fixed {
        padding: 40px 60px;
        top: 64px;
        left: 64px;
      }

      .menu-content {
        column-gap: 72px;
      }


      .logo-container {
        width: 164px;
        height: 52px;
      }
    }
  }


</style>