<template>
  <div class="quiz-list-container">
    <item-quiz-list v-for="(item, index) in activeList"
                    :class="{'active' : isVisible(index)}"
                    @changeStatus="changeStatus"
                    :info-item="item"/>
  </div>
</template>

<script>
import ItemQuizList from "@/components/shared/ItemQuizList.vue";
import {mapActions} from "vuex";

export default {
  name: "ListQuiz",
  components: {ItemQuizList},
  props: {
    activeList: {
      type: Array,
      required: true
    }
  },
  data() {
    return {
      currentItem: -1
    }
  },
  computed: {
    activeQuestion() {
      return this.$store.state.quizModule.activeQuestion;
    },
    activeAnswerId() {
      return this.$store.state.quizModule.resultQuizAnswer[this.activeQuestion].answer;
    }
  },
  mounted() {
    this.showNextItem();
  },
  methods: {
    ...mapActions('quizModule', ['ACTIVE_ANSWER', 'QUIZ_INFO_ACTIVE', 'RESULT_QUIZ_ANSWER']),
    showNextItem() {
      if (this.currentItem < this.activeList?.length - 1) {
        setTimeout(() => {
          this.currentItem++
          this.showNextItem()
        }, 100)
      }
    },

    changeStatus(item) {
      console.log(item)
      let id = item.id
      if(id === this.activeAnswerId) {
        this.ACTIVE_ANSWER(false)
        this.QUIZ_INFO_ACTIVE(false)
        this.RESULT_QUIZ_ANSWER(-1)
      } else {
        if(this.activeAnswerId === -1) {
          this.RESULT_QUIZ_ANSWER(id)
          this.QUIZ_INFO_ACTIVE(true)
        } else {
          this.QUIZ_INFO_ACTIVE(false)
          this.RESULT_QUIZ_ANSWER(id)
          this.QUIZ_INFO_ACTIVE(true)
        }
        this.ACTIVE_ANSWER(true)
      }
      this.$emit('changeStatus', item)
    },

    isVisible(index) {
      return index <= this.currentItem
    },
  }
}
</script>

<style scoped lang="scss">

.quiz-list-container {
  position: relative;
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;

  .quiz-list-item-container {
    opacity: 0;
    transition: opacity .33s ease;

    &.active {
      opacity: 1;
    }
  }
}


@media (max-width: 3024px){
      .quiz-list-container {
        row-gap: 32px;
        max-height: 656px;
      }
}


@media (max-width: 1920px) {
      .quiz-list-container {
        row-gap: 22px;
        max-height: 510px;
      }
}


@media (max-width: 1600px) {
      .quiz-list-container {
        row-gap: 20px;
        max-height: 416px;
      }
}
@media (max-height: 800px) {

      .quiz-list-container {
        row-gap: 14px;
        max-height: 374px;
      }
}

@media (max-width: 1400px) and (min-height: 801px) {
      .quiz-list-container {
        row-gap: 20px;
        max-height: 416px;
      }
}

@media (max-width: 1133px) {
      .quiz-list-container {
        row-gap: 14px;
        max-height: 374px;
      }
}

@media (max-width: 769px) {
  .quiz-list-container {
    row-gap: 12px;
    max-height: 100%;
  }
}

@media (max-width: 769px)  and (min-height: 720px) and (max-height: 1000px) {
  .quiz-list-container {
    row-gap: 14px;
    max-height: 100%;
  }
}

@media (min-height: 600px) and (max-height: 630px){

  //8 plus
}

@media (max-width: 375px) {
  .quiz-list-container {
    row-gap: 10px;
    max-height: 100%;
  }
}

@media (max-width: 375px) and (min-height: 630px) {
  .quiz-list-container {
    row-gap: 12px;
    max-height: 100%;
  }
}


@media (max-width: 320px) {
  .quiz-list-container {
    row-gap: 8px;
    max-height: 100%;
  }
}

@media (min-width: 3025px) {
      .quiz-list-container {
        row-gap: 56px;
        max-height: 1160px;
      }
}
</style>