<template>
  <div class="main-title-with-circle-container">
    <main-title>
      <slot/>
    </main-title>
    <div class="circle-container"></div>
  </div>
</template>

<script>
import MainTitle from "@/components/shared/text/MainTitle.vue";

export default {
  name: "TitleWithCircle",
  components: {MainTitle}
}
</script>

<style scoped lang="scss">
  .main-title-with-circle-container {
    display: flex;
    flex-direction: row;
    align-items: baseline;

    .circle-container {
      border-radius: 50%;
      background-color: var(--color-main-accent);
    }
  }



  @media (max-width: 3024px){
    .main-title-with-circle-container {
      column-gap: 10px;

      .circle-container {
        width: 12px;
        height: 12px;
      }
    }
  }


  @media (max-width: 1920px) {
    .main-title-with-circle-container {
      column-gap: 8px;

      .circle-container {
        width: 10px;
        height: 10px;
      }
    }
  }


  @media (max-width: 1600px) {
    .main-title-with-circle-container {
      column-gap: 6px;

      .main-title-container {
        font-size: 48px;
      }

      .circle-container {
        width: 8px;
        height: 8px;
      }
    }
  }
  @media (max-height: 800px) {
    .main-title-with-circle-container {
      column-gap: 4px;

      .main-title-container {
        font-size: 28px;
      }

      .circle-container {
        width: 6px;
        height: 6px;
      }
    }
  }

  @media (max-width: 1400px) and (min-height: 801px) {
    .main-title-with-circle-container {
      column-gap: 6px;

      .main-title-container {
        font-size: 48px;
      }

      .circle-container {
        width: 8px;
        height: 8px;
      }
    }
  }

  @media (max-width: 1133px) {
    .main-title-with-circle-container {
      column-gap: 4px;

      .main-title-container {
        font-size: 28px;
      }

      .circle-container {
        width: 6px;
        height: 6px;
      }
    }
  }

  @media (max-width: 769px) {
    .main-title-with-circle-container {
      column-gap: 2px;
      align-items: flex-end;

      .main-title-container {
        font-size: 22px;
      }

      .circle-container {
        margin-bottom: 5.5px;
        width: 4px;
        height: 4px;
      }
    }
  }

  @media (max-width: 769px)  and (min-height: 720px) and (max-height: 1000px) {
    .main-title-with-circle-container {
      column-gap: 3px;
      align-items: flex-end;

      .main-title-container {
        font-size: 24px;
      }

      .circle-container {
        margin-bottom: 6.5px;
        width: 5px;
        height: 5px;
      }
    }
  }

  @media (min-height: 600px) and (max-height: 630px){

    //8 plus
  }

  @media (max-width: 375px) {
    //se new
  }

  @media (max-width: 375px) and (min-height: 630px) {
    //x
  }


  @media (max-width: 320px) {
    //se old
  }

  @media (min-width: 3025px) {
    .main-title-with-circle-container {
      column-gap: 12px;

      .circle-container {
        width: 16px;
        height: 16px;
      }
    }
  }

</style>