<template>
  <div class="frame-modal-container">
      <div class="frame-modal-title-container">
        <span>
          {{ activeTitle }}
        </span>
        <button-square-icon   :icon-active="'cross'"
                     class="active-blur"
                     @click="closeModalCross"/>
      </div>
      <slot/>
    </div>
</template>

<script>
import ButtonSquareIcon from "@/components/shared/ButtonSquareIcon.vue";

export default {
  name: "ModalFrame",
  components: {ButtonSquareIcon},
  props: {
    activeTitle: {
      type: String,
      required: true
    }
  },
  methods: {
    closeModalCross() {
      this.$emit('closeModalCross')
    }
  }
}
</script>

<style scoped lang="scss">

.frame-modal-container {
    border-color: var(--color-sub-dark-color);
    border-style: solid;
    background-color: var(--color-main-dark-color);
    display: flex;
    flex-direction: column;
    width: 100%;
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 916;

  &.stickers {
    .frame-modal-title-container {
      white-space: pre-wrap;
      text-align: center;

      & span {
        width: 100%;
      }
    }
  }


    .frame-modal-title-container {
      display: flex;
      align-items: center;
      justify-content: space-between;
      font-weight: 400;
      color: #FDFDFD;
      z-index: 902;

      .button-icon-container {
        cursor: pointer;
        opacity: .6;
        transition: all .6s ease;

        &:hover {
          opacity: 1;
        }
      }
    }
}

@media (max-width: 3024px){
  .frame-modal-container {
    row-gap: 32px;
    padding: 64px;
    border-radius: 64px;
    border-width: 7px;

    &.stickers {
      .frame-modal-title-container {
        font-size: 26px;
      }
    }

    .frame-modal-title-container {
      font-size: 32px;
      height: 66px;
    }
  }
}



@media (max-width: 1920px) {
  .frame-modal-container {
    padding: 48px;
    border-radius: 48px;
    border-width: 6px;
    row-gap: 24px;

    &.stickers {
      .frame-modal-title-container {
        font-size: 20px;
      }
    }

    .frame-modal-title-container {
      font-size: 28px;
      height: 54px;
    }
  }
}

@media (max-width: 1600px) {
  .frame-modal-container {
    padding: 32px;
    border-radius: 32px;
    border-width: 4px;
    row-gap: 16px;

    &.stickers {
      .frame-modal-title-container {
        font-size: 16px;
      }
    }
    .frame-modal-title-container {
      font-size: 24px;
      height: 42px;
    }
  }
}
@media (max-height: 800px) {
  .frame-modal-container {
    padding: 24px;
    border-radius: 24px;
    border-width: 3px;
    row-gap: 12px;

    .frame-modal-title-container {
      font-size: 22px;
      height: 34px;
    }
  }
}

@media (max-width: 1400px) and (min-height: 801px) {
  .frame-modal-container {
    padding: 32px;
    border-radius: 32px;
    border-width: 4px;
    row-gap: 16px;

    .frame-modal-title-container {
      font-size: 24px;
      height: 42px;
    }
    
  }
}

@media (max-width: 1133px) {
  .frame-modal-container {
    padding: 24px;
    border-radius: 24px;
    border-width: 3px;
    row-gap: 12px;

    .frame-modal-title-container {
      font-size: 22px;
      height: 34px;
    }
  }
}
@media (max-width: 769px) {
  .frame-modal-container {
    padding: 24px;
    row-gap: 12px;

    &.stickers {
      .frame-modal-title-container {
        font-size: 12px;
      }
    }

    .frame-modal-title-container {
      font-size: 18px;
      height: 34px;
    }
  }
}

@media (max-width: 769px)  and (min-height: 720px) and (max-height: 1000px) {
  .frame-modal-container {
    padding: 24px;
    row-gap: 12px;

    &.stickers {
      .frame-modal-title-container {
        font-size: 14px;
      }
    }

    .frame-modal-title-container {
      font-size: 20px;
      height: 34px;
    }
  }
}

@media (max-width: 320px) {
  .frame-modal-container {
    padding: 12px;
    row-gap: 8px;

    &.stickers {
      .frame-modal-title-container {
        font-size: 10px;
      }
    }


    .frame-modal-title-container {
      font-size: 14px;
      height: 32px;
    }
  }
}


@media (min-width: 3025px) {
  .frame-modal-container {
    padding: 80px;
    border-radius: 80px;
    border-width: 10px;
    row-gap: 56px;

    .frame-modal-title-container {
      font-size: 42px;
      height: 96px;
    }
  }
}
</style>