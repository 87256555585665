<template>
  <div class="card-rp-container"
       @mouseenter="focusTrue"
       @mouseleave="focusFalse">
    <div class="top-info-content">
      <div class="title-info-content">
        <sub-paragraph>
          {{ infoCard.datePublish }}
        </sub-paragraph>

        <div class="view-info-container">
          <sub-paragraph>
<!--            {{ infoCard.view }}-->
            {{ viewRandom }}
          </sub-paragraph>

          <div class="img-container">
            <div class="img-content">
              <transition name="fade-opacity-lazy">
                <img v-if="activeLogo || activeMobile" :src="getUrlIcons('view-new.svg')" alt="view">
                <img v-else :src="getUrlIcons('view-grey.svg')" alt="view">
              </transition>
            </div>
          </div>
        </div>
      </div>

      <div class="text-info-content">
        <main-title>
          {{ infoCard.mainTitle }}
        </main-title>

        <main-paragraph>
          {{ infoCard.description }}
        </main-paragraph>
      </div>
    </div>



    <div class="bottom-info-content">
        <div class="href-info-container">
          <sub-paragraph v-for="item in infoCard.href"
                         :active-big="true">
            #{{ item }}
          </sub-paragraph>
        </div>
        <div class="link-info-container">
          <div class="img-container" v-for="item in linkInfo" :key="item.id">
            <img :src="getUrlIcons(item.icon + '.svg')" alt="view">
          </div>
        </div>
    </div>
  </div>
</template>

<script>
import SubParagraph from "@/components/shared/text/Card/SubParagraph.vue";
import MainParagraph from "@/components/shared/text/Card/MainParagraph.vue";
import MainTitle from "@/components/shared/text/Card/MainTitle.vue";

export default {
  name: "CardNews",
  components: {MainTitle, MainParagraph, SubParagraph},
  props: {
    infoCard: {
      type: Object,
      required: true
    },
    activeCardMobile: {
      type: Boolean
    }
  },
  computed: {
    activeLogo() {
        return this.focusParam
    }
  },
  mounted() {
    this.getRandomInt(100, 1000)
  },
  methods: {
    getRandomInt(min, max) {
      min = Math.ceil(min);
      max = Math.floor(max);
      this.viewRandom = Math.floor(Math.random() * (max - min) + min);
    }
  },
  data() {
    return {
      viewRandom: 0,
      focusParam: false,
      linkInfo: [
        {
          id: 0,
          icon: "telegram"
        },
        {
          id: 1,
          icon: "vk"
        },
        {
          id: 2,
          icon: "links"
        }
      ]
    }
  }
}
</script>

<style scoped lang="scss">
.img-container {
  width: 100%;
  height: 100%;
  .img-content {
    width: inherit;
    height: inherit;
  }
  & img {
    width: inherit;
    height: inherit;
  }
}


.card-rp-container {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
  width: 100%;
  background-color: rgba(0,0,0,0);
  border-style: solid;
  border-color: var(--color-main-dark-color);
  transition: all .6s ease;

  &.not-active-mobile {
    transform: scale(95%);
    opacity: .4;
  }

  &.active-mobile {
    opacity: 1;
    transform: scale(1);
  }


  .top-info-content {
    display: flex;
    flex-direction: column;

    .title-info-content {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      width: 100%;
      align-items: center;

      .view-info-container {
        display: flex;
        flex-direction: row-reverse;
        align-items: center;

        .mini-paragraph-container {
          line-height: normal;
        }
      }
    }

    .text-info-content {
      display: flex;
      flex-direction: column;

      .main-paragraph-container {
        font-weight: 300;
        color: #B6B6B6;
      }
    }
  }

  .bottom-info-content {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-end;
    width: 100%;

      .href-info-container {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
      }

      .link-info-container {
        display: flex;
        flex-direction: row;
    }
  }


  &:hover {
    background-color: var(--color-main-dark-color);
  }
}


@media (max-width: 3024px) {

  .img-container {
    width: 32px;
    height: 32px;
  }

  .card-rp-container {
    padding: 24px;
    border-radius: 20px;
    border-width: 6px;


    .top-info-content {
      row-gap: 24px;

      .title-info-content {
        .view-info-container {
          column-gap: 10px;
        }
      }

      .text-info-content {
        row-gap: 24px;
      }

    }


    .bottom-info-content {

      .href-info-container {
        column-gap: 24px;
      }

      .link-info-container {
        column-gap: 12px;
      }
    }
  }
}




@media (max-width: 1920px) {
  .img-container {
    width: 24px;
    height: 24px;
  }

  .card-rp-container {
    padding: 20px;
    border-radius: 16px;
    border-width: 4px;


    .top-info-content {
      row-gap: 24px;

      .title-info-content {
        .view-info-container {
          column-gap: 10px;


          .sub-paragraph-container {
            margin-top: 2px;
          }
        }
      }

      .text-info-content {
        row-gap: 16px;
      }

    }


    .bottom-info-content {

      .href-info-container {
        column-gap: 16px;
      }

        .link-info-container {
          column-gap: 10px;

          .img-container {
            width: 32px;
            height: 32px;
          }
        }
    }
  }
}


@media (max-width: 1600px) {
  .img-container {
    width: 24px;
    height: 24px;
  }

  .card-rp-container {
    padding: 20px;
    border-radius: 12px;
    border-width: 4px;


    .top-info-content {
      row-gap: 20px;

      .title-info-content {
        .view-info-container {
          column-gap: 10px;

          .sub-paragraph-container {
            margin-top: 3px;
          }
        }

      }

      .text-info-content {
        row-gap: 24px;
      }

    }


    .bottom-info-content {

      .href-info-container {
        column-gap: 8px;
      }

      .link-info-container {
        column-gap: 8px;

        .img-container {
          width: 24px;
          height: 24px;
        }
      }
    }
  }
}

@media (max-height: 800px) {
  .img-container {
    width: 24px;
    height: 24px;
  }

  .card-rp-container {
    padding: 20px;
    border-radius: 8px;
    border-width: 3px;


    .top-info-content {
      row-gap: 20px;

      .title-info-content {
        .view-info-container {
          column-gap: 10px;

          .sub-paragraph-container {
            margin-top: 2px;
          }
        }

      }

      .text-info-content {
        row-gap: 24px;
      }
    }


    .bottom-info-content {

      .href-info-container {
        column-gap: 8px;
      }

      .link-info-container {
        column-gap: 8px;
      }
    }
  }
}

@media (max-width: 1400px) and (min-height: 801px) {
  .img-container {
    width: 24px;
    height: 24px;
  }

  .card-rp-container {
    padding: 20px;
    border-radius: 12px;
    border-width: 4px;


    .top-info-content {
      row-gap: 20px;

      .title-info-content {
        .view-info-container {
          column-gap: 10px;

          .sub-paragraph-container {
            margin-top: 3px;
          }
        }

      }

      .text-info-content {
        row-gap: 24px;
        .img-container {
          width: 24px;
          height: 24px;
        }
      }

    }


    .bottom-info-content {

      .href-info-container {
        column-gap: 8px;
      }

      .link-info-container {
        column-gap: 8px;
      }
    }
  }
}

@media (max-width: 1133px) {
  .img-container {
    width: 24px;
    height: 24px;
  }

  .card-rp-container {
    padding: 20px;
    border-radius: 8px;
    border-width: 3px;


    .top-info-content {
      row-gap: 20px;

      .title-info-content {
        .view-info-container {
          column-gap: 10px;

          .sub-paragraph-container {
            margin-top: 1px;
          }
        }

      }

      .text-info-content {
        row-gap: 24px;
      }
    }


    .bottom-info-content {

      .href-info-container {
        column-gap: 8px;
      }

      .link-info-container {
        column-gap: 8px;
      }
    }
  }
}

@media (max-width: 769px) {
  .img-container {
    width: 18px;
    height: 18px;
  }

  .card-rp-container {
    padding: 16px;
    border-radius: 4px;
    border-width: 2px;

    &:hover {
      background-color: rgba(0,0,0,0);
    }


    .top-info-content {
      row-gap: 16px;

      .title-info-content {
        .view-info-container {
          flex-direction: row-reverse;
          column-gap: 8px;

          .sub-paragraph-container {
            margin-bottom: -2px;
          }
        }
      }

      .text-info-content {
        row-gap: 16px;

        .main-title-container {
          font-size: 14px;
        }

        .main-paragraph-container {
          font-size: 10px;
        }

        .small-paragraph-container {
          font-weight: 200;
        }
      }
    }


    .bottom-info-content {
      .href-info-container {
        flex-direction: column;
        row-gap: 2px;
        .sub-paragraph-container {
          color: #B6B6B6;
        }
      }

      .link-info-container {
        column-gap: 8px;

        .img-container {
          width: 24px;
          height: 24px;
        }

      }
    }
  }
}

@media (max-width: 769px)  and (min-height: 720px) and (max-height: 1000px) {
  .img-container {
    width: 18px;
    height: 18px;
  }

  .card-rp-container {
    padding: 18px 16px 16px;
    border-radius: 6px;
    border-width: 2px;


    .top-info-content {
      row-gap: 16px;

      .title-info-content {
        .view-info-container {
          flex-direction: row-reverse;
          column-gap: 8px;

          .sub-paragraph-container {
            margin-bottom: -2px;
          }
        }
      }

      .text-info-content {
        row-gap: 16px;

        .main-title-container {
          font-size: 16px;
        }
        .main-paragraph-container {
          font-size: 12px;
        }
        .small-paragraph-container {
          font-weight: 200;
        }
      }
    }


    .bottom-info-content {
      .href-info-container {
        flex-direction: column;
        row-gap: 2px;
        .sub-paragraph-container {
          color: #B6B6B6;
        }
      }

      .link-info-container {
        column-gap: 8px;

        .img-container {
          width: 24px;
          height: 24px;
        }

      }
    }
  }
}

@media (max-width: 320px) {
  .img-container {
    width: 16px;
    height: 16px;
  }

  .card-rp-container {
    border-width: 2px;
    padding: 14px;

    .top-info-content {
      row-gap: 14px;

      .text-info-content {
        row-gap: 10px;

        .main-title-container {
          font-size: 12px;
        }
      }
    }


    .bottom-info-content {

      .link-info-container {
        column-gap: 6px;
        .img-container {
          width: 24px;
          height: 24px;
        }
      }
    }
  }
}


@media (min-width: 3025px) {

  .img-container {
    width: 48px;
    height: 48px;
  }

  .card-rp-container {
    padding: 30px;
    border-radius: 32px;
    border-width: 8px;


    .top-info-content {
      row-gap: 48px;

      .title-info-content {
        .view-info-container {
          column-gap: 9px;

          .sub-paragraph-container {
            margin-top: 4px;
          }
        }
      }

      .text-info-content {
        row-gap: 24px;
      }

    }


    .bottom-info-content {

      .href-info-container {
        column-gap: 24px;
      }

      .link-info-container {
        column-gap: 24px;
      }
    }
  }
}
</style>