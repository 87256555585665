<template>
  <div class="start-screen-container">
        <main-title>
          {{ mainTitle }}
        </main-title>
        <p class="sub-paragraph-container">
          {{ subParagraphFirst }}
        </p>
        <p class="sub-paragraph-container two">
          {{ subParagraphSecond }}
        </p>
        <p class="main-paragraph-container">
          {{ subParagraphThird }}
        </p>
  </div>
</template>

<script>

import MainTitle from "@/components/shared/sticker/MainTitle.vue";

export default {
  name: "StartScreen",
  components: {MainTitle},
  data() {
    return {
      mainTitle: 'Наклейка \n«ЗАЩИЩЕНО АДВОКАТСКОЙ ТАЙНОЙ»',
      subParagraphFirst: 'При поддержке Федеральной Палаты Адвокатов Российской Федерации В2В маркетинг-бутик группы компаний «visioners» разработал варианты дизайна наклейки адвокатской тайны',
      subParagraphSecond: 'Предлагаем Вам принять участие в голосовании\nи выбрать вариант будущей официальной наклейки',
      subParagraphThird: 'Варианты наклеек на различных носителях',
      activeItem: -1,
    }
  },
  mounted() {
    this.showNextItem();
  },
  methods: {
    showNextItem() {
      if (this.activeItem < 4) {
        setTimeout(() => {
          this.activeItem++
          this.showNextItem();
        }, 600)
      }
    },

    isVisible(index) {
      return index <= this.activeItem
    },
  }
}
</script>

<style scoped lang="scss">
  .start-screen-container {
    margin: 0 auto;
    width: 100%;
    white-space: pre-wrap;

    .sub-paragraph-container {
      margin: 0 auto 24px;
      line-height: 135%;
      text-align: center;
      color: var(--color-sub-light-color);

      &.two {
        margin-bottom: 120px;
      }
    }

    .main-paragraph-container {
      text-align: center;
      color: var(--color-main-light-color);
    }
  }

  @media (max-width: 3024px){
    .start-screen-container {
      padding-top: 194px;
      max-width: 1378px;
      height: 924px;
      .main-title-container {
        margin-bottom: 36px;
      }

      .sub-paragraph-container {
        margin: 0 auto 24px;
        font-size: 28px;

        &.two {
          margin-bottom: 160px;
        }
      }

      .main-paragraph-container {
        font-size: 38px;
      }
    }
  }


  @media (max-width: 1920px) {
    .start-screen-container {
      padding-top: 168px;
      max-width: 1507px;
      height: 713px;
      .main-title-container {
        margin-bottom: 36px;
      }

      .sub-paragraph-container {
        margin: 0 auto 24px;
        max-width: 811px;
        font-size: 20px;

        &.two {
          margin-bottom: 52px;
        }
      }

      .main-paragraph-container {
        font-size: 36px;
      }
    }
  }


  @media (max-width: 1600px) {
    .start-screen-container {
      padding-top: 132px;
      max-width: 923px;
      height: 548px;
      .main-title-container {
        margin-bottom: 27px;
      }

      .sub-paragraph-container {
        margin: 0 auto 18px;
        max-width: inherit;
        font-size: 18px;

        &.two {
          margin-bottom: 60px;
        }
      }

      .main-paragraph-container {
        font-size: 27px;
      }
    }
  }

  @media (max-height: 800px) {
    .start-screen-container {
      padding-top: 130px;
      max-width: 771px;
      height: 470px;
      .main-title-container {
        margin-bottom: 27px;
      }

      .sub-paragraph-container {
        margin: 0 auto 18px;
        max-width: 555px;
        font-size: 16px;

        &.two {
          margin-bottom: 60px;
        }
      }

      .main-paragraph-container {
        font-size: 22px;
      }
    }
  }

  @media (max-width: 1400px) and (min-height: 801px) {
    .start-screen-container {
      padding-top: 132px;
      max-width: 923px;
      height: 548px;
      .main-title-container {
        margin-bottom: 27px;
      }

      .sub-paragraph-container {
        margin: 0 auto 18px;
        max-width: inherit;
        font-size: 18px;

        &.two {
          margin-bottom: 60px;
        }
      }

      .main-paragraph-container {
        font-size: 27px;
      }
    }
  }

  @media (max-width: 1133px) {
    .start-screen-container {
      padding-top: 130px;
      max-width: 771px;
      height: 470px;
      .main-title-container {
        margin-bottom: 27px;
      }

      .sub-paragraph-container {
        margin: 0 auto 18px;
        max-width: 555px;
        font-size: 16px;

        &.two {
          margin-bottom: 60px;
        }
      }

      .main-paragraph-container {
        font-size: 22px;
      }
    }
  }
  @media (max-width: 769px) {
    .start-screen-container {
      padding: 75px 16px 0;
      max-width: 100%;
      height: 304px;
      .main-title-container {
        margin-bottom: 16px;
      }

      .sub-paragraph-container {
        max-width: 100%;
        font-size: 13px;
        margin: 0 auto 14px;

        &.two {
          margin-bottom: 0;
        }
      }


      .main-paragraph-container {
        display: none;
      }
    }
  }

  @media (max-width: 769px)  and (min-height: 740px) and (max-height: 1000px) {
    .start-screen-container {
      padding: 88px 16px 0;
      max-width: 100%;
      height: 316px;
      .main-title-container {
        margin-bottom: 20px;
      }

      .sub-paragraph-container {
        max-width: 100%;
        font-size: 14px;
      }
    }
  }

  @media (max-width: 320px) {
    .start-screen-container {
      padding: 75px 16px 0;
      height: 230px;
      .main-title-container {
        margin-bottom: 16px;
      }

      .sub-paragraph-container {
        white-space: normal;
        font-size: 10px;
      }
    }
  }

  @media (min-width: 3025px) {
    .start-screen-container {
      padding-top: 342px;
      max-width: 1923px;
      height: 1458px;

      .main-title-container {
        margin-bottom: 80px;
      }

      .sub-paragraph-container {
        margin: 0 auto 32px;
        max-width: 2480px;
        font-size: 52px;

        &.two {
          margin-bottom: 200px;
        }
      }

      .main-paragraph-container {
        font-size: 80px;
      }
    }
  }

</style>