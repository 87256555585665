<template>
  <p class="main-paragraph-container">
    <slot/>
  </p>
</template>

<script>
export default {
  name: "MainParagraph"
}
</script>

<style scoped lang="scss">
  .main-paragraph-container {
    font-weight: 400;
    line-height: 150%;
    color: var(--color-sub-light-color);
  }



  @media (max-width: 3024px){
    .main-paragraph-container {
      font-size: 28px;
    }
  }


  @media (max-width: 1920px) {
    .main-paragraph-container {
      font-size: 20px;
    }
  }


  @media (max-width: 1600px) {
    .main-paragraph-container {
      font-size: 18px;
    }
  }

  @media (max-width: 769px) {
    .main-paragraph-container {
      font-size: 12px;
    }
  }

  @media (max-width: 769px)  and (min-height: 720px) and (max-height: 1000px) {
    //plus
  }

  @media (min-height: 600px) and (max-height: 630px){

    //8 plus
  }

  @media (max-width: 375px) {
    //se new
  }

  @media (max-width: 375px) and (min-height: 630px) {
    //x
  }


  @media (max-width: 320px) {
    //se old
  }

  @media (min-width: 3025px) {
    .main-paragraph-container {
      font-size: 42px;
    }
  }
</style>