<template>
  <div class="main-button-container"
       :class="{'active-dark' : activeDark, 'is-disabled' : isDisabled, 'active-big' : activeBig}">
    <main-title :active-big="activeBig">
      <slot/>
    </main-title>
  </div>
</template>

<script>
import MainTitle from "@/components/shared/text/Card/MainTitle.vue";

export default {
  name: "ButtonMain",
  components: {MainTitle},
  props: {
    activeDark: {
      type: Boolean,
      default: false
    },
    isDisabled: {
      type: Boolean,
          default: false
    },
    activeArrow: {
      type: Boolean,
      default: true
    },
    activeBig: {
      type: Boolean,
      default: false
    }
  }
}
</script>

<style scoped lang="scss">
  .main-button-container {
    background: var(--color-purple-accent);
    display: flex;
    align-items: center;
    justify-content: center;
    width: fit-content;
    cursor: pointer;
    transition: all .6s ease;

    &.card-mobile {
      .main-title-container {
        font-size: 12px !important;
      }
    }

    .main-title-container {
      transition: color .6s ease;
      color: var(--color-sub-light-color);

      @media (max-width: 769px) {
        color: #FDFDFD;
      }
    }

    &:hover {
      .main-title-container {
        color: #FDFDFD;
      }
    }

    &.is-disabled {
      cursor: default;
      opacity: .4;
      background: var(--color-purple-accent-disabled);

      &:hover {
        .main-title-container {
          color: var(--color-sub-light-color);
        }
      }

      &:hover {
        background: var(--color-purple-accent-disabled);
      }
    }

    &.active-dark {
      background: var(--color-purple-accent-disabled);

      &.is-disabled {
        cursor: default;
        opacity: .4;
        background: var(--color-purple-accent-disabled);

        &:hover {
          color: var(--color-main-light-color);
        }
      }
    }

    &:hover {
      color: var(--color-main-light-color);
    }

    .icon-container {
      width: 100%;
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: center;

      & img {
        width: 100%;
        height: 100%;
      }
    }
  }



  @media (max-width: 3024px) and (min-width: 1921px){
    .main-button-container {
      border-radius: 20px;
      padding: 16px 48px;
      column-gap: 16px;

      &.small {
        border-radius: 16px;
        padding: 18px 40px;
        .main-title-container {
          font-size: 20px !important;
        }
      }

      .main-title-container {
        font-size: 28px !important;
      }

      &.active-big {
        .main-title-container {
          font-size: 24px !important;
        }
      }

      .icon-container {
        width: 8px;
        height: 21px;
      }
    }
  }


  @media (max-width: 1920px) {
    .main-button-container {
      border-radius: 16px;
      padding: 14px 40px;
      column-gap: 16px;

      &.small {
        border-radius: 14px;
        padding: 12px 38px;
        .main-title-container {
          font-size: 18px !important;
        }
      }

      .main-title-container {
        font-size: 20px !important;
      }

      .icon-container {
        width: 8px;
        height: 21px;
      }

    }
  }


  @media (max-width: 1600px) {
    .main-button-container {
      border-radius: 14px;
      padding: 12px 38px;
      column-gap: 12px;

      &.small {
        border-radius: 14px;
        padding: 12px 24px;
        .main-title-container {
          font-size: 16px !important;
        }
      }


      &.active-big {
        .main-title-container {
          font-size: 16px !important;
        }
      }

      .main-title-container {
        font-size: 18px !important;
      }
    }
  }

  @media (max-height: 800px) {
    .main-button-container {
      border-radius: 12px;
      padding: 11px 32px;
      column-gap: 12px;

      &.active-big {
        .main-title-container {
          font-size: 16px !important;
        }
      }

      .main-title-container {
        font-size: 16px !important;
      }
    }
  }

  @media (max-width: 1400px) and (min-height: 801px) {
    .main-button-container {
      border-radius: 14px;
      padding: 12px 38px;
      column-gap: 12px;

      &.active-big {
        .main-title-container {
          font-size: 16px !important;
        }
      }

      .main-title-container {
        font-size: 18px !important;
      }
    }
  }

  @media (max-width: 1133px) {
    .main-button-container {
      border-radius: 12px;
      padding: 11px 32px;
      column-gap: 12px;

      &.active-big {
        .main-title-container {
          font-size: 16px !important;
        }
      }

      .main-title-container {
        font-size: 16px !important;
      }
    }
  }


  @media (max-width: 769px) {
    .main-button-container {
      border-radius: 6px;
      padding: 8px 24px;
      column-gap: 4px;


      &.small {
        border-radius: 6px;
        padding: 8px 24px;
        .main-title-container {
          font-size: 13px !important;
        }
      }


      &.active-big {
        .main-title-container {
          font-size: 14px !important;
        }
      }

      .main-title-container {
        font-size: 14px !important;
      }
    }
  }

  @media (max-width: 769px)  and (min-height: 720px) and (max-height: 1000px) {
    //plus
  }

  @media (min-height: 600px) and (max-height: 630px){

    //8 plus
  }

  @media (max-width: 375px) {
    //se new
  }

  @media (max-width: 375px) and (min-height: 630px) {
    //x
  }


  @media (max-width: 320px) {
    .main-button-container {
      border-radius: 6px;
      padding: 4px 24px;
      column-gap: 4px;

      &.active-big {
        .main-title-container {
          font-size: 10px !important;
        }
      }

      .main-title-container {
        font-size: 10px !important;
      }
    }
  }

  @media (min-width: 3025px) {
    .main-button-container {
      border-radius: 20px;
      padding: 24px 80px;
      column-gap: 24px;

      .main-title-container {
        font-size: 42px;
      }

      .icon-container {
        width: 12px;
        height: 32px;
      }
    }
  }

</style>