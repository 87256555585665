<template>
  <div class="info-container-contact">
    <main-title class="contact" @click="openModalContact">
      {{ titleContact }}
    </main-title>

<!--    <main-paragraph v-if="!activeMobileMini">-->
<!--      <a :href="mailTo.type + ':' + mailTo.text">-->
<!--        {{ mailTo.text }}-->
<!--      </a>-->
<!--    </main-paragraph>-->
    <main-paragraph @click="openModalContact">
      {{ mailTo.text }}
    </main-paragraph>
  </div>
</template>

<script>
import MainTitle from "@/components/shared/footer/MainTitle.vue";
import MainParagraph from "@/components/shared/footer/MainParagraph.vue";
import {mapActions} from "vuex";

export default {
  name: "ContactMobile",
  components: {MainParagraph, MainTitle},
  data() {
    return {
      titleContact: 'Связаться  с нами',
      mailTo: {
        text: 'info@visioners.tech',
        type: 'mailto'
      },
    }
  },
  methods: {
    ...mapActions(['ACTIVE_MODAL_CONTACT']),
    openModalContact() {
      this.ACTIVE_MODAL_CONTACT(true)
    }
  },
  computed: {
    activeMobileMini() {
      if(window.innerWidth <= 375 && window.innerHeight <= 630) {
        return true
      } else {
        return false
      }
    }
  }
}
</script>

<style scoped lang="scss">
.footer-main-title-container {
  &.contact {
    font-weight: 600;
    color: var(--color-main-accent);
    font-size: 16px;
  }
}

.footer-main-paragraph-container {
  font-size: 16px;
  & a {
    color: #B6B6B6 !important;
  }
}

.info-container-contact {
  display: flex;
  align-items: center;
  flex-direction: column;
  row-gap: 6px;
}

@media (max-width: 375px) and (max-height: 630px) {
  //.footer-main-title-container {
  //  display: none;
  //}

  //.footer-main-paragraph-container {
  //  font-weight: 600;
  //  color: var(--color-main-accent) !important;
  //}
}
//
//@media (max-width: 375px) and (min-height: 630px) {
//  .footer-main-title-container {
//    display: block;
//  }
//}


@media (max-width: 320px) {
  //se old
}
</style>