<template>
      <div class="header-title-container">
        <p class="header-title-text"> {{ mainTitle }} </p>
        <div class="header-decoration-container">
          <div class="line one"></div>
          <div class="line two"></div>
          <div class="line three"></div>
          <div class="line four"></div>
        </div>
      </div>
</template>

<script>
export default {
  name: "LineHeader",
  data() {
    return {
      mainTitle: 'Маркетинг-бутик'
    }
  }
}
</script>

<style scoped lang="scss">
.header-title-container {
  line-height: normal;
  color: var(--color-sub-light-color);
  position: relative;
    &.num {
      display: none;
    }

    .header-decoration-container {
      display: flex;
      flex-direction: row;

      .line {
        border-radius: 1px;
        background-color: #FDFDFD;

        &.two {
          background-color: var(--color-main-accent);
        }
      }
    }
}

@media (max-width: 3024px){
    .header-title-container {
        .header-title-text {
          font-size: 26px;
          margin-bottom: 8px;
        }

        .header-decoration-container {
          .line {
            height: 3px;
            &.one {
              width: 98px;
              margin-right: 18.05px;
            }

            &.two {
              height: 2.8px;
              width: 55px;
              margin-right: 5.5px;
            }

            &.three {
              width: 13.05px;
              height: 2.4px;
              margin-right: 7.4px;
            }

            &.four {
              height: 2.4px;
              width: 7.4px;
            }
          }
        }
      }
    }

@media (max-width: 2240px) {
    .header-title-container {
        .header-title-text {
          font-size: 26px;
          margin-bottom: 8px;
        }
      }
    }


@media (max-width: 1920px) {
    .header-title-container {
        .header-title-text {
          font-size: 26px;
          margin-bottom: 8px;
        }

        .header-decoration-container {
          .line {
            height: 3px;
            &.one {
              width: 98px;
              margin-right: 18.05px;
            }

            &.two {
              height: 2.8px;
              width: 55px;
              margin-right: 5.5px;
            }

            &.three {
              width: 13.05px;
              height: 2.4px;
              margin-right: 7.4px;
            }

            &.four {
              height: 2.4px;
              width: 7.4px;
            }
          }
        }
      }
    }

@media (max-width: 1600px) {
    .header-title-container {
        .header-title-text {
          font-size: 20px;
          margin-bottom: 6px;
        }

        .header-decoration-container {
          .line {
            height: 3px;
            &.one {
              width: 74px;
              margin-right: 13.56px;
            }

            &.two {
              height: 2.3px;
              width: 43.4px;
              margin-right: 4.8px;
            }

            &.three {
              width: 9.8px;
              height: 2.4px;
              margin-right: 5.6px;
            }

            &.four {
              height: 2.4px;
              width: 5.64px;
            }
          }
        }
      }
    }
@media (max-height: 800px) {
    .header-title-container {
        .header-title-text {
          font-size: 18px;
          margin-bottom: 5px;
        }

        .header-decoration-container {
          .line {
            height: 2px;
            &.one {
              width: 66px;
              margin-right: 12.3px;
            }

            &.two {
              height: 1.2px;
              width: 38.8px;
              margin-right: 3.7px;
            }

            &.three {
              width: 8.9px;
              height: 1.6px;
              margin-right: 5.1px;
            }

            &.four {
              height: 1.8px;
              width: 5.09px;
            }
          }
        }
      }
    }

@media (max-width: 1400px) and (min-height: 801px) {
    .header-title-container {
        .header-title-text {
          font-size: 19px;
          margin-bottom: 8px;
        }

        .header-decoration-container {
          .line {
            height: 2.85px;
            &.one {
              width: 70px;
              margin-right: 12.9px;
            }

            &.two {
              height: 1.7px;
              width: 40.1px;
              margin-right: 3.9px;
            }

            &.three {
              width: 9.3px;
              height: 2.27px;
              margin-right: 5.36px;
            }

            &.four {
              height: 2.26px;
              width: 5.35px;
            }
          }
        }
      }
    }

@media (max-width: 1133px) {
    .header-title-container {
        .header-title-text {
          font-size: 16px;
          margin-bottom: 7px;
        }

        .header-decoration-container {
          .line {
            height: 3px;
            &.one {
              width: 59px;
              margin-right: 10.5px;
            }

            &.two {
              height: 1.8px;
              width: 33.8px;
              margin-right: 3.3px;
            }

            &.three {
              width: 7.8px;
              height: 2.4px;
              margin-right: 4.52px;
            }

            &.four {
              height: 2.4px;
              width: 4.51px;
            }
          }
        }
      }
    }


@media (max-width: 769px) {
    .header-title-container {
      align-self: center;
        .header-title-text {
          font-size: 13px;
          margin-bottom: 7px;
        }

        .header-decoration-container {
          .line {
            height: 2.08px;
            &.one {
              width: 50px;
              margin-right: 9.25px;
            }

            &.two {
              height: 1.25px;
              width: 28.65px;
              margin-right: 2.8px;
            }

            &.three {
              width: 6.6px;
              height: inherit;
              margin-right: 3.8px;
            }

            &.four {
              height: inherit;
              width: 3.8px;
            }
          }
        }
      }
    }

@media (max-width: 769px)  and (min-height: 740px) and (max-height: 1000px) {
    .header-title-container {
        .header-title-text {
          font-size: 14px;
          margin-bottom: 8px;
        }

        .header-decoration-container {
          .line {
            height: 3px;
            &.one {
              width: 56px;
              margin-right: 10.2px;
            }

            &.two {
              height: 1.8px;
              width: 31.6px;
              margin-right: 3.3px;
            }

            &.three {
              width: 7.3px;
              margin-right: 3.1px;
            }

            &.four {
              width: 4.2px;
            }
          }
        }
      }
    }

@media (max-width: 375px){
    .header-title-container {
        &.num {
          font-size: 13px;
        }
        .header-title-text {
          font-size: 12px;
          margin-bottom: 6px;
        }

        .header-decoration-container {
          .line {
            height: 2px;
            &.one {
              width: 48px;
              margin-right: 9.25px;
            }

            &.two {
              height: 1.2px;
              width: 27.5px;
              margin-right: 2.7px;
            }

            &.three {
              width: 6.4px;
              margin-right: 3.6px;
            }

            &.four {
              width: 3.6px;
            }
          }
        }
      }
    }


@media (max-width: 375px) and (min-height: 630px) {
    .header-title-container {
        &.num {
          font-size: 14px;
        }

        .header-title-text {
          font-size: 12px;
          margin-bottom: 6px;
        }

        .header-decoration-container {
          .line {
            height: 2px;
            &.one {
              width: 48px;
              margin-right: 9.25px;
            }

            &.two {
              height: 1.2px;
              width: 27.5px;
              margin-right: 2.7px;
            }

            &.three {
              width: 6.4px;
              margin-right: 3.6px;
            }

            &.four {
              width: 3.6px;
            }
          }
        }
      }
    }


@media (max-width: 320px) {
    .header-title-container {
        &.num {
          font-size: 12px;
        }
        .header-title-text {
          font-size: 10px;
          margin-bottom: 6px;
        }

        .header-decoration-container {
          .line {
            height: 2px;
            &.one {
              width: 40px;
              margin-right: 7.5px;
            }

            &.two {
              height: 1.2px;
              width: 23.4px;
              margin-right: 2.3px;
            }

            &.three {
              width: 5.47px;
              margin-right: 3.14px;
            }

            &.four {
              width: 3.3px;
            }
          }
        }
      }
}

@media (min-width: 3025px){
    .header-title-container {
        .header-title-text {
          font-size: 45.5px;
          margin-bottom: 14px;
        }

        .header-decoration-container {
          .line {
            height: 5.25px;
            &.one {
              width: 170px;
              margin-right: 31.5px;
            }

            &.two {
              height: 3.1px;
              width: 98px;
              margin-right: 9.6px;
            }

            &.three {
              width: 22.8px;
              height: 4.2px;
              margin-right: 13px;
            }

            &.four {
              height: 4.9px;
              width: 13.05px;
            }
        }
      }
    }
}
</style>