<template>
  <div class="color-text-container">
    <main-title v-for="(item, index) in sliceEl"
                :key="index"
                :class="{'active-color' : item === colorText}">
      {{ item }}
    </main-title>
  </div>
</template>

<script>
import MainTitle from "@/components/shared/text/MainTitle.vue";

export default {
  name: "ColorText",
  components: {MainTitle},
  props: {
    textInfo: {
      type: String,
      required: true
    },
    colorText: {
      type: String,
      required: true
    }
  },
  computed: {
    sliceEl() {
      return this.textInfo.split(' ');
    }
  }
}
</script>

<style scoped lang="scss">
  .color-text-container {
    display: flex;
    flex-direction: row;
    column-gap: 16px;
    .main-title-container {
      line-height: 120%;
      font-weight: 600;
      &.active-color {
        color: var(--color-main-accent)
      }
    }
  }

  @media (max-width: 769px) {
    .color-text-container {
      align-items: center;
      flex-wrap: wrap;
      justify-content: center;
      column-gap: 10px;
    }
  }



  @media (max-width: 3024px){
    .color-text-container {
      column-gap: 16px;
    }
  }


  @media (max-width: 1920px) {

  }


  @media (max-width: 1600px) {
    .color-text-container {
      column-gap: 12px;

      .main-title-container {
        font-size: 48px;
      }
    }
  }
  @media (max-height: 800px) {
    .color-text-container {
      column-gap: 8px;

      .main-title-container {
        font-size: 40px;
      }
    }
  }

  @media (max-width: 1400px) and (min-height: 801px) {
    .color-text-container {
      column-gap: 12px;

      .main-title-container {
        font-size: 48px;
      }
    }
  }

  @media (max-width: 1133px) {
    .color-text-container {
      column-gap: 8px;

      .main-title-container {
        font-size: 40px;
      }
    }
  }

  @media (max-width: 769px) {
    .color-text-container {
      column-gap: 8px;
      .main-title-container {
        font-size: 28px;
      }
    }
  }

  @media (max-width: 769px)  and (min-height: 720px) and (max-height: 1000px) {
    .color-text-container {
      column-gap: 10px;
      .main-title-container {
        font-size: 32px;
      }
    }
  }

  @media (min-height: 600px) and (max-height: 630px){

    //8 plus
  }

  @media (max-width: 375px) {
    //se new
  }

  @media (max-width: 375px) and (min-height: 630px) {
    //x
  }


  @media (max-width: 320px) {
    //se old
  }

  @media (min-width: 3025px) {
    .color-text-container {
      column-gap: 24px;
    }
    .main-title-container {
      font-size: 120px;
    }
  }

</style>