<template>
  <div class="main-sticker-container" :class="{'active-block' : activeBlock}">
    <transition name="fade-opacity-lazy">
     <modal-feedback v-if="activeModalStickers" @closeModal="closeModal"/>
    </transition>
    <sub-header @scrollToOpacity="scrollTo"/>
    <router-view v-slot="{ Component }">
      <transition name="fade-opacity-lazy" mode="out-in">
        <component :is="Component" />
      </transition>
    </router-view>
  </div>

</template>

<script>
import SubHeader from "@/components/widgets/SubHeader.vue";
import {mapActions} from "vuex";
import ModalFeedback from "@/components/entities/sticker/ModalFeedback.vue";

export default {
  name: "SubLayout",
  components: {ModalFeedback, SubHeader},
  mounted() {
    this.activeBlock = true
    this.checkSize();
    addEventListener('resize', this.checkSize)
  },
  unmounted() {
    removeEventListener('resize', this.checkSize)
  },
  computed: {
    activeModalStickers() {
      return this.$store.state.activeModalStickers;
    }
  },
  data() {
    return {
      activeBlock: false
    }
  },
  methods: {
    ...mapActions(['ACTIVE_MOBILE', 'ACTIVE_MODAL_STICKER']),
    checkSize() {
      this.innerHeight = window.innerHeight;
      document.documentElement.style.setProperty('--vh', `${this.innerHeight * 0.01}px`);
      this.innerWidth = window.innerWidth;
      document.documentElement.style.setProperty('--vw', `${this.innerWidth * 0.01}px`);
      if (this.innerWidth <= 769) {
        this.ACTIVE_MOBILE(true)
      } else {
        this.ACTIVE_MOBILE(false)
      }
    },

    scrollTo() {
          this.activeBlock = false;
    },

    closeModal() {
      this.ACTIVE_MODAL_STICKER(false)
    }
  }
}
</script>

<style scoped lang="scss">
.main-sticker-container {
  position: relative;
  opacity: 0;
  transition: all .6s ease;

  &.active-block {
    opacity: 1;
  }
}
</style>