<template>
  <div class="stickers-screen-container max-width-stickers" :class="{'active-result' : activeQuizResult}" v-animateonscroll="{ enterClass: 'fade-in'}">
        <transition name="fade-opacity" mode="out-in">
          <div class="stickers-screen-content" v-if="!activeQuizResult">
            <main-title> {{ mainTitle }} </main-title>

            <div class="sticker-quiz-container">
              <card-sticker v-for="(item, index) in versionSticker"
                            @changeActiveButton="changeActiveButton"
                            :active-quiz-result="false"
                            :key="index"
                            :active-item="item"/>
            </div>

            <transition name="fade-opacity-lazy">
              <main-button v-if="!activeQuizResult"
                           :active-dark="true"
                           :is-disabled="isActiveButton"
                           @click="submitInfo">
                {{ buttonTitle }}
              </main-button>
            </transition>
          </div>

          <div class="stickers-screen-content result" v-else>
            <main-title> {{ mainTitleResult }} </main-title>
            <p class="sub-title-container">
              {{ subTitleResult }}
            </p>
            <p class="sub-title-container last">
              {{ subTitleResultTwo }}
            </p>
            <div class="sticker-quiz-container">
              <card-sticker v-for="(item, index) in versionSticker"
                            class="active"
                            @changeActiveButton="changeActiveButton"
                            :active-quiz-result="true"
                            :key="index"
                            :active-item="item"/>
            </div>
            <div class="result-button-container" @click="routToMainPage">
              <div class="main-title-container" @mouseenter="focusTrue" @mouseleave="focusFalse">
                <p>
                  {{ titleLook }}
                </p>
                <icon-arrow :active-grey="false"
                            :direction="'right-red'"/>
<!--                <transition-arrow :focus-param="!focusParam" :img-arrow="'right-arrow.svg'" :img-arrow-hover="'right-arrow-red.svg'"/>-->
              </div>
            </div>
          </div>
        </transition>
    </div>
</template>

<script>
import MainTitle from "@/components/shared/sticker/MainTitle.vue";
import CardSticker from "@/components/entities/sticker/CardSticker.vue";
import MainButton from "@/components/shared/ButtonMain.vue";
import {mapActions} from "vuex";
import IconArrow from "@/components/shared/IconArrow.vue";

export default {
  name: "StickersScreen",
  components: {IconArrow, MainButton, CardSticker, MainTitle},
  data() {
    return {
      titleVisioners: 'Ознакомиться с деятельностью маркетинг-бутик\nгруппы компаний «visioners» ',
      titleLook: 'Заказать разработку дизайна,\nсайта, маркетинг-стратегии',
      focusParam: false,
      mainTitle: 'Проголосуйте\nза предпочтительный вариант',
      mainTitleResult: 'Спасибо за участие!',
      subTitleResult: 'Когда финальный вариант наклейки будет утвержден Федеральной Палатой Адвокатов РФ,\n мы пришлем на вашу почту ссылку для создания персонализированной наклейки.',
      subTitleResultTwo: 'Предварительное распределение голосов:',
      versionSticker: [
        {
          id: 0,
          name: '1-й вариант',
          img: '1.svg',
          percent: 50,
          isActive: false
        },
        {
          id: 1,
          name: '2-й вариант',
          img: '2.svg',
          percent: 32,
          isActive: false
        },
        {
          id: 2,
          name: '3-й вариант',
          img: '3.svg',
          percent: 24,
          isActive: false
        }
      ],
      activeId: -1,
      isActiveButton: true,
      buttonTitle: 'Проголосовать',
      activeTitleLook: false
    }
  },

  mounted() {
   this.checkResultQuiz();
  },

  computed: {
    activeQuizResult() {
      return this.$store.state.activeStickersResult;
    }
  },

  watch: {
    activeStickersResult() {
      if(this.activeStickersResult) {
        setTimeout(() => {
          this.activeTitleLook = true
        }, 1500)
      }
    }
  },

  methods: {
    ...mapActions(['ACTIVE_STICKERS_RESULT', 'ACTIVE_MODAL_STICKER']),

    routToMainPage() {
      this.$router.push('/')
    },

    checkResultQuiz() {
      if(localStorage.getItem('activeResultSticker') === 'true') {
        this.ACTIVE_STICKERS_RESULT(true)
        this.activeTitleLook = true
      }
    },

    changeActiveButton(id) {
      if(this.activeId === id) {
        this.versionSticker[id].isActive = false
        this.activeId = -1
        this.isActiveButton = true
      } else {
        this.isActiveButton = false
        if(this.versionSticker.some(item => item.isActive === true)) {
          this.versionSticker.find(item => item.isActive === true).isActive = false;
        }
        this.versionSticker[id].isActive = !this.versionSticker[id].isActive
        this.activeId = id
      }
    },
    submitInfo() {
      if(!this.isActiveButton) {
        this.ACTIVE_MODAL_STICKER(true)
      }
    }
  }
}
</script>

<style scoped lang="scss">
 .stickers-screen-container {
   width: 100%;
   margin: 0 auto;
   display: flex;
   flex-direction: column;
   align-items: center;
   transition: padding .6s ease;
   overflow: hidden;
   justify-content: center;

   .stickers-screen-content {
     width: 100%;
   }

   .bold-text-content {
     white-space: pre-wrap;
     text-align: center;
     line-height: 120%;
     font-family: 'ProximaNova-Semibold', sans-serif;

     &.desktop {
       display: block;
     }

     &.mobile {
       display: none;
     }
   }

   .main-button-container {
     margin: 0 auto;
   }


   .sticker-quiz-container {
     margin: 0 auto;
     align-items: center;
     display: flex;
     width: 100%;
     flex-direction: row;
     justify-content: space-between;
   }

   .sub-title-container {
     color: var(--color-sub-light-color);
     white-space: pre-wrap;
     line-height: 120%;
     text-align: center;
   }

   .result-button-container {
     justify-content: center;
     display: flex;
     flex-direction: column;

     .main-title-container {
       align-items: center;
       align-self: center;
       font-family: 'ProximaNova', sans-serif;
       line-height: 120%;
       display: flex;
       flex-direction: row;
       color: var(--color-main-light-color) !important;
       cursor: pointer;
       text-align: center;
       white-space: pre-wrap;

       .arrow-animation {
         align-self: center;
       }
     }
   }
 }

 @media (max-width: 3024px){
   .stickers-screen-container {
     padding: 160px 0;
     .stickers-screen-content {
       &.result {
         .main-title-container {
           font-size: 72px;
           margin-bottom: 24px;
         }

         .sub-title-container {
           font-size: 28px;
           margin-bottom: 40px;
         }
       }
     }

     &.active-result {
       padding: 120px 0;
     }

     .main-title-container {
       margin-bottom: 80px;
     }

     .sub-title-container {
       font-size: 28px;
     }

     .sticker-quiz-container {
       max-width: 1898px;
       margin-bottom: 120px;
     }

     .result-button-container {
       row-gap: 16px;
       .main-title-container {
         column-gap: 16px;
         font-size: 32px !important;
       }

       .icon-arrow-container {
         width: 36px;
         height: 36px;
       }
     }
   }
 }


 @media (max-width: 1920px) {
   .stickers-screen-container {
     padding: 120px 0;
     .stickers-screen-content {
       &.result {
         .main-title-container {
           font-size: 64px;
           margin-bottom: 16px;
         }

         .sub-title-container {
           font-size: 26px;
           margin-bottom: 40px;
         }
       }
     }

     &.active-result {
       padding: 126px 0 97px;
     }

     .main-title-container {
       margin-bottom: 60px;
     }

     .sticker-quiz-container {
       max-width: 1435px;
       margin-bottom: 100px;
     }

     .result-button-container {
       row-gap: 20px;

       .sub-title-container {
         font-size: 24px;
       }

       .main-title-container {
         column-gap: 20px;
         font-size: 24px !important;
       }

       .icon-arrow-container {
         width: 30px;
         height: 30px;
       }
     }
   }
 }

 @media (max-width: 1600px) {
   .stickers-screen-container {
      padding: 100px 0;
     .stickers-screen-content {
       &.result {
         .main-title-container {
           font-size: 32px;
           margin-bottom: 16px;
         }

         .sub-title-container {
           font-size: 20px;
           margin-bottom: 40px;
         }
       }
     }

     &.active-result {
       padding: 120px 0;
     }

     .main-title-container {
       margin-bottom: 45px;
     }


     .sticker-quiz-container {
       max-width: 1181px;
       margin-bottom: 80px;
     }

     .result-button-container {
       row-gap: 20px;

       .sub-title-container {
         font-size: 18px;
       }

       .main-title-container {
         column-gap: 16px;
         font-size: 18px !important;
       }

       .icon-arrow-container {
         width: 24px;
         height: 24px;
       }
     }
   }
 }
 @media (max-height: 800px) {
   .stickers-screen-container {
     padding: 60px 0;
     .stickers-screen-content {
       &.result {
         .bold-text-content {
           font-size: 38px;
           margin-bottom: 12px;
         }

         .sub-title-container {
           font-size: 16px;
           margin-bottom: 40px;
         }
       }
     }

     &.active-result {
       padding: 72px 0;
     }

     .main-title-container {
       margin-bottom: 60px;
     }

     .sticker-quiz-container {
       max-width: 898px;
       margin-bottom: 60px;
     }

     .result-button-container {
       row-gap: 20px;

       .sub-title-container {
         font-size: 18px;
       }

       .main-title-container {
         column-gap: 12px;
         font-size: 16px !important;
       }

       .icon-arrow-container {
         width: 20px;
         height: 20px;
       }
     }
   }
 }

 @media (max-width: 1400px) and (min-height: 801px) {
   .stickers-screen-container {
     padding: 100px 0;
     .stickers-screen-content {
       &.result {
         .main-title-container {
           font-size: 32px;
           margin-bottom: 16px;
         }

         .sub-title-container {
           font-size: 20px;
           margin-bottom: 40px;
         }
       }
     }

     &.active-result {
       padding: 120px 0;
     }

     .main-title-container {
       margin-bottom: 45px;
     }


     .sticker-quiz-container {
       max-width: 1181px;
       margin-bottom: 80px;
     }

     .result-button-container {
       row-gap: 20px;

       .sub-title-container {
         font-size: 18px;
       }

       .main-title-container {
         column-gap: 16px;
         font-size: 18px !important;
       }

       .icon-arrow-container {
         width: 24px;
         height: 24px;
       }
     }
   }
 }

 @media (max-width: 1133px) {
   .stickers-screen-container {
     padding: 60px 0;
     .stickers-screen-content {
       &.result {
         .bold-text-content {
           font-size: 38px;
           margin-bottom: 12px;
         }

         .sub-title-container {
           font-size: 16px;
           margin-bottom: 40px;
         }
       }
     }

     &.active-result {
       padding: 72px 0;
     }

     .main-title-container {
       margin-bottom: 60px;
     }

     .sticker-quiz-container {
       max-width: 898px;
       margin-bottom: 60px;
     }

     .result-button-container {
       row-gap: 20px;

       .sub-title-container {
         font-size: 18px;
       }

       .main-title-container {
         column-gap: 12px;
         font-size: 16px !important;
       }

       .icon-arrow-container {
         width: 20px;
         height: 20px;
       }
     }
   }
 }
 @media (max-width: 769px) {
   .stickers-screen-container {
     justify-content: space-between;
     max-width: 100%;
     padding: 24px 20px;
     .main-title-container {
       white-space: pre-wrap;
     }


     .stickers-screen-content {
       display: flex;
       flex-direction: column;
       justify-content: space-between;
       height: 100%;
       &.result {
         display: flex;
         flex-direction: column;
         justify-content: space-between;
         height: 100%;
         .main-title-container {
           font-size: 18px;
           margin-bottom: 12px;
         }

         .sub-title-container {
           font-size: 13px;
           margin-bottom: 12px;
           &.last {
             margin-bottom: 24px;
           }
         }


         .sticker-quiz-container {
           row-gap: 16px;
           margin-bottom: 24px;
         }
       }
     }

     &.active-result {
       padding: 24px 20px;
     }
     .main-title-container {
       font-size: 18px;
       margin-bottom: 44px;
     }

     .sticker-quiz-container {
       flex-direction: column;
       row-gap: 32px;
       margin-bottom: 24px;
     }

     .result-button-container {
       row-gap: 8px;

       .sub-title-container {
         font-size: 14px;
       }

       .main-title-container {
         column-gap: 8px;
         font-size: 14px !important;
       }
     }
   }
 }

 @media (max-width: 769px)  and (min-height: 740px) and (max-height: 1000px) {
   .stickers-screen-container {
     justify-content: space-between;
     max-width: 100%;
     padding: 32px 24px;

     .stickers-screen-content {
       &.result {
         .main-title-container {
           font-size: 20px;
           margin-bottom: 12px;
         }

         .sub-title-container {
           font-size: 14px;
           margin-bottom: 12px;
           &.last {
             margin-bottom: 24px;
           }
         }
       }
     }

     &.active-result {
       padding: 24px 24px 54px;
     }

     .main-title-container {
       margin-bottom: 42px;
     }

     .sticker-quiz-container {
       flex-direction: column;
       row-gap: 32px;
       margin-bottom: 32px;
     }

     .result-button-container {
       row-gap: 16px;

       .sub-title-container {
         font-size: 16px;
       }

       .main-title-container {
         margin-top: 0;
         margin-bottom: 0 !important;
         column-gap: 8px;
         font-size: 16px !important;
       }
     }
   }
 }

@media (max-width: 320px) {
  .stickers-screen-container {
    justify-content: space-between;
    max-width: 100%;
    padding: 20px 16px;
    .main-title-container {
      white-space: pre-wrap;
    }


    .stickers-screen-content {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      height: 100%;
      &.result {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        height: 100%;
        .main-title-container {
          font-size: 16px;
          margin-bottom: 12px;
        }
        .sub-title-container {
          font-size: 10px;
          margin-bottom: 10px;
          &.last {
            margin-bottom: 16px;
          }
        }


        .sticker-quiz-container {
          row-gap: 16px;
          margin-bottom: 24px;
        }
      }
    }

    &.active-result {
      padding: 20px 16px;
    }
    .main-title-container {
      font-size: 16px;
      margin-bottom: 24px;
    }

    .sticker-quiz-container {
      flex-direction: column;
      row-gap: 32px;
      margin-bottom: 24px;
    }

    .result-button-container {
      row-gap: 8px;

      .sub-title-container {
        font-size: 14px;
      }

      .main-title-container {
        column-gap: 16px;
        font-size: 12px !important;

        .icon-arrow-container {
          width: 16px;
          height: 16px;
        }
      }
    }
  }
}

 @media (min-width: 3025px) {
   .stickers-screen-container {
     padding: 200px 0;

     &.active-result {
       padding: 317px 0;
     }

     .stickers-screen-content {
       &.result {
         .main-title-container {
           font-size: 98px;
           margin-bottom: 52px;
         }

         .sub-title-container {
           font-size: 52px;
           margin-bottom: 120px;
         }
       }
     }

     .main-title-container {
       margin-bottom: 260px;
     }

     .sub-title-container {
       font-size: 52px;
     }
     .sticker-quiz-container {
       max-width: 3320px;
       margin-bottom: 240px;
     }

     .result-button-container {
       row-gap: 80px;

       .main-title-container {
         column-gap: 40px;
         font-size: 56px !important;
       }
     }
   }
 }

</style>