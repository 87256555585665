<template>
  <div class="project-screen-container main-container">
      <block-title :button-info="buttonInfo"
                   @actionButton="uploadFile"
                   :active-button="!activeMobile"
                   :main-paragraph="mainParagraph"
                   :main-title="mainTitle"/>

      <div class="info-container top" v-if="!activeMobile" v-animateonscroll="{ enterClass: 'fade-in-up'}">
        <card-project :card-info="projectInfo[0]"/>
        <card-project :card-info="projectInfo[1]"/>
      </div>

      <div class="info-container bottom" v-if="!activeMobile" v-animateonscroll="{ enterClass: 'fade-in-down' }">
        <card-project :card-info="projectInfo[2]"/>
        <card-project :card-info="projectInfo[3]"/>
      </div>


    <slider-scroll :active-info-length="projectInfo.length"
                   v-animateonscroll="{ enterClass: 'fade-in' }"
                   v-if="activeMobile"
                   :active-dark="true"
                   :virtual-status="false"
                   :slides-per-view="activeSlides"
                   :active-scroll-bar="false">
      <swiper-slide v-for="(item, index) in projectInfo" v-slot="{ isActive, isPrev }">
        <card-project :card-info="item" :class="{'is-active' : isActive,
          'is-last' : index === projectInfo.length - 1 && isActive,
          'is-last-prev' : isPrev && index === projectInfo.length - 2}" :is-active="isActive"/>
      </swiper-slide>
    </slider-scroll>

    <button-main v-animateonscroll="{ enterClass: 'fade-in-down' }"
                 v-if="activeMobile"
                 :active-dark="buttonInfo.activeDark"
                 @click="uploadFile">
      {{ buttonInfo.text }}
    </button-main>



<!--    <button-main-->
<!--        v-if="activeMobile"-->
<!--        :active-dark="buttonInfo.activeDark"-->
<!--        @click="routTo(buttonInfo.rout)">-->
<!--      {{ buttonInfo.text }}-->
<!--    </button-main>-->
  </div>
</template>

<script>
import BlockTitle from "@/components/entities/BlockTitle.vue";
import CardProject from "@/components/entities/CardProject.vue";
import SliderScroll from "@/components/entities/SliderScroll.vue";
import ButtonMain from "@/components/shared/ButtonMain.vue";

export default {
  name: "ProjectScreen",
  components: {ButtonMain, SliderScroll, CardProject, BlockTitle},
  data() {
    return {
      buttonInfo: {
        text: 'Кейс-каталог',
        activeDark: true
      },

      mainParagraph: 'Среди множества проектов есть те, которыми мы особенно гордимся',
      mainTitle: 'Проекты'
    }
  },
  computed: {
    activeSlides() {
      if(window.innerWidth <= 375) {
        return 1.01
      } else {
        return 1.1
      }
    },
    projectInfo() {
      return this.$store.state.projectInfo;
    }
  },
  methods: {
    uploadFile() {
      let fileUrl =  '/Кейс-каталог .look.pdf'
      const link = document.createElement('a');
      link.href = fileUrl;
      link.setAttribute('download', 'Кейс-каталог .look.pdf');
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    }
  }
}
</script>

<style scoped lang="scss">
  .project-screen-container {
    .info-container {
      width: 100%;
      display: flex;
      flex-direction: row;
    }
  }

  @media (max-width: 3024px) and (min-width: 1921px){
    .project-screen-container {
      row-gap: 120px;
      .info-container {
        &.top {
          column-gap: 180px;
        }

        &.bottom {
          column-gap: 180px;
        }
      }
    }
  }


  @media (max-width: 1920px) {
    .project-screen-container {
      .info-container {
        &.top {
          column-gap: 210px;
        }
        &.bottom {
          column-gap: 280px;
        }
      }
    }
  }


  @media (max-width: 1600px) {
    .project-screen-container {
      .info-container {
        &.top {
          column-gap: 120px;
        }
        &.bottom {
          column-gap: 120px;
        }
      }
    }
  }
  @media (max-height: 800px) {
    .project-screen-container {
      .info-container {
        &.top {
          column-gap: 110px;
        }
        &.bottom {
          column-gap: 110px;
        }
      }
    }
  }

  @media (max-width: 1400px) and (min-height: 801px) {
    .project-screen-container {
      .info-container {
        &.top {
          column-gap: 120px;
        }
        &.bottom {
          column-gap: 120px;
        }
      }
    }
  }

  @media (max-width: 1133px) {
    .project-screen-container {
      .info-container {
        &.top {
          column-gap: 110px;
        }
        &.bottom {
          column-gap: 110px;
        }
      }
    }
  }

  @media (max-width: 769px) {
    .project-screen-container {
      .slider-scroll-container {
        max-height: 316px;


        .card-project-container {
          opacity: .6;
          transition: all .6s ease;
          &.is-active {
            opacity: 1;
          }

          &.is-last {
            transform: translateX(-34px);
          }
          &.is-last-prev {
            transform: translateX(-34px);
          }
        }
      }
    }
  }

  @media (max-width: 769px)  and (min-height: 720px) and (max-height: 1000px) {
    .project-screen-container {
      .slider-scroll-container {
        max-height: 350px;

        .card-project-container {

          &.is-last {
            transform: translateX(-38px);
          }
          &.is-last-prev {
            transform: translateX(-38px);
          }
        }
      }
    }
  }

  @media (min-height: 600px) and (max-height: 630px){

    //8 plus
  }

  @media (max-width: 375px) {
    .project-screen-container {
      .slider-scroll-container {
        .card-project-container {
          &.is-last {
            transform: translateX(-4px);
          }
          &.is-last-prev {
            transform: translateX(-4px);
          }
        }
      }
    }
  }

  @media (max-width: 375px) and (min-height: 630px) {
    //x
  }


  @media (max-width: 320px) {
    .project-screen-container {
      .slider-scroll-container {
        max-height: 348px;

        .card-project-container {
          &.is-last {
            transform: translateX(-2px);
          }
          &.is-last-prev {
            transform: translateX(-2px);
          }
        }
      }
    }
  }

  @media (min-width: 3025px) {
    .project-screen-container {
      row-gap: 120px;
      .info-container {
        &.top {
          column-gap: 320px;
        }

        &.bottom {
          column-gap: 320px;
        }
      }
    }
  }

</style>