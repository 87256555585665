<template>
  <div class="card-partners-container" :class="{'active' : activeMobile || focusParam}" @mouseenter="focusTrue" @mouseleave="focusFalse">
    <div class="text-info-container">
      <main-title>
        {{ infoCard.mainTitle }}
      </main-title>

      <main-paragraph :active-big="true">
        {{ infoCard.description }}
      </main-paragraph>
    </div>
    
    <div class="img-container">
      <div class="img-content">
        <transition name="fade-opacity-lazy">
          <img v-if="activeMobile || focusParam" :src="getUrlPartners(infoCard.img + '-hover-new.svg')" alt="logo">
          <img v-else :src="getUrlPartners(infoCard.img + '.svg')" alt="logo">
        </transition>
      </div>
    </div>
  </div>
</template>

<script>
import MainTitle from "@/components/shared/text/Card/MainTitle.vue";
import MainParagraph from "@/components/shared/text/Card/MainParagraph.vue";

export default {
  name: "CardPartners",
  components: {MainParagraph, MainTitle},
  props: {
    infoCard: {
      type: Object,
      required: true
    },
      activeCardMobile: {
        type: Boolean
      }
  },
  data() {
    return {
      focusParam: false
    }
  }
}
</script>

<style scoped lang="scss">
  .card-partners-container {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    text-align: center;
    height: 100%;
    width: 100%;
    border-style: solid;
    border-color: var(--color-sub-dark-color);
    transition: all .6s ease;

    &.active {
      .text-info-container {
        .main-title-container {
          color: #FDFDFD;
        }
      }
    }

    .text-info-container {
      display: flex;
      flex-direction: column;

      .main-title-container {
        transition: color .6s ease;
        color: #B6B6B6;
        line-height: 120%;
      }

      .main-paragraph-container {
        font-weight: 300;
        line-height: 120%;
        color: var(--color-sub-light-color);
      }
    }

    .img-container {
      align-self: center;
      height: 100%;
      width: 100%;
      .img-content {
        max-height: inherit;
        max-width: inherit;
        width: 100%;
        height: 100%;

        & img {
          max-width: inherit;
          max-height: inherit;
          object-fit: contain;
          width: 100%;
          height: 100%;
        }
      }
    }
  }

  @media (max-width: 3024px) and (min-width: 1921px) {
    .card-partners-container {
      padding: 40px 40px 30px;
      border-radius: 20px;
      border-width: 6px;

      .text-info-container {
        row-gap: 32px;

        .main-title-container {
          font-size: 40px;
        }

        .main-paragraph-container {
          font-size: 28px;
        }
      }

      .img-container {
        max-width: 391px;
        max-height: 292px;
      }
    }
  }



  @media (max-width: 1920px) {
    .card-partners-container {
      padding: 40px 30px 30px;
      border-radius: 14px;
      border-width: 4px;

      .text-info-container {
        row-gap: 32px;

        .main-title-container {
          font-size: 32px;
        }

        .main-paragraph-container {
          font-size: 20px;
        }
      }

      .img-container {
        max-width: 350px;
        max-height: 260px;
      }
    }
  }


  @media (max-width: 1600px) {
    .card-partners-container {
      padding: 32px 20px 30px;
      border-radius: 12px;
      border-width: 4px;

      .text-info-container {
        row-gap: 32px;

        .main-title-container {
          font-size: 24px;
        }

        .main-paragraph-container {
          font-size: 18px;
        }
      }

      .img-container {
        max-width: 280px;
        max-height: 208px;
      }
    }
  }

  @media (max-height: 800px) {
    .card-partners-container {
      padding: 32px 30px 30px;
      border-radius: 12px;
      border-width: 3px;

      .text-info-container {
        row-gap: 32px;

        .main-title-container {
          font-size: 24px;
        }

        .main-paragraph-container {
          font-size: 18px;
        }
      }

      .img-container {
        max-width: 260px;
        max-height: 190px;
      }
    }
  }

  @media (max-width: 1400px) and (min-height: 801px) {
    .card-partners-container {
      padding: 32px 20px 30px;
      border-radius: 12px;
      border-width: 4px;

      .text-info-container {
        row-gap: 32px;

        .main-title-container {
          font-size: 24px;
        }

        .main-paragraph-container {
          font-size: 18px;
        }
      }

      .img-container {
        max-width: 280px;
        max-height: 208px;
      }
    }
  }

  @media (max-width: 1133px) {
    .card-partners-container {
      padding: 32px 30px 30px;
      border-radius: 12px;
      border-width: 3px;

      .text-info-container {
        row-gap: 32px;

        .main-title-container {
          font-size: 24px;
        }

        .main-paragraph-container {
          font-size: 18px;
        }
      }

      .img-container {
        max-width: 260px;
        max-height: 190px;
      }
    }
  }

  @media (max-width: 769px) {
    .card-partners-container {
      padding: 16px;
      border-radius: 12px;
      border-width: 2px;

      .text-info-container {
        row-gap: 10px;

        .main-title-container {
          font-size: 18px;
        }

        .main-paragraph-container {
          font-size: 10px;
        }
      }

      .img-container {
        max-width: 135px;
        max-height: 100px;
      }
    }
  }

  @media (max-width: 769px)  and (min-height: 720px) and (max-height: 1000px) {
    .card-partners-container {
      padding: 20px;
      border-radius: 12px;
      border-width: 2px;

      .text-info-container {
        row-gap: 12px;

        .main-title-container {
          font-size: 20px;
        }

        .main-paragraph-container {
          font-size: 12px;
        }
      }

      .img-container {
        max-width: 190px;
        max-height: 140px;
      }
    }
  }
  @media (min-height: 600px) and (max-height: 630px){
    //для экранов 8 плюс
  }

  @media (max-width: 375px){
    //iPhone 8
  }


  @media (max-width: 375px) and (min-height: 630px) {
    //iPhone X
  }


  @media (max-width: 320px) {
    .card-partners-container {
      padding: 16px;
      border-radius: 10px;
      border-width: 2px;

      .text-info-container {
        row-gap: 12px;

        .main-title-container {
          font-size: 14px;
        }

        .main-paragraph-container {
          font-size: 10px;
        }
      }

      .img-container {
        max-width: 104px;
        max-height: 104px;
      }
    }
  }

  @media (min-width: 3025px) {
    .card-partners-container {
      padding: 64px;
      border-radius: 16px;
      border-width: 8px;

      .text-info-container {
        row-gap: 56px;

        .main-title-container {
          font-size: 62px;
        }

        .main-paragraph-container {
          font-size: 42px;
        }
      }

      .img-container {
        max-width: 698px;
        max-height: 520px;
      }
    }
  }

</style>