<template>
  <div class="text-container">
    <sub-title v-for="(item, index) in infoText"
               :key="index">
      <span v-if="item !== 'visioners' && item !== 'look'"> {{ item }} </span>
      <div v-else class="img-container" :class="item">
        <img :src="item + '.svg'" alt="logo">
      </div>
    </sub-title>
  </div>
</template>

<script>
import SubTitle from "@/components/shared/text/SubTitle.vue";

export default {
  name: "TextWithLogo",
  components: {SubTitle},
  props: {
    infoText: {
      type: Array,
      required: true
    }
  }
}
</script>

<style scoped lang="scss">
  .text-container {
    display: flex;
    flex-direction: row;
    align-items: baseline;
    justify-content: center;

    &.look {
      align-items: center;
    }


    .sub-title-container {
      line-height: normal;
      color: var(--color-card-light-color);
    }

    .img-container {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 100%;
      height: 100%;

      & img {
        width: 100%;
        height: 100%;
      }
    }
  }


  @media (max-width: 3024px){
    .text-container {
      column-gap: 8px;

      .img-container {

        &.look {
          width: 100px;
          height: 33px;
        }

        &.visioners {
          width: 195px;
          height: 33px;
        }
      }
    }
  }


  @media (max-width: 1920px) {
    .text-container {
      column-gap: 6px;

      .img-container {

        &.look {
          width: 59px;
          height: 20px;
        }

        &.visioners {
          width: 118px;
          height: 20px;
        }
      }
    }
  }


  @media (max-width: 1600px) {
    .text-container {
      column-gap: 6px;

      .img-container {

        &.look {
          width: 48px;
          height: 16px;
        }

        &.visioners {
          width: 98px;
          height: 16px;
        }
      }
    }
  }


  @media (max-width: 769px) {
    .text-container {
      column-gap: 4px;
      flex-wrap: wrap;
      row-gap: 4px;

      .img-container {

        &.look {
          width: 44px;
          height: 15px;
        }

        &.visioners {
          width: 70px;
          height: 12px;
        }
      }
    }
  }

  @media (max-width: 769px)  and (min-height: 720px) and (max-height: 1000px) {
    //plus
  }

  @media (min-height: 600px) and (max-height: 630px){

    //8 plus
  }

  @media (max-width: 375px) {
    //se new
  }

  @media (max-width: 375px) and (min-height: 630px) {
    //x
  }


  @media (max-width: 320px) {
    //se old
  }

  @media (min-width: 3025px) {
    .text-container {
      column-gap: 16px;

      .img-container {

        &.look {
          width: 140px;
          height: 48px;
        }

        &.visioners {
          width: 284px;
          height: 48px;
        }
      }
    }
  }

</style>