<template>
  <header class="main-header-container active-fixed"
          @mouseenter="focusTrue"
          @mouseleave="focusFalse"
          :class="{'active-focus' : focusParam}">
    <transition name="fade-opacity-lazy">
      <div class="main-header-content" v-if="activeMenu">
        <div class="logo-container" @click="scrollToTop">
          <img :src="getUrlLogo(logoImg)" alt="logo">
        </div>
        <menu-item :menu-info="menuInfo"
                   :active-index="activeIndex"
                   @onItemChanged="onItemChanged"
                   @scrollTo="scrollTo"
                   :offset="256"/>
      </div>
    <div class="circle-container" v-else></div>
    </transition>
  </header>
</template>

<script>
import MenuItem from "@/components/entities/MenuItem.vue";

export default {
  name: "HeaderFixed",
  components: {MenuItem},
  props: {
    menuInfo: {
      type: Array,
      required: true
    },
    activeIndex: {
      type: Number
    },
    active: {
      type: Boolean
    }
  },
  data() {
    return {
      focusParam: false,
      activeMenu: false,
      logoImg: 'look.svg',
      timer: null
    }
  },
  watch: {
    active() {
      if(!this.active) {
        this.activeMenu = false
        this.focusParam = false
      }
    }
  },
  methods: {
    onItemChanged(event, currentItem) {
      this.$emit('onItemChanged', event, currentItem)
    },
    scrollTo(id) {
      this.$emit('scrollTo',id)
    },

    focusTrue() {
      if(this.active) {
        this.focusParam = true
        this.timer = setTimeout(() => {
          this.activeMenu = true
        }, 350)
      }

    },

    focusFalse() {
      clearTimeout(this.timer)
      this.activeMenu = false
      this.focusParam = false
    }
  }
}
</script>

<style scoped lang="scss">
.circle-container {
  border-radius: 50%;
  background-color: var(--color-main-accent);
  position: absolute;
}

.main-header-container {
  transition: all .6s ease;
}

.main-header-content {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: fit-content;

  &.active-fixed {
    overflow: hidden;
    z-index: 900;
    position: fixed;
  }

  .scrollactive-item {
    &.active {
      .main-paragraph-container {
        opacity: 1;
      }
    }
  }


  .logo-container {
    cursor: pointer;
    width: 100%;
    height: 100%;

    & img {
      width: 100%;
      height: 100%;
    }
  }
}


@media (max-width: 3024px) and (min-width: 1921px){
  .circle-container {
    width: 10.23px;
    margin-top: 28px;
    height: 10.23px;
  }


  .main-header-container {
    width: 91px !important;
    height: 100px !important;

    &.active-focus {
      width: 1215px !important;
    }
  }

  .main-header-content {
    column-gap: 56px;

    .menu-content {
      column-gap: 56px;
    }
    &.active-fixed {
      top: 40px;
    }

    .main-paragraph-container {
      font-size: 26px;
    }

    .logo-container {
      width: 140px;
      height: 48px;
    }
  }
}


@media (max-width: 1920px) {
  .circle-container {
    width: 7.25px;
    height: 7.25px;
    margin-top: 23.54px;
  }

  .main-header-container {
    width: 72px !important;
    height: 78px !important;

    &.active-focus {
      width: 937px !important;
    }
  }

  .main-header-content {
    column-gap: 50px;

    .menu-content {
      column-gap: 50px;
    }
    &.active-fixed {
      padding: 22px 32px;
      top: 40px;
      left: 40px;
    }
    .logo-container {
      width: 102px;
      height: 34px;
    }
  }
}


@media (max-width: 1600px) {

  .circle-container {
    width: 5.55px;
    height: 5.55px;
    margin-top: 16px;
  }


  .main-header-container {
    width: 44px !important;
    height: 62px !important;

    &.active-focus {
      width: 840px !important;
    }
  }

  .main-header-content {
    column-gap: 48px;


    .main-paragraph-container {
      font-size: 18px;
    }

    .menu-content {
      column-gap: 48px;
    }
    &.active-fixed {
      top: 40px;
    }

    .logo-container {
      width: 77px;
      height: 26px;
    }
  }
}

@media (max-height: 800px) {

  .main-paragraph-container {
    font-size: 16px;
  }
  .circle-container {
    width: 4.19px;
    height: 4.19px;
    margin-top: 13.6px;
  }


  .main-header-container {
    width: 37px !important;
    height: 52px !important;

    &.active-focus {
      width: 756px !important;
    }
  }

  .main-header-content {
    column-gap: 48px;

    .menu-content {
      column-gap: 48px;
    }

    .logo-container {
      width: 58px;
      height: 20px;
    }
  }
}

@media (max-width: 1400px) and (min-height: 801px) {
  .circle-container {
    width: 5.55px;
    height: 5.55px;
    margin-top: 16px;
  }


  .main-header-container {
    width: 44px !important;
    height: 62px !important;

    &.active-focus {
      width: 840px !important;
    }
  }

  .main-header-content {
    column-gap: 48px;


    .main-paragraph-container {
      font-size: 18px;
    }

    .menu-content {
      column-gap: 48px;
    }
    &.active-fixed {
      top: 40px;
    }

    .logo-container {
      width: 77px;
      height: 26px;
    }
  }
}

@media (max-width: 1133px) {

  .main-paragraph-container {
    font-size: 16px !important;
  }

  .circle-container {
    width: 4.19px;
    height: 4.19px;
    margin-top: 13.6px;
  }


  .main-header-container {
    width: 37px !important;
    height: 52px !important;
    padding: 16px;

    &.active-focus {
      width: 756px !important;
    }
  }

  .main-header-content {
    column-gap: 48px;

    .menu-content {
      column-gap: 48px;
    }

    .logo-container {
      width: 58px;
      height: 20px;
    }
  }
}


@media (min-width: 3025px) {
  .circle-container {
    width: 11.09px;
    height: 11.09px;
    margin-top: 36.02px;
  }

  .main-header-container {
    width: 131px !important;
    height: 132px !important;

    &.active-focus {
      width: 1698px !important;
    }
  }

  .main-header-content {
    column-gap: 72px;


    .menu-content {
      column-gap: 72px;
    }


    .logo-container {
      width: 164px;
      height: 52px;
    }
  }
}

</style>