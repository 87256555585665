<template>
  <div class="examples-screen-container" id="my-sticky-element">

    <div class="phone-screen-container max-width-stickers" v-animateonscroll="{ enterClass: 'fade-in'}">
      <title-with-circle>
        {{ phoneTitle }}
      </title-with-circle>
      <div class="phone-screen-content desktop" v-if="!activeMobile">
        <div class="phone-img-content" v-for="(item, index) in imgPhone" :key="index">
          <img :src="getUrlStickers(item)" alt="phone-example">
        </div>
      </div>
      <div class="phone-screen-content mobile" v-else>
        <swiper @slide-change="onSwiperMain"
                @swiper="changeSettingSwiper"
                :breakpoints="{
                    0: {
                      slidesPerView: 2.2,
                      spaceBetween: 16
                    },
                    375: {
                      slidesPerView: 2.3,
                      spaceBetween: 20
                    },
                    // when window width is >= 480px
                    390: {
                      slidesPerView: 2.4,
                      spaceBetween: 20
                    },
                    // when window width is >= 640px
                    425: {
                      slidesPerView: 2.3,
                      spaceBetween: 24
                    }
                }"
                :centered-slides="true"
                class="phone-screen-content-slider"
                direction="horizontal"
                :speed="1000"
                :allow-touch-move="true">
          <swiper-slide v-for="(item, index) in imgPhone" :key="index">
            <div class="phone-img-content">
              <img :src="getUrlStickers(item)" alt="phone-example">
            </div>
          </swiper-slide>
        </swiper>

      </div>
    </div>
    <div class="laptop-screen-container" v-animateonscroll="{ enterClass: 'fade-in'}">
      <title-with-circle>
        {{ laptopTitle }}
      </title-with-circle>
      <div class="laptop-screen-content">
        <div class="laptop-img-content" v-for="(item, index) in imgLaptop" :key="index">
          <img :src="getUrlStickers(item)" alt="laptop-example">
        </div>
      </div>
    </div>
    <div class="notebook-screen-container max-width-stickers" v-animateonscroll="{ enterClass: 'fade-in'}">
      <title-with-circle>
        {{ notebookTitle }}
      </title-with-circle>
      <div class="notebook-screen-content">
        <div class="notebook-img-content" v-for="(item, index) in imgNotebook" :key="index" id="trigger">
          <img :src="getUrlStickers(item)" alt="notebook-example">
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import TitleWithCircle from "@/components/shared/TitleWithCircle.vue";

export default {
  name: "ExamplesScreen",
  components: {TitleWithCircle},
  data() {
    return {
      phoneTitle: 'Мобильный телефон',
      laptopTitle: 'Ноутбук',
      notebookTitle: 'Блокнот',
      imgPhone: ['phone_1.webp','phone_2.webp','phone_3.webp'],
      imgLaptop: ['laptop_1.webp','laptop_2.webp'],
      imgNotebook: ['notebook_2.webp','notebook_3.webp','notebook_1.webp'],
      examplesSwiper: '',
      activeExampleSlide: 0
    }
  },


  methods: {
    onSwiperMain(swiper) {
      this.activeExampleSlide = swiper.activeIndex;
    },

    changeSettingSwiper(swiper) {
      this.examplesSwiper = swiper
    },
  }
}
</script>

<style scoped lang="scss">
.main-title-with-circle-container {
  margin: 0 auto;
}

  .examples-screen-container {
    overflow: hidden;
    height: fit-content;
    display: flex;
    flex-direction: column;
    background-color: var(--color-sub-dark-color);

    .phone-screen-container {
      width: 100%;
      margin: 0 auto;
      display: flex;
      flex-direction: column;

      .phone-screen-content {
        width: 100%;
        display: flex;
        flex-direction: row;

        .phone-img-content {
          & img {
            width: inherit;
            height: inherit;
          }
        }
      }
    }

    .laptop-screen-container {
      width: 100%;
      display: flex;
      flex-direction: column;

      .laptop-screen-content {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        width: 100%;
        .laptop-img-content {
          & img {
            width: inherit;
            height: inherit;
          }
        }
      }
    }

    .notebook-screen-container {
      display: flex;
      flex-direction: column;
      margin: 0 auto;
      width: 100%;
      .notebook-screen-content {
        display: flex;
        flex-direction: row;
        align-items: center;
        .notebook-img-content  {
          & img {
            width: inherit;
            height: inherit;
          }
        }

      }
    }
  }

  @media (max-width: 3024px){
    .examples-screen-container {
      padding: 160px 0;
      row-gap: 160px;

      .phone-screen-container {
        row-gap: 100px;

        .phone-screen-content {
          column-gap: 330px;

          .phone-img-content {
            width: 413px;
            filter: drop-shadow(9.33333px 9.33333px 30.66667px  rgba(0, 0, 0, 0.45));
          }
        }
      }

      .laptop-screen-container {
        row-gap: 100px;
        .laptop-screen-content {
          max-height: 1422px;
          .laptop-img-content {
            filter: drop-shadow( 48px 58.66667px 76.00001px  rgba(0, 0, 0, 0.23));

            &:first-child {
              width: 1042px;
              margin-top: 450px;
            }
            &:last-child {
              width: 1050px;
            }
          }
        }
      }

      .notebook-screen-container {
        row-gap: 100px;
        .notebook-screen-content {
          column-gap: 134px;
          .notebook-img-content  {
            width: 544px;
            filter: drop-shadow(9.544123649597168px 9.544123649597168px 31.35926055908203px rgba(0, 0, 0, 0.25));
          }
        }
      }
    }
  }


  @media (max-width: 1920px) {
    .examples-screen-container {
      padding: 120px 0;
      row-gap: 160px;

      .phone-screen-container {
        row-gap: 80px;
        .phone-screen-content {
          column-gap: 236px;
          .phone-img-content {
            width: 354px;
            filter: drop-shadow(7px 7px 23px rgba(0, 0, 0, 0.45));
          }
        }
      }

      .laptop-screen-container {
        row-gap: 80px;

        .laptop-screen-content {
          max-height: 1218px;
          .laptop-img-content {
            filter: drop-shadow(36px 44px 57px rgba(0, 0, 0, 0.23));

            &:first-child {
              width: 863px;
              margin-top: 380px;
            }
            &:last-child {
              width: 900px;
            }
          }
        }
      }

      .notebook-screen-container {
        row-gap: 80px;
        .notebook-screen-content {
          column-gap: 73px;
          .notebook-img-content  {
            width: 463px;
            filter: drop-shadow(-3.7771530151367188px 7.5543060302734375px 18.885766983032227px rgba(0, 0, 0, 0.20));
          }
        }
      }
    }
  }
  @media (max-width: 1600px) {
    .examples-screen-container {
      padding: 100px 0;
      row-gap: 140px;

      .phone-screen-container {
        row-gap: 60px;

        .phone-screen-content {
          column-gap: 188px;
          .phone-img-content {
            width: 266px;
            filter: drop-shadow(5.25px 5.25px 17.25px rgba(0, 0, 0, 0.45));
          }
        }
      }

      .laptop-screen-container {
        row-gap: 60px;
        .laptop-screen-content {
          max-height: 919px;
          .laptop-img-content {
            filter: drop-shadow(36px 44px 57px rgba(0, 0, 0, 0.23));

            &:first-child {
              width: 604px;
              margin-top: 290px;
            }
            &:last-child {
              width: 668px;
            }
          }
        }
      }

      .notebook-screen-container {
        row-gap: 60px;
        .notebook-screen-content {
          column-gap: 66px;
          .notebook-img-content  {
            width: 350px;
            filter: drop-shadow(-2.8315515518188477px 5.663103103637695px 14.157758712768555px rgba(0, 0, 0, 0.20));          }
        }
      }
    }
  }
  @media (max-height: 800px) {
    .examples-screen-container {
      padding: 80px 0;
      row-gap: 100px;

      .phone-screen-container {
        row-gap: 48px;

        .phone-screen-content {
          column-gap: 174px;

          .phone-img-content {
            width: 238px;
            filter: drop-shadow(4.66667px 4.66667px 15.33333px rgba(0, 0, 0, 0.45));
          }
        }
      }

      .laptop-screen-container {
        row-gap: 48px;

        .laptop-screen-content {
          max-height: 780px;
          .laptop-img-content {
            filter: drop-shadow(24px 29.33333px 38px rgba(0, 0, 0, 0.23));

            &:first-child {
              width: 509px;
              margin-top: 257px;
            }

            &:last-child {
              width: 561px;
            }
          }
        }
      }

      .notebook-screen-container {
        row-gap: 48px;
        .notebook-screen-content {
          column-gap: 67px;

          .notebook-img-content {
            width: 308px;
            filter: drop-shadow(-2.5163509845733643px 5.0327019691467285px 12.581755638122559px rgba(0, 0, 0, 0.20));
          }
        }
      }
    }
  }

  @media (max-width: 1400px) and (min-height: 801px) {
    .examples-screen-container {
      padding: 100px 0;
      row-gap: 140px;

      .phone-screen-container {
        row-gap: 60px;

        .phone-screen-content {
          column-gap: 188px;
          .phone-img-content {
            width: 266px;
            filter: drop-shadow(5.25px 5.25px 17.25px rgba(0, 0, 0, 0.45));
          }
        }
      }

      .laptop-screen-container {
        row-gap: 60px;
        .laptop-screen-content {
          max-height: 919px;
          .laptop-img-content {
            filter: drop-shadow(36px 44px 57px rgba(0, 0, 0, 0.23));

            &:first-child {
              width: 604px;
              margin-top: 290px;
            }
            &:last-child {
              width: 668px;
            }
          }
        }
      }

      .notebook-screen-container {
        row-gap: 60px;
        .notebook-screen-content {
          column-gap: 66px;
          .notebook-img-content  {
            width: 350px;
            filter: drop-shadow(-2.8315515518188477px 5.663103103637695px 14.157758712768555px rgba(0, 0, 0, 0.20));          }
        }
      }
    }
  }

  @media (max-width: 1133px) {
    .examples-screen-container {
      padding: 80px 0;
      row-gap: 100px;

      .phone-screen-container {
        row-gap: 48px;

        .phone-screen-content {
          column-gap: 174px;

          .phone-img-content {
            width: 238px;
            filter: drop-shadow(4.66667px 4.66667px 15.33333px rgba(0, 0, 0, 0.45));
          }
        }
      }

      .laptop-screen-container {
        row-gap: 48px;

        .laptop-screen-content {
          max-height: 780px;
          .laptop-img-content {
            filter: drop-shadow(24px 29.33333px 38px rgba(0, 0, 0, 0.23));

            &:first-child {
              width: 509px;
              margin-top: 257px;
            }

            &:last-child {
              width: 561px;
            }
          }
        }
      }

      .notebook-screen-container {
        row-gap: 48px;
        .notebook-screen-content {
          column-gap: 67px;

          .notebook-img-content {
            width: 308px;
            filter: drop-shadow(-2.5163509845733643px 5.0327019691467285px 12.581755638122559px rgba(0, 0, 0, 0.20));
          }
        }
      }
    }
  }

@media (max-width: 1050px) {
  .examples-screen-container {
    .phone-screen-container {
      .phone-screen-content {
        column-gap: 128px;
      }
    }

    .laptop-screen-container {
      row-gap: 48px;
      .laptop-screen-content {
        .laptop-img-content {
          &:first-child {
            width: 496px;
            margin-top: 257px;
          }

          &:last-child {
            width: 500px;
          }
        }
      }
    }

    .notebook-screen-container {
      .notebook-screen-content {
        column-gap: 32px;
        .notebook-img-content {
          width: 300px;
        }
      }
    }
  }
}

  @media (max-width: 769px) {
    .examples-screen-container {
      padding: 50px 0;
      row-gap: 60px;
      overflow: hidden;

      .phone-screen-container {
        max-width: 100%;
        row-gap: 30px;

        .phone-screen-content {
          width: 100%;
          min-height: 297px;
          align-self: flex-end;

          .phone-screen-content-slider {
            width: 100%;
            max-height: 297px;
            overflow: visible;
          }
          .phone-img-content {
            width: 149px;
            filter: drop-shadow( 3.30699px 4.34043px 12.19453px rgba(0, 0, 0, 0.35));
          }
        }
      }

      .laptop-screen-container {
        row-gap: 30px;

        .laptop-screen-content {
          max-height: 692px;
          min-height: 692px;
          flex-direction: column;
          .laptop-img-content {
            filter: drop-shadow( 5.28098px 4.67744px 15.54118px rgba(0, 0, 0, 0.13));

            &:first-child {
              position: relative;
              left: -82px;
              width: 338px;
              margin-top: 0;
            }

            &:last-child {
              position: relative;
              right: -32px;
              align-self: flex-end;
              width: 310px;
            }
          }
        }
      }
      .notebook-screen-container {
        align-items: center;
        padding: 0 20px;
        row-gap: 30px;
        .notebook-screen-content {
          width: 100%;
          position: relative;
          min-height: 417px;
          height: 100%;

          .notebook-img-content {
            position: absolute;
            width: 194px;
            top: 0;
            filter: drop-shadow(-2.3429908752441406px 4.685981750488281px 11.71495532989502px rgba(0, 0, 0, 0.10));

            &:nth-child(2) {
              right: 0;
              left: auto;
              top: 32px;
              z-index: 1;
            }

            &:last-child {
              left: 71px;
              top: 112px;
              z-index: 2;
            }
          }
        }
      }
    }
  }

  @media (max-width: 769px)  and (min-height: 740px) and (max-height: 1000px) {
    .examples-screen-container {
      padding: 52px 0;
      row-gap: 52px;
      overflow: hidden;

      .phone-screen-container {
        max-width: 100%;
        row-gap: 32px;

        .phone-screen-content {
          width: 100%;
          min-height: 340px;
          align-self: flex-end;

          .phone-screen-content-slider {
            max-height: 340px;
            overflow: visible;
          }

            .phone-img-content {
              width: 171px;
              filter: drop-shadow( 3.30699px 4.34043px 12.19453px rgba(0, 0, 0, 0.35));
          }
        }
      }

      .laptop-screen-container {
        row-gap: 32px;

        .laptop-screen-content {
          max-height: 721px;
          min-height: 721px;
          flex-direction: column;
          .laptop-img-content {
            filter: drop-shadow( 5.28098px 4.67744px 15.54118px rgba(0, 0, 0, 0.13));

            &:first-child {
              left: -82px;
              width: 381px;
              margin-top: 0;
            }

            &:last-child {
              position: relative;
              right: -32px;
              align-self: flex-end;
              width: 324px;
            }
          }
        }
      }

      .notebook-screen-container {
        align-items: center;
        padding: 0 24px;
        row-gap: 32px;
        .notebook-screen-content {
          width: 100%;
          position: relative;
          column-gap: 34px;
          min-height: 425px;
          height: 100%;

          .notebook-img-content {
            position: absolute;
            width: 195px;
            top: 0;
            filter: drop-shadow(-2.3429908752441406px 4.685981750488281px 11.71495532989502px rgba(0, 0, 0, 0.10));

            &:nth-child(2) {
              right: 0;
              left: auto;
              top: 35px;
              z-index: 1;
            }

            &:last-child {
              left: 93px;
              top: 127px;
              z-index: 2;
            }
          }
        }
      }
    }
  }





  @media (max-width: 320px) {
    .examples-screen-container {
      padding: 32px 0;
      row-gap: 60px;
      overflow: hidden;

      .phone-screen-container {
        max-width: 100%;
        row-gap: 24px;

        .phone-screen-content {
          width: 100%;
          min-height: 280px;
          align-self: flex-end;

          .phone-screen-content-slider {
            width: 100%;
            max-height: 280px;
            overflow: visible;
          }
          .phone-img-content {
            width: 140px;
            filter: drop-shadow( 3.30699px 4.34043px 12.19453px rgba(0, 0, 0, 0.35));
          }
        }
      }

      .laptop-screen-container {
        row-gap: 24px;

        .laptop-screen-content {
          max-height: 565px;
          min-height: 565px;
          flex-direction: column;
          .laptop-img-content {
            filter: drop-shadow( 5.28098px 4.67744px 15.54118px rgba(0, 0, 0, 0.13));

            &:first-child {
              position: relative;
              left: -56px;
              width: 278px;
              margin-top: 0;
            }

            &:last-child {
              position: relative;
              right: -24px;
              align-self: flex-end;
              width: 250px;
            }
          }
        }
      }
      .notebook-screen-container {
        align-items: center;
        padding: 0 16px;
        row-gap: 24px;
        .notebook-screen-content {
          width: 100%;
          position: relative;
          min-height: 375px;
          height: 100%;

          .notebook-img-content {
            position: absolute;
            width: 168px;
            top: 0;
            filter: drop-shadow(-2.3429908752441406px 4.685981750488281px 11.71495532989502px rgba(0, 0, 0, 0.10));

            &:nth-child(2) {
              right: 0;
              left: auto;
              top: 32px;
              z-index: 1;
            }

            &:last-child {
              left: 71px;
              top: 112px;
              z-index: 2;
            }
          }
        }
      }
    }
  }

  @media (min-width: 3025px) {
    .examples-screen-container {
      padding: 200px 0;
      row-gap: 400px;

      .phone-screen-container {
        row-gap: 160px;

        .phone-screen-content {
          column-gap: 420px;

          .phone-img-content {
            width: 825px;
            filter: drop-shadow(16.33333px 16.33333px 53.66667px rgba(0, 0, 0, 0.45));
          }
        }
      }

      .laptop-screen-container {
        row-gap: 160px;

        .laptop-screen-content {
          max-height: 2678px;
          .laptop-img-content {
            filter: drop-shadow(84px 102.66667px 133.00002px rgba(0, 0, 0, 0.23));

            &:first-child {
              width: 1659px;
              margin-top: 720px;
            }
            &:last-child {
              width: 1940px;
            }
          }
        }
      }

      .notebook-screen-container {
        row-gap: 160px;
        .notebook-screen-content {
          column-gap: 280px;
          .notebook-img-content  {
            width: 915px;
            filter: drop-shadow(-8.78884220123291px 17.57768440246582px 43.9442138671875px rgba(0, 0, 0, 0.20));
          }
        }
      }
    }
  }

</style>