<template>
  <div class="button-arrow-container">
    <slot/>
    <div class="img-container">
      <img :src="getUrlIcons('arrow-right-icon.svg')" alt="arrow">
    </div>
  </div>
</template>

<script>
export default {
  name: "ButtonWithArrow"
}
</script>

<style scoped lang="scss">
  .button-arrow-container {
    width: 100%;
    display: flex;
    flex-direction: row;
    font-size: 20px;
    opacity: 1;
    line-height: 120%;
    padding: 21.5px 24px;
    align-items: center;
    justify-content: space-between;
    transition: opacity .6s ease;

    .img-container {
      width: 6px;
      height: 13px;

      & img {
        width: 100%;
        height: 100%;
      }
    }
  }

  @media (max-width: 320px) {
    .button-arrow-container {
      font-size: 16px;
      padding: 16.5px 18px;
    }
  }
</style>