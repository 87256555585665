<template>
  <div class="start-screen-container" :style="{'height' : activeHeight}">
    <vision-screen v-if="!activeMobile" :img-content="'look-border.svg'"/>
    <transition name="fade-opacity-1">
      <button-reels v-if="activeStart[1]"/>
    </transition>

    <div class="text-logo-container">
      <transition name="fade-opacity-1">
        <text-with-logo class="look"
                        v-if="activeStart[1]" :info-text="sliceElFirst"/>
      </transition>
      <transition name="fade-opacity-1">
        <text-with-logo class="visioners"
                        v-if="activeStart[2]" :info-text="sliceElSecond"/>
      </transition>
    </div>

    <div class="img-container" v-if="activeMobile && activeStart[2]">
      <LottieAnimation :animationData="require('@/assets/animation-lottie/look.json')"
                       style="height: inherit; width: inherit"
                       :flex-growth="'None'"
                       :loop="false"
                       class="brain-add-container"
                       :rendererSettings="{ preserveAspectRatio: 'xMinYMid meet' }" />
    </div>


    <transition name="fade-opacity-1">
      <color-text :color-text="colorText"
                  v-if="activeStart[0]"
                  :text-info="mainTitle"/>
    </transition>


    <transition name="fade-opacity-1">
        <main-header v-if="!activeMobile && activeStart[3]" :active-fixed="false"/>
    </transition>
  </div>
</template>

<script>
import ColorText from "@/components/shared/ColorText.vue";
import MainHeader from "@/components/widgets/MainHeader.vue";
import VisionScreen from "@/components/features/VisionScreen.vue";
import SubTitle from "@/components/shared/text/SubTitle.vue";
import TextWithLogo from "@/components/shared/textLogo/TextWithLogo.vue";
import ButtonReels from "@/components/shared/ButtonReels.vue";

export default {
  name: "StartScreen",
  components: {ButtonReels, TextWithLogo, SubTitle, VisionScreen, MainHeader, ColorText},
  data() {
    return {
      subTitleFirst: 'B2B маркетинг-бутик',
      mainTitle: 'Представляя стиль — создаем бренды',
      colorText: '—',
      subTitleSecond: 'международной группы компаний visioners',
      scrollY: 0,
      activeStart: [false, false, false, false],
      activeHeight: 0
    }
  },
  mounted() {
    if(window.innerWidth <= 769) {
      this.activeHeight = 'var(--calc-height)'
      setTimeout(() => {
        this.activeHeight = window.innerHeight + 'px'
        this.startAnimation();
      }, 500)
    } else {
      this.activeHeight = 'var(--calc-height)'
      this.startAnimation();
    }

  },
  methods: {
    startAnimation() {
      for (let i = 0; i < this.activeStart.length; i++) {
        this.activeStart[i] = true
      }
    }
  },
  computed: {
    sliceElFirst() {
      return this.subTitleFirst.split(' ');
    },
    sliceElSecond() {
      return this.subTitleSecond.split(' ');
    }
  }
}
</script>

<style scoped lang="scss">
  .start-screen-container {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: var(--calc-height);
    align-items: center;

    .button-reels-container {
      position: absolute;
      z-index: 500;
    }

    .img-container {
      width: 100%;
      max-height: 150px;
      height: 100%;
    }

    .color-text-container {
      margin: 0 auto;
    }

    .text-logo-container {
      display: flex;
      flex-direction: column;
    }

    .main-header-container {
      margin: 0 auto;
    }
  }



  @media (max-width: 3024px){
    .start-screen-container {
      padding: 128px 0 64px;
      .button-reels-container {
        right: 64px;
        top: 64px;
      }
      .text-logo-container {
        row-gap: 8px;
      }
    }
  }


  @media (max-width: 1920px) {
    .start-screen-container {
      padding: 96px 0 32px;
      .button-reels-container {
        right: 48px;
        top: 48px;
      }
      .text-logo-container {
        row-gap: 8px;
      }
    }
  }


  @media (max-width: 1600px) {
    .start-screen-container {
      padding: 96px 0 24px;
      .button-reels-container {
        right: 32px;
        top: 32px;
      }
      .text-logo-container {
        row-gap: 6px;
      }
    }
  }

  @media (max-height: 800px) {
    .start-screen-container {
      padding: 64px 0 24px;

      .text-logo-container {
        row-gap: 6px;
      }
    }
  }

  @media (max-width: 1400px) and (min-height: 801px) {
    .start-screen-container {
      padding: 96px 0 24px;

      .text-logo-container {
        row-gap: 6px;
      }
    }
  }

  @media (max-width: 1133px) {
    .start-screen-container {
      padding: 64px 0 24px;

      .text-logo-container {
        row-gap: 6px;
      }
    }
  }

  @media (max-width: 769px) {
    .start-screen-container {
      flex-direction: column-reverse;
      padding: 88px 0 32px;
      .button-reels-container {
        position: relative;
        top: auto;
        right: auto;
      }
      .color-text-container {
        max-width: 258px;
      }

      .text-logo-container {
        row-gap: 3px;
      }
    }
  }

  @media (max-width: 769px)  and (min-height: 720px) and (max-height: 1000px) {
    .start-screen-container {
      padding: 104px 0 48px;

      .color-text-container {
        max-width: 298px;
      }

      .text-logo-container {
        row-gap: 3px;
      }
    }
  }

  @media (min-height: 600px) and (max-height: 630px){

    //8 plus
  }

  @media (max-width: 375px) {
    //se new
  }

  @media (max-width: 375px) and (min-height: 630px) {
    //x
  }


  @media (max-width: 320px) {
    //se old
  }

  @media (min-width: 3025px) {
    .start-screen-container {
      padding: 256px 0 128px;

      .button-reels-container {
        right: 128px;
        top: 128px;
      }

      .text-logo-container {
        row-gap: 8px;
      }
    }
  }

</style>