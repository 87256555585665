<template>
  <div class="look-title-container" :class="{'active-look' : activeLook}">
    <main-paragraph v-if="!activeLook">
      {{ mainTitle }}
    </main-paragraph>
    <div class="main-paragraph-content" v-else>
      <main-paragraph> {{ mainTitle }} </main-paragraph>
      <div class="look-title-content">
        <main-paragraph class="look red"> {{ circleText }} </main-paragraph>
        <main-paragraph class="look"> {{ textLook }} </main-paragraph>
      </div>
    </div>

    <icon-arrow @click="routTo" :direction="activeLookDirection" :active-grey="false"/>
  </div>
</template>

<script>
import MainParagraph from "@/components/shared/text/MainParagraph.vue";
import IconArrow from "@/components/shared/IconArrow.vue";

export default {
  name: "LookTitleArrow",
  components: {IconArrow, MainParagraph},
  props: {
    activeLook: {
      type: Boolean,
      default: false
    },
    mainTitle: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      circleText: '.',
      textLook: 'look',
      footerTitleStart: 'Создать сильный бренд\nс командой ',
      footerTitleEnd: ' займет 3 недели',
    }
  },
  computed: {
    activeLookDirection() {
      if(this.activeLook) {
        return 'bottom'
      } else {
        if(this.activeMobile) {
          return 'right-red'
        } else {
          return 'bottom'
        }
      }
    }
  },
  methods: {
    routTo() {
      if(this.activeLook) {
        this.$emit('routTo')
        this.routToHomeWithScroll();
      } else {
        this.$router.push('/')
      }
    }
  }
}
</script>

<style scoped lang="scss">

.main-paragraph-container {
  text-align: center;
  white-space: pre-wrap;
  line-height: normal;
}

  .look-title-container {
    display: flex;
    flex-direction: column;
    align-items: center;

    &.active-look {
      .main-paragraph-container {
        color: var(--color-main-light-color);
      }
    }

    .main-paragraph-content {
      display: flex;
      flex-direction: row;
      .look-title-content {
        display: flex;
        flex-direction: row;
      }

      .main-paragraph-container {
        &.look {
          font-weight: 700;
        }
        &.red {
          color: var(--color-main-accent) !important;
        }
      }
    }
  }



@media (max-width: 3024px){
  .look-title-container {
    row-gap: 16px;
    .main-paragraph-container {
      font-size: 24px;
    }

    &.active-look {
      .main-paragraph-container {
        font-size: 24px;
      }
    }

    .main-paragraph-content {
      column-gap: 4px;
      .look-title-content {
        column-gap: 2px;
      }
    }

    .icon-arrow-container {
      width: 32px;
      height: 32px;
    }
  }
}


@media (max-width: 1920px) {
  .look-title-container {
    row-gap: 14px;

    .main-paragraph-container {
      font-size: 20px;
    }

    &.active-look {
      .main-paragraph-container {
        font-size: 24px;
      }
    }

    .main-paragraph-content {
      column-gap: 4px;
      .look-title-content {
        column-gap: 2px;
      }
    }
    .icon-arrow-container {
      width: 30px;
      height: 30px;
    }
  }
}


@media (max-width: 1600px) {
  .look-title-container {
    row-gap: 12px;


    .main-paragraph-container {
      font-size: 16px;
    }

    &.active-look {
      .main-paragraph-container {
        font-size: 18px;
      }
    }

    .main-paragraph-content {
      column-gap: 3px;
      .look-title-content {
        column-gap: 1px;
      }
    }

    .icon-arrow-container {
      width: 20px;
      height: 20px;
    }
  }
}
@media (max-height: 800px) {
  .look-title-container {
    row-gap: 10px;

    &.active-look {
      .main-paragraph-container {
        font-size: 16px;
      }
    }

    .main-paragraph-content {
      column-gap: 2px;
      .look-title-content {
        column-gap: 1px;
      }
    }
  }
}

@media (max-width: 1400px) and (min-height: 801px) {
  .look-title-container {
    row-gap: 12px;

    &.active-look {
      .main-paragraph-container {
        font-size: 18px;
      }
    }

    .main-paragraph-content {
      column-gap: 3px;
      .look-title-content {
        column-gap: 1px;
      }
    }
  }
}

@media (max-width: 1133px) {
  .look-title-container {
    row-gap: 10px;

    &.active-look {
      .main-paragraph-container {
        font-size: 16px;
      }
    }

    .main-paragraph-content {
      column-gap: 2px;
      .look-title-content {
        column-gap: 1px;
      }
    }
  }
}

@media (max-width: 769px) {


  .look-title-container {
    flex-direction: row;
    justify-content: flex-end;

    &.active-look {
      justify-content: center;
      row-gap: 10px;
      flex-direction: column;
      .main-paragraph-container {
        padding-left: 0;
        font-size: 14px;
      }
    }

    .main-paragraph-container {
      padding-left: 24px;
      margin: 0 auto;
      line-height: normal;
      color: var(--color-main-light-color) !important;
      font-size: 12px;
    }

    .main-paragraph-content {
      column-gap: 2px;
      .look-title-content {
        column-gap: 1px;
      }
    }
  }
}

@media (max-width: 769px)  and (min-height: 720px) and (max-height: 1000px) {

  .look-title-container {

    &.active-look {
      row-gap: 10px;
      .main-paragraph-container {
        font-size: 14px;
      }
    }

    .main-paragraph-container {
      font-size: 12px;
    }

    .main-paragraph-content {
      column-gap: 2px;
      .look-title-content {
        column-gap: 1px;
      }
    }
  }
}

@media (min-height: 600px) and (max-height: 630px){

  //8 plus
}

@media (max-width: 375px) {
  .look-title-container {
    flex-direction: row;
    justify-content: flex-end;

    &.active-look {
      justify-content: center;
      row-gap: 10px;
      flex-direction: column;
      .main-paragraph-container {
        font-size: 14px;
      }
    }

    .main-paragraph-container {
      color: var(--color-main-light-color) !important;
      font-size: 12px;
    }

    .main-paragraph-content {
      column-gap: 2px;
      .look-title-content {
        column-gap: 1px;
      }
    }
  }
}

@media (max-width: 375px) and (min-height: 630px) {
  //x
}


@media (max-width: 320px) {
  .look-title-container {
    flex-direction: row;
    justify-content: flex-end;

    &.active-look {
      justify-content: center;
      row-gap: 10px;
      flex-direction: column;
      .main-paragraph-container {
        font-size: 12px;
      }
    }

    .main-paragraph-container {
      color: var(--color-main-light-color) !important;
      font-size: 10px;
    }

    .main-paragraph-content {
      column-gap: 2px;
      .look-title-content {
        column-gap: 1px;
      }
    }

    .icon-arrow-container {
      width: 14px;
      height: 14px;
    }
  }

}

@media (min-width: 3025px) {

  .look-title-container {
    row-gap: 28px;

    &.active-look {
      .main-paragraph-container {
        font-size: 56px;
      }
    }

    .main-paragraph-content {
      column-gap: 16px;
      .look-title-content {
        column-gap: 8px;
      }
    }
  }
}

</style>