<template>
  <div class="contact-screen-container main-container" v-animateonscroll="{ enterClass: 'fade-in'}"  @animationend="changeStateLottie">
      <div class="left-info-container" >

        <div class="main-title-container" >
          <p class="main-title-content"
             v-for="(item, index) in sliceEl"
             :key="index">
            {{ item }}
            <span v-if="index === sliceEl.length - 1" class="circle-container"></span>
          </p>
        </div>

          <div class="img-container" :class="{'active ': activeAnimation}">
            <video class="video" loop playsinline preload="auto" autoplay  muted>
              <source @load="load('webm')" :src="getUrlLogo(imgContent)" type="video/webm"/>
              <source @load="load('mp4')" :src="getUrlLogo(imgContentMP4)" type="video/mp4"/>
            </video>
          </div>

      </div>
        <block-contact/>
  </div>
</template>

<script>
import InputMain from "@/components/shared/InputMain.vue";
import BlockContact from "@/components/entities/BlockContact.vue";

export default {
  name: "ContactsScreen",
  components: {BlockContact, InputMain},
  data() {
    return {
      mainTitle: 'Каким вы видите дизайн своего бизнеса ?',
      imgContent: 'robot.webm',
      imgContentMP4: 'robot.mp4',
      activeAnimation: false,
    }
  },

  computed: {
    sliceEl() {
      return this.mainTitle.split(' ')
    }
  },
}
</script>

<style scoped lang="scss">
  .contact-screen-container {
    flex-direction: row;
    align-items: flex-start;

    .left-info-container {
      position: relative;
      height: 100%;
      .main-title-container {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;

        .main-title-content {
          position: relative;

          .circle-container {
            border-radius: 50%;
            background-color: var(--color-main-accent);
            position: absolute;
          }
        }
      }

      .img-container {
        top: 0;
        display: flex;
        align-items: center;
        justify-content: center;
        position: absolute;
        width: 100%;
        height: 100%;

        &.active {
          & video {
            opacity: 1;
          }
        }

        & video {
          transition: opacity .6s ease;
          will-change: mix-blend-mode;
          mix-blend-mode: screen;
          width: 100%;
          height: 100%;
          opacity: 0;
        }
      }
    }

    .block-contact-container {
      max-width: 760px;
    }
  }

  @media (max-width: 3840px) and (min-width: 3025px) {
    .contact-screen-container {
      .left-info-container {
        .main-title-container {
          .main-title-content {
            .circle-container {
              bottom: 30.5px !important;
              right: 19.1px !important;
            }
          }
        }
      }
    }
  }


  @media (max-width: 3024px){
    .contact-screen-container {

      .left-info-container {
        max-width: 910px;
        .main-title-container {
          column-gap: 10px;
          margin-bottom: 105px;
          max-width: 778px;

          .main-title-content {
            font-size: 80px;

            .circle-container {
              width: 12px;
              height: 12px;
              bottom: 21px;
              right: 12.5px;
            }
          }
        }

        .img-container {
          max-width: 910px;
          max-height: 910px;
          min-height: 910px;
        }
      }

      .block-contact-container {
        max-width: 780px;
      }
    }
  }

  @media (max-width: 2240px){
    .contact-screen-container {

      .left-info-container {
        .main-title-container {

          .main-title-content {
            .circle-container {
              width: 12px;
              height: 12px;
              bottom: 21.2px;
              right: 12.85px;
            }
          }
        }
      }
    }
  }


  @media (max-width: 1920px) {
    .contact-screen-container {

      .left-info-container {
        max-width: 582px;
        .main-title-container {
          column-gap: 10px;
          margin-bottom: 100px;

          .main-title-content {
            font-size: 56px;

            .circle-container {
              width: 8px;
              height: 8px;
              bottom: 15.1px;
              right: 9px;
            }
          }
        }

        .img-container {
          max-width: 760px;
          max-height: 760px;
          min-height: 760px;
        }
      }

      .block-contact-container {
        max-width: 760px;
      }
    }
  }


  @media (max-width: 1600px) {
    .contact-screen-container {

      .left-info-container {
        max-width: 480px;
        .main-title-container {
          column-gap: 10px;
          margin-bottom: 80px;

          .main-title-content {
            font-size: 48px;

            .circle-container {
              width: 8px;
              height: 8px;
              bottom: 12.5px;
              right: 7px;
            }
          }
        }

        .img-container {
          max-width: 580px;
          max-height: 580px;
          min-height: 580px;
        }
      }

      .block-contact-container {
        max-width: 580px;
      }
    }
  }
  @media (max-height: 800px) {
    .contact-screen-container {

      .left-info-container {
        max-width: 480px;
        .main-title-container {
          column-gap: 10px;
          margin-bottom: 80px;

          .main-title-content {
            font-size: 48px;

            .circle-container {
              width: 7px;
              height: 7px;
              bottom: 12.5px;
              right: 7.5px;
            }
          }
        }
        .img-container {
          max-width: 580px;
          max-height: 580px;
          min-height: 580px;
        }
      }

      .block-contact-container {
        max-width: 396px;
      }
    }
  }

  @media (max-width: 1400px) and (min-height: 801px) {
    .contact-screen-container {

      .left-info-container {
        max-width: 480px;
        .main-title-container {
          column-gap: 10px;
          margin-bottom: 80px;

          .main-title-content {
            font-size: 48px;

            .circle-container {
              width: 8px;
              height: 8px;
              bottom: 12px;
              right: 7.5px;
            }
          }
        }
        .img-container {
          max-width: 580px;
          max-height: 580px;
          min-height: 580px;
        }
      }

      .block-contact-container {
        max-width: 580px;
      }
    }
  }

  @media (max-width: 1133px) {
    .contact-screen-container {

      .left-info-container {
        max-width: 480px;
        .main-title-container {
          column-gap: 10px;
          margin-bottom: 117px;

          .main-title-content {
            font-size: 48px;


            .circle-container {
              width: 7px;
              height: 7px;
              bottom: 12.4px;
              right: 7.7px;
            }
          }
        }

        .img-container {
          max-width: 580px;
          max-height: 580px;
          min-height: 580px;
        }
      }

      .block-contact-container {
        max-width: 396px;
      }
    }
  }

  @media (max-width: 769px) {
    .contact-screen-container {
      flex-direction: column;
      align-items: center;
      .left-info-container {
        display: flex;
        flex-direction: column-reverse;
        row-gap: 24px;

        .img-container {
          position: relative;
          max-width: 352px;
          max-height: 352px;
          min-height: 352px;
        }
        .main-title-container {
          max-width: 352px;
          margin-bottom: 0;
          align-items: center;
          justify-content: center;
          column-gap: 5px;

          .main-title-content {
            font-size: 28px;
            text-transform: inherit;
            .circle-container {
              width: 5px;
              height: 5px;
              bottom: 7px;
              right: 4px;
            }
          }
        }
      }
    }
  }

  @media (max-width: 769px)  and (min-height: 720px) and (max-height: 1000px) {
    .contact-screen-container {
      padding-top: 0;
      .left-info-container {
        row-gap: 32px;
        .main-title-container {
          column-gap: 5px;
          .main-title-content {
            font-size: 32px;
            .circle-container {
              width: 5px;
              height: 5px;
              bottom: 9px;
              right: 5px;
            }
          }
        }
      }
    }
  }

  @media (min-height: 600px) and (max-height: 630px){

    //8 plus
  }

  @media (max-width: 375px) {
    //se new
  }

  @media (max-width: 375px) and (min-height: 630px) {
    //x
  }


  @media (max-width: 320px) {
    //se old
  }

  @media (min-width: 3025px) {
    .contact-screen-container {
      flex-direction: row;

      .left-info-container {
        max-width: 1289px;
        .main-title-container {
          column-gap: 16px;
          .main-title-content {
            font-size: 112px;

            .circle-container {
              width: 14px;
              height: 14px;
              bottom: 31.6px;
              right: 19px;
            }
          }
        }

        .img-container {
          max-width: 1270px;
          max-height: 1270px;
          min-height: 1270px;
        }
      }

      .block-contact-container {
        max-width: 1254px;
      }
    }
  }

</style>