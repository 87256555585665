<template>
  <footer class="main-footer-container">
    <div class="main-footer-content main-container">
      <div class="info-container">
<!--        <div class="title-info-container">-->
<!--          <main-title>-->
<!--            {{ infoNum.mainTitle }}-->
<!--          </main-title>-->

<!--          <main-paragraph v-for="(item, index) in infoNum.info"-->
<!--                          :key="index">-->
<!--            {{ item }}-->
<!--          </main-paragraph>-->
<!--        </div>-->

<!--        <div class="title-info-container visioners">-->
<!--          <div class="visioners-info-container">-->
<!--            <p v-for="(item, index) in sliceEl"-->
<!--               :key="index">-->
<!--              <span v-if="item !== 'visioners'">-->
<!--                {{ item }}-->
<!--              </span>-->
<!--            <div v-else class="img-container">-->
<!--              <img :src="'visioners.svg'" alt="">-->
<!--            </div>-->
<!--            </p>-->
<!--          </div>-->

<!--          <main-paragraph v-for="(item, index) in visionersInfo.info"-->
<!--                          :key="index">-->
<!--            {{ item }}-->
<!--          </main-paragraph>-->
<!--        </div>-->

        <div class="title-info-container">
          <main-title>
            {{ officeInfo.mainTitle }}
          </main-title>

          <main-paragraph>
            {{ officeInfo.info }}
          </main-paragraph>
        </div>

        <contact-mobile/>
      </div>

      <div class="info-container">
        <div class="logo-container" @click="scrollToTop">
          <img :src="'look.svg'" alt="logo">
        </div>

        <div class="info-container small">
          <main-paragraph v-for="(item, index) in titleInfo" :key="index">
            {{ item }}
          </main-paragraph>
        </div>
      </div>

      <div class="info-container">

        <div class="info-container small button">
          <main-paragraph v-for="item in buttonInfo"
                          :key="item.id">
            <span>
              {{ item.text }}
            </span>
          </main-paragraph>
        </div>

        <main-paragraph class="watermark">
          {{ watermarkInfo }}
        </main-paragraph>
      </div>
    </div>
  </footer>
</template>

<script>
import MainTitle from "@/components/shared/footer/MainTitle.vue";
import MainParagraph from "@/components/shared/footer/MainParagraph.vue";
import {mapActions} from "vuex";
import ContactMobile from "@/components/entities/ContactMobile.vue";

export default {
  name: "MainFooterMobile",
  components: {ContactMobile, MainParagraph, MainTitle},
  data() {
    return {
      titleInfo: ['Маркетинг-бутик', 'Представляя стиль — создаем бренды'],
      watermarkInfo: '© 2014 — 2024 HoldCo «visioners»',
      infoNum: {
        mainTitle: 'ООО «Визионерс КРЕАТИВ»',
        info: ['ИНН: 7709638741', 'ОГРН: 1057748748562']
      },
      visionersInfo: {
        mainTitle: 'Входит в ГК visioners',
        info: ['АО «Группа компаний «Визионерс»', 'ИНН: 9709102369']
      },
      officeInfo: {
        mainTitle: 'Офис',
        info: 'Россия, Москва, 105064,\nул. Земляной Вал, д.9'
      },
      buttonInfo: [
        {
          id: 1,
          text: 'Политика обработки персональных данных',
          type: '/'
        }
      ]
    }
  },
  computed: {
    sliceEl() {
      return this.visionersInfo.mainTitle.split(' ')
    }
  }
}
</script>

<style scoped lang="scss">

.title-info-container {
  display: flex;
  flex-direction: column;
  align-items: center;

  .visioners-info-container {
    display: flex;
    flex-direction: row;
    color: #FDFDFD;
    align-items: baseline;

    .img-container {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 100%;
      height: 100%;

      & img {
        width: 100%;
        height: 100%;
      }
    }
  }

  &.visioners {
    .footer-main-paragraph-container {
      margin-bottom: 0;
    }
  }

  .footer-main-paragraph-container {

    &.watermark {
      font-size: 12px;
    }

    &:last-child {
      margin-bottom: 0;
    }
  }
}

.footer-main-paragraph-container {

  &.watermark {
    font-size: 12px;
  }

  & a {
    color: #B6B6B6;
  }

}

.footer-main-title-container {
  &.contact {
    color: var(--color-main-accent);
    font-size: 16px;
  }
}

.main-footer-container {
  padding: 32px 24px;
  border-top: 1px solid #B6B6B6;
  background-color: var(--color-sub-dark-color);
  width: 100%;

  .main-footer-content {
    padding: 0;
    height: 100%;
    max-width: var(--max-width);
    display: flex;
    flex-direction: column;
    align-items: center;
    margin:  0 auto;

    .info-container {
      display: flex;
      align-items: center;
      flex-direction: column;
      row-gap: 16px;

      &.contact {
        row-gap: 6px;
      }

      &.small {
        row-gap: 4px;
      }

      &.button {
        flex-direction: column-reverse;
      }
    }

  }
}


@media (max-width: 769px) {
  .title-info-container {

    .visioners-info-container {
      column-gap: 4px;
      font-size: 16px;

      .img-container {
        max-width: 66px;
        max-height: 13px;
      }
    }

    &.visioners {
      row-gap: 12px;
    }

    .footer-main-title-container {
      margin-bottom: 10px;
    }

    .footer-main-paragraph-container {
      margin-bottom: 6px;
    }
  }
}

@media (max-width: 769px)  and (min-height: 720px) and (max-height: 1000px) {
  //plus
}

@media (min-height: 600px) and (max-height: 630px){

  //8 plus
}

@media (max-width: 375px) {
  //se new
}

@media (max-width: 375px) and (min-height: 630px) {
  //x
}


@media (max-width: 320px) {
  //se old
}




</style>