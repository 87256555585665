<template>
  <div class="quiz-screen-container" :class="{'active': activeBlock}">
    <div class="header-container">
      <div class="main-title-container">
        {{ subTitle }}
      </div>
      <title-with-circle>
        {{ activeResult.typeName }}
      </title-with-circle>

      <div class="logo-img-container">
        <div class="img-container"
             :class="{'active-arr' : activeResult?.imgBrand.length > 1}"
             v-for="(item, index) in activeResult?.imgBrand" :key="index">
          <img :src="getUrlBrand(item)" alt="brand-logo">
        </div>
      </div>

    </div>
    

    <div class="quiz-screen-content">
      <p class="sub-title-container">
        {{ textBrand }} {{ activeResult?.nameBrand }}
      </p>

      <main-paragraph>
        {{ activeResult?.description }}
      </main-paragraph>

      <div class="list-container" v-for="(item, index) in titleResult" :key="index">
        <p class="sub-title-container">
          {{ item.title }}
        </p>

        <main-paragraph>
          {{ activeResult[item?.type] }}
        </main-paragraph>
      </div>


      <div class="center-content">
        <main-paragraph class="disclaimer">
          {{ disclaimerTitle }}
        </main-paragraph>

        <div class="slogan-container">
          <p class="sub-title-container">
            {{ sloganTitle }}
          </p>
          <div class="circle-container"></div>
        </div>
      </div>
    </div>

    <div class="footer-container">
      <main-paragraph>
        {{ consaltingTitle }}
      </main-paragraph>
      <look-title-arrow
          @routTo="routTo"
          :active-look="true"
          :main-title="lookTitle"/>
    </div>

  </div>
</template>

<script>
import MainParagraph from "@/components/shared/text/MainParagraph.vue";
import TitleWithCircle from "@/components/shared/TitleWithCircle.vue";
import SubTitle from "@/components/shared/text/SubTitle.vue";
import LookTitleArrow from "@/components/entities/LookTitleArrow.vue";

export default {
  name: "QuizResultScreen",
  components: {LookTitleArrow, SubTitle, TitleWithCircle, MainParagraph},
  props: ['result'],
  computed: {
    activeResult() {
      return this.$store.state.quizModule.resultInfo.find(item => item.slug === this.result)
    }
  },
  data() {
    return {
      activeBlock: false,
      subTitle: 'Результат',
      textBrand: 'Пример бренда:',
      titleResult: [
          {
            title: 'Желание:',
            type: 'wish'
          },
          {
            title: 'Слабость:',
            type: 'weakness'
          },
          {
            title: 'Страх:',
            type: 'fear'
          },
          {
            title: 'Характер:',
            type: 'character'
          },
          {
            title: 'Применение архетипа:',
            type: 'application'
          },
      ],
      disclaimerTitle: 'Результаты теста носят ознакомительный характер.',
      sloganTitle: 'Сильные бренды правят миром.\nА мы - их создаем.',
      consaltingTitle: 'Получите консультацию по построению бренда, с учетом специфики деятельности,\nистории создания и личности руководителей компании.',
      lookTitle: 'Познакомиться  с ',
    }
  },

  mounted() {
    setTimeout(() => {
      this.activeBlock = true
    }, 250)
  },

  methods: {
    routTo() {
      this.activeBlock = false
      this.$emit('routTo')
    }
  }

}
</script>

<style scoped lang="scss">
.main-paragraph-container {
  white-space: pre-wrap;
}

.main-title-container {
  color: var(--color-sub-light-color)
}

.sub-title-container {
  width: fit-content;
  margin: 0 auto;
  white-space: pre-wrap;
}

  .quiz-screen-container {
    position: relative;
    display: flex;
    flex-direction: column;
    opacity: 0;
    transition: all .6s ease;

    &.active {
      opacity: 1;
    }

    .header-container {
      display: flex;
      flex-direction: column;
      .main-title-with-circle-container {
        justify-content: center;
      }
    }

    .quiz-screen-content {
      display: flex;
      flex-direction: column;

      .list-container {
        display: flex;
        flex-direction: column;
      }
    }

    .logo-img-container {
      margin: 0 auto;
      display: flex;
      flex-direction: column;
    }

    .img-container {
      width: 100%;
      height: var(--height-img);

      &.active-arr {
        height: calc(var(--height-img) / 2);
      }

      & img {
        max-height: inherit;
        width: 100%;
        height: 100%;
        object-fit: contain;
      }
    }

    .center-content {
      display: flex;
      flex-direction: column;

      .slogan-container {
        display: flex;
        align-self: flex-end;
        flex-direction: row;
        align-items: center;

        .sub-title-container {
          line-height: 120%;
          text-align: right;
        }

        .circle-container {
          border-radius: 50%;
          background-color: var(--color-main-accent);
        }
      }
    }

    .footer-container {
      display: flex;
      align-items: center;
      flex-direction: column;
      .main-paragraph-container {
        text-align: center;
      }
    }
  }

@media (max-width: 3024px){
  .main-title-container {
    font-size: 28px;
  }
  .sub-title-container {
    font-size: 44px;
  }
  .quiz-screen-container {
    row-gap: 148px;
    .header-container {
      .main-title-container {
        margin-bottom: 80px;
      }
      .main-title-with-circle-container {
        margin-bottom: 148px;
      }
    }
    .quiz-screen-content {
      row-gap: 85px;
      .list-container {
        row-gap: 32px;
      }
    }

    .logo-img-container {
      row-gap: 72px;
    }

    .img-container {
      max-width: 690px;
      --height-img: 300px;
    }

    .center-content {
      row-gap: 105px;
      .slogan-container {
        column-gap: 22px;
        .circle-container {
          width: 16px;
          height: 16px;
        }
      }
    }
    .footer-container {
      row-gap: 40px;
      .main-paragraph-container {
        font-size: 28px;
      }
    }
  }
}


@media (max-width: 1920px) {
  .main-title-container {
    font-size: 20px;
  }
  .sub-title-container {
    font-size: 32px;
  }
  .quiz-screen-container {
    row-gap: 140px;
    .header-container {
      .main-title-container {
        margin-bottom: 72px;
      }
      .main-title-with-circle-container {
        margin-bottom: 140px;
      }
    }
    .quiz-screen-content {
      row-gap: 64px;
      .list-container {
        row-gap: 32px;
      }
    }

    .logo-img-container {
      row-gap: 64px;
    }

    .img-container {
      max-width: 450px;
      --height-img: 225px;
    }
    .center-content {
      row-gap: 64px;
      .slogan-container {
        column-gap: 20px;
        .circle-container {
          width: 14px;
          height: 14px;
        }
      }
    }
    .footer-container {
      row-gap: 72px;
      .main-paragraph-container {
        font-size: 24px;
      }
    }
  }
}


@media (max-width: 1600px) {
  .main-title-container {
    font-size: 18px;
  }
  .sub-title-container {
    font-size: 28px;
  }
  .quiz-screen-container {
    row-gap: 90px;
    .header-container {
      .main-title-container {
        margin-bottom: 64px;
      }
      .main-title-with-circle-container {
        margin-bottom: 90px;
      }
    }
    .quiz-screen-content {
      row-gap: 64px;
      .list-container {
        row-gap: 20px;
      }
    }
    .img-container {
      max-width: 632px;
      --height-img: 200px;
    }
    .logo-img-container {
      row-gap: 48px;
    }

    .center-content {
      row-gap: 76px;
      .slogan-container {
        column-gap: 16px;
        .circle-container {
          width: 14px;
          height: 14px;
        }
      }
    }
    .footer-container {
      row-gap: 64px;
      .main-paragraph-container {
        font-size: 18px;
      }
    }
  }
}
@media (max-height: 800px) {
  .main-title-container {
    font-size: 16px;
  }
  .sub-title-container {
    font-size: 24px;
  }
  .main-paragraph-container {
    font-size: 16px;
  }
  .quiz-screen-container {
    row-gap: 60px;
    .header-container {
      .main-title-container {
        margin-bottom: 60px;
      }
      .main-title-with-circle-container {
        margin-bottom: 60px;
      }
    }
    .quiz-screen-content {
      row-gap: 40px;
      .list-container {
        row-gap: 16px;
      }
    }

    .logo-img-container {
      row-gap: 32px;
    }

    .img-container {
      max-width: 446px;
      --height-img: 150px;
    }
    .center-content {
      row-gap: 46px;
      .slogan-container {
        column-gap: 14px;
        .circle-container {
          width: 14px;
          height: 14px;
        }
      }
    }
    .footer-container {
      row-gap: 88px;
      .main-paragraph-container {
        font-size: 16px;
      }
    }
  }
}

@media (max-width: 1400px) and (min-height: 801px) {
  .main-title-container {
    font-size: 18px;
  }
  .sub-title-container {
    font-size: 28px;
  }
  .quiz-screen-container {
    row-gap: 90px;
    .header-container {
      .main-title-container {
        margin-bottom: 64px;
      }
      .main-title-with-circle-container {
        margin-bottom: 90px;
      }
    }
    .quiz-screen-content {
      row-gap: 64px;
      .list-container {
        row-gap: 20px;
      }
    }
    .img-container {
      max-width: 632px;
      --height-img: 200px;
    }
    .logo-img-container {
      row-gap: 48px;
    }
    .center-content {
      row-gap: 76px;
      .slogan-container {
        column-gap: 16px;
        .circle-container {
          width: 14px;
          height: 14px;
        }
      }
    }
    .footer-container {
      row-gap: 64px;
      .main-paragraph-container {
        font-size: 18px;
      }
    }
  }
}

@media (max-width: 1133px) {
  .main-title-container {
    font-size: 16px;
  }
  .sub-title-container {
    font-size: 24px;
  }
  .main-paragraph-container {
    font-size: 16px;
  }
  .quiz-screen-container {
    row-gap: 60px;
    .header-container {
      .main-title-container {
        margin-bottom: 60px;
      }
      .main-title-with-circle-container {
        margin-bottom: 60px;
      }
    }
    .quiz-screen-content {
      row-gap: 40px;
      .list-container {
        row-gap: 16px;
      }
    }

    .logo-img-container {
      row-gap: 32px;
    }

    .img-container {
      max-width: 446px;
      --height-img: 150px;
    }
    .center-content {
      row-gap: 46px;
      .slogan-container {
        column-gap: 14px;
        .circle-container {
          width: 14px;
          height: 14px;
        }
      }
    }
    .footer-container {
      row-gap: 88px;
      .main-paragraph-container {
        font-size: 16px;
      }
    }
  }
}


@media (max-width: 769px) {
  .main-title-container {
    font-size: 12px;
  }
  .sub-title-container {
    font-size: 16px;
  }

  .main-paragraph-container {
    font-size: 12px;
  }

  .quiz-screen-container {
    padding-bottom: 30px;
    row-gap: 104px;
    .header-container {
      .main-title-container {
        margin-bottom: 18px;
      }
      .main-title-with-circle-container {
        margin-bottom: 104px;
      }
    }
    .quiz-screen-content {
      row-gap: 20px;
      .list-container {
        row-gap: 14px;
      }
    }
    .img-container {
      max-width: 100%;
      --height-img: 92px;
    }
    .center-content {
      row-gap: 20px;

      .disclaimer {
          font-size: 10px;
          margin-left: 9px;
       }
      .slogan-container {
        flex-direction: row-reverse;
        column-gap: 12px;
        align-self: flex-start;

        .sub-title-container {
          text-align: left;
          font-size: 18px;
        }

        .circle-container {
          width: 12px;
          height: 12px;
        }
      }
    }
    .footer-container {
      margin-top: -86px;
      row-gap: 108px;
      .main-paragraph-container {
        font-size: 12px;
        text-align: justify;
        white-space: normal;
      }
    }
  }
}

@media (max-width: 769px)  and (min-height: 720px) and (max-height: 1000px) {
  .main-title-container {
    font-size: 14px;
  }
  .sub-title-container {
    font-size: 16px;
  }

  .main-paragraph-container {
    font-size: 14px;
  }

  .quiz-screen-container {
    padding-bottom: 79px;
    row-gap: 124px;
    .header-container {
      .main-title-container {
        margin-bottom: 20px;
      }
      .main-title-with-circle-container {
        margin-bottom: 124px;
      }
    }
    .quiz-screen-content {
      row-gap: 24px;
      .list-container {
        row-gap: 16px;
      }
    }
    .img-container {
      max-width: 100%;
      --height-img: 92px;
    }
    .center-content {
      row-gap: 47px;

      .disclaimer {
        font-size: 14px;
        margin-left: 9px;
      }
      .slogan-container {
        column-gap: 18px;
        .sub-title-container {
          font-size: 22px;
        }

        .circle-container {
          width: 17px;
          height: 17px;
        }
      }
    }
    .footer-container {
      margin-top: -77px;
      row-gap: 47px;
      .main-paragraph-container {
        font-size: 14px;
      }
    }
  }
}

@media (min-height: 600px) and (max-height: 630px){

  //8 plus
}

@media (max-width: 375px) {
  .main-title-container {
    font-size: 12px;
  }
  .sub-title-container {
    font-size: 16px;
  }

  .main-paragraph-container {
    font-size: 12px;
  }

  .quiz-screen-container {
    padding-bottom: 30px;
    row-gap: 51px;
    .header-container {
      .main-title-container {
        margin-bottom: 18px;
      }
      .main-title-with-circle-container {
        margin-bottom: 51px;
      }
    }
    .quiz-screen-content {
      row-gap: 20px;
      .list-container {
        row-gap: 14px;
      }
    }
    .img-container {
      max-width: 100%;
      --height-img: 92px;
    }
    .center-content {
      row-gap: 18px;

      .disclaimer {
        font-size: 10px;
        margin-left: 9px;
      }
      .slogan-container {
        column-gap: 12px;

        .sub-title-container {
          font-size: 18px;
        }

        .circle-container {
          width: 12px;
          height: 12px;
        }
      }
    }
    .footer-container {
      margin-top: -33px;
      row-gap: 20px;
      .main-paragraph-container {
        font-size: 12px;
      }
    }
  }
}

@media (max-width: 375px) and (min-height: 630px) {
  .main-title-container {
    font-size: 12px;
  }
  .sub-title-container {
    font-size: 16px;
  }

  .main-paragraph-container {
    font-size: 12px;
  }

  .quiz-screen-container {
    padding-bottom: 30px;
    row-gap: 91.5px;
    .header-container {
      .main-title-container {
        margin-bottom: 18px;
      }
      .main-title-with-circle-container {
        margin-bottom: 91.5px;
      }
    }
    .quiz-screen-content {
      row-gap: 20px;
      .list-container {
        row-gap: 14px;
      }
    }
    .img-container {
      max-width: 100%;
      --height-img: 92px;
    }
    .center-content {
      row-gap: 18px;

      .disclaimer {
        font-size: 10px;
        margin-left: 9px;
      }
      .slogan-container {
        column-gap: 12px;

        .sub-title-container {
          font-size: 18px;
        }

        .circle-container {
          width: 12px;
          height: 12px;
        }
      }
    }
    .footer-container {
      margin-top: -83.5px;
      row-gap: 83px;
      .main-paragraph-container {
        font-size: 12px;
      }
    }
  }
}


@media (max-width: 320px) {
  .main-title-container {
    font-size: 10px;
  }
  .sub-title-container {
    font-size: 14px;
  }

  .main-paragraph-container {
    font-size: 10px;
  }

  .quiz-screen-container {
    padding-bottom: 24px;
    row-gap: 32px;
    .header-container {
      .main-title-container {
        margin-bottom: 14px;
      }
      .main-title-with-circle-container {
        margin-bottom: 32px;
      }
    }
    .quiz-screen-content {
      row-gap: 14px;
      .list-container {
        row-gap: 12px;
      }
    }
    .img-container {
      max-width: 100%;
      --height-img: 86px;
    }
    .center-content {
      row-gap: 18px;

      .disclaimer {
        font-size: 10px;
        margin-left: 9px;
      }
      .slogan-container {
        column-gap: 12px;

        .sub-title-container {
          font-size: 16px;
        }

        .circle-container {
          width: 12px;
          height: 12px;
        }
      }
    }
    .footer-container {
      margin-top: -24px;
      row-gap: 20px;
      .main-paragraph-container {
        font-size: 10px;
      }
    }
  }
}

@media (min-width: 3025px) {
  .main-title-container {
    font-size: 64px;
  }
  .sub-title-container {
    font-size: 72px;
  }
  .quiz-screen-container {
    row-gap: 180px;
    .header-container {
      .main-title-container {
        margin-bottom: 180px;
      }
      .main-title-with-circle-container {
        margin-bottom: 180px;
      }
    }
    .quiz-screen-content {
      row-gap: 120px;
      .list-container {
        row-gap: 100px;
      }
    }

    .logo-img-container {
      row-gap: 72px;
    }

    .img-container {
      max-width: 1892px;
      --height-img: 445px;
    }
    .center-content {
      row-gap: 186px;
      .slogan-container {
        column-gap: 38px;
        .circle-container {
          width: 40px;
          height: 40px;
        }
      }
    }
    .footer-container {
      row-gap: 332px;
      .main-paragraph-container {
        font-size: 52px;
      }
    }
  }
}

</style>