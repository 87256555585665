<template>
  <div class="quiz-list-item-container" @click="changeStatus" :class="{'active-big' : activeBig}">
    <check-box  :is-active="infoItem?.active" :active-block="true"/>

    <main-paragraph :class="{'active' : infoItem.active}">
      {{ infoItem?.text }}
    </main-paragraph>
  </div>
</template>

<script>
import ButtonAgreement from "@/components/shared/ButtonAgreement.vue";
import MainParagraph from "@/components/shared/text/MainParagraph.vue";
import CheckBox from "@/components/shared/CheckBox.vue";
import {mapActions} from "vuex";

export default {
  name: "ItemQuizList",
  props: {
    infoItem: {
      type: Object,
      required: true
    },
    activeBig: {
      type: Boolean,
      default: false
    }
  },
  components: {CheckBox, MainParagraph, ButtonAgreement},
  methods: {
    changeStatus() {
      this.$emit('changeStatus', this.infoItem)
    },
  }
}
</script>

<style scoped lang="scss">
  .quiz-list-item-container {
    display: flex;
    flex-direction: row;
    align-items: center;

    .main-paragraph-container {
      cursor: pointer;
      transition: color .6s ease;
      &.active {
        color: var(--color-main-light-color)
      }

      &:hover {
        color: var(--color-main-light-color)
      }
    }
  }


  @media (max-width: 3024px){
    .quiz-list-item-container {
      column-gap: 26px;


      &.active-big {
        column-gap: 42px;

        .check-box-container {
          --check-box-size: 66px;
        }

        .main-paragraph-container {
          font-size: 42px;
        }
      }
    }
  }


  @media (max-width: 1920px) {
    .quiz-list-item-container {
      column-gap: 26px;


      &.active-big {
        column-gap: 32px;

        .check-box-container {
          --check-box-size: 54px;
        }

        .main-paragraph-container {
          font-size: 36px;
        }
      }
    }
  }


  @media (max-width: 1600px) {
    .quiz-list-item-container {
      column-gap: 26px;

      &.active-big {
        column-gap: 24px;

        .check-box-container {
          --check-box-size: 42px;
        }

        .main-paragraph-container {
          font-size: 28px;
        }
      }


      .main-paragraph-container {
        font-size: 18px;
      }
    }
  }
  @media (max-height: 800px) {
    .quiz-list-item-container {
      column-gap: 12px;

      &.active-big {
        column-gap: 20px;

        .check-box-container {
          --check-box-size: 32px;
        }

        .main-paragraph-container {
          font-size: 20px;
        }
      }

      .main-paragraph-container {
        font-size: 16px;
      }
    }
  }

  @media (max-width: 1400px) and (min-height: 801px) {
    .quiz-list-item-container {
      column-gap: 26px;

      &.active-big {
        column-gap: 24px;

        .check-box-container {
          --check-box-size: 42px;
        }

        .main-paragraph-container {
          font-size: 28px;
        }
      }

      .main-paragraph-container {
        font-size: 18px;
      }
    }
  }

  @media (max-width: 1133px) {
    .quiz-list-item-container {
      column-gap: 12px;

      &.active-big {
        column-gap: 20px;

        .check-box-container {
          --check-box-size: 32px;
        }

        .main-paragraph-container {
          font-size: 20px;
        }
      }


      .main-paragraph-container {
        font-size: 16px;
      }
    }
  }

  @media (max-width: 769px) {
    .quiz-list-item-container {
      column-gap: 8px;


      &.active-big {
        column-gap: 8px;

        .check-box-container {
          --check-box-size: 24px;
        }

        .main-paragraph-container {
          font-size: 14px;
        }
      }

      .check-box-container {
        border-radius: 5px;
        --check-box-size: 20px;
      }

      .main-paragraph-container {
        font-size: 12px;
      }
    }
  }

  @media (max-width: 769px)  and (min-height: 720px) and (max-height: 1000px) {
    .quiz-list-item-container {
      column-gap: 8px;

      .check-box-container {
        border-radius: 6px;
        --check-box-size: 24px;
      }

      .main-paragraph-container {
        font-size: 14px;
      }
    }
  }

  @media (min-height: 600px) and (max-height: 630px){

    //8 plus
  }

  @media (max-width: 375px) {
    //se new
  }

  @media (max-width: 375px) and (min-height: 630px) {
    //x
  }


  @media (max-width: 320px) {
    .quiz-list-item-container {
      column-gap: 8px;

      .check-box-container {
        border-radius: 4px;
        --check-box-size: 16px;
      }

      .main-paragraph-container {
        font-size: 12px;
      }
    }
  }

  @media (min-width: 3025px) {
    .quiz-list-item-container {
      column-gap: 32px;

      &.active-big {
        column-gap: 64px;

        .check-box-container {
          --check-box-size: 96px;
        }

        .main-paragraph-container {
          font-size: 56px;
        }
      }
    }
  }

</style>