<template>
  <div class="block-title-container">
    <title-with-circle v-animateonscroll="{ enterClass: 'fade-in' }">
      {{ mainTitle }}
    </title-with-circle>

    <main-paragraph v-animateonscroll="{ enterClass: 'fade-in' }">
      {{ mainParagraph }}
    </main-paragraph>

    <button-main v-animateonscroll="{ enterClass: 'fade-in-down' }"
        v-if="activeButton"
        :active-dark="buttonInfo.activeDark"
        @click="routTo(buttonInfo?.rout)">
      {{ buttonInfo.text }}
    </button-main>
  </div>
</template>

<script>

import TitleWithCircle from "@/components/shared/TitleWithCircle.vue";
import MainParagraph from "@/components/shared/text/MainParagraph.vue";
import ButtonMain from "@/components/shared/ButtonMain.vue";

export default {
  name: "BlockTitle",
  components: {ButtonMain, MainParagraph, TitleWithCircle},
  props: {
    mainTitle: {
      type: String,
      required: true
    },
    mainParagraph: {
      type: String,
      required: true
    },
    buttonInfo: {
      type: Object,
      required: true
    },
    activeButton: {
      type: Boolean,
      default: false
    }
  },
  methods: {
    routTo(rout) {
      if(rout) {

      } else {
        this.$emit('actionButton')
      }
    },
  }
}
</script>

<style scoped lang="scss">
  .block-title-container {
    display: flex;
    flex-direction: column;
    align-items: center;

    .main-paragraph-container {
      text-align: center;
      white-space: pre-wrap;
    }
  }


  @media (max-width: 3024px){
    .block-title-container {
      max-width: 1300px;
      row-gap: 40px;
    }
  }


  @media (max-width: 1920px) {
  .block-title-container {
    max-width: 950px;
    row-gap: 40px;
  }
  }

  @media (max-width: 1600px) {
    .block-title-container {
      row-gap: 32px;
    }
  }


  @media (max-width: 769px) {
    .block-title-container {
      row-gap: 12px;
      max-width: 100%;

      .main-paragraph-container {
        line-height: 150%;
        font-size: 10px;
      }
    }
  }

  @media (max-width: 769px)  and (min-height: 720px) and (max-height: 1000px) {
    .block-title-container {
      row-gap: 16px;
      .main-paragraph-container {
        font-size: 12px;
      }
    }
  }

  @media (min-height: 600px) and (max-height: 630px){

    //8 plus
  }

  @media (max-width: 375px) {
    //se new
  }

  @media (max-width: 375px) and (min-height: 630px) {
    //x
  }


  @media (max-width: 320px) {
    //se old
  }

  @media (min-width: 3025px) {
    .block-title-container {
      max-width: 2042px;
      row-gap: 64px;
    }
  }

</style>