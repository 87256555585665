<template>
  <div class="main-button-container" @mouseenter="focusTrue" @mouseleave="focusFalse">
      <div class="icon-container"
          :class="{'active-hover' : focusParam}">
          <img :src="getUrlIcons(iconActive + '-hover.svg')" alt="icon">
      </div>
    <slot/>
  </div>
</template>

<script>
export default {
  name: "ButtonSquareIcon",
  props: {
    iconActive: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      focusParam: false
    }
  }
}
</script>

<style scoped lang="scss" >
  .main-button-container {
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    max-width: var(--size);
    max-height: var(--size);
    min-width: var(--size);
    min-height: var(--size);
    background-color: var(--color-sub-dark-color);

    .icon-container {
      display: flex;
      position: relative;
      justify-content: center;
      align-items: center;
      filter: blur(var(--active-blur-button));
      transition: filter .6s ease;

      &.active-hover {
        filter: blur(0px)
      }

      & img {
        width: 100%;
        height: 100%;
      }
    }
  }

  @media (max-width: 3024px){
    .main-button-container {
      --size: 66px;
      border-radius: 16px;

      .icon-container {
        width: 24px;
        height: 24px;
      }
    }
  }

  @media (max-width: 1920px) {
    .main-button-container {
      --size: 54px;
      border-radius: 12px;


      .icon-container {
        width: 20px;
        height: 20px;
      }
    }
  }

  @media (max-width: 1600px) {
    .main-button-container {
      --size: 42px;
      border-radius: 10px;


      .icon-container {
        width: 16px;
        height: 16px;
      }
    }
  }
  @media (max-height: 800px) {
    .main-button-container {
      --size: 34px;
      border-radius: 8px;


      .icon-container {
        width: 14px;
        height: 14px;
      }
    }
  }

  @media (max-width: 1400px) and (min-height: 801px) {
    .main-button-container {
      --size: 42px;
      border-radius: 10px;


      .icon-container {
        width: 16px;
        height: 16px;
      }
    }
  }

  @media (max-width: 1133px) {
    .main-button-container {
      --size: 34px;
      border-radius: 8px;


      .icon-container {
        width: 14px;
        height: 14px;
      }
    }
  }
  @media (max-width: 769px) {
    .main-button-container {
      .icon-container {
        filter: blur(0px);
      }
    }
  }

  @media (max-width: 769px)  and (min-height: 720px) and (max-height: 1000px) {
    //iPhone Plus
  }
  @media (min-height: 600px) and (max-height: 630px){
    //для экранов 8 плюс
  }

  @media (max-width: 375px){
    //iPhone 8
  }


  @media (max-width: 375px) and (min-height: 630px) {
    //iPhone X
  }


  @media (max-width: 320px) {
    //iPhone SE
  }

  @media (min-width: 3025px) {
    .main-button-container {
      --size: 96px;
      border-radius: 24px;

      .icon-container {
        width: 32px;
        height: 32px;
      }
    }
  }

</style>