<template>
  <modal-frame-background>
  <div class="frame-modal-container">
    <div class="frame-modal-title-container">
      <button-square-icon   :icon-active="'cross'"
                            class="active-blur"
                            @click="closeModal"/>
    </div>
    <div class="video-container">
      <video id="video 1" ref="videoPlayer" :class="['video-content']"
             :preload="'auto'" :controls="true">
        <source src="video_desktop.mp4" type="video/mp4"/>
      </video>
    </div>
  </div>
  </modal-frame-background>
</template>

<script>
import ModalFrameBackground from "@/components/entities/ModalFrameBackground.vue";
import ModalFrame from "@/components/entities/ModalFrame.vue";
import ButtonSquareIcon from "@/components/shared/ButtonSquareIcon.vue";
import ButtonMain from "@/components/shared/ButtonMain.vue";

export default {
  name: "ModalVideo",
  components: {ButtonMain, ButtonSquareIcon, ModalFrame, ModalFrameBackground},
  methods: {
    closeModal() {
      this.$emit('closeModal')
    }
  }
}
</script>

<style scoped lang="scss">
.frame-modal-container {
  display: flex;
  flex-direction: column;
  row-gap: 16px;
  width: 90%;
  height: 90%;
  margin: 16px;
  position: fixed;
  top: 48%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 916;
  @media (max-width: 769px) {
    justify-content: center;
    margin: 0;
  }
  .frame-modal-title-container {
    .main-button-container {
      margin-left: auto;
    }
  }
}
  .video-container {
    border-color: var(--color-sub-dark-color);
    border-style: solid;
    background-color: var(--color-main-dark-color);
    overflow: hidden;
    height: 100%;
    align-self: center;
    @media (max-width: 3024px){
      border-radius: 16px;
    }


    @media (max-width: 1920px) {
      border-radius: 12px;
      height: auto;
    }

    @media (max-width: 1600px) {
      border-radius: 10px;
    }
    @media (max-height: 800px) {
      border-radius: 16px;
    }

    @media (max-width: 1400px) and (min-height: 801px) {
      border-radius: 12px;
    }

    @media (max-width: 1133px) {

      border-radius: 16px;
    }
    @media (max-width: 769px) {
      border-radius: 4px;
    }


    @media (min-width: 3025px) {
      border-radius: 12px;
    }
    & video {
      width: 100%;
      height: 100%;
    }
  }
</style>