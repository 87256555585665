<template>
  <div class="about-screen-container">
    <div class="about-screen-content main-container" v-animateonscroll="{ enterClass: 'fade-in'}" @animationend="changeStateLottie">
      <div class="left-info-container">


        <div class="top-info-container">
          <text-with-logo-big :text-info="sliceEl"/>
          <button-main @click="openModal">
            {{ textButton }}
          </button-main>
          <div class="text-info-container">
            <main-paragraph v-for="(item, index) in mainParagraph" :key="index">
              {{ item }}
            </main-paragraph>
          </div>
        </div>



<!--        <button-main>-->
<!--            {{ textButton }}-->
<!--        </button-main>-->
      </div>
      
      <div class="right-info-container">
        <LottieAnimation :animationData="require('@/assets/animation-lottie/Brain main.json')"
                         v-if="activeAnimation"
                         :speed=".5"
                         @onComplete="activeAnimationLogo = true"
                         style="height: inherit; width: inherit; opacity: .2"
                         :flex-growth="'None'"
                         :loop="false"
                         :delay="250"
                         :rendererSettings="{ preserveAspectRatio: 'xMidYMid meet' }" />
        <LottieAnimation :animationData="require('@/assets/animation-lottie/Brain add.json')"
                         v-if="activeAnimation"
                         :speed=".5"
                         style="height: inherit; width: inherit; opacity: 1"
                         :flex-growth="'None'"
                         class="brain-add-container"
                         :loop="true"
                         :delay="250"
                         :rendererSettings="{ preserveAspectRatio: 'xMidYMid meet' }" />
<!--        <transition name="fade-opacity-animation">-->
<!--          <div class="img-container visioners" v-if="!activeMobile && activeAnimationLogo">-->
<!--            <img :src="getUrlLottie(lottieImgVisioners)" alt="visioners">-->
<!--          </div>-->
<!--        </transition>-->
      </div>
    </div>
  </div>
</template>

<script>
import MainParagraph from "@/components/shared/text/MainParagraph.vue";
import MainTitle from "@/components/shared/text/MainTitle.vue";
import ButtonMain from "@/components/shared/ButtonMain.vue";
import TextWithLogoBig from "@/components/shared/textLogo/TextWithLogoBig.vue";
import {mapActions} from "vuex";

export default {
  name: "AboutScreen",
  components: {TextWithLogoBig, ButtonMain, MainTitle, MainParagraph},
  data() {
    return {
      activeAnimation: false,
      activeAnimationLogo: false,
      lottieImgVisioners: 'visioner-brain.svg',
      lottieImg: 'brain.svg',
      mainTitle: 'look – входит в структуру  международной группы компаний visioners',
      mainParagraph: ['Небольшая команда из 12 человек с широким профилем компетенций от разработки сложных и креативных сайтов, до создания и продвижения международных брендов, готова предоставить помощь на любом этапе развития Вашего бизнеса и личного бренда.',
      'В основе нашей работы лежит методичный подход с глубинным пониманием особенностей развития бизнеса в разных отраслях, креативность в решении стандартных задач и умение донести самые сложные смыслы в форме солидных и изящных решений.'],
      textButton: 'Связаться с нами'
    }
  },
  computed: {
    sliceEl() {
      return this.mainTitle.split(' ');
    }
  },
  methods: {
    ...mapActions(['ACTIVE_MODAL_CONTACT']),
    openModal(){
      this.ACTIVE_MODAL_CONTACT(true)
    }
  }
}
</script>

<style scoped lang="scss">
  .about-screen-container {
    width: 100%;
    background-color: var(--color-sub-dark-color);

    .about-screen-content{
      --padding-top-bottom: 0;
      --max-width: var(--calc-width);
      display: flex;
      flex-direction: row;
      width: 100%;
      justify-content: space-between;
      position: relative;
      overflow: hidden;

      .left-info-container {
        display: flex;
        flex-direction: column;

        .top-info-container {
          display: flex;
          flex-direction: column;
          .text-info-container {
            display: flex;
            flex-direction: column;
          }
        }
      }

      .right-info-container {
        display: flex;
        align-items: center;
        justify-content: center;
        position: absolute;
        right: 0;
        width: 100%;
        height: 100%;

        .brain-add-container {
          position: absolute;
        }

        .img-container {
          width: 100%;
          height: 100%;

          &.visioners {
            position: absolute;
          }

          & img {
            width: 100%;
            height: 100%;
            object-fit: contain;
          }
        }


      }
    }
  }

  @media (max-width: 3840px){
    .about-screen-container {
      .about-screen-content{
        padding-left: 260px;

        .left-info-container {
          padding: 560px 0 280px;
          max-width: 1701px;
          row-gap: 64px;

          .top-info-container {
            row-gap: 64px;

            .text-info-container {
              max-width: 1380px;
              row-gap: 64px;
            }
          }

        }

        .right-info-container {
          max-width: 1801px;
          max-height: 1897px;
          right: 0;

          .img-container {
            &.visioners {
              left: 32%;
              top: 32%;
              max-width: 590px;
              max-height: 425px;
            }
          }
        }
      }
    }
  }

  @media (max-width: 3024px) and (min-width: 2241px){
    .about-screen-container {
      .about-screen-content{
        padding-left: 330px;

        .left-info-container {
          padding: 320px 0 160px;
          max-width: 1100px;
          row-gap: 40px;

          .top-info-container {
            row-gap: 40px;

            .text-container {
              max-width: 1100px;
            }

            .text-info-container {
              row-gap: 24px;
              max-width: 920px;

              .main-paragraph-container {
                font-size: 28px;
              }
            }
          }
        }

        .right-info-container {
          max-width: 1014px;
          max-height: 1142px;
          right: 51px;

          .img-container {
            &.visioners {
              left: 30%;
              top: 30%;
              max-width: 391px;
              max-height: 267px;
            }
          }
        }
      }
    }
  }

  @media (max-width: 2240px) and (min-width: 1921px){
    .about-screen-container {
      .about-screen-content{
        padding-left: 170px;

        .left-info-container {
          padding: 320px 0 160px;
          max-width: 1100px;
          row-gap: 56px;
          .top-info-container {
            row-gap: 40px;

            .text-container {
              max-width: 1100px;
            }

            .text-info-container {
              row-gap: 24px;
              max-width: 920px;

              .main-paragraph-container {
                font-size: 28px;
              }
            }
          }
        }

        .right-info-container {
          max-width: 1004px;
          max-height: 1204px;
          right: 12px;

          .img-container {
            &.visioners {
              left: 30%;
              top: 30%;
              max-width: 391px;
              max-height: 267px;
            }
          }
        }
      }
    }
  }


  @media (max-width: 1920px) {
    .about-screen-container {
      .about-screen-content{
        padding-left: 190px;

        .left-info-container {
          padding: 240px 0 120px;
          max-width: 960px;
          row-gap: 64px;
          .top-info-container {
            row-gap: 32px;

            .text-info-container {
              max-width: 667px;
              row-gap: 20px;
            }
          }
        }

        .right-info-container {
          max-width: 871px;
          max-height: 100%;
          right: -24px;

          .img-container {
            &.visioners {
              left: 30%;
              top: 30%;
              max-width: 300px;
              max-height: 216px;
            }
          }
        }
      }
    }
  }


  @media (max-width: 1600px) {
    .about-screen-container {
      .about-screen-content{
        padding-left: 130px;

        .left-info-container {
          padding: 200px 0 100px;
          max-width: 720px;
          row-gap: 40px;
          .top-info-container {
            row-gap: 32px;

            .text-info-container {
              max-width: 592px;
              row-gap: 18px;
            }
          }
        }

        .right-info-container {
          max-width: 666px;
          max-height: 745px;
          right: -25px;

          .img-container {
            &.visioners {
              left: 28%;
              top: 28%;
              max-width: 266px;
              max-height: 192px;
            }
          }
        }
      }
    }
  }

  @media (max-height: 800px) {
    .about-screen-container {
      .about-screen-content{
        padding-left: 110px;

        .left-info-container {
          padding: 160px 0 80px;
          max-width: 575px;
          row-gap: 48px;
          .top-info-container {
            row-gap: 24px;
            .text-info-container {
              max-width: 530px;
              row-gap: 16px;
            }
          }
        }

        .right-info-container {
          max-width: 647px;
          max-height: 618px;
          right: -36px;

          .img-container {
            &.visioners {
              left: 32%;
              top: 28%;
              max-width: 200px;
              max-height: 174px;
            }
          }
        }
      }
    }
  }

  @media (max-width: 1400px) and (min-height: 801px) {
    .about-screen-container {
      .about-screen-content{
        padding-left: 90px;

        .left-info-container {
          padding: 200px 0 100px;
          max-width: 720px;
          row-gap: 40px;
          .top-info-container {
            row-gap: 32px;

            .text-info-container {
              max-width: 592px;
              row-gap: 18px;
            }
          }
        }

        .right-info-container {
          max-width: 666px;
          max-height: 745px;
          right: -10px;

          .img-container {
            &.visioners {
              left: 28%;
              top: 28%;
              max-width: 266px;
              max-height: 192px;
            }
          }
        }
      }
    }
  }

  @media (max-width: 1133px) {
    .about-screen-container {
      .about-screen-content{
        padding-left: 37px;

        .left-info-container {
          padding: 160px 0 80px;
          max-width: 580px;
          row-gap: 48px;
          .top-info-container {
            row-gap: 32px;

            .text-info-container {
              max-width: 530px;
              row-gap: 24px;

              .main-paragraph-container {
                font-size: 16px;
              }
            }
          }
        }

        .right-info-container {
          max-width: 604px;
          max-height: 612px;
          right: -36px;

          .img-container {
            &.visioners {
              left: 29%;
              top: 29%;
              max-width: 230px;
              max-height: 174px;
            }
          }
        }
      }
    }
  }

  @media (max-width: 769px) {
    .about-screen-container {
      .about-screen-content{
        padding: 0 20px;

        .left-info-container {
          z-index: 1;
          position: relative;
          padding: 32px 0;
          max-width: 300px;
          .top-info-container {
            row-gap: 32px;

            .text-info-container {
              max-width: 284px;
              row-gap: 16px;

              .main-paragraph-container {
                line-height: 150%;
                font-size: 12px;
              }
            }
          }
        }

        .right-info-container {
          z-index: 0;
          max-width: 100%;
          max-height: 100%;
          height: 125%;
          left: 55%;
          top: 0;
        }
      }
    }
  }

  @media (max-width: 769px)  and (min-height: 720px) and (max-height: 1000px) {
    .about-screen-container {
      .about-screen-content{
        padding: 0 24px;

        .left-info-container {
          padding: 48px 0;
        }
      }
    }
  }

  @media (min-height: 600px) and (max-height: 630px){

    //8 plus
  }

  @media (max-width: 375px) {
    //se new
  }

  @media (max-width: 375px) and (min-height: 630px) {
    //x
  }


  @media (max-width: 320px) {
    //se old
  }

  @media (min-width: 3841px) {
    .about-screen-container {
      .about-screen-content{
        padding-left: 580px;

        .left-info-container {
          padding: 560px 0 280px;
          max-width: 1701px;
          row-gap: 64px;


          .top-info-container {
            row-gap: 64px;
            .text-info-container {
              max-width: 1380px;
              row-gap: 64px;
            }
          }


        }

        .right-info-container {
          max-width: 1703px;
          max-height: 1884px;
          right: 175px;

          .img-container {
            &.visioners {
              left: 32%;
              top: 32%;
              max-width: 590px;
              max-height: 425px;
            }
          }
        }
      }
    }
  }

</style>