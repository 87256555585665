<template>
  <p class="sub-paragraph-container" :class="{'active-big' : activeBig}">
    <slot/>
  </p>
</template>

<script>
export default {
  name: "MainTitle",
  props: {
    activeBig: {
      type: Boolean,
      default: false
    }
  }
}
</script>

<style scoped lang="scss">
.sub-paragraph-container {
  font-weight: 400;
  line-height: 120%;
  color: var(--color-card-light-color);

}


@media (max-width: 3024px){
  .sub-paragraph-container {
    font-size: 20px;
  }
}


@media (max-width: 1920px) and (min-width: 1601px){
  .sub-paragraph-container {
    font-size: 14px;

    &.active-big {
      font-size: 18px;
    }
  }
}


@media (max-width: 1600px) and  (min-width: 770px) {
  .sub-paragraph-container {
    font-size: 14px;
    &.active-big {
      font-size: 16px;
    }
  }
}


@media (max-width: 769px) {
  .sub-paragraph-container {
    font-size: 10px;
  }
}

@media (max-width: 769px)  and (min-height: 720px) and (max-height: 1000px) {
  .sub-paragraph-container {
    font-size: 12px;
  }
}

@media (min-height: 600px) and (max-height: 630px){

  //8 plus
}

@media (max-width: 375px) {
  //se new
}

@media (max-width: 375px) and (min-height: 630px) {
  //x
}


@media (max-width: 320px) {
  //se old
}

@media (min-width: 3025px) {
  .sub-paragraph-container {
    font-size: 36px;
  }
}
</style>