<template>
  <p class="text-container">
    Нажимая на кнопку, вы соглашаетесь с
    <span class="solid">
              политикой конфиденциальности
            </span>
    и даете согласие на обработку ваших персональных данных
  </p>
</template>

<script>
export default {
  name: "TextControlInput"
}
</script>

<style scoped lang="scss">
.text-container {
  font-weight: 250;
  .solid {
    font-weight: 400;
  }
}
@media (max-width: 3024px){
  .text-container {
    margin-top: 24px;
    font-size: 23.5px;
  }
}


@media (max-width: 1920px) {
  .text-container {
    margin-top: 20px;
    font-size: 22.5px;
  }
}

@media (max-width: 1600px) {
  .text-container {
    margin-top: 12px;
    font-size: 16px;
  }
}

@media (max-width: 769px) {
  .text-container {
    margin-top: 12px;
    font-size: 12px;
  }
}

@media (max-width: 769px)  and (min-height: 720px) and (max-height: 1000px) {

}
@media (min-height: 600px) and (max-height: 630px){
  //для экранов 8 плюс
}

@media (max-width: 375px){
  //iPhone 8
}


@media (max-width: 375px) and (min-height: 630px) {
  //iPhone X
}


@media (max-width: 320px) {

}

@media (min-width: 3025px) {
  .text-container {
    margin-top: 24px;
    font-size: 37px;
  }
}
</style>