import { createRouter, createWebHistory } from 'vue-router'
import MainLayout from "@/layouts/MainLayout.vue";
import MainPage from "@/views/main-page.vue";
import QuizPage from "@/views/quiz-page.vue";
import QuizQuestionScreen from "@/components/features/quiz-page/QuizQuestionScreen.vue";
import QuizResultScreen from "@/components/features/quiz-page/QuizResultScreen.vue";
import QuizStartScreen from "@/components/features/quiz-page/QuizStartScreen.vue";
import store from "@/store";
import StickerPage from "@/views/sticker-page.vue";
import SubLayout from "@/layouts/SubLayout.vue";
import CasePage from "@/views/case-page.vue";

const routes = [
  {
    path: '/:catchAll(.*)',
    redirect: () => {
      return { name: 'home'}
    },
  },
  {
    path: '/',
    name: 'main',
    component: MainLayout,
    children: [
      {
        path: '/',
        name: 'home',
        meta: {
          activeQuestion: false
        },
        component: MainPage
      },
      {
        path: '/testnaarkhetip',
        name: 'testnaarkhetip',
        redirect: () => {
          return { name: 'quiz-start'}
        },
        component: QuizPage
      },
      {
        path: '/quiz',
        name: 'quiz',
        meta: {
          activeQuestion: false
        },
        redirect: () => {
          return { name: 'quiz-start'}
        },
        component: QuizPage,
        children: [
          {
            path: 'start',
            name: 'quiz-start',
            meta: {
              activeQuestion: false
            },
            component: QuizStartScreen,
          },
          {
            path: 'question/:id',
            props: true,
            meta: {
              activeQuestion: true
            },
            name: 'quiz-loading',
            component: QuizQuestionScreen,
          },
          {
            path: 'result/:result',
            props: true,
            meta: {
              activeQuestion: false
            },
            name: 'quiz-result',
            component: QuizResultScreen,
          }
        ]
      }
    ]
  },
  {
    path: '/secret-stickers',
    name: 'stickers',
    component: SubLayout,
    children: [
      {
        path: '',
        name: 'main-stickers',
        component: StickerPage,
      },
      {
        path: 'case',
        name: 'case-stickers',
        component: CasePage,
      },
    ]
  },
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
  scrollBehavior(to, from, savedPosition) {
      return { left: 0, top: 0}
  },
})

// router.beforeEach(async (to, from, next) => {
//   // if (to.meta?.activeQuestion) {
//   //   console.log(to, from)
//   //   let resultArr = store.state.quizModule.resultQuizAnswer;
//   //   let id = Number(to?.params.id)
//   //   if(id !== 0) {
//   //     let url;
//   //     for (let i = 0; i < id; i++) {
//   //       if(resultArr[i].answer === -1) {
//   //         url = '/quiz/start'
//   //       }
//   //     }
//   //     return next(url)
//   //   } else {
//   //     return next()
//   //   }
//   // } else {
//   //   return next()
//   // }
// });

export default router
