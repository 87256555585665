<template>
  <div class="main-wrapper">
    <main :class="{'active-auto-height' : $route.name === 'quiz-result'}">
      <router-view v-slot="{ Component }">
        <transition name="fade-opacity-lazy" mode="out-in">
          <component :is="Component" />
        </transition>
      </router-view>
    </main>
  </div>


</template>

<script>
import MainHeader from "@/components/widgets/MainHeader.vue";

export default {
  name: "quiz-page",
  components: {MainHeader},
  data() {
    return {
      activeBlock: true
    }
  },
}
</script>

<style scoped lang="scss">
.main-wrapper {
  position: relative;
}
main {
  width: 100%;
  margin: 0 auto;
  height: var(--calc-height);

  &.active-auto-height {
    height: auto;
  }
}


@media (max-width: 3840px){
  main {
    padding: 128px 0;
    max-width: 2200px;
  }
}

@media (max-width: 3024px){
  main {
    padding: 88px 0;
    max-width: 1260px;
  }
}

@media (max-width: 1920px) {
  main {
    padding: 56px 0;
    max-width: 1020px;
  }
}


@media (max-width: 1600px) {
  main {
    padding: 64px 0;
    max-width: 980px;
  }
}
@media (max-height: 800px) {
  main {
    padding: 40px 0;
    max-width: 878px;
  }
}

@media (max-width: 1400px) and (min-height: 801px) {
  main {
    padding: 64px 0;
    max-width: 980px;
  }
}

@media (max-width: 1133px) {
  main {
    padding: 40px 0;
    max-width: 878px;
  }
}

@media (max-width: 769px) {
  main {
    padding: 64px 20px 8px;
    max-width: 100%;
  }
}

@media (max-width: 769px)  and (min-height: 720px) and (max-height: 1000px) {
  main {
    padding: 65px 24px 9px;
  }
}

@media (min-height: 600px) and (max-height: 630px){

  //8 plus
}

@media (max-width: 375px) {
  //se new
}

@media (max-width: 375px) and (min-height: 630px) {
  //x
}


@media (max-width: 320px) {
  main {
    padding: 56px 16px 8px;
    max-width: 100%;
  }
}

@media (min-width: 3841px) {
  main {
    padding: 152px 0;
    max-width: 2200px;
  }
}
</style>