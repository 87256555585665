<template>
  <div class="vision-screen-container"
       @mouseenter="startPosition"
       @mouseleave="resetPosition"
       @mousemove="updateMousePosition">
        <div class="overlay-container" :class="{'active-touch' : activeTouch, 'is-animation' : activeAnimation}"
             :style="{ left: mouseX + 'px', top: mouseY + 'px'}">
        </div>
    <div class="img-container" :class="{'is-hovered' : isHovered}">
      <img :src="imgContent" alt="img" >
    </div>
  </div>
</template>

<script>
export default {
  name: "VisionScreen",
  props: {
    imgContent: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      mouseX: 0,
      mouseY: 0,
      isHovered: false,
      activeAnimation: false,
      timer: null
    }
  },
  mounted() {
    if(window.innerWidth > 769) {
      this.resetPosition();
    }
  },
  unmounted() {
    clearInterval(this.timer)
  },
  methods: {
    updateMousePosition(event) {
      if(window.innerWidth >= 3841) {
        this.mouseX = event.pageX - 1050;
        this.mouseY = event.pageY - 250;
      } else if (window.innerWidth >= 3025) {
        this.mouseX = event.pageX - 700;
        this.mouseY = event.pageY - 250;
      } else if (window.innerWidth >= 2241) {
        this.mouseX = event.pageX - 100;
        this.mouseY = event.pageY - 125;
      } else if (window.innerWidth >= 1921) {
        this.mouseX = event.pageX - 10;
        this.mouseY = event.pageY - 125;
      } else if (window.innerWidth >= 1300) {
        this.mouseX = event.pageX - 25;
        this.mouseY = event.pageY - 100;
      } else {
        this.mouseX = event.pageX - 25;
        this.mouseY = event.pageY - 75;
      }
    },

    resetPosition() {
      this.isHovered = false
      this.timer = setInterval(() => {
        this.activeAnimation = !this.activeAnimation
        this.setParentPosition();
        if(this.activeAnimation) {
          this.isHovered = true
        } else {
          setTimeout(() => {
            this.isHovered = false
          }, 500)
        }
      }, 2000)
    },

    startPosition() {
      clearInterval(this.timer)
      this.activeAnimation = false
      this.isHovered = true;
    },

    setParentPosition() {
      if(!this.activeMobile) {
        let parent = document.querySelector('.vision-screen-container')
        this.mouseX = parent?.getBoundingClientRect().width / 2;
        this.mouseY = parent?.getBoundingClientRect().height;
      }
    }
  }
}
</script>

<style scoped lang="scss">
  .vision-screen-container {
    position: relative;
    overflow: hidden;
    width: 100%;
    margin: 1px 0;

    .img-container {
      position: relative;
      margin: 0 auto;
      width: 100%;
      height: 100%;
      z-index: 0;
      opacity: 0;
      transition: all .5s ease;
      &.is-hovered {
        opacity: 1;
      }

      & img {
        object-fit: contain;
        width: 100%;
        height: 100%;
      }
    }

    .background-container {
      position: absolute;
      width: 200%;
      height: 200%;
      background: var(--color-main-dark-color);
      transform: translate(-50%, -50%);
      z-index: 1;
    }

    .overlay-container {
      position: absolute;
      width: 200%;
      height: 200%;
      background: radial-gradient(circle at center, transparent -8%, rgba(var(--color-rgba-main-dark), 1) 7%);
      transform: translate(-50%, -50%);
      z-index: 1;
      transition: width .6s ease, height 6s ease;

      &.active-touch {
        transition: all .6s ease;
      }

      &.is-animation {
        width: 2000%;
        height: 2000%;
      }

    }
  }

  @media (max-width: 3840px) {
    .vision-screen-container {
      max-width: 2415px;
      .img-container {
        padding: 0 16px;
        max-height: 816px;
      }
      .overlay-container {
        width: 400%;
        height: 400%;
      }
    }
  }



  @media (max-width: 3024px){
    .vision-screen-container {
      .img-container {
        padding: 0 16px;
        max-width: 1370px;
        max-height: 465px;
      }
      .overlay-container {
        width: 225%;
        height: 225%;
      }
    }
  }


  @media (max-width: 1920px) {
    .vision-screen-container {
      .img-container {
        padding: 0 16px;
        max-width: 1280px;
        max-height: 433px;
      }
    }
  }


  @media (max-width: 1600px) {
    .vision-screen-container {
      .img-container {
        padding: 0 16px;
        max-width: 925px;
        max-height: 313px;
      }


      .overlay-container {
        width: 250%;
        height: 250%;
      }
    }
  }
  @media (max-height: 800px) {

    .vision-screen-container {
      .img-container {
        padding: 0 16px;
        max-width: 725px;
        max-height: 245px;
      }


      .overlay-container {
        width: 250%;
        height: 250%;
        transition: none;
      }
    }
  }

  @media (max-width: 1400px) and (min-height: 801px) {
    .vision-screen-container {
      .img-container {
        padding: 0 16px;
        max-width: 925px;
        max-height: 313px;
      }


      .overlay-container {
        width: 250%;
        height: 250%;
      }
    }
  }

  @media (max-width: 1133px) {
    .vision-screen-container {
      .img-container {
        padding: 0 16px;
        max-width: 725px;
        max-height: 245px;
      }


      .overlay-container {
        width: 250%;
        height: 250%;
        transition: none;
      }
    }
  }


  @media (max-width: 769px) {
    //14
  }

  @media (max-width: 769px)  and (min-height: 720px) and (max-height: 1000px) {
    //plus
  }

  @media (min-height: 600px) and (max-height: 630px){

    //8 plus
  }

  @media (max-width: 375px) {
    //se new
  }

  @media (max-width: 375px) and (min-height: 630px) {
    //x
  }


  @media (max-width: 320px) {
    //se old
  }

  @media (min-width: 3841px) {
    .vision-screen-container {
      max-width: 2415px;
      .img-container {
        padding: 0 16px;
        max-height: 1000px;
      }

      .overlay-container {
        width: 500%;
        height: 500%;

        &.is-animation {
          width: 2500%;
          height: 2500%;
        }
      }
    }
  }

</style>