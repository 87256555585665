<template>
  <div class="card-services-container"
       :class="{'active-info' : activeAnimation}"
       @mouseenter="changeStateInfo(true)"
       @mouseleave="changeStateInfo(false)">
    <main-title :active-big="true">
      {{ cardInfo.title }}
    </main-title>

    <div class="bottom-info-container">
      <transition name="fade-opacity-lazy" mode="out-in">
        <div class="icon-container img" v-if="!activeInfo">
          <img :src="getUrlIcons(imgContent)" alt="">
        </div>
        <div class="info-container" v-else>
          <div class="text-info-container">
            <main-paragraph v-for="item in cardInfo.info"
                            :active-big="true"
                            :key="item.id">
              — {{ item.text }}
            </main-paragraph>
          </div>

          <button-main :active-dark="true" :active-arrow="false" :active-big="true"> {{ textButton }} </button-main>
        </div>
      </transition>
    </div>
  </div>
</template>

<script>
import MainTitle from "@/components/shared/text/Card/MainTitle.vue";
import MainParagraph from "@/components/shared/text/Card/MainParagraph.vue";
import ButtonMain from "@/components/shared/ButtonMain.vue";

export default {
  name: "CardServices",
  components: {ButtonMain, MainParagraph, MainTitle},
  props: {
    cardInfo: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      imgContent: 'arrow-double-bottom.svg',
      textButton: 'Узнать больше',
      activeInfo: false,
      activeAnimation: false
    }
  },
  mounted() {
    this.mountedInfoCard();
  },
  watch: {
    activeMobile() {
      this.mountedInfoCard();
    }
  },
  methods: {

    mountedInfoCard() {
      if(this.activeMobile) {
        this.activeInfo = true
        this.activeAnimation = true
      } else {
        this.activeInfo = false
        this.activeAnimation = false
      }
    },

      changeStateInfo(state) {
        if(!this.activeMobile) {
          this.activeInfo = state
          if(state) {
            this.activeAnimation = state
          } else {
            setTimeout(() => {
              this.activeAnimation = state
            }, 300)
          }
        }
    }
  }
}
</script>

<style scoped lang="scss">
.card-services-container {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  background-color: var(--color-main-dark-color);
  transition: max-height .5s ease, opacity 1s ease;
  overflow: hidden;
  &.active-info {
    max-height: 100%;
  }

  .bottom-info-container {
    display: flex;
    flex-direction: column;
    height: 100%;
    .icon-container {
      position: relative;
      margin-left: auto;
    }

    .info-container {
      position: relative;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      height: 100%;

      .text-info-container {
        display: flex;
        flex-direction: column;
      }

      .main-button-container {
        align-self: flex-end;
      }

    }
  }
}

@media (max-width: 3024px){
  .card-services-container {
    padding: 24px 32px;
    max-height: 166px;
    row-gap: 40px;
    border-radius: 20px;

    &.active-info {
      row-gap: 33px;
    }

    .bottom-info-container {
      .icon-container {
        max-width: 39px;
        max-height: 39px;
      }

      .info-container {
        row-gap: 40px;

        .text-info-container {
          row-gap: 16px;
        }
      }
    }
  }
}


@media (max-width: 1920px) {
  .card-services-container {
    padding: 24px 32px;
    max-height: 148px;
    row-gap: 40px;
    border-radius: 14px;

    &.active-info {
      row-gap: 33px;
    }

    .bottom-info-container {
      .icon-container {
        max-width: 31px;
        max-height: 31px;
      }

      .info-container {
        row-gap: 40px;

        .text-info-container {
          row-gap: 16px;
        }
      }
    }
  }
}


@media (max-width: 1600px) {
  .card-services-container {
    padding: 16px 24px;
    max-height: 119px;
    row-gap: 24px;
    border-radius: 12px;

    &.active-info {
      row-gap: 16px;
    }

    .bottom-info-container {
      .icon-container {
        max-width: 24px;
        max-height: 24px;
      }

      .info-container {
        row-gap: 24px;

        .main-paragraph-container {
          font-size: 18px;
        }

        .text-info-container {
          row-gap: 8px;
        }
      }

      .main-button-container {
        border-radius: 10px;
        padding: 8px 24px;
      }
    }
  }
}


@media (max-width: 769px) {
  .card-services-container {
    padding: 16px 24px;
    max-height: fit-content;
    border-radius: 12px;

    &.active-info {
      height: fit-content;
      max-height: fit-content;
      row-gap: 16px;
    }

    .bottom-info-container {
      height: auto;

      .info-container {
        row-gap: 16px;

        .main-title-container {
          font-size: 16px;
        }

        .main-paragraph-container {
          font-size: 14px;
        }

        .text-info-container {
          row-gap: 8px;
        }
      }

      .main-button-container {
        border-radius: 6px;
        padding: 6px 20px;
      }
    }
  }
}

@media (max-width: 769px)  and (min-height: 720px) and (max-height: 1000px) {
  .card-services-container {
    padding: 16px 24px;
    max-height: fit-content;
    border-radius: 12px;

    &.active-info {
      height: fit-content;
      max-height: fit-content;
      row-gap: 16px;
    }

    .bottom-info-container {
      height: auto;

      .info-container {
        row-gap: 16px;

        .main-paragraph-container {
          font-size: 16px;
        }

        .text-info-container {
          row-gap: 8px;
        }
      }

      .main-button-container {
        border-radius: 6px;
        padding: 6px 20px;
      }
    }
  }
}



@media (min-width: 3025px) {
  .card-services-container {
    padding: 32px 48px;
    max-height: 320px;
    row-gap: 146px;
    border-radius: 20px;

    &.active-info {
      row-gap: 40px;
    }

    .bottom-info-container {
      .icon-container {
        max-width: 44px;
        max-height: 44px;
      }

      .info-container {

        .text-info-container {
          row-gap: 16px;
        }
      }
    }
  }
}

</style>