<template>
  <main class="main-wrapper">
    <start-screen v-animateonscroll="{ enterClass: 'fade-in'}"/>
    <examples-screen/>
    <stickers-screen/>
  </main>
</template>

<script>
import StartScreen from "@/components/features/sticker-page/StartScreen.vue";
import ExamplesScreen from "@/components/features/sticker-page/ExamplesScreen.vue";
import StickersScreen from "@/components/features/sticker-page/StickersScreen.vue";

export default {
  name: "sticker-page",
  components: {StickersScreen, ExamplesScreen, StartScreen},
}
</script>

<style scoped>

</style>