<template>
  <div class="title-input">
    <slot/>
  </div>
</template>

<script>
export default {
  name: "TitleInput"
}
</script>

<style scoped lang="scss">
.title-input {
  color: #b6b6b6;
  font-weight: 300;
}

  @media (max-width: 3024px){
    .title-input {
      font-size: 32px;
      margin-bottom: 20px;
    }
  }


  @media (max-width: 1920px) {
    .title-input {
      font-size: 26px;
      margin-bottom: 20px;
    }
  }

  @media (max-width: 1600px) {
    .title-input {
      font-size: 22px;
      margin-bottom: 16px;
    }
  }
  @media (max-height: 800px) {
    .title-input {
      font-size: 18px;
      margin-bottom: 16px;
    }
  }

  @media (max-width: 1400px) and (min-height: 801px) {
    .title-input {
      font-size: 22px;
      margin-bottom: 16px;
    }
  }

  @media (max-width: 1133px) {
    .title-input {
      font-size: 18px;
      margin-bottom: 16px;
    }
  }
  @media (max-width: 769px) {
    .title-input {
      font-size: 12px;
      margin-bottom: 6px;
    }
  }

  @media (max-width: 769px)  and (min-height: 720px) and (max-height: 1000px) {

  }
  @media (min-height: 600px) and (max-height: 630px){
    //для экранов 8 плюс
  }

  @media (max-width: 375px){
    //iPhone 8
  }


  @media (max-width: 375px) and (min-height: 630px) {
    //iPhone X
  }


  @media (max-width: 320px) {

  }

  @media (min-width: 3025px) {
    .title-input {
      font-size: 56px;
      margin-bottom: 32px;
    }
  }
</style>