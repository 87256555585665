<template>
  <div class="card-sticker-container" ref="cardStickerContainer"  :class="{'active-result' : activeResultPercent}">
    <div class="img-container">
      <img :src="getUrlStickers(activeItem.img)" alt="sticker">
    </div>

    <transition name="fade-opacity-lazy" mode="out-in">
      <item-quiz-list v-if="!activeQuizResult"
                      :active-big="true"
          @click="changeActiveButton"
          :info-item="{
            text: activeItem.name,
            active: activeItem.isActive
          }"/>
      <div class="result-quiz-container" v-else>

        <div class="main-title-container">
          <p>
            {{ activeItem.name }}
          </p>
          <p class="percent-title-container">
            {{ activeItem.percent }} %
          </p>
        </div>
        <div class="line-container">
          <div class="line-content" :class="{'active-result' : activeResultPercent}"></div>
        </div>
      </div>
    </transition>
  </div>
</template>

<script>
import ItemQuizList from "@/components/shared/ItemQuizList.vue";

export default {
  name: "CardSticker",
  components: {ItemQuizList},
  props: {
    activeItem: {
      type: Object,
      required: true
    },
    activeQuizResult: {
      type: Boolean,
      default: true
    }
  },
  data() {
    return {
      activeResultPercent: false,
    }
  },
  mounted() {
    if(this.activeQuizResult) {
      this.$refs.cardStickerContainer.style.setProperty('--percent-result', this.activeItem.percent + '%')
      setTimeout(() => {
        this.activeResultPercent = true
      }, 1000)
    }
  },
  methods: {
    changeActiveButton() {
      this.$emit('changeActiveButton', this.activeItem.id)
    },

  }
}
</script>

<style scoped lang="scss">
  .card-sticker-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;

    .img-container {
      height: 100%;
      width: 100%;

      & img {
        object-fit: contain;
        height: 100%;
        width: 100%;
      }
    }

    .policy-container {
      justify-content: center;
    }

    .result-quiz-container {
      display: flex;
      flex-direction: column;
      width: 100%;

      .main-title-container {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        line-height: 120%;

        .percent-title-container {
          font-family: 'ProximaNova-Semibold', sans-serif;
        }
      }

      .line-container {
        width: 100%;
        background-color: var(--color-sub-dark-color);

        .line-content {
          border-radius: inherit;
          height: 100%;
          background-color: var(--color-main-accent);
          width: 0;
          transition: width .6s ease;

          &.active-result {
            width: var(--percent-result);
          }
        }
      }
    }
  }

  @media (max-width: 3024px){
    .card-sticker-container {
      max-width: 414px;

      .img-container {
        margin-bottom: 80px;
        max-height: 399px;
      }

      .result-quiz-container {
        row-gap: 16px;

        .main-title-container {
          font-size: 32px;
        }

        .line-container {
          height: 10px;
          border-radius: 7px;
        }
      }
    }
  }

  @media (max-width: 1920px) {
    .card-sticker-container {
      max-width: 330px;

      .img-container {
        margin-bottom: 60px;
        max-height: 317px;
      }


      .result-quiz-container {
        row-gap: 16px;

        .main-title-container {
          font-size: 24px;
        }

        .line-container {
          height: 8px;
          border-radius: 7px;
        }
      }
    }
  }

  @media (max-width: 1600px) {
    .card-sticker-container {
      max-width: 248px;

      .img-container {
        margin-bottom: 40px;
        max-height: 238px;
      }


      .result-quiz-container {
        row-gap: 16px;

        .main-title-container {
          font-size: 20px;
        }

        .line-container {
          height: 6px;
          border-radius: 7px;
        }
      }
    }
  }

  @media (max-height: 800px) {
    .card-sticker-container {
      max-width: 206px;

      .img-container {
        margin-bottom: 16px;
        max-height: 206px;
      }


      .result-quiz-container {
        row-gap: 16px;

        .main-title-container {
          font-size: 16px;
        }

        .line-container {
          height: 4px;
          border-radius: 7px;
        }
      }
    }
  }

  @media (max-width: 1400px) and (min-height: 801px) {
    .card-sticker-container {
      max-width: 248px;

      .img-container {
        margin-bottom: 40px;
        max-height: 238px;
      }


      .result-quiz-container {
        row-gap: 16px;

        .main-title-container {
          font-size: 20px;
        }

        .line-container {
          height: 6px;
          border-radius: 7px;
        }
      }
    }
  }

  @media (max-width: 1133px) {
    .card-sticker-container {
      max-width: 206px;

      .img-container {
        margin-bottom: 16px;
        max-height: 206px;
      }


      .result-quiz-container {
        row-gap: 16px;

        .main-title-container {
          font-size: 16px;
        }

        .line-container {
          height: 4px;
          border-radius: 7px;
        }
      }
    }
  }

  @media (max-width: 769px) {
    .card-sticker-container {
      flex-direction: row;
      width: 100%;
      justify-content: space-between;
      max-width: 294px;
      margin: 0 auto;


      &.active {
        max-width: 325px;
      }

      .policy-container {
        justify-content: flex-end;
      };

      .img-container {
        margin-bottom: 0;
        max-height: 128px;
        max-width: 132px;
      }


      .result-quiz-container {
        max-width: 168px;
        row-gap: 16px;

        .main-title-container {
          font-size: 14px;
        }

        .line-container {
          height: 4px;
          border-radius: 7px;
        }
      }
    }
  }

  @media (max-width: 769px)  and (min-height: 720px) and (max-height: 1000px) {
    .card-sticker-container {
      flex-direction: row;
      width: 100%;
      justify-content: space-between;
      max-width: 295px;
      margin: 0 auto;

      &.active {
        max-width: 364px;
      }

      .policy-container {
        justify-content: flex-end;
      };

      .img-container {
        margin-bottom: 0;
        max-height: 128px;
        max-width: 132px;
      }


      .result-quiz-container {
        max-width: 168px;
        row-gap: 16px;

        .main-title-container {
          font-size: 14px;
        }

        .line-container {
          height: 4px;
          border-radius: 7px;
        }
      }
    }
  }

  @media (min-height: 600px) and (max-height: 630px){

    //8 plus
  }

  @media (max-width: 375px) {
    //se new
  }

  @media (max-width: 375px) and (min-height: 630px) {
    //x
  }


  @media (max-width: 320px) {
    .card-sticker-container {
      flex-direction: row;
      width: 100%;
      justify-content: space-between;
      max-width: 250px;
      margin: 0 auto;

      .policy-container {
        justify-content: flex-end;
      };

      .img-container {
        margin-bottom: 0;
        max-height: 96px;
        max-width: 100px;
      }


      .result-quiz-container {
        max-width: 168px;
        row-gap: 10px;

        .main-title-container {
          font-size: 12px;
        }

        .line-container {
          height: 4px;
          border-radius: 7px;
        }
      }
    }
  }

  @media (min-width: 3025px) {
    .card-sticker-container {
      max-width: 712px;

      .img-container {
        margin-bottom: 80px;
        max-height: 682px;
      }

      .result-quiz-container {
        row-gap: 16px;

        .main-title-container {
          font-size: 56px;
        }

        .line-container {
          height: 18px;
          border-radius: 9px;
        }
      }
    }
  }

</style>