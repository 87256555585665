<template>
  <div class="input-container" @click="focusInput"
       :class="{'textarea' : info.type === 'textarea',
       'focus' : focused, 'active-modal' : activeModal}">

<!--    <circle-mini-block :class="{'active-textarea' : info.type === 'textarea'}" :is-necessary="info.isNecessary" :focused="focused"/>-->
    <div class="letter-counter" v-if="info.type === 'textarea'">
      <p>{{this.countLetter}}/{{lengthTextArea}}</p>
    </div>
    <input :type="info.type"
           :name="info.dataIndex"
           @focus="focused = true"
           @blur="focused = false"
           :placeholder="info.placeholder"
           v-if="info.type !== 'textarea'"
           v-model="textInput">

    <textarea class="textarea"
              ref="textareaMessage"
              :name="info.dataIndex"
              @focus="focused = true"
              @blur="focused = false"
              :placeholder="info.placeholder"
              :maxlength="lengthTextArea"
              v-model="textInput" v-else></textarea>
  </div>
</template>

<script>

import CircleMiniBlock from "@/components/shared/CircleMiniBlock.vue";

export default {
  name: "InputMain",
  components: {CircleMiniBlock},
  props: {
    activeModal: {
      type: Boolean,
      default: false
    },
    info: {
      type: Object,
      required: true
    },
    lengthTextArea: {
      type: Number
    },
    submitActive: {
      type: Boolean
    },
    nameBlock: {
      type: String
    },
    activeTextInput: {
      type: String
    }
  },

  mounted() {
    if(this.activeTextInput !== undefined) {
      this.textInput = this.activeTextInput
    }
  },

  data() {
    return {
      textInput: '',
      countLetter: 0,
      focused: false
    }
  },
  watch: {
    textInput() {
      this.countLetter = this.textInput.length
      this.$emit('changeTextInput', this.textInput, this.info.dataIndex)
    },
    submitActive() {
      if(this.submitActive) {
        this.textInput = ''
      }
    },
  },
  methods: {
    focusInput() {
      let block = document.querySelector("." + this.nameBlock)
      if(this.info.type !== 'textarea') {
        let input = block.querySelectorAll("input")
        input[this.info.id].focus()
      } else {
        let textarea = block.querySelectorAll("textarea")
        textarea[this.info.id].focus()
      }
    },
  }
}
</script>

<style scoped lang="scss">

.input-container {
  overflow: visible;
  cursor: text;
  display: flex;
  justify-content: center;
  align-items: center;
  background: var(--color-input);
  font-family: 'ProximaNova', sans-serif;
  font-weight: 100;
  color: var(--color-input-border);
  border: 1px solid var(--color-input-border);
  position: relative;
  transition: all .5s ease;
  opacity: .6;
  height: 100%;

  &.not-circle {
    .circle-container {
      display: none;
    }
  }

  &.textarea {
    align-items: flex-start;
  }


  &:hover {
    box-shadow: 0px 0px 30px 0px rgba(240, 240, 249, 0.15);
    opacity: 1;
  }

  &.focus {
    opacity: 1;
  }

  .button-icon-container {
    position: absolute;
  }

  .letter-counter {
    color: var(--color-main-light-color);
    font-family: 'ProximaNova', sans-serif;
    font-weight: 200;
    position: absolute;
    opacity: .4;
  }


  .important-input {
    position: relative;
  }


  & input, textarea {
    overflow: visible;
    width: 100%;
    height: 100%;
    font-family: 'ProximaNova', sans-serif;
    font-weight: 200;
    color: var(--color-rgba-main-dark) !important;
    background-color: rgba(1,1,1,0) !important;

    &::placeholder {
      color: var(--color-main-light-color);
      opacity: .4;
    }

    &::-webkit-scrollbar {
      display: none;
    }
  }

  .textarea {
    resize: none;
  }
}

@media (max-width: 3024px){
  .input-container {
    padding: 18px 22px;
    column-gap: 16px;
    border-radius: 16px;
    max-height: 60px;

    &.textarea {
      max-height: 276px;
      min-height: 276px;
    }

    .letter-counter {
      bottom: 18px;
      right: 22px;
      font-size: 24px;
    }

    & input, textarea {
      font-size: 24px;
    }

    & textarea {
      height: 196px;
    }
  }
}


@media (max-width: 1920px) {
  .input-container {
    padding: 20px 16px;
    column-gap: 14px;
    border-radius: 14px;
    max-height: 54px;
    &.textarea {
      max-height: 200px;
      min-height: 200px;
    }
    .letter-counter {
      bottom: 16px;
      right: 20px;
      font-size: 22px;
    }
    & input, textarea {
      font-size: 22px;
    }

    & textarea {
      height: 120px;
    }
  }
}

@media (max-width: 1600px) {
  .input-container {
    padding: 12px 14px;
    column-gap: 12px;
    border-radius: 12px;
    max-height: 40px;

    &.textarea {
      max-height: 184px;
      min-height: 184px;
    }

    .letter-counter {
      bottom: 12px;
      right: 14px;
      font-size: 16px;
    }

    & input, textarea {
      font-size: 16px;
    }

    & textarea {
      height: 128px;
    }
  }
}

@media (max-height: 800px) {
  .input-container {
    padding: 12px 14px;
    column-gap: 12px;
    border-radius: 12px;
    max-height: 40px;

    &.textarea {
      max-height: 130px;
      min-height: 130px;
    }

    .letter-counter {
      bottom: 12px;
      right: 14px;
      font-size: 16px;
    }

    & input, textarea {
      font-size: 14px;
    }

    & textarea {
      height: 80px;
    }
  }
}

@media (max-width: 1400px) and (min-height: 801px) {
  .input-container {
    padding: 12px 14px;
    column-gap: 12px;
    border-radius: 12px;
    max-height: 40px;

    &.textarea {
      max-height: 184px;
      min-height: 184px;
    }

    .letter-counter {
      bottom: 12px;
      right: 14px;
      font-size: 16px;
    }

    & input, textarea {
      font-size: 16px;
    }

    & textarea {
      height: 112px;
    }
  }
}

@media (max-width: 1133px) {
  .input-container {
    padding: 12px 14px;
    column-gap: 12px;
    border-radius: 12px;
    max-height: 40px;

    &.textarea {
      max-height: 130px;
      min-height: 130px;
    }

    .letter-counter {
      bottom: 12px;
      right: 14px;
      font-size: 16px;
    }

    & input, textarea {
      font-size: 14px;
    }

    & textarea {
      height: 80px;
    }
  }
}

@media (max-width: 769px) {
  .input-container {
    opacity: 1;
    padding: 9px 10px;
    column-gap: 10px;
    border-radius: 8px;
    max-height: 30px;

    &:hover {
      box-shadow: none;
    }

    &.active-modal {
      &.textarea {
        max-height: 100%;
        min-height: auto;
      }
      & textarea {
        height: inherit;
      }
    }

    &.textarea {
      padding: 8px 10px;
      max-height: 188px;
      min-height: 188px;
    }

    .circle-container {
      margin-top: 2px;
    }


    .letter-counter {
      bottom: 10px;
      right: 20px;
      font-size: 10px;
    }

    & input, textarea {
      font-weight: 100;
      font-size: 12px;
    }
  }
}

@media (max-width: 320px) {
  .input-container {
    opacity: 1;
    padding: 6px 6px 6px 10px;
    column-gap: 8px;
    border-radius: 8px;
    max-height: 24px;


    &.active-modal {
      &.textarea {
        max-height: 100%;
        min-height: 100%;
      }

      & input, textarea {
        font-size: 10px;
      }

      .circle-container {
        min-width: 6px;
        width: 6px;
        height: 6px;
        &.active-textarea {
          margin-top: 3px;
        }
      }
      .letter-counter {
        bottom: 10px;
        right: 10px;
        font-size: 10px;
      }
    }

    &.textarea {
      padding: 8px 16px 8px 8px;
      max-height: 188px;
      min-height: 188px;
    }

    .circle-container {
      margin-top: 2px;
    }


    .letter-counter {
      bottom: 10px;
      right: 20px;
      font-size: 10px;
    }

    & input, textarea {
      font-weight: 100;
      font-size: 10px;
    }
  }
}



@media (min-width: 3025px) {
  .input-container {
    padding: 28px 38px;
    column-gap: 24px;
    border-radius: 20px;
    max-height: 94px;

    &.textarea {
      max-height: 468px;
      min-height: 468px;
    }


    .letter-counter {
      bottom: 28px;
      right: 38px;
      font-size: 38px;
    }

    & input, textarea {
      font-size: 38px;
    }

    & textarea {
      height: 324px;
    }
  }
}

</style>