<template>
  <p class="sub-paragraph-code-container">
    {{ activeMinutes }}:<span v-if="activeSeconds < 10">0</span>{{ activeSeconds }}
  </p>
</template>

<script>
export default {
  name: "Timer",
  data() {
    return {
      activeMinutes: 4,
      activeSeconds: 59,
      timer: ''
    }
  },
  mounted() {
      this.timer = setInterval(() => {
        this.checkActiveTimer()
      }, 1000)
  },
  methods: {
    checkActiveTimer() {
      if(this.activeSeconds === 0 && this.activeMinutes > 0) {
        this.activeSeconds = 59
        this.activeMinutes = this.activeMinutes - 1;
      } else {
        this.activeSeconds--;
      }

      if(this.activeMinutes === 0 && this.activeSeconds === 1) {
        clearInterval(this.timer)
        this.$emit('closeTimer')
      }
    }
  }
}
</script>

<style scoped lang="scss">
@media (max-width: 3024px){
}

@media (max-width: 2240px) {
}


@media (max-width: 1920px) {
}

@media (max-height: 969px){
  //переходной вариант для экранов меньше 1000px
}
@media (max-width: 1600px) {
}
@media (max-height: 800px) {
}

@media (max-width: 1400px) and (min-height: 801px) {
}

@media (max-width: 1133px) {
}
@media (max-width: 769px) {
  //iPhone 14
}

@media (max-width: 769px)  and (min-height: 740px) and (max-height: 1000px) {
  //iPhone Plus
}
@media (min-height: 600px) and (max-height: 630px){
  //для экранов 8 плюс
}

@media (max-width: 375px){
  //iPhone 8
}


@media (max-width: 375px) and (min-height: 630px) {
  //iPhone X
}


@media (max-width: 320px) {
  //iPhone SE
}

@media (min-width: 3025px) {
  .sub-paragraph-code-container {
    font-size: 34px;
  }
}

</style>