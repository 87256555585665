<template>
  <div class="button-agreement-container" :class="{'active-modal' : activeModal}">
    <check-box :is-active="isActive"
               :active-modal="activeModal"
               @changeStatus="changeStatus"/>

    <p class="sub-title-container">
      {{ textInfo }}
    </p>
  </div>
</template>

<script>
import CheckBox from "@/components/shared/CheckBox.vue";

export default {
  name: "ButtonAgreement",
  components: {CheckBox},
  props: {
    textInfo: {
      type: String,
      default: 'Ознакомлен(а) с Политикой в области обработки персональных данных и даю согласие на их обработку'
    },
    isActive: {
      type: Boolean,
      required: true
    },
    activeModal: {
      type: Boolean,
      default: false
    },
  },
  methods: {
    changeStatus() {
      this.$emit('changeStatus')
    }
  }
}
</script>

<style scoped lang="scss">
  .button-agreement-container {
    display: flex;
    flex-direction: row;
    align-items: center;
    width: 100%;

    &.italic {
      .sub-title-container {
        font-style: italic;
        white-space: pre-wrap;
      }
    }

    .sub-title-container {
      color: var(--color-card-light-color);
      font-weight: 100;
      line-height: 120%;
    }
  }



  @media (max-width: 3024px){
    .button-agreement-container {
      column-gap: 16px;
      max-width: 100%;
      width: 100%;
      &.active-modal {
        column-gap: 48px;
        .sub-title-container {
          font-size: 22px;
        }
      }
      &.italic {
        column-gap: 24px;

        .check-box-container {
          --check-box-size: 40px;
        }

        .sub-title-container {
          font-size: 24px;
        }
      }

      .sub-title-container {
        font-size: 22px;
      }
    }
  }


  @media (max-width: 1920px) {
    .button-agreement-container {
      column-gap: 16px;
      max-width: 583px;
      width: 100%;
      &.active-modal {
        column-gap: 32px;
        .sub-title-container {
          font-size: 18px;
        }
      }

      &.italic {
        column-gap: 24px;

        .check-box-container {
          --check-box-size: 54px;
        }

        .sub-title-container {
          font-size: 18px;
        }
      }

      .sub-title-container {
        font-size: 16px;
      }
    }
  }


  @media (max-width: 1600px) {
    .button-agreement-container {
      column-gap: 16px;
      max-width: 100%;
      width: 100%;

      &.italic {
        column-gap: 24px;
        .check-box-container {
          --check-box-size: 34px;
        }

        .sub-title-container {
          font-size: 14px;
        }
      }

      &.active-modal {
        column-gap: 24px;


        .sub-title-container {
          font-size: 14px;
        }
      }

      .sub-title-container {
        font-size: 16px;
      }
    }
  }
  @media (max-height: 800px) {
    .button-agreement-container {
      column-gap: 16px;
      max-width: 100%;
      width: 100%;

      &.active-modal {
        column-gap: 16px;
        .sub-title-container {
          font-size: 12px;
        }
      }

      .sub-title-container {
        font-size: 16px;
      }
    }
  }

  @media (max-width: 1400px) and (min-height: 801px) {
    .button-agreement-container {
      column-gap: 16px;
      max-width: 100%;
      width: 100%;



      &.active-modal {
        column-gap: 24px;
        .sub-title-container {
          font-size: 14px;
        }
      }

      .sub-title-container {
        font-size: 16px;
      }
    }
  }

  @media (max-width: 1133px) {
    .button-agreement-container {
      column-gap: 16px;
      max-width: 100%;
      width: 100%;



      &.active-modal {
        column-gap: 16px;
        .sub-title-container {
          font-size: 12px;
        }
      }

      .sub-title-container {
        font-size: 16px;
      }
    }
  }

  @media (max-width: 769px) {
    .button-agreement-container {

      &.italic {
        column-gap: 10px;
        .check-box-container {
          --check-box-size: 34px;
        }

        .sub-title-container {
          font-weight: 300;
          font-size: 11px;
        }
      }

      &.active-modal {
        .sub-title-container {
          font-size: 10px;
        }
      }
      .sub-title-container {
        font-size: 12px;
        font-weight: 300;
      }
    }
  }

  @media (max-width: 769px)  and (min-height: 720px) and (max-height: 1000px) {
    .button-agreement-container {

      &.italic {
        column-gap: 12px;
        .check-box-container {
          --check-box-size: 34px;
        }

        .sub-title-container {
          font-weight: 300;
          font-size: 12px;
        }
      }

      &.active-modal {
        .sub-title-container {
          font-size: 12px;
        }
      }

      .sub-title-container {
        font-size: 12px;
        font-weight: 300;
      }
    }
  }

  @media (min-height: 600px) and (max-height: 630px){

    //8 plus
  }

  @media (max-width: 375px) {
    //se new
  }

  @media (max-width: 375px) and (min-height: 630px) {
    //x
  }


  @media (max-width: 320px) {
    .button-agreement-container {

      &.italic {
        column-gap: 10px;
        .check-box-container {
          --check-box-size: 24px;
        }

        .sub-title-container {
          font-weight: 300;
          font-size: 10px;
        }
      }

      &.active-modal {
        .sub-title-container {
          font-size: 8px;
        }
      }
      .sub-title-container {
        font-size: 10px;
        font-weight: 300;
      }
    }
  }

  @media (min-width: 3025px) {
    .button-agreement-container {
      column-gap: 24px;
      max-width: 100%;
      width: 100%;

      &.active-modal {
        column-gap: 60px;
        .sub-title-container {
          font-size: 32px;
        }
      }


      &.italic {
        column-gap: 40px;

        .check-box-container {
          --check-box-size: 96px;
        }

        .sub-title-container {
          font-size: 40px;
        }
      }

      .sub-title-container {
        font-size: 38px;
      }
    }
  }

</style>