<template>
  <div class="check-box-container" @click="changeStatus" v-if="!activeBlock" :class="{'active-modal' : activeModal}">
    <transition name="fade-opacity-lazy">
      <div class="img-container" v-if="isActive">
        <img  :src="getUrlIcons(imgIcon + '.svg')" alt="check-mark">
      </div>
    </transition>
  </div>

  <div class="check-box-container" @click="changeStatus" v-else :class="{'active-modal' : activeModal}">
      <div :class="{'active' : isActive}" class="square-container"></div>
  </div>
</template>

<script>
export default {
  name: "CheckBox",
  props: {
    activeBlock: {
      type: Boolean,
      default: false
    },
    isActive: {
      type: Boolean,
      required: true
    },
    activeModal: {
      type: Boolean
    },
    imgIcon: {
      type: String,
      default: 'check-mark'
    },
    activeBlur: {
      type: Boolean,
      default: false
    }
  },
  methods: {
    changeStatus() {
      this.$emit('changeStatus')
    }
  }
}
</script>

<style scoped lang="scss">
.check-box-container {
  min-width: var(--check-box-size);
  max-width: var(--check-box-size);
  width: 100%;
  max-height: var(--check-box-size);
  height: 100%;
  min-height: var(--check-box-size);
  --check-box-size: 48px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 12px;
  background: var(--color-sub-dark-color);
  cursor: pointer;

  .square-container {
    background-color: var(--color-main-accent);
    transform: scaleY(0);
    transform-origin: top;
    transition: transform 0.5s;

    &.active {
      transform: scaleY(1);
      transform-origin: bottom;
      transition: transform 0.5s;
    }
  }

  .img-container {
    width: 24px;
    height: 24px;
    display: flex;
    align-items: center;
    justify-content: center;

    & img {
      width: 100%;
      height: 100%;
    }
  }
}



@media (max-width: 3024px){
  .check-box-container {
    --check-box-size: 48px;
    border-radius: 12px;


    .square-container {
      width: 24px;
      height: 24px;
    }

    &.active-modal {
      --check-box-size: 54px;

      .img-container {
        width: 20px;
        height: 20px;
      }
    }

    .img-container {
      width: 24px;
      height: 24px;
    }
  }
}


@media (max-width: 1920px) {
  .check-box-container {
    --check-box-size: 48px;
    border-radius: 14px;


    .square-container {
      width: 20px;
      height: 20px;
    }

    &.active-modal {
      --check-box-size: 54px;

      .img-container {
        width: 20px;
        height: 20px;
      }
    }

    .img-container {
      width: 24px;
      height: 24px;
    }
  }
}


@media (max-width: 1600px) {
  .check-box-container {
    --check-box-size: 38px;
    border-radius: 8px;

    .square-container {
      width: 18px;
      height: 18px;
    }

    &.active-modal {
      --check-box-size: 42px;

      .img-container {
        width: 16px;
        height: 16px;
      }
    }

    .img-container {
      width: 19px;
      height: 19px;
    }
  }
}

@media (max-height: 800px) {
  .check-box-container {
    --check-box-size: 38px;
    border-radius: 8px;

    .square-container {
      width: 14px;
      height: 14px;
    }

    &.active-modal {
      --check-box-size: 34px;

      .img-container {
        width: 14px;
        height: 14px;
      }
    }

    .img-container {
      width: 19px;
      height: 19px;
    }
  }
}

@media (max-width: 1400px) and (min-height: 801px) {
  .check-box-container {
    --check-box-size: 38px;
    border-radius: 8px;

    .square-container {
      width: 18px;
      height: 18px;
    }

    &.active-modal {
      --check-box-size: 42px;

      .img-container {
        width: 16px;
        height: 16px;
      }
    }

    .img-container {
      width: 19px;
      height: 19px;
    }
  }
}

@media (max-width: 1133px) {
  .check-box-container {
    --check-box-size: 38px;
    border-radius: 8px;

    .square-container {
      width: 14px;
      height: 14px;
    }

    &.active-modal {
      --check-box-size: 34px;

      .img-container {
        width: 14px;
        height: 14px;
      }
    }

    .img-container {
      width: 19px;
      height: 19px;
    }
  }
}

@media (max-width: 769px) {
  .check-box-container {
    --check-box-size: 34px;
    border-radius: 8px;

    .square-container {
      width: 6px;
      height: 6px;
    }

    .img-container {
      width: 17px;
      height: 17px;
    }
  }
}

@media (max-width: 769px)  and (min-height: 720px) and (max-height: 1000px) {
  .check-box-container {
    --check-box-size: 34px;
    border-radius: 8px;

    .square-container {
      width: 8px;
      height: 8px;
    }

    .img-container {
      width: 17px;
      height: 17px;
    }
  }
}

@media (min-height: 600px) and (max-height: 630px){

  //8 plus
}

@media (max-width: 375px) {
  //se new
}

@media (max-width: 375px) and (min-height: 630px) {
  //x
}


@media (max-width: 320px) {
  .check-box-container {
    --check-box-size: 28px;
    border-radius: 8px;


    &.active-modal {
      --check-box-size: 28px;

      .img-container {
        width: 14px;
        height: 14px;
      }
    }

    .img-container {
      width: 14px;
      height: 14px;
    }
  }
}

@media (min-width: 3025px) {
  .check-box-container {
    --check-box-size: 80px;
    border-radius: 20px;

    &.active-modal {
      --check-box-size: 96px;

      .img-container {
        width: 24px;
        height: 24px;
      }
    }

    .square-container {
      width: 32px;
      height: 32px;
    }

    .img-container {
      width: 40px;
      height: 40px;
    }
  }
}

</style>