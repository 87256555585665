<template>
  <div class="partners-screen-container main-container">
    <block-title :button-info="buttonInfo"
                 :main-paragraph="mainParagraph"
                 :main-title="mainTitle"/>

<!--    <div class="bottom-info-container" v-if="!activeMobile">-->
<!--      <card-partners v-for="item in partnersInfo" :key="item.id"-->
<!--                     v-animateonscroll="{ enterClass: 'fade-in'}"-->
<!--                     :info-card="item"/>-->
<!--    </div>-->

<!--    <div class="bottom-info-container" v-else>-->
<!--      <slider-scroll :active-info-length=" partnersInfo.length"-->
<!--                     class="service active-gradient"-->
<!--                     :active-dark="true"-->
<!--                     v-animateonscroll="{ enterClass: 'fade-in'}"-->
<!--                     :active-scroll="false"-->
<!--                     :virtual-status="false"-->
<!--                     :slides-per-view="slideCount"-->
<!--                     :active-scroll-bar="false">-->
<!--        <swiper-slide v-for="(item, index) in partnersInfo" v-slot="{ isActive, isPrev }">-->
<!--          <card-partners :info-card="item" :class="{-->
<!--          'is-last' : index === partnersInfo.length - 1 && isActive,-->
<!--          'is-last-prev' : isPrev && index === partnersInfo.length - 2}"/>-->
<!--        </swiper-slide>-->
<!--      </slider-scroll>-->
<!--    </div>-->

        <div class="bottom-info-container" v-if="!activeMobile">
          <card-partners v-for="item in partnersInfo" :key="item.id"
                         v-animateonscroll="{ enterClass: 'fade-in'}"
                         :info-card="item"/>
        </div>

        <div class="bottom-info-container" v-else>
          <slider-scroll :active-info-length=" partnersInfo.length"
                         class="service"
                         :active-dark="true"
                         v-animateonscroll="{ enterClass: 'fade-in'}"
                         :active-scroll="false"
                         :virtual-status="false"
                         :slides-per-view="1.1"
                         :active-scroll-bar="false">
            <swiper-slide v-for="(item, index) in partnersInfo" v-slot="{ isActive, isPrev }">
              <card-partners :info-card="item" :class="{
              'is-last' : index === partnersInfo.length - 1 && isActive,
              'is-last-prev' : isPrev && index === partnersInfo.length - 2}"/>
            </swiper-slide>
          </slider-scroll>
        </div>

<!--    <button-main-->
<!--        v-if="!activeMobile"-->
<!--        :active-dark="buttonInfo.activeDark"-->
<!--        @click="routTo(buttonInfo.rout)">-->
<!--      {{ buttonInfo.text }}-->
<!--    </button-main>-->
  </div>
</template>

<script>
import BlockTitle from "@/components/entities/BlockTitle.vue";
import CardPartners from "@/components/entities/CardPartners.vue";
import ButtonMain from "@/components/shared/ButtonMain.vue";
import SliderScroll from "@/components/entities/SliderScroll.vue";

export default {
  name: "PartnersScreen",
  components: {SliderScroll, ButtonMain, CardPartners, BlockTitle},
  data() {
    return {
      buttonInfo: {
        text: 'Партнеры',
        rout: '/',
        activeDark: true
      },
      slideCount: 1,
      mainParagraph: 'Создаем эффективные партнерства, открываем новые горизонты, помогаем формировать тренды',
      mainTitle: 'Партнеры'
    }
  },
  // mounted() {
  //   this.checkSize();
  //   addEventListener('resize', this.checkSize)
  // },
  // unmounted() {
  //   removeEventListener('resize', this.checkSize)
  // },
  computed: {
    partnersInfo() {
      return this.$store.state.partnersInfo;
    }
  },
  // methods: {
  //   checkSize() {
  //     if(window.innerWidth > 3840) {
  //       this.slideCount = 2.4
  //     } else if (window.innerWidth > 3024) {
  //       this.slideCount = 2.3
  //     } else if (window.innerWidth > 1921) {
  //       this.slideCount = 2.4
  //     } else if (window.innerWidth > 1500 && window.innerHeight <= 800) {
  //       this.slideCount = 1.5
  //     }  else if (window.innerWidth > 1500) {
  //       this.slideCount = 2.4
  //     }   else if (window.innerWidth > 1300) {
  //       this.slideCount = 2.4
  //     } else if ( window.innerWidth <= 769) {
  //       this.slideCount = 1.1
  //     }  else {
  //       this.slideCount = 2.3
  //     }
  //   },
  // }
}
</script>

<style scoped lang="scss">
.partners-screen-container {
  .bottom-info-container {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    //
    //width: 100%;
  }
}



@media (max-width: 3024px){
  .partners-screen-container {
    .bottom-info-container {
      max-width: 1580px;
      column-gap: 20px;

      height: 680px;
    }
  }
}


@media (max-width: 1920px) {
  .partners-screen-container {
    .bottom-info-container {
      max-width: 1241px;
      column-gap: 20px;

      height: 552px;
    }
  }
}


@media (max-width: 1600px) {
  .partners-screen-container {
    .bottom-info-container {
      max-width: 980px;
      column-gap: 20px;

      height: 456px;
    }
  }
}

@media (max-height: 800px) {
  .partners-screen-container {
    .bottom-info-container {
      max-width: 880px;

      height: 430px;
    }
  }
}

@media (max-width: 1400px) and (min-height: 801px) {
  .partners-screen-container {
    .bottom-info-container {
      max-width: 980px;

      height: 456px;
    }
  }
}

@media (max-width: 1133px) {
  .partners-screen-container {
    .bottom-info-container {
      max-width: 880px;

      height: 430px;
    }
  }
}

@media (max-width: 769px) {
  .partners-screen-container {
    .bottom-info-container {
      max-width: 100%;
      height: 220px;
    }
  }

  .card-partners-container {
    transition: all .6s ease;
    &.is-last {
      transform: translateX(-32px);
    }
    &.is-last-prev {
      transform: translateX(-32px);
    }
  }
}

@media (max-width: 769px)  and (min-height: 720px) and (max-height: 1000px) {
  .partners-screen-container {
    .bottom-info-container {
      max-width: 100%;
      height: 300px;
    }
  }
  .card-partners-container {
    transition: all .6s ease;
    &.is-last {
      transform: translateX(-34px);
    }
    &.is-last-prev {
      transform: translateX(-34px);
    }
  }
}

@media (min-height: 600px) and (max-height: 630px){

  //8 plus
}

@media (max-width: 375px) {
  //se new
}

@media (max-width: 375px) and (min-height: 630px) {
  //x
}


@media (max-width: 320px) {
  .card-partners-container {
    transition: all .6s ease;
    &.is-last {
      transform: translateX(-28px);
    }
    &.is-last-prev {
      transform: translateX(-28px);
    }
  }
}

@media (min-width: 3025px) {
  .partners-screen-container {
    .bottom-info-container {
      max-width: 2760px;
      column-gap: 40px;

      height: 1124px;
    }
  }
}

</style>