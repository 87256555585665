<template>
  <div class="button-reels-container" @click="openVideo">
    <div class="img-container">
      <img :src="getUrlImg('reels.svg')" alt="button">
    </div>
    <p class="text-container">
      {{ textButton }}
    </p>

  </div>
</template>

<script>
import {mapActions} from "vuex";

export default {
  name: "ButtonReels",
  data() {
    return {
      activeFullScreen: false,
      textButton: 'Смотреть\nшоурил',
      activeVideo: false
    }
  },
  methods: {
    ...mapActions(['ACTIVE_MODAL_VIDEO']),
    openVideo() {
      this.ACTIVE_MODAL_VIDEO(true)
    },
  }

}
</script>

<style scoped lang="scss">
  .button-reels-container {
    cursor: pointer;
    display: flex;
    flex-direction: column;
    align-items: center;

   .video-container {
     opacity: 0;
     pointer-events: none;
     position: absolute;

     &.active {
       opacity: 1;
       pointer-events: visible;
     }
   }

    .img-container {

      & img {
        object-fit: contain;
        width: 100%;
        height: 100%;
      }
    }

    .text-container {
      white-space: pre-wrap;
      text-align: center;
      text-transform: uppercase;
      font-weight: 600;
    }
  }


@media (max-width: 3024px) and (min-width: 1921px){
  .button-reels-container {
    row-gap: 32px;

    .img-container {
      width: 173px;
      height: 175px;
    }

    .text-container {
      font-size: 40px;
    }
  }
}


@media (max-width: 1920px) {
  .button-reels-container {
    row-gap: 24px;

    .img-container {
      width: 100px;
      height: 100px;
    }

    .text-container {
      font-size: 24px;
    }
  }
}


@media (max-width: 1600px) {
  .button-reels-container {
    row-gap: 16px;

    .img-container {
      width: 105px;
      height: 106px;
    }

    .text-container {
      font-size: 20px;
    }
  }
}

@media (max-height: 800px) {

}

@media (max-width: 1400px) and (min-height: 801px) {

}

@media (max-width: 1133px) {

}


@media (max-width: 769px) {
  .button-reels-container {
    row-gap: 16px;

    .img-container {
      width: 73px;
      height: 75px;
    }

    .text-container {
      font-size: 16px;
    }
  }
}

@media (max-width: 769px)  and (min-height: 720px) and (max-height: 1000px) {
  //plus
}

@media (min-height: 600px) and (max-height: 630px){

  //8 plus
}

@media (max-width: 375px) {
  //se new
}

@media (max-width: 375px) and (min-height: 630px) {
  //x
}


@media (max-width: 320px) {

}

@media (min-width: 3025px) {
  .button-reels-container {
    row-gap: 32px;

    .img-container {
      width: 233px;
      height: 238px;
    }

    .text-container {
      font-size: 54px;
    }
  }
}

</style>