<template>
  <div class="switch-title-container">
    <div class="switch-title-content"
         @mouseenter="focusTrue(item.id)"
         @mouseleave="focusFalse"
         :class="{'active' : activeIndex === item.id}"
         v-for="item in infoSwitch"
         :key="item.id"
         @click="changeActive(item.id)">
      <transition name="fade-translate-x-revert">
        <circle-title v-if="activeIndex === item.id || activeFocus === item.id" :active-blur="activeFocus === item.id"/>
      </transition>

      <p class="main-title-container">
        {{ item.title }}
      </p>
    </div>
  </div>
</template>

<script>
import CircleTitle from "@/components/shared/CircleTitle.vue";

export default {
  name: "SwitchTitle",
  components: {CircleTitle},
  props: {
    infoSwitch: {
      type: Array,
      required: true
    },
    activeIndex: {
      type: Number,
      required: true
    }
  },
  data() {
    return {
      activeFocus: -1
    }
  },
  methods: {
    changeActive(id) {
      this.$emit('changeActive', id)
    },
    focusTrue(id) {
      this.activeFocus = id
    },
    focusFalse() {
      this.activeFocus = -1
    }
  }
}
</script>

<style scoped lang="scss">
.switch-title-container {
  display: flex;
  flex-direction: column;

  .switch-title-content {
    display: flex;
    flex-direction: row;
    align-items: center;
    position: relative;
    transition: all .6s ease;
    cursor: pointer;

    &:hover {
      .main-title-container {
        opacity: 1;
      }
    }

    &.active {

      .main-title-container {
        opacity: 1;
      }
    }

    .main-title-container {
      transition: all .6s ease;
      font-weight: 300;
      opacity: .15;
    }

    .circle-container {
      position: absolute;
      left: 0;
    }
  }
}




@media (max-width: 3024px){
  .switch-title-container {
    row-gap: 14px;
    .switch-title-content {
      height: 54px;
      padding: 7.5px 0 7.5px 26px;
      &.active {
        padding: 0 0 0 26px;
        .main-title-container {
          font-size: 40px;
        }
      }
      .main-title-container {
        font-size: 32px;
      }
    }
  }
}


@media (max-width: 1920px) {
  .switch-title-container {
    row-gap: 12px;
    .switch-title-content {
      height: 42px;
      padding: 6.5px 0 6.5px 20px;
      &.active {
        padding: 0 0 0 20px;
        .main-title-container {
          font-size: 32px;
        }
      }
      .main-title-container {
        font-size: 24px;
      }
    }
  }
}


@media (max-width: 1600px) {
  .switch-title-container {
    row-gap: 8px;
    .switch-title-content {
      height: 36px;
      padding: 6px 0 6px 16px;
      &.active {
        padding: 0 0 0 16px;
        .main-title-container {
          font-size: 28px;
        }
      }
      .main-title-container {
        font-size: 20px;
      }
    }
  }
}
@media (max-height: 800px) {
  .switch-title-container {
    row-gap: 6px;
    .switch-title-content {
      height: 32px;
      padding: 6px 0 6px 12px;
      &.active {
        padding: 0 0 0 12px;
        .main-title-container {
          font-size: 24px;
        }
      }
      .main-title-container {
        font-size: 20px;
      }
    }
  }
}

@media (max-width: 1400px) and (min-height: 801px) {
  .switch-title-container {
    row-gap: 8px;
    .switch-title-content {
      height: 36px;
      padding: 6px 0 6px 16px;
      &.active {
        padding: 0 0 0 16px;
        .main-title-container {
          font-size: 28px;
        }
      }
      .main-title-container {
        font-size: 20px;
      }
    }
  }
}

@media (max-width: 1133px) {
  .switch-title-container {
    row-gap: 6px;
    .switch-title-content {
      height: 32px;
      padding: 6px 0 6px 12px;
      &.active {
        padding: 0 0 0 12px;
        .main-title-container {
          font-size: 24px;
        }
      }
      .main-title-container {
        font-size: 20px;
      }
    }
  }
}

@media (max-width: 769px) {
  //14
}

@media (max-width: 769px)  and (min-height: 720px) and (max-height: 1000px) {
  //plus
}

@media (min-height: 600px) and (max-height: 630px){

  //8 plus
}

@media (max-width: 375px) {
  //se new
}

@media (max-width: 375px) and (min-height: 630px) {
  //x
}


@media (max-width: 320px) {
  //se old
}

@media (min-width: 3025px) {
  .switch-title-container {
    row-gap: 16px;
    .switch-title-content {
      height: 90px;
      padding: 16px 0 16px 36px;
      &.active {
        padding: 0 0 0 36px;
        .main-title-container {
          font-size: 72px;
        }
      }
      .main-title-container {
        font-size: 46px;
      }
    }
  }
}

</style>