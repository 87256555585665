<template>
  <div class="quiz-screen-container">
    <div class="quiz-screen-content">
        <transition name="fade-translate-y">
          <main-title-quiz class="main-title-container" v-if="isVisible(0)">{{ mainTitle }}</main-title-quiz>
        </transition>
      <div class="sub-title-container one" v-if="isVisible(1)">
        <div class="sub-title-line" :class="{'active-line' : activeSubTitleLine}"></div>
        <transition name="fade-translate-y-revert">
          <main-title-quiz class="main-title-container slogan" v-if="activeSubTitleText && activeMobile">
            {{ subTitle }}<span :class="{'color-title' : activeSubTitleLine}">{{ subTitleEnd }}</span>
          </main-title-quiz>
        </transition>
        <transition name="fade-translate-y-revert">
          <main-title-quiz class="main-title-container slogan" v-if="activeSubTitleText && !activeMobile">
            {{ subTitleDesktop }}<span :class="{'color-title' : activeSubTitleLine}">{{ subTitleEnd }}</span>
          </main-title-quiz>
        </transition>
      </div>

      <div class="sub-paragraph-container one">
        <transition name="fade-translate-x">
          <main-paragraph v-if="isVisible(2)">{{ firstParagraph }}</main-paragraph>
        </transition>
      </div>

      <div class="sub-title-container two">
        <transition name="fade-translate-x-revert">
          <main-title-quiz class="main-title-container" v-if="isVisible(3)">{{ subSecondTitle }}</main-title-quiz>
        </transition>
      </div>

      <div class="sub-paragraph-container two">
        <transition name="fade-translate-x">
          <main-paragraph v-if="isVisible(4)">{{ secondParagraph }}</main-paragraph>
        </transition>
      </div>


      <transition name="fade-translate-x-revert">
        <div class="button-container" v-if="isVisible(5)">
            <button-main @click="routTo"  :active-dark="true">
              {{ buttonName }}
            </button-main>
            <main-paragraph class="time" :class="{'desktop' : !activeMobile}">
              {{timeTitle}}
              <div class="img-container">
                <img :src="getUrlIcons(timeImg)" alt="time-icon">
              </div>
            </main-paragraph>
        </div>
      </transition>
    </div>

    <transition name="fade-opacity-lazy">
      <look-title-arrow v-if="isVisible(6)"
                        :main-title="footerTitle"/>
    </transition>

  </div>
</template>

<script>
import ButtonMain from "@/components/shared/ButtonMain.vue";
import SubTitle from "@/components/shared/text/SubTitle.vue";
import MainParagraph from "@/components/shared/text/MainParagraph.vue";
import MainTitleQuiz from "@/components/shared/text/MainTitleQuiz.vue";
import LookTitleArrow from "@/components/entities/LookTitleArrow.vue";

export default {
  name: "QuizStartScreen",
  components: {LookTitleArrow, MainTitleQuiz, MainParagraph, SubTitle, ButtonMain},
  data() {
    return {
      footerTitle: 'Создать сильный бренд\nс командой .look займет 3 недели',
      activeSubTitleLine: false,
      activeSubTitleText: false,
      currentItem: -1,

      buttonName: 'Ответить',

      mainTitle: 'Что отличает рядовую компанию от лидера?',
      subTitle: 'Сильный бренд, способный\n вызывать набор эмоций и\n ассоциаций, совсем как...\n',
      subTitleDesktop: 'Сильный бренд, способный вызывать\nнабор эмоций и ассоциаций, совсем\nкак... ',
      subTitleEnd: 'человек',

      subSecondTitle: 'Искатель, Славный малый или Правитель?',

      firstParagraph: 'Мы склонны подсознательно считывать и доверять архетипичным образам, заложенным в нас социумом и культурой. Поэтому маркетологи адаптировали теорию архетипов Юнга, чтобы создавать бренды, которые находят место в сердцах и умах людей.',
      secondParagraph: 'Узнайте какой архетип подойдёт вашей компании и как его применить, чтобы усилить значимость и влияние бренда.',

      timeTitle: 'Тест займет ≈ 5 минут',
      timeImg: 'time.svg',
    }
  },
  mounted() {
    this.showNextItem();
  },
  methods: {

    routTo() {
      this.$router.push({name: 'quiz-loading', params: {id: 0}})
    },
    startSubTitle() {
        setTimeout(() => {
          this.activeSubTitleText = true
        }, 500)
        setTimeout(() => {
          this.activeSubTitleLine = true
        }, 1000)
    },

    showNextItem() {
      if (this.currentItem < 6) {
        setTimeout(() => {
          this.currentItem++
          if(this.currentItem === 1) {
            this.startSubTitle();
            setTimeout(() => {
              this.showNextItem()
            }, 1500)
          } else {
            this.showNextItem()
          }
        }, 500)
      }
    },

    isVisible(index) {
      return index <= this.currentItem
    },
  }
}
</script>


<style scoped lang="scss">
.main-paragraph-container {
  line-height: 120%;
}
  .quiz-screen-container {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100%;
    position: relative;

    .quiz-screen-content {
      display: flex;
      flex-direction: column;

      .main-title-container {
        font-weight: 700;
      }

      .sub-title-container {
        display: flex;
        flex-direction: row;
        text-align: right;
        white-space: pre-wrap;
        align-self: flex-end;


        .main-title-container {
          &.slogan {
            font-weight: 400;
          }
        }

        &.two {
          align-self: flex-start;
        }

        .sub-title-line {
          background-color: var(--color-main-accent);
          width: 100%;
          transform: scaleX(0);
          transition: transform 1s ease;
          transform-origin: left;

          &.active-line {
            transform: scaleX(1);
          }
        }
      }

      .button-container {
        display: flex;
        align-self: center;
        align-items: center;
        flex-direction: column;

        .main-paragraph-container {
          display: flex;
          width: 100%;
          flex-direction: row;
          align-items: stretch;
          white-space: nowrap;

          .img-container {
            width: 100%;
            height: 100%;display: flex;
            overflow: visible;
            & img {
              width: 100%;
              height: 100%;
              object-fit: contain;
            }
          }
        }
      }
    }
  }

  .main-title-container {
    span {
      transition: color .6s ease;
    }

    .color-title {
      color: var(--color-main-accent);
    }
  }

@media (max-width: 3840px) {

  .quiz-screen-container {
    .quiz-screen-content {
      row-gap: 96px;

      .sub-title-container {
        column-gap: 40px;
        .sub-title-line {
          width: 58px;
          height: 10px;
          margin-top: 52px;
        }
      }

      .button-container {
        row-gap: 24px;

        .main-paragraph-container {
          column-gap: 24px;

          .img-container {
            width: 40px;
            max-height: 40px;
          }
        }
      }
    }
  }
}

  @media (max-width: 3024px){

    .main-paragraph-container {
      &.time {
        font-size: 24px;
      }
    }

    .quiz-screen-container {
      .quiz-screen-content {
        row-gap: 60px;

        .sub-title-container {
          column-gap: 24px;

          .main-title-container {
            &.slogan {
              font-size: 44px;
            }
          }

          .sub-title-line {
            width: 31px;
            height: 6px;
            margin-top: 26px;
          }
        }

        .button-container {
          margin-top: 40px;
          row-gap: 22px;

          .main-paragraph-container {
            column-gap: 24px;

          .img-container {
            max-width: 24px;
            max-height: 24px;
          }
        }
        }
      }
    }
  }

@media (max-width: 2240px){
  .quiz-screen-container {
    .quiz-screen-content {
      row-gap: 48px;

      .sub-title-container {
        column-gap: 18px;

        .main-title-container {
          &.slogan {
            font-size: 44px;
          }
        }

        .sub-title-line {
          width: 31px;
          height: 6px;
          margin-top: 26px;
        }
      }

      .button-container {
        row-gap: 22px;

        .main-paragraph-container {
          column-gap: 18px;

          .img-container {
            max-width: 22px;
            max-height: 22px;
          }
        }
      }
    }
  }
}


  @media (max-width: 1920px) {
    .main-paragraph-container {
      &.time {
        font-size: 20px;
      }
    }
    .quiz-screen-container {
      .quiz-screen-content {
        row-gap: 56px;

        .main-title-container {
          font-size: 42px;
        }

        .sub-title-container {
          column-gap: 18px;


          .main-title-container {
            &.slogan {
              font-size: 32px;
            }
          }

          .sub-title-line {
            width: 26px;
            height: 4px;
            margin-top: 16px;
          }
        }

        .button-container {
          margin-top: 0;
          row-gap: 18px;

          .main-paragraph-container {
            column-gap: 24px;
            .img-container {
              max-width: 22px;
              max-height: 22px;
            }
          }
        }
      }
    }
  }


  @media (max-width: 1600px) {

    .main-paragraph-container {
      &.time {
        font-size: 16px;
      }
    }

    .quiz-screen-container {
      .quiz-screen-content {
        row-gap: 48px;

        .main-title-container {
          font-size: 28px;
        }

        .sub-title-container {
          column-gap: 16px;


          .main-title-container {
            &.slogan {
              font-size: 28px;
            }
          }

          .sub-title-line {
            width: 17px;
            height: 3px;
            margin-top: 16px;
          }
        }

        .button-container {
          row-gap: 12px;

          .main-paragraph-container {
            column-gap: 12px;
            .img-container {
              max-width: 18px;
              max-height: 18px;
            }
          }
        }
      }
    }
  }
  @media (max-height: 800px) {


    .main-paragraph-container {
      &.time {
        font-size: 14px;
      }
    }

    .main-title-container {
        font-size: 24px;
    }


    .main-paragraph-container {
      font-size: 16px;
    }

    .quiz-screen-container {
      .quiz-screen-content {
        row-gap: 36px;

        .main-title-container {
          font-size: 24px;
        }


        .sub-title-container {
          column-gap: 14px;

          .main-title-container {
            &.slogan {
              font-size: 24px;
            }
          }

          .sub-title-line {
            width: 19px;
            height: 4px;
            margin-top: 14px;
          }
        }

        .button-container {
          row-gap: 8px;

          .main-paragraph-container {
            column-gap: 16px;
            .img-container {
              max-width: 15px;
              max-height: 15px;
            }
          }
        }
      }
    }
  }

  @media (max-width: 1400px) and (min-height: 801px) {

    .main-paragraph-container {
      &.time {
        font-size: 16px;
      }
    }
    .quiz-screen-container {
      .quiz-screen-content {
        row-gap: 48px;

        .main-title-container {
          font-size: 28px;
        }


        .sub-title-container {
          column-gap: 16px;

          .main-title-container {
            &.slogan {
              font-size: 28px;
            }
          }
          .sub-title-line {
            width: 17px;
            height: 3px;
            margin-top: 16px;
          }
        }

        .button-container {
          row-gap: 12px;

          .main-paragraph-container {
            column-gap: 12px;
            .img-container {
              max-width: 18px;
              max-height: 18px;
            }
          }
        }
      }
    }
  }

  @media (max-width: 1133px) {

    .main-paragraph-container {
      &.time {
        font-size: 14px;
      }
    }

    .main-paragraph-container {
      font-size: 16px;
    }

    .quiz-screen-container {
      .quiz-screen-content {
        row-gap: 36px;

        .main-title-container {
          font-size: 24px;
        }

        .sub-title-container {
          column-gap: 14px;

          .main-title-container {
            &.slogan {
              font-size: 24px;
            }
          }
          .sub-title-line {
            width: 19px;
            height: 4px;
            margin-top: 14px;
          }
        }

        .button-container {
          row-gap: 8px;

          .main-paragraph-container {
            column-gap: 16px;
            .img-container {
              max-width: 15px;
              max-height: 15px;
            }
          }
        }
      }
    }
  }

  @media (max-width: 769px) {
    .main-paragraph-container {
      &.time {
        font-size: 12px;
      }
    }
    .main-title-container {
      font-size: 22px;
    }

    .main-paragraph-container {
      font-size: 12px;
      line-height: 135%;
    }

    .quiz-screen-container {
      padding-bottom: 10px;
      .quiz-screen-content {
        row-gap: 32px;

        .sub-title-container {
          column-gap: 7px;

          &.two {
            align-self: flex-start;

            .main-title-container {
              font-size: 16px;
            }
          }

          .main-title-container {
            &.slogan {
              font-size: 16px;
            }
          }
          .sub-title-line {
            width: 16px;
            height: 3px;
            margin-top: 12px;
          }
        }

        .button-container {
          row-gap: 10px;

          .main-paragraph-container {
            column-gap: 10px;
            .img-container {
              max-width: 12px;
              max-height: 12px;
            }
          }
        }
      }
    }
  }

  @media (max-width: 769px)  and (min-height: 720px) and (max-height: 1000px) {
    .main-paragraph-container {
      &.time {
        font-size: 12px;
      }
    }
    .main-title-container {
      font-size: 22px;
    }

    .main-paragraph-container {
      font-size: 14px;
      line-height: 135%;
    }

    .quiz-screen-container {
      padding-bottom: 21px;
      .quiz-screen-content {
        row-gap: 34px;

        .sub-title-container {
          column-gap: 7px;

          &.two {
            align-self: flex-start;

            .main-title-container {
              font-size: 16px;
            }
          }

          .main-title-container {
            &.slogan {
              font-size: 22px;
            }
          }
          .sub-title-line {
            width: 16px;
            height: 3px;
            margin-top: 12px;
          }
        }

        .button-container {
          row-gap: 10px;

          .main-paragraph-container {
            column-gap: 10px;
            .img-container {
              max-width: 12px;
              max-height: 12px;
            }
          }
        }
      }
    }
  }

  @media (min-height: 600px) and (max-height: 630px){

    //8 plus
  }

  @media (max-width: 375px) {
    .main-paragraph-container {
      &.time {
        font-size: 12px;
      }
    }
    .main-title-container {
      font-size: 22px;
    }

    .main-paragraph-container {
      font-size: 12px;
      line-height: 135%;
    }

    .quiz-screen-container {
      padding-bottom: 10px;
      .quiz-screen-content {
        row-gap: 18px;

        .sub-title-container {
          column-gap: 7px;

          &.two {
            align-self: flex-start;

            .main-title-container {
              font-size: 16px;
            }
          }

          .main-title-container {
            &.slogan {
              font-size: 16px;
            }
          }
          .sub-title-line {
            width: 16px;
            height: 3px;
            margin-top: 12px;
          }
        }

        .button-container {
          row-gap: 10px;

          .main-paragraph-container {
            column-gap: 10px;
            .img-container {
              max-width: 12px;
              max-height: 12px;
            }
          }
        }
      }
    }
  }

  @media (max-width: 375px) and (min-height: 630px) {
    .main-paragraph-container {
      &.time {
        font-size: 12px;
      }
    }
    .main-title-container {
      font-size: 22px;
    }

    .main-paragraph-container {
      font-size: 12px;
      line-height: 135%;
    }

    .quiz-screen-container {
      padding-bottom: 10px;
      .quiz-screen-content {
        row-gap: 32px;

        .sub-title-container {
          column-gap: 7px;

          &.two {
            align-self: flex-start;

            .main-title-container {
              font-size: 16px;
            }
          }

          .main-title-container {
            &.slogan {
              font-size: 16px;
            }
          }
          .sub-title-line {
            width: 16px;
            height: 3px;
            margin-top: 12px;
          }
        }

        .button-container {
          row-gap: 10px;

          .main-paragraph-container {
            column-gap: 10px;
            .img-container {
              max-width: 12px;
              max-height: 12px;
            }
          }
        }
      }
    }
  }

@media (max-width: 320px) {
  .main-paragraph-container {
    &.time {
      font-size: 8px;
    }
  }
  .main-title-container {
    font-size: 16px;
  }

  .main-paragraph-container {
    font-size: 10px;
    line-height: 135%;
  }

  .quiz-screen-container {
    padding-bottom: 5px;
    .quiz-screen-content {
      row-gap: 12px;

      .sub-title-container {
        column-gap: 7px;

        &.two {
          align-self: flex-start;

          .main-title-container {
            font-size: 12px;
          }
        }

        .main-title-container {
          &.slogan {
            font-size: 12px;
          }
        }
        .sub-title-line {
          width: 16px;
          height: 3px;
          margin-top: 12px;
        }
      }

      .button-container {
        row-gap: 10px;

        .main-paragraph-container {
          column-gap: 10px;
          .img-container {
            max-width: 12px;
            max-height: 12px;
          }
        }
      }
    }
  }
}


  @media (min-width: 3841px) {
    .quiz-screen-container {
      .quiz-screen-content {
        row-gap: 128px;

        .sub-title-container {
          column-gap: 48px;
          .sub-title-line {
            width: 58px;
            height: 10px;
            margin-top: 52px;
          }
        }

        .button-container {
          row-gap: 24px;

          .main-paragraph-container {
            column-gap: 32px;

            .img-container {
              width: 40px;
              max-height: 40px;
            }
          }
        }
      }
    }
  }
</style>