<template>
  <div class="slider-container">
    <div class="top-slider-container active-gradient">
        <div class="arrow-icon-container left"  v-show="!activeMobile" :class="{'active' : activeIndex !== 0}">
          <img class="swiper-button-team-left" :src="getUrlIcons('arrow-double-left.svg')" alt="">
        </div>

      <swiper @slide-change="onSwiperMain"
              v-if="!activeMobile"
              @swiper="changeSettingSwiper"
              :modules="modules"
              :slideToClickedSlide="true"
              class="swiper"
              :mousewheel="true"
              :navigation = "{
                nextEl: '.swiper-button-team-right',
                prevEl: '.swiper-button-team-left'
              }"
              :breakpoints="
            {
              0: {
                slidesPerView: 1,
                centeredSlides: false,
                spaceBetween: 20,
                allowTouchMove: true
              },
              1050: {
                slidesPerView: 1,
                centeredSlides: false,
                spaceBetween: 22,
                allowTouchMove: true
              },
               1200: {
                slidesPerView: 1,
                centeredSlides: false,
                spaceBetween: 22,
                allowTouchMove: true
              },
              1300: {
                slidesPerView: 1,
                centeredSlides: false,
                spaceBetween: 24,
                allowTouchMove: true
              },
              1600: {
                slidesPerView: 1,
                centeredSlides: false,
                spaceBetween: 28,
                allowTouchMove: true
              },
              1921: {
                slidesPerView: 1,
                spaceBetween: 32,
                centeredSlides: false,
                allowTouchMove: true
              },
              2241: {
                slidesPerView: 1,
                spaceBetween: 32,
                centeredSlides: false,
                allowTouchMove: true
              },
              3025: {
                slidesPerView: 1,
                centeredSlides: false,
                spaceBetween: 64,
                allowTouchMove: true
              },
              3841: {
                slidesPerView: 1,
                centeredSlides: false,
                spaceBetween: 0,
                allowTouchMove: true
              }
            }"
              :speed="1000"
              direction="horizontal">
        <swiper-slide class="custom-slide" v-for="(item) in teamInfo"
                      v-slot="{ isNext, isActive, isPrev }">
          <card-team :class="{'is-active' : isActive, 'is-next': isNext, 'is-prev': isPrev, 'is-animation' : isActive, 'is-hide': hideIndex === item.id}"
                     :is-click-active="isClickActive"
                     :is-active="isActive"
                     @changeStateNext="changeStateNext"
                     :is-next="isNext"
                     :active-index="item.id"
                     :card-info="item"/>
        </swiper-slide>
      </swiper>


        <swiper v-else
                @slide-change="onSwiperMain"
                @swiper="changeSettingSwiper"
                :slidesPerView="1.3"
                :loop="true"
                :centeredSlides="true"
                :allowTouchMove="true"
                class="swiper"
                :speed="1000"
                direction="horizontal">
          <swiper-slide class="custom-slide" v-for="(item,index) in teamInfo"
                        :virtualIndex="index"
                        v-slot="{ isNext, isActive, isPrev }">
            <card-team-mobile :class="{'is-active' : isActive, 'is-next' : isNext, 'is-prev' : isPrev, 'is-animation' : isActive || isNext}"
                              :is-active="isActive"
                              :card-info="item"/>
          </swiper-slide>
        </swiper>


      <div class="arrow-icon-container right" v-show="!activeMobile" :class="{'active' : activeIndex !== teamInfo.length - 1}">
          <img class="swiper-button-team-right" :src="getUrlIcons('arrow-double-right.svg')" alt="">
        </div>
    </div>
  </div>
</template>

<script>
import {Navigation, Mousewheel} from "swiper";
import CardTeam from "@/components/entities/CardTeam.vue";
import BlockPagination from "@/components/entities/BlockPagination.vue";
import CardTeamMobile from "@/components/entities/CardTeamMobile.vue";

export default {
  name: "SliderTeam",
  components: {CardTeamMobile, BlockPagination, CardTeam},
  data() {
    return {
      hideIndex: -1,
      scrollSwiper: '',
      activeIndex: 0,
      modules: [Navigation, Mousewheel],
      teamInfo: [
        {
          id: 0,
          name: 'Анна Растрыгина',
          position: 'Маркетинг-директор',
          description: ['Успешно вывела на рынок онлайн-сервис Сбера по подписке на автомобили. 12 лет опыта в полном цикле маркетинговых коммуникаций: разработка маркетинг-стратегии, построение бренда, полный цикл разработки и менеджмента рекламных кампаний: ТВ, digital, outdoor, performance, социальные сети, блогеры и PR.', 'Курирует работу команд PR и маркетинга группы.'],
          title: 'Директор функции PR, лидер вертикали «PR и Маркетинг» ГК «visioners»',
          img: '1.webp',
        },
        {
          id: 1,
          name: 'Евгений Ковалёв',
          position: 'Арт-директор',
          description: ['Более 10 лет опыта в различных областях индустрии искусств. Активно занимался направлениями цифровой колористики, фотоискусства, видеографией и скульптурированием. Имеет богатый опыт в области UI/UX/CX дизайна, арт-диджитал дизайна, а также консалтинга в сфере промышленного дизайна и геймдев разработки.', 'Курирует работу дизайнеров группы, отвечает за развитие стиля бренда и использование его элементов в PR кампаниях группы.'],
          title: 'Арт-директор, лидер вертикали «Дизайн» ГК «visioners»',
          img: '2.webp',
        },
        {
          id: 2,
          name: 'Данил Хуртаев',
          position: 'Ивент-директор',
          description: ['Более 5 лет опыта в коммерческой event-индустрии с опытом реализации 30 проектов бизнес-мероприятий, форумов и отраслевых экспертных площадок федерального значения. Многофункциональный менеджер C-уровня с компетенциями в области организации ивентов полного цикла и проведения переговорного процесса с партнерами.', 'Курирует вопросы плана мероприятий, а также процессы их организации и PR поддержки в группе компаний.'],
          title: 'Директор функции сопровождения мероприятий, лидер вертикали «Ивенты» ГК «visioners»',
          img: '3.webp',
        },
        {
          id: 3,
          name: 'Ольга Ершкова',
          position: 'Лид-фотограф',
          description: ['Фотограф с многолетним стажем и опытом коммерческой съемки для Яндекс, Лукоил, WorldClass, Металлург и других крупных национальных компаний и корпораций. Также снимает коммерческий контент для локальных брендов, компаний, истории обычных людей, личные проекты. Имеет богатый опыт фотосъемки в киноиндустрии.', 'Организовывает съемочный процесс, отвечает за все этапы работы с фото от студии до постобработки.'],
          title: 'Лид-фотограф вертикали «PR и Маркетинг» ГК «visioners»',
          img: '4.webp',
        },{
          id: 4,
          name: 'Даниил Баданин',
          position: 'Лид-разработчик',
          description: ['Лид разработки с более чем 10 летним опытом фокуса на сопровождение проектов полного цикла. Управлял крупными командами разработки и внедрял программное обеспечение с акцентом на качество и стабильность в высоконагрузочной среде. Реализовывал кастомные проекты  и продукты на базе широкоизвестных конструкторов программных решений.',
          'Организовывает работу команд разработки на спец-проектах группы компаний.'],
          title: 'Лид-разработчик спец-проектов вертикали «Программная инженерия» ГК «visioners»',
          img: '5.webp',
        },
        {
          id: 5,
          name: 'Ева Любимова',
          position: 'Лид-стилист',
          description: ['Стилист со специализацией в области мужского и женского стайлинга, рекламной съемки, создания лукбуков и богатым опытом работы на съемочных площадках. В разные годы отвечала за формирование образов в PR и рекламных кампаниях брендов Rich, Ostin, OZON, Яндекс.Плюс. Имеет опыт работы с крупными селебрети и в создании эксклюзивных образов.', 'Организовывает работу команд стилистов, ассистентов и имиджмейкеров.'],
          title: 'Лид-стилист вертикали «PR и Маркетинг» ГК «visioners»',
          img: '6.webp',
        },
        {
          id: 6,
          name: 'Артем Кузьмин',
          position: 'Лид по 3D моделированию',
          description: ['Специализируется на высокоточном и гиперреалистичном моделировании объектов, имеет опыт моделинга сложных промышленных систем, а также медицинских аппаратов и установок. Успешно работал и руководил командами на проектах в области архитектуры и ландшафтного дизайна. Составлял технические задания для проектов полного цикла.', 'Организовывает работу 3D команд, контролирует качество, степень реализма и детализации объектов'],
          title: 'Лид-специалист 3D моделирование вертикали «Дизайн» ГК «visioners»',
          img: '7.webp',
        },
        {
          id: 7,
          name: 'Мария Левченко',
          position: 'Лид-графический дизайнер',
          description: ['Специализируется на создании идентичности бренда, уникальных визуальных решений, рекламных материалов и носителей фирменного стиля. Отвечает за разработку дизайн-концепций, управление проектами и руководство дизайнерской командой, обеспечивая высокое качество и креативность дизайн-решений.',
          'Организовывает работу дизайн-команд, формирует арт-концепцию'],
          title: 'Лид-графический дизайнер вертикали «Дизайн» ГК «visioners»',
          img: '8.webp',
        },
        {
          id: 8,
          name: 'Екатерина Цыкман',
          position: 'Лид-UI/UX дизайнер',
          description: ['Специалист по разработке интерфейсов и улучшению пользовательского опыта. Проводила качественные исследования, to-be прототипирование, дизайн и тестирование UI/UX. Обеспечивает интуитивно понятные, эффективные и эстетически привлекательные интерфейсные решения, формирует компоненты дизайн-системы.',
          'Организовывает работу дизайн-команд, отвечает за качество пользовательского опыта решния'],
          title: 'Лид-UI/UX дизайнер вертикали «Дизайн» ГК «visioners»',
          img: '9.webp',
        },
        {
          id: 9,
          name: 'Кристина Булгакова',
          position: 'Лид-вэб дизайнер',
          description: ['Эксперт-специалист в области создания и оптимизации дизайна веб-сайтов. Имеет обширный опыт в разработке многостраничных корпоративных сайтов с кастомными панелями администратора, а также в области интеграции сложных дизайн элементов в вэб концепции. Разрабатывала 3D иммерсивные и интерактивные сайты.','Организовывает работу дизайн-команд, отвечает за техническую исполнимость дизайна'],
          title: 'Лид-вэб дизайнер вертикали «Дизайн» ГК «visioners»',
          img: '10.webp',
        },
        {
          id: 10,
          name: 'Кирилл Ким',
          position: 'Лид по 3D анимации',
          description: ['Специалист по созданию анимационных скелетов и управлению движениями объектов. Работает с оптимизацией меша под риггинг и скиннинг, анимациями сложных механизмов и персонажей. Имеет большой опыт ручного ригга и ручной отрисовки весов. Создает блоки управления (контроллеры) для последующей анимации.','Организовывает работу 3D аниматоров, обеспечивает контроль качества анимации.'],
          title: 'Лид-специалист 3D анимация и риггинг вертикали «Дизайн» ГК «visioners»',
          img: 'empty.webp',
        },
        {
          id: 11,
          name: 'Ангелина Парамонова',
          position: 'Лид 3D текстурирование',
          description: ['Специалист в области создания гиперреалистичных и стилизованных структур по методу отрисовки и PBR текстурирования. В совершенстве владеет теорией и практикой имитации материалов, правилами настройки освещения и процедурного создания эффектов. Имеет богатый опыт работы по ручной UV-развертке и подбору референсов материалов.','Организовывает работу 3D команд, контролирует качество текстур и безошибочность внедрения объектов'],
          title: 'Лид-специалист 3D текстурирование вертикали «Дизайн» ГК «visioners»',
          img: '12.webp',
        },{
          id: 12,
          name: 'Алина Березнякова',
          position: 'Лид моушен',
          description: ['Специализируется на комплексной разработке анимационных роликов в том числе с внедрением 3D графики и объектов. Обладает глубоким пониманием современных технологий и инструментов в моушн-графике. Эффективно реализовывает идеи от раскадровки и драфта до готовой анимации с минимальным техническим заданием.', 'Организовывает работу команды по моушен дизайну, отвечает за качество и современность решений'],
          title: 'Лид-специалист моушен и видео- продакшн вертикали «Дизайн» ГК «visioners»',
          img: '13.webp',
        },{
          id: 13,
          name: 'Екатерина Сердитова',
          position: 'Лид печать и производство',
          description: ['Специализируется на подготовке и производстве печатной продукции. Имеет обширный опыт работы с цифровой, офсетной, флексопечатью. Знает особенности подготовки макетов для каждого продукта. Ведёт коммуникацию с типографиями и контролирует производство для достижения высокого качества продукции и соблюдения сроков.', 'Организовывает работу по печати, контролирует качество печатной продукции.'],
          title: 'Лид-специалист печать и производство вертикали «Дизайн» ГК «visioners»',
          img: '14.webp',
        }
      ],

      isClickActive: false,
      stateArrow: [true, true]
    }
  },


  methods: {
    onSwiperMain(swiper) {
        // this.changeStateArrow('all')
        this.activeIndex = swiper.realIndex;
    },

    changeSettingSwiper(swiper) {
      this.scrollSwiper = swiper
    },

    changeStateNext(index) {
      this.hideIndex = index;
    },

    // scrollTo(index) {
    //   if(this.activeIndex !== index) {
    //     this.scrollSwiper.slideToLoop(index)
    //     this.isClickActive = true
    //     this.$nextTick(() => {
    //       this.isClickActive = false
    //     })
    //   }
    // },
  }
}
</script>

<style scoped lang="scss">


.active-gradient:after {
  top: 0;
  right: -1px;
  background: linear-gradient(90deg, rgba(30, 32, 37, 0) 0, rgba(30, 32, 37) 100%);
}

.active-gradient:before{
  top: 0;
  left: -4px;
  background: linear-gradient(270deg, rgba(30, 32, 37, 0) 0, rgba(30, 32, 37) 100%);
}

  .slider-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    height: 100%;

    .top-slider-container {
      display: flex;
      flex-direction: row;
      align-items: center;
      width: 100%;
      height: 100%;
      position: relative;
      .arrow-icon-container {
        position: relative;
        z-index: 12;
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
        height: 100%;
        opacity: 0;
        transition: all .6s ease;
        cursor: none;

        &.active {
          cursor: pointer;
          opacity: 1;
        }

        & img {
          width: 100%;
          height: 100%;
        }
      }

      .swiper {
        overflow: visible;
        width: 100%;
        height: 100%;
      }

      .card-team-container {
        position: relative;
        transition: all 1s ease;
        z-index: 10;
        opacity: 1;

        &.is-hide {
          opacity: 0;
        }


        &.is-active {
          transform: scale(1);
        }

        &.is-next {
          transform: scale(1);
        }
      }
    }
  }


  @media (min-width: 769px) {
    .slider-container {
      .top-slider-container {
        .card-team-container {
          &.is-active {
            z-index: 12;
          }

          &.is-next {
            z-index: 11;
          }
        }
      }
    }
  }

  @media (max-width: 3024px){
    .slider-container {

      .top-slider-container {
        .arrow-icon-container {
          max-width: 36px;
          max-height: 36px;
          min-width: 36px;
          min-height: 36px;
        }

        .swiper {
          max-width: 1616px;
        }

        .card-team-container {
          transform: scale(0.66) translateX(-3112px);

          &.is-next {
            transform: scale(0.66) translateX(-1123px);
          }

          &.is-prev {
            transform: scale(0.66) translateX(1500px);
          }
        }
      }
    }
  }


  @media (max-width: 1920px) {
    .slider-container {

      .top-slider-container {
        .arrow-icon-container {
          max-width: 30px;
          max-height: 30px;
          min-width: 30px;
          min-height: 30px;
        }

        .swiper {
          max-width: 1304px;
        }

        .card-team-container {
          transform: scale(0.72) translateX(-2278px);

          &.is-next {
            transform: scale(0.72) translateX(-805px);
          }

          &.is-prev {
            transform: scale(0.72) translateX(1100px);
          }
        }
      }
    }
  }


  @media (max-width: 1600px) {
    .slider-container {

      .top-slider-container {
        .arrow-icon-container {
          max-width: 24px;
          max-height: 24px;
          min-width: 24px;
          min-height: 24px;
        }

        .swiper {
          max-width: 1032px;
        }

        .card-team-container {
          transform: scale(0.85) translateX(-1525px);

          &.is-next {
            transform: scale(0.85) translateX(-545px);
          }

          &.is-prev {
            transform: scale(0.85) translateX(800px);
          }
        }
      }
    }
  }

  @media (max-height: 800px) {
    .slider-container {

      .top-slider-container {
        .arrow-icon-container {
          max-width: 20px;
          max-height: 20px;
          min-width: 20px;
          min-height: 24px;
        }

        .swiper {
          max-width: 886px;
        }

        .card-team-container {
          transform: scale(0.77) translateX(-1445px);

          &.is-next {
            transform: scale(0.77) translateX(-504px);
          }

          &.is-prev {
            transform: scale(0.77) translateX(800px);
          }
        }
      }
    }
  }

  @media (max-width: 1400px) and (min-height: 801px) {
    .slider-container {

      .top-slider-container {
        .arrow-icon-container {
          max-width: 24px;
          max-height: 24px;
          min-width: 24px;
          min-height: 24px;
        }

        .card-team-container {
          transform: scale(0.85) translateX(-1525px);

          &.is-next {
            transform: scale(0.85) translateX(-545px);
          }

          &.is-prev {
            transform: scale(0.85) translateX(800px);
          }
        }
      }
    }
  }

  @media (max-width: 1133px) {
    .slider-container {

      .top-slider-container {
        .arrow-icon-container {
          max-width: 20px;
          max-height: 20px;
          min-width: 20px;
          min-height: 24px;
        }

        .swiper {
          max-width: 886px;
        }

        .card-team-container {
          transform: scale(0.77) translateX(-1445px);

          &.is-next {
            transform: scale(0.77) translateX(-504px);
          }

          &.is-prev {
            transform: scale(0.77) translateX(800px);
          }
        }
      }
    }
  }

  @media (max-width: 1050px) {
    .slider-container {

      .top-slider-container {

        .swiper {
          max-width: 900px;
        }

        .card-team-container {
          transform: scale(0.77) translateX(-1480px);

          &.is-next {
            transform: scale(0.77) translateX(-520px);
          }

          &.is-prev {
            transform: scale(0.77) translateX(800px);
          }
        }
      }
    }
  }

  @media (max-width: 769px) {
    .slider-container {

      .top-slider-container {

        .swiper {
          height: 100%;
          max-width: 100%;
        }

        .card-team-container {
          transform: scale(0.8) translateX(0) translateY(-65px);

          &.is-prev {
            transform: scale(0.8) translateX(28px) translateY(-65px);
          }

          &.is-next {
            transform: scale(0.8) translateX(-28px) translateY(-65px);
          }
        }
      }
    }
  }

  @media (max-width: 769px)  and (min-height: 720px) and (max-height: 1000px) {
    .slider-container {

      .top-slider-container {
        .card-team-container {
          transform: scale(0.8) translateX(0) translateY(-65px);

          &.is-prev {
            transform: scale(0.8) translateX(36px) translateY(-65px);
          }

          &.is-next {
            transform: scale(0.8) translateX(-36px) translateY(-65px);
          }
        }
      }
    }
  }

  @media (min-height: 600px) and (max-height: 630px){

    //8 plus
  }

  @media (max-width: 375px) {
    .slider-container {

      .top-slider-container {
        .card-team-container {
          transform: scale(0.8) translateX(0) translateY(-65px);

          &.is-prev {
            transform: scale(0.8) translateX(22px) translateY(-65px);
          }

          &.is-next {
            transform: scale(0.8) translateX(-22px) translateY(-65px);
          }
        }
      }
    }
  }

  @media (max-width: 375px) and (min-height: 630px) {
    //x
  }


  @media (max-width: 320px) {
    .slider-container {

      .top-slider-container {
        .card-team-container {
          transform: scale(0.8) translateX(0) translateY(-40px);

          &.is-prev {
            transform: scale(0.8) translateX(8px) translateY(-40px);
          }

          &.is-next {
            transform: scale(0.8) translateX(-8px) translateY(-40px);
          }
        }
      }
    }
  }

  @media (min-width: 3025px) {
    .slider-container {

      .top-slider-container {
        .arrow-icon-container {
          max-width: 44px;
          max-height: 44px;
          min-width: 44px;
          min-height: 44px;

        }

        .swiper {
          max-width: 2832px;
        }

        .card-team-container {
          transform: scale(0.7) translateX(-4940px);

          &.is-next {
            transform: scale(0.7) translateX(-1720px);
          }

          &.is-prev {
            transform: scale(0.7) translateX(1700px);
          }
        }
      }
    }
  }

</style>