<template>
  <div class="video-screen-container main-container">
    <div class="video-content">

    </div>
  </div>
</template>

<script>
export default {
  name: "VideoScreen"
}
</script>

<style scoped lang="scss">
  .video-screen-container {
    width: 100%;
    height: var(--calc-height);
    justify-content: center;

    .video-content {
      width: 100%;
      height: 100%;
      background-color: #F5F5F5;
    }
  }


  @media (max-width: 3024px){
    .video-screen-container {
      .video-content {
        border-radius: 16px;
        height: 1068px;
      }
    }
  }

  @media (max-width: 1920px) {
    .video-screen-container {
      .video-content {
        height: 865px;
        border-radius: 12px;
      }
    }
  }


  @media (max-width: 1600px) {
    .video-screen-container {
      .video-content {
        height: 662px;
        border-radius: 10px;
      }
    }
  }
  @media (max-height: 800px) {
    .video-screen-container {
      .video-content {
        height: 556px;
        border-radius: 16px;
      }
    }
  }

  @media (max-width: 1400px) and (min-height: 801px) {
    .video-screen-container {
      .video-content {
        height: 662px;
        border-radius: 10px;
      }
    }
  }

  @media (max-width: 1133px) {
    .video-screen-container {
      .video-content {
        height: 556px;
        border-radius: 16px;
      }
    }
  }

  @media (max-width: 769px) {
    .video-screen-container {
      height: auto;
      .video-content {
        height: 201px;
        border-radius: 4px;
      }
    }
  }

  @media (max-width: 769px)  and (min-height: 720px) and (max-height: 1000px) {
    .video-screen-container {
      .video-content {
        height: 223px;
        border-radius: 4px;
      }
    }
  }


  @media (max-width: 375px) {
    .video-screen-container {
      .video-content {
        height: 193px;
        border-radius: 4px;
      }
    }
  }


  @media (min-width: 3025px) {
    .video-screen-container {
      --max-width: 3080px;
      .video-content {
        height: 1734px;
        border-radius: 16px;
      }
    }

  }

</style>