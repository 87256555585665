<template>
  <div class="card-team-container" ref="cardTeam" :class="{'is-active' : isActive}">
    <div class="card-team-content" :class="{'not-active' : !isActive}">
      <div class="img-container">
        <img :src="getUrlTeam(cardInfo.img)" alt="team">
      </div>
      <transition name="fade-translate-y-team">
        <div class="description-info-container" v-if="activeAnimation">
            <div class="description-info-content" :class="{'is-active' : activeInfo}">

              <div class="top-info-container">
                <main-title>
                  {{ cardInfo.name }}
                </main-title>
                <p class="sub-paragraph-container">
                  {{ cardInfo.position }}
                </p>
              </div>

              <div class="bottom-info-container">
                <p class="main-paragraph-container" v-for="item in cardInfo.description" :key="item.id">
                  {{ item }}
                </p>

                <p class="main-paragraph-container sub">
                  {{ cardInfo.title }}
                </p>
              </div>
            </div>
        </div>
      </transition>

    </div>
  </div>
</template>

<script>
import MainTitle from "@/components/shared/text/Card/MainTitle.vue";

export default {
  name: "CardTeamMonile",
  components: {MainTitle},
  props: {
    cardInfo: {
      type: Object,
      required: true
    },
    isActive: {
      type: Boolean,
      required: true
    }
  },
  data() {
    return {
      blockAnimation: false,
      activeInfo: true,
      activeAnimation: true
    }
  },

  mounted() {
    this.checkActiveInfo()
  },

  watch: {
    isActive() {
      this.checkActiveInfo();
    }
  },

  methods: {
    checkActiveInfo() {
      if(this.isActive) {
        setTimeout(() => {
          this.activeAnimation = this.isActive
          setTimeout(() => {
            this.activeInfo = this.isActive
          }, 400)
        }, 550)
      } else {
        this.activeInfo = this.isActive
        setTimeout(() => {
          this.activeAnimation = this.isActive
        }, 300)
      }
    }
  }


}
</script>

<style scoped lang="scss">
.card-team-container {
  max-height: 665px;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  transition: all .6s ease;
  opacity: .6;
  &.is-active {
    opacity: 1;
  }

  .card-team-content {
    width: 100%;
    display: flex;
    justify-content: center;
    position: relative;
    height: 100%;
    z-index: 1;

    //&.not-active {
    //  .img-container {
    //    max-height: 272px;
    //    min-height: 272px;
    //    max-width: 200px;
    //  }
    //}

    .img-container {
      top: 0;
      max-height: 340px;
      min-height: 340px;
      max-width: 250px;
      border-radius: 10px;
      height: 100%;
      overflow: hidden;
      position: relative;
      width: 100%;
      z-index: 500;
      transition: all .6s ease;
      & img {
        min-height: inherit;
        max-height: inherit;
        width: 100%;
        height: 100%;
      }
    }


    .description-info-container {
      border-radius: 8px;
      width: 100%;
      background-color: var(--color-sub-dark-color);
      position: absolute;
      top: 273px;
      left: 0;
      height: fit-content;
      display: flex;
      flex-direction: column;
      align-items: center;
      z-index: 2;
      padding: 75px 16px 16px;

      .description-info-content {
        display: flex;
        flex-direction: column;
        align-items: center;
        row-gap: 16px;
        opacity: 0;
        transition: all .6s ease;

        &.is-active {
          opacity: 1;
        }

        .top-info-container {
          row-gap: 6px;
          display: flex;
          flex-direction: column;
          align-items: center;
          text-align: center;

          .main-title-container {
            font-size: 18px;
            color: #FDFDFD;
          }

          .sub-paragraph-container {
            font-size: 14px;
            color: var(--color-main-accent)
          }
        }

        .main-paragraph-container {
          font-size: 12px;
          color: #FDFDFD;
          font-weight: 300;
          line-height: 150%;

          &.sub {
            line-height: normal;
            color: #B6B6B6;
          }
        }

        .bottom-info-container {
          display: flex;
          flex-direction: column;
          row-gap: 12px;
        }
      }
    }
  }
}

@media (max-width: 769px) {

  .card-team-container {
    max-height: 600px;

    .card-team-content {

      .img-container {
        max-height: 313px;
        min-height: 313px;
        max-width: 240px;
        border-radius: 8px;
      }


      .description-info-container {
        border-radius: 8px;
        top: 232px;
        padding: 87px 16px 16px;

        .description-info-content {
          row-gap: 12px;

          .top-info-container {
            row-gap: 4px;

            .main-title-container {
              font-size: 16px;
            }

            .sub-paragraph-container {
              font-size: 12px;
            }
          }

          .main-paragraph-container {
            font-size: 10px;
          }

          .bottom-info-container {
            row-gap: 10px;
          }
        }
      }
    }
  }
}

@media (max-width: 769px)  and (min-height: 720px) and (max-height: 1000px) {
  .card-team-container {
    max-height: 665px;

    .card-team-content {

      .img-container {
        max-height: 340px;
        min-height: 340px;
        max-width: 250px;
        border-radius: 10px;
      }


      .description-info-container {
        border-radius: 8px;
        top: 273px;
        padding: 75px 16px 16px;


        .description-info-content {
          row-gap: 16px;

          .top-info-container {
            row-gap: 6px;

            .main-title-container {
              font-size: 18px;
            }

            .sub-paragraph-container {
              font-size: 14px;
            }
          }

          .main-paragraph-container {
            font-size: 12px;
          }

          .bottom-info-container {
            row-gap: 12px;
          }
        }
      }
    }
  }
}


@media (max-width: 320px) {

  .card-team-container {
    max-height: 400px;

    .card-team-content {

      //&.not-active {
      //  .img-container {
      //    max-height: 225px;
      //    min-height: 225px;
      //    max-width: 175px;
      //  }
      //}

      .img-container {
        max-height: 250px;
        min-height: 250px;
        max-width: 200px;
        border-radius: 8px;
      }


      .description-info-container {
        border-radius: 8px;
        top: 232px;
        padding: 32px 16px 16px;

        .description-info-content {
          row-gap: 8px;

          .top-info-container {
            row-gap: 4px;

            .main-title-container {
              font-size: 14px;
            }

            .sub-paragraph-container {
              font-size: 10px;
            }
          }

          .main-paragraph-container {
            font-size: 8px;
          }

          .bottom-info-container {
            row-gap: 8px;
          }
        }
      }
    }
  }
}

</style>