<template>
  <div class="team-screen-container main-container">
    <block-title :button-info="buttonInfo"
                 :main-paragraph="mainParagraph"
                 :main-title="mainTitle"/>
    <slider-team v-animateonscroll="{ enterClass: 'fade-in'}"/>

<!--    <button-main-->
<!--        v-if="activeMobile"-->
<!--        :active-dark="buttonInfo.activeDark"-->
<!--        @click="routTo(buttonInfo.rout)">-->
<!--      {{ buttonInfo.text }}-->
<!--    </button-main>-->
  </div>
</template>

<script>
import BlockTitle from "@/components/entities/BlockTitle.vue";
import CardTeam from "@/components/entities/CardTeam.vue";
import SliderTeam from "@/components/entities/SliderTeam.vue";
import ButtonMain from "@/components/shared/ButtonMain.vue";

export default {
  name: "TeamScreen",
  components: {ButtonMain, SliderTeam, CardTeam, BlockTitle},
  data() {
    return {
      buttonInfo: {
        text: 'Подробнее',
        rout: '/',
        activeDark: true
      },

      mainParagraph: 'Команда профессионалов, работающая по высоким стандартам\nОбеспечиваем превосходное качество услуг на всех этапах',
      mainTitle: 'Лидеры и команда',
    }
  }
}
</script>

<style scoped lang="scss">
.team-screen-container {

  .slider-container {
    overflow: hidden;
  }
}


@media (max-width: 3024px){
  .team-screen-container {

    .slider-container {
      max-width: 1900px;
    }
  }
}
//
//
@media (max-width: 1920px) {
  .team-screen-container {

    .slider-container {
      max-width: 1540px;
    }
  }
}
//
//
@media (max-width: 1600px) {
  .team-screen-container {

    .slider-container {
      max-width: 1180px;
    }
  }
}
@media (max-height: 800px) {
  .team-screen-container {
    --max-width: 1060px;
    .slider-container {
      max-width: 1060px;
    }
  }
}

@media (max-width: 1400px) and (min-height: 801px) {
  .team-screen-container {

    .slider-container {
      max-width: 1180px;
    }
  }
}

@media (max-width: 1133px) {
  .team-screen-container {

    --max-width: 1059px;
    .slider-container {
      max-width: 1059px;
    }
  }
}
//
@media (max-width: 769px) {
  .team-screen-container {
    --padding-left-right: 0 !important;
    --max-width: 100%;

    .block-title-container {
      padding: 0 16px;
    }

    .slider-container {
      height: 575px;
      overflow: visible;
      --max-width: 100%;
    }
  }
}

@media (max-width: 769px)  and (min-height: 720px) and (max-height: 1000px) {
  .team-screen-container {

    .slider-container {
      height: 669px;
      overflow: visible;
      --max-width: 100%;
    }
  }
}

@media (max-width: 320px) {
  .team-screen-container {
    .slider-container {
      height: 460px;
    }
  }
}

@media (min-width: 3025px) {
  .team-screen-container {

    .slider-container {
    }
  }
}

</style>