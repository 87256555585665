<template>
  <p class="main-title-container">
    <slot/>
  </p>
</template>

<script>
export default {
  name: "MainTitle"
}
</script>

<style scoped lang="scss">
.main-title-container {
  text-align: center;
  line-height: 120%;
}

@media (max-width: 3024px){
    .main-title-container {
      font-size: 72px;
    }
}


@media (max-width: 1920px) {
    .main-title-container {
      font-size: 64px;
    }
}


@media (max-width: 1600px) {
    .main-title-container {
      font-size: 48px;
    }
}

@media (max-height: 800px) {
    .main-title-container {
      font-size: 38px;
    }
}

@media (max-width: 1400px) and (min-height: 801px) {
  .main-title-container {
    font-size: 48px;
  }
}

@media (max-width: 1133px) {
    .main-title-container {
      font-size: 38px;
    }
}
@media (max-width: 769px) {
    .main-title-container {
      font-size: 18px;
    }
}

@media (max-width: 769px)  and (min-height: 740px) and (max-height: 1000px) {
    .main-title-container {
      font-size: 20px;
    }
}

@media (max-width: 320px) {
    .main-title-container {
      font-size: 14px;
    }
}

@media (min-width: 3025px) {
    .main-title-container {
      font-size: 98px;
    }
}
</style>