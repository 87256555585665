<template>
  <div class="card-project-container" :class="cardInfo.img"
       @mouseenter="focusFalse"
       @mouseleave="focusTrue">
      <div class="top-info-container" >
        <div class="img-container">
          <img :src="getUrlProject(cardInfo.img + '.svg')" alt="logo">
        </div>
      </div>
    <div class="bottom-info-container">
      <div class="title-container mobile" v-if="activeMobile" :class="{'is-active' : isActive} ">
        <transition name="fade-opacity-lazy">
          <circle-title v-if="isActive" :active-blur="true" :active-margin="true"/>
        </transition>
        <main-title>
          {{ cardInfo.mainTitle }}
        </main-title>
      </div>
      <div class="title-container" v-else>
        <circle-title :active-blur="focusParam" :active-animation="true" :active-margin="true"/>
        <main-title>
          {{ cardInfo.mainTitle }}
        </main-title>
      </div>

      <main-paragraph>
        {{ cardInfo.description }}
      </main-paragraph>
    </div>
  </div>
</template>

<script>
import MainTitle from "@/components/shared/text/Card/MainTitle.vue";
import MainParagraph from "@/components/shared/text/Card/MainParagraph.vue";
import CircleTitle from "@/components/shared/CircleTitle.vue";

export default {
  name: "CardProject",
  components: {CircleTitle, MainParagraph, MainTitle},
  props: {
    cardInfo: {
      type: Object,
      required: true
    },
    isActive: {
      type: Boolean
    }
  },
  data() {
    return {
      focusParam: true
    }
  }
}
 </script>

<style scoped lang="scss">
  .card-project-container {
    width: 100%;
    display: flex;
    flex-direction: column;
    height: 100%;

    &:hover {
      .top-info-container {
        background-color: var(--color-sub-dark-color);
      }
      .bottom-info-container {
        .main-paragraph-container {
          color: #FDFDFD;
        }
      }
    }

    .top-info-container {
      width: 100%;
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      transition: background-color .6s ease;
      border-style: solid;
      border-color: var(--color-sub-dark-color);

      .img-container {
        width: 100%;
        height: 100%;

        & img {
          width: 100%;
          height: 100%;
        }
      }
    }

    .bottom-info-container {
      display: flex;
      flex-direction: column;

      .title-container {
        display: flex;
        flex-direction: row;
      }

      .main-paragraph-container {
        font-weight: 400;
        transition: color .6s ease;
      }
    }
  }


  @media (max-width: 3024px){
    .card-project-container {
      row-gap: 24px;

      &.legal {
        max-width: 940px;

        .top-info-container {
          height: 685px;
          .img-container {
            max-width: 556px;
            max-height: 228px;
          }
        }
      }


      &.valhalla {
        margin-top: 152px;
        max-width: 620px;

        .top-info-container {
          height: 381px;
          .img-container {
            max-width: 325px;
            max-height: 70px;
          }
        }
      }

      &.advocate {
        margin-left: 160px;
        margin-top: 200px;
        max-width: 690px;

        .top-info-container {
          max-width: 620px;
          height: 620px;
          .img-container {
            max-width: 275px;
            max-height: 275px;
          }
        }
      }

      &.visioners {
        max-width: 940px;

        .top-info-container {
          height: 685px;
          .img-container {
            max-width: 620px;
            max-height: 87px;
          }
        }
      }

      .top-info-container {
        border-radius: 20px;
        border-width: 6px;
      }

      .bottom-info-container {
        row-gap: 8px;

        .title-container {
          column-gap: 12px;

          .main-title-container {
            font-size: 32px;
          }
        }

        .main-paragraph-container {
          font-size: 28px;
        }
      }
    }
  }


  @media (max-width: 1920px) {
    .card-project-container {
      row-gap: 24px;

      &.legal {
        max-width: 700px;

        .top-info-container {
          height: 510px;
          .img-container {
            max-width: 440px;
            max-height: 180px;
          }
        }
      }


      &.valhalla {
        margin-top: 100px;
        max-width: 500px;

        .top-info-container {
          height: 307px;
          .img-container {
            max-width: 310px;
            max-height: 65px;
          }
        }

      }

      &.advocate {
        margin-left: 130px;
        margin-top: 180px;
        max-width: 515px;

        .top-info-container {
          max-width: 500px;
          height: 446px;
          .img-container {
            max-width: 180px;
            max-height: 180px;
          }
        }
      }

      &.visioners {
        max-width: 630px;

        .top-info-container {
          height: 450px;
          .img-container {
            max-width: 397px;
            max-height: 56px;
          }
        }
      }

      .top-info-container {
        border-radius: 16px;
        border-width: 6px;
      }

      .bottom-info-container {
        row-gap: 8px;

        .title-container {
          column-gap: 8px;

          .main-title-container {
            font-size: 24px;
          }
        }

        .main-paragraph-container {
          font-size: 18px;
        }
      }
    }
  }


  @media (max-width: 1600px) {
    .card-project-container {
      row-gap: 24px;

      &.legal {
        max-width: 580px;

        .top-info-container {
          height: 424px;
          .img-container {
            max-width: 360px;
            max-height: 148px;
          }
        }
      }


      &.valhalla {
        margin-top: 95px;
        max-width: 380px;

        .top-info-container {
          height: 234px;
          .img-container {
            max-width: 211px;
            max-height: 45px;
          }
        }

      }

      &.advocate {
        margin-left: 100px;
        margin-top: 120px;
        max-width: 420px;

        .top-info-container {
          max-width: 380px;
          height: 380px;
          .img-container {
            max-width: 155px;
            max-height: 155px;
          }
        }
      }

      &.visioners {
        max-width: 580px;

        .top-info-container {
          height: 414px;
          .img-container {
            max-width: 368px;
            max-height: 51px;
          }
        }
      }

      .top-info-container {
        border-radius: 12px;
        border-width: 4px;
      }

      .bottom-info-container {
        row-gap: 8px;

        .title-container {
          column-gap: 8px;

          .main-title-container {
            font-size: 20px;
          }

        }

        .main-paragraph-container {
          font-size: 18px;
        }
      }
    }
  }
  @media (max-height: 800px) {
    .card-project-container {
      row-gap: 24px;

      &.legal {
        max-width: 521px;

        .top-info-container {
          height: 424px;
          .img-container {
            max-width: 313px;
            max-height: 128px;
          }
        }
      }


      &.valhalla {
        margin-top: 95px;
        max-width: 339px;

        .top-info-container {
          height: 234px;
          .img-container {
            max-width: 200px;
            max-height: 43px;
          }
        }

      }

      &.advocate {
        margin-left: 90px;
        margin-top: 120px;
        max-width: 340px;

        .top-info-container {
          height: 380px;
          .img-container {
            max-width: 145px;
            max-height: 145px;
          }
        }
      }

      &.visioners {
        max-width: 520px;

        .top-info-container {
          height: 414px;
          .img-container {
            max-width: 355px;
            max-height: 50px;
          }
        }
      }

      .top-info-container {
        border-radius: 8px;
        border-width: 3px;
      }

      .bottom-info-container {
        row-gap: 8px;

        .title-container {
          column-gap: 6px;

          .main-title-container {
            font-size: 18px;
          }
        }

        .main-paragraph-container {
          font-size: 16px;
        }
      }
    }
  }

  @media (max-width: 1400px) and (min-height: 801px) {
    .card-project-container {
      row-gap: 24px;

      &.legal {
        max-width: 580px;

        .top-info-container {
          height: 424px;
          .img-container {
            max-width: 360px;
            max-height: 148px;
          }
        }
      }


      &.valhalla {
        margin-top: 95px;
        max-width: 380px;

        .top-info-container {
          height: 234px;
          .img-container {
            max-width: 211px;
            max-height: 45px;
          }
        }

      }

      &.advocate {
        margin-left: 100px;
        margin-top: 120px;
        max-width: 420px;

        .top-info-container {
          max-width: 380px;
          height: 380px;
          .img-container {
            max-width: 155px;
            max-height: 155px;
          }
        }
      }

      &.visioners {
        max-width: 580px;

        .top-info-container {
          height: 414px;
          .img-container {
            max-width: 368px;
            max-height: 51px;
          }
        }
      }

      .top-info-container {
        border-radius: 12px;
        border-width: 4px;
      }

      .bottom-info-container {
        row-gap: 8px;

        .title-container {
          column-gap: 8px;

          .main-title-container {
            font-size: 20px;
          }

        }

        .main-paragraph-container {
          font-size: 18px;
        }
      }
    }
  }

  @media (max-width: 1133px) {
    .card-project-container {
      row-gap: 24px;

      &.legal {
        max-width: 521px;

        .top-info-container {
          height: 424px;
          .img-container {
            max-width: 313px;
            max-height: 128px;
          }
        }
      }


      &.valhalla {
        margin-top: 95px;
        max-width: 339px;

        .top-info-container {
          height: 234px;
          .img-container {
            max-width: 200px;
            max-height: 43px;
          }
        }

      }

      &.advocate {
        margin-left: 90px;
        margin-top: 120px;
        max-width: 340px;

        .top-info-container {
          height: 380px;
          .img-container {
            max-width: 145px;
            max-height: 145px;
          }
        }
      }

      &.visioners {
        max-width: 520px;

        .top-info-container {
          height: 414px;
          .img-container {
            max-width: 355px;
            max-height: 50px;
          }
        }
      }

      .top-info-container {
        border-radius: 8px;
        border-width: 3px;
      }

      .bottom-info-container {
        row-gap: 8px;

        .title-container {
          column-gap: 6px;

          .main-title-container {
            font-size: 18px;
          }
        }

        .main-paragraph-container {
          font-size: 16px;
        }
      }
    }
  }

  @media (max-width: 769px) {
    .card-project-container {
      row-gap: 20px;
      max-width: 100%;

      .bottom-info-container {
        .title-container {
          &.mobile {
            padding-left: 0;
            transition: padding-left .6s ease;

            .circle-container {
              position: absolute;
              left: 0;
            }

            &.is-active {
              padding-left: 12px;
            }
          }
        }
      }

      &.legal {
        max-width: inherit;

        .top-info-container {
          height: 216px;
          .img-container {
            max-width: 186px;
            max-height: 76px;
          }
        }
      }


      &.valhalla {
        margin-top: 0;
        max-width: inherit;

        .top-info-container {
          height: 216px;
          .img-container {
            max-width: 200px;
            max-height: 43px;
          }
        }

      }

      &.advocate {
        margin-left: 0;
        margin-top: 0;
        max-width: inherit;

        .top-info-container {
          height: 216px;
          .img-container {
            max-width: 118px;
            max-height: 118px;
          }
        }
      }

      &.visioners {
        max-width: inherit;

        .top-info-container {
          height: 216px;
          .img-container {
            max-width: 214px;
            max-height: 30px;
          }
        }
      }

      &:hover {
        .top-info-container {
          background-color: rgba(0,0,0,0);
        }
      }

      .top-info-container {
        border-radius: 10px;
        border-width: 4px;
      }

      .bottom-info-container {
        row-gap: 6px;

        .title-container {
          column-gap: 6px;

          .main-title-container {
            font-size: 14px;
          }
        }

        .main-paragraph-container {
          font-size: 12px;
          margin-left: 0;
          color: #FDFDFD;
        }
      }
    }
  }

  @media (max-width: 769px)  and (min-height: 720px) and (max-height: 1000px) {
    .card-project-container {
      row-gap: 24px;
      max-width: 100%;

      &.legal {
        .top-info-container {
          height: 250px;
          .img-container {
            max-width: 186px;
            max-height: 76px;
          }
        }
      }


      &.valhalla {
        .top-info-container {
          height: 250px;
          .img-container {
            max-width: 200px;
            max-height: 43px;
          }
        }

      }

      &.advocate {

        .top-info-container {
          height: 250px;
          .img-container {
            max-width: 118px;
            max-height: 118px;
          }
        }
      }

      &.visioners {
        .top-info-container {
          height: 250px;
          .img-container {
            max-width: 214px;
            max-height: 30px;
          }
        }
      }

      .top-info-container {
        border-radius: 10px;
        border-width: 3px;
      }

      .bottom-info-container {
        row-gap: 6px;

        .title-container {
          column-gap: 6px;

          .main-title-container {
            font-size: 16px;
          }
        }

        .main-paragraph-container {
          font-size: 12px;
          margin-left: 0;
        }
      }
    }
  }

  @media (min-height: 600px) and (max-height: 630px){

    //8 plus
  }

  @media (max-width: 375px) {
    //se new
  }

  @media (max-width: 375px) and (min-height: 630px) {
    //x
  }


  @media (max-width: 320px) {
    .card-project-container {
      .top-info-container {
        border-width: 2px;
      }
      &.legal {
        .top-info-container {
          height: 250px;
          .img-container {
            max-width: 167px;
            max-height: 67px;
          }
        }
      }


      &.valhalla {
        .top-info-container {
          height: 250px;
          .img-container {
            max-width: 179px;
            max-height: 39px;
          }
        }

      }

      &.advocate {

        .top-info-container {
          height: 250px;
          .img-container {
            max-width: 100px;
            max-height: 100px;
          }
        }
      }

      &.visioners {
        .top-info-container {
          height: 250px;
          .img-container {
            max-width: 192px;
            max-height: 27px;
          }
        }
      }

      .bottom-info-container {
        .title-container {
          .main-title-container {
            font-size: 16px;
          }
        }

        .main-paragraph-container {
          font-size: 10px;
        }
      }
    }
  }

  @media (min-width: 3025px) {
    .card-project-container {
      row-gap: 24px;

      &.legal {
        max-width: 1360px;

        .top-info-container {
          height: 870px;
          .img-container {
            max-width: 625px;
            max-height: 256px;
          }
        }
      }


      &.valhalla {
        margin-top: 155px;
        max-width: 1080px;

        .top-info-container {
          height: 559px;
          .img-container {
            max-width: 545px;
            max-height: 115px;
          }
        }

      }

      &.advocate {
        margin-left: 560px;
        margin-top: 240px;
        max-width: 1080px;

        .top-info-container {
          height: 660px;
          .img-container {
            max-width: 345px;
            max-height: 345px;
          }
        }
      }

      &.visioners {
        max-width: 1360px;

        .top-info-container {
          height: 782px;
          .img-container {
            max-width: 798px;
            max-height: 112px;
          }
        }
      }

      .top-info-container {
        border-radius: 32px;
        border-width: 8px;
      }

      .bottom-info-container {
        row-gap: 12px;

        .title-container {
          column-gap: 16px;

          .main-title-container {
            font-size: 46px;
          }

        }

        .main-paragraph-container {
          font-size: 42px;
        }
      }
    }
  }

</style>