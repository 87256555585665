<template>
  <div class="visioners-screen-container">
    <div class="visioners-screen-content main-container">
      <div class="left-info-container" v-animateonscroll="{ enterClass: 'fade-in'}">
        <div class="top-info-container">
          <text-with-logo-big :text-info="sliceEl"/>

          <div class="text-info-container">
          <main-paragraph v-for="(item, index) in mainParagraph" :key="index">
            {{ item }}
          </main-paragraph>
          </div>
        </div>


        <button-main @click="routToOut">
          {{ textButton }}
        </button-main>
      </div>

      <div class="right-info-container" v-animateonscroll="{ enterClass: 'fade-in'}" @animationend="changeStateLottie">
<!--        <img :src="getUrlLottie(lottieImg)" alt="visioners">-->
        <LottieAnimation :animationData="require('@/assets/animation-lottie/LogoMain.json')"
                         v-if="activeAnimation"
                         :speed=".5"
                         style="height: inherit; width: inherit; opacity: .1"
                         :flex-growth="'None'"
                         :loop="false"
                         :rendererSettings="{ preserveAspectRatio: 'xMidYMid meet' }" />
        <LottieAnimation :animationData="require('@/assets/animation-lottie/LogoAdd.json')"
                         v-if="activeAnimation"
                         :speed=".5"
                         style="height: inherit; width: inherit; opacity: 1"
                         :flex-growth="'None'"
                         class="logo-add-container"
                         :loop="true"
                         :rendererSettings="{ preserveAspectRatio: 'xMidYMid meet' }" />
      </div>
    </div>
  </div>
</template>

<script>
import MainParagraph from "@/components/shared/text/MainParagraph.vue";
import MainTitle from "@/components/shared/text/MainTitle.vue";
import ButtonMain from "@/components/shared/ButtonMain.vue";
import TextWithLogoBig from "@/components/shared/textLogo/TextWithLogoBig.vue";

export default {
  name: "VisionersScreen",
  components: {TextWithLogoBig, ButtonMain, MainTitle, MainParagraph},
  data() {
    return {
      activeAnimation: false,
      lottieImg: 'visioners.svg',
      mainTitle: 'Международная группа компаний visioners',
      mainParagraph: ['Группа компаний специализирующаяся на разработке высокосложного программного обеспечения, цифровых проектов, инженерных решений и консалтинга в области управления цифровой трансформацией.',
        'В структуру Группы входят три дивизиона, а также 5 суббрендов, включая суббренд .look, представляющий неофициальную сторону бренда.'],
      textButton: 'Подробнее'
    }
  },
  computed: {
    sliceEl() {
      return this.mainTitle.split(' ');
    }
  },
  methods: {
    routToOut() {
      window.open('https://visioners.tech/', '_blank');
    }
  }
}
</script>

<style scoped lang="scss">
.visioners-screen-container {
  width: 100%;
  background-color: var(--color-sub-dark-color);

  .visioners-screen-content{
    max-width: var(--calc-width);
    display: flex;
    flex-direction: row;
    width: 100%;
    justify-content: space-between;
    position: relative;
    overflow: hidden;

    .left-info-container {
      display: flex;
      flex-direction: column;

      .top-info-container {
        display: flex;
        flex-direction: column;

        .text-info-container {
          display: flex;
          flex-direction: column;
        }
      }
    }

    .right-info-container {
      display: flex;
      align-items: center;
      justify-content: center;
      position: absolute;
      width: 100%;
      height: 100%;
      .logo-add-container {
        position: absolute;
      }

      & img {
        width: 100%;
        height: 100%;
        object-fit: contain;
      }
    }
  }
}



@media (max-width: 3024px) and (min-width: 2241px){
  .visioners-screen-container {
    .visioners-screen-content{
      padding-left: 330px;

      .left-info-container {
        max-width: 954px;
        row-gap: 56px;

        .top-info-container {
          row-gap: 32px;

          .text-info-container {
            max-width: 740px;
            row-gap: 24px;

            .main-paragraph-container {
              font-size: 28px;
            }
          }
        }
      }

      .right-info-container {
        top: 141px;
        right: 0;
        max-width: 1174px;
        max-height: 644px;
      }
    }
  }
}

@media (max-width: 2240px) and (min-width: 1921px){
  .visioners-screen-container {
    .visioners-screen-content{
      padding-left: 170px;

      .left-info-container {
        padding: 160px 0;
        max-width: 954px;
        row-gap: 56px;

        .top-info-container {
          row-gap: 32px;

          .text-info-container {
            max-width: 740px;
            row-gap: 24px;

            .main-paragraph-container {
              font-size: 28px;
            }
          }
        }
      }

      .right-info-container {
        top: 141px;
        right: 0;
        max-width: 1174px;
        max-height: 644px;
      }
    }
  }
}



@media (max-width: 1920px) {
  .visioners-screen-container {
    .visioners-screen-content{
      padding-left: 190px;

      .left-info-container {
        max-width: 760px;
        row-gap: 64px;

        .top-info-container {
          row-gap: 32px;

          .text-info-container {
            max-width: 528px;
            row-gap: 20px;
          }
        }
      }

      .right-info-container {
        top: 120px;
        right: 42px;
        max-width: 880px;
        max-height: 482px;
      }
    }
  }
}


@media (max-width: 1600px) {
  .visioners-screen-container {
    .visioners-screen-content{
      padding-left: 130px;

      .left-info-container {
        max-width: 565px;
        row-gap: 40px;

        .top-info-container {
          row-gap: 32px;

          .text-info-container {
            max-width: 476px;
            row-gap: 20px;
          }
        }
      }

      .right-info-container {
        top: 109px;
        right: 0;
        max-width: 687px;
        max-height: 377px;
      }
    }
  }
}
@media (max-height: 800px) {
  .visioners-screen-container {
    .visioners-screen-content{
      padding-left: 110px;

      .left-info-container {
        max-width: 564px;
        row-gap: 32px;

        .top-info-container {
          row-gap: 32px;

          .text-info-container {
            max-width: 476px;
            row-gap: 24px;
            .main-paragraph-container {
              font-size: 18px;
            }
          }
        }
      }

      .right-info-container {
        top: 127px;
        right: 24px;
        max-width: 483px;
        max-height: 265px;
      }
    }
  }
}

@media (max-width: 1400px) and (min-height: 801px) {
  .visioners-screen-container {
    .visioners-screen-content{
      padding-left: 93px;

      .left-info-container {
        max-width: 565px;
        row-gap: 40px;

        .top-info-container {
          row-gap: 32px;

          .text-info-container {
            max-width: 476px;
            row-gap: 20px;
          }
        }
      }

      .right-info-container {
        top: 109px;
        right: 11px;
        max-width: 687px;
        max-height: 377px;
      }
    }
  }
}

@media (max-width: 1133px) {
  .visioners-screen-container {
    .visioners-screen-content{
      padding-left: 37px;

      .left-info-container {
        max-width: 564px;
        row-gap: 32px;

        .top-info-container {
          row-gap: 32px;
          .text-info-container {
            max-width: 476px;
            row-gap: 24px;
            .main-paragraph-container {
              font-size: 18px;
            }
          }
        }
      }

      .right-info-container {
        top: 132px;
        right: 0;
        max-width: 483px;
        max-height: 265px;
      }
    }
  }
}

@media (max-width: 769px) {
  .visioners-screen-container {
    .visioners-screen-content{
      padding: 0 20px;

      .left-info-container {
        padding: 32px 0;
        max-width: 284px;
        row-gap: 32px;

        .top-info-container {
          row-gap: 32px;

          .text-info-container {
            row-gap: 16px;

            .main-paragraph-container {
              font-size: 12px;
            }
          }
        }
      }

      .right-info-container {
        bottom: -5px;
        right: -140px;
        top: auto;
        max-width: 430px;
        max-height: 265px;
      }
    }
  }
}

@media (max-width: 769px)  and (min-height: 720px) and (max-height: 1000px) {
  .visioners-screen-container {
    .visioners-screen-content{
      padding: 0 24px;

      .left-info-container {
        padding: 48px 0;
      }
    }
  }
}

@media (min-height: 600px) and (max-height: 630px){

  //8 plus
}

@media (max-width: 375px) {
  //se new
}

@media (max-width: 375px) and (min-height: 630px) {
  //x
}


@media (max-width: 320px) {
  //se old
}

@media (min-width: 3025px) {
  .visioners-screen-container {
    .visioners-screen-content{
      padding-left: 580px;

      .left-info-container {
        max-width: 1239px;
        row-gap: 64px;

        .top-info-container {
          row-gap: 64px;

          .text-info-container {
            max-width: 1110px;
            row-gap: 24px;
          }
        }
      }

      .right-info-container {
        top: 203px;
        right: 423px;
        max-width: 1506px;
        max-height: 826px;
      }
    }
  }
}

</style>