<template>
  <div class="circle-container" :class="{'active-blur' : activeBlur, 'active-margin' : activeMargin, 'active-animation' : activeAnimation}"></div>
</template>

<script>
export default {
  name: "CircleTitle",
  props: {
    activeBlur: {
      default: false
    },
    activeMargin: {
      type: Boolean,
      default: false
    },
    activeAnimation: {
      type: Boolean,
      default: false
    }
  }
}
</script>

<style scoped lang="scss">
.circle-container {
  min-width: var(--width);
  max-width: var(--width);
  background-color: var(--color-main-accent);
  border-radius: 50%;

  &.active-animation {
    transition: filter .6s ease;
  }

  &.active-blur {
    filter: blur(1.6524293422698975px);
  }
}
@media (max-width: 3840px) {
        .circle-container {
          --width: 16px;
          height: 16px;

          &.active-margin {
            margin-top: 18px;
          }
        }
}


@media (max-width: 3024px){

        .circle-container {
          --width: 12px;
          height: 12px;

          &.active-margin {
            margin-top: 10px;
          }
        }
}


@media (max-width: 1920px) {
        .circle-container {
          --width: 10px;
          height: 10px;

          &.active-margin {
            margin-top: 8px;
          }
        }
}


@media (max-width: 1600px) {
        .circle-container {
          --width: 8px;
          height: 8px;
          &.active-margin {
            margin-top: 7px;
          }
        }
}
@media (max-height: 800px) {
        .circle-container {
          --width: 6px;
          height: 6px;
          &.active-margin {
            margin-top: 8px;
          }
        }
}

@media (max-width: 1400px) and (min-height: 801px) {
        .circle-container {
          --width: 8px;
          height: 8px;
          &.active-margin {
            margin-top: 7px;
          }
        }
}

@media (max-width: 1133px) {
        .circle-container {
          --width: 6px;
          height: 6px;
          &.active-margin {
            margin-top: 7px;
          }
        }
}

@media (max-width: 769px) {
        .circle-container {
          --width: 6px;
          height: 6px;
          &.active-margin {
            margin-top: 3.5px;
          }
        }
}

@media (min-height: 600px) and (max-height: 630px){
  .circle-container {
    --width: 6px;
    height: 6px;
    &.active-margin {
      margin-top: 5px;
    }
  }
}

@media (max-width: 375px) {
  //se new
}

@media (max-width: 375px) and (min-height: 630px) {
  //x
}


@media (max-width: 320px) {
  //se old
}

@media (min-width: 3841px) {
        .circle-container {
          --width: 16px;
          height: 16px;

          &.active-margin {
            margin-top: 18px;
          }
        }
}
</style>