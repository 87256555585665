<template>
  <div class="text-container">
    <main-title v-for="(item, index) in textInfo"
                :key="index">
      <span v-if="item !== 'visioners' && item !== 'look'"> {{ item }} </span>
      <div v-else class="img-container" :class="item">
        <img :src="item + '.svg'" alt="logo">
      </div>
    </main-title>
  </div>
</template>

<script>
import MainTitle from "@/components/shared/text/MainTitle.vue";

export default {
  name: "TextWithLogoBig",
  components: {MainTitle},
  props: {
    textInfo: {
      type: Array,
      required: true
    }
  }
}
</script>

<style scoped lang="scss">

.text-container {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: baseline;

  .main-title-container {
    line-height: 120%;
  }

  .img-container {
    display: flex;
    align-items: center;
    justify-content: center;

    & img {
      width: 100%;
      height: 100%;
    }
  }
}



@media (max-width: 3024px){
  .text-container {
    column-gap: 16px;

    .img-container {
      &.look {
        width: 145px;
        height: 49px;
      }

      &.visioners {
        width: 260px;
        height: 43px;
      }
    }
  }
}


@media (max-width: 1920px) {
  .text-container {
    column-gap: 16px;

    .img-container {
      &.look {
        width: 137px;
        height: 47px;
      }

      &.visioners {
        width: 261px;
        height: 44px;
      }
    }
  }
}


@media (max-width: 1600px) {
  .text-container {
    column-gap: 12px;

    .img-container {
      &.look {
        width: 100px;
        height: 34px;
      }

      &.visioners {
        width: 192px;
        height: 32px;
      }
    }
  }
}
@media (max-height: 800px) {
  .text-container {
    column-gap: 8px;

    .main-title-container {
      font-size: 38px !important;
    }

    .img-container {
      &.look {
        width: 89px;
        height: 30px;
      }

      &.visioners {
        width: 168px;
        height: 28px;
      }
    }
  }
}

@media (max-width: 1400px) and (min-height: 801px) {
  .text-container {
    column-gap: 12px;

    .img-container {
      &.look {
        width: 100px;
        height: 34px;
      }

      &.visioners {
        width: 192px;
        height: 32px;
      }
    }
  }
}

@media (max-width: 1133px) {
  .text-container {
    column-gap: 8px;

    .main-title-container {
      font-size: 38px !important;
    }

    .img-container {
      &.look {
        width: 89px;
        height: 30px;
      }

      &.visioners {
        width: 168px;
        height: 28px;
      }
    }
  }
}

@media (max-width: 769px) {
  .text-container {
    column-gap: 6px;

    .main-title-container {
      font-size: 24px !important;
    }

    .img-container {
      &.look {
        width: 55px;
        height: 17px;
      }

      &.visioners {
        width: 94px;
        height: 16px;
      }
    }
  }
}

@media (max-width: 375px) and (min-height: 630px) {
  //x
}


@media (max-width: 320px) {
  //se old
}

@media (min-width: 3025px) {
  .text-container {
    column-gap: 36px;

    .img-container {
      &.look {
        width: 243px;
        height: 82px;
      }

      &.visioners {
        width: 440px;
        height: 74px;
      }
    }
  }
}


</style>