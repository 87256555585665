<template>
  <div class="service-screen-container">
    <div class="service-screen-content main-container">
      <block-title :button-info="buttonInfo"
                   :main-paragraph="mainParagraph"
                   :main-title="mainTitle"/>

      <div class="service-screen-content-text" v-if="!activeMobile">
        <switch-title :info-switch="serviceInfo"
                      v-animateonscroll="{ enterClass: 'fade-in'}"
                      :active-index="activeIndex"
                      @changeActive="changeActive"/>

        <transition name="fade-translate-x" mode="out-in">
        <div class="text-info-container" :key="activeInfo" v-animateonscroll="{ enterClass: 'fade-in' }" @animationend="changeStateLottie">
            <main-paragraph >
              {{ activeInfo.description }}
            </main-paragraph>
          <div class="list-container">
              <main-paragraph v-for="item in activeInfo.info"
                              :active-big="true"
                              :key="item.id">
                — {{ item.text }}
              </main-paragraph>
          </div>
          <button-main @click="openModal">
          {{ activeInfo.buttonText }}
        </button-main>
        </div>
        </transition>
      </div>


      <slider-service v-else :info-service="serviceInfo" v-animateonscroll="{ enterClass: 'fade-in' }" @animationend="changeStateLottie"/>



<!--      <slider-scroll :active-info-length="serviceInfo.length"-->
<!--                     :virtual-status="false"-->
<!--                     :slides-per-view="slideCount"-->
<!--                     :active-scroll-bar="true">-->
<!--        <swiper-slide v-for="item in serviceInfo">-->
<!--            <card-services :card-info="item"/>-->
<!--        </swiper-slide>-->
<!--      </slider-scroll>-->

<!--      <button-main-->
<!--          v-if="activeMobile"-->
<!--          :active-dark="buttonInfo.activeDark"-->
<!--          @click="routTo(buttonInfo.rout)">-->
<!--        {{ buttonInfo.text }}-->
<!--      </button-main>-->
    </div>

    <div class="background-img-container img">
      <LottieAnimation :animationData="require('@/assets/animation-lottie/HeartMain.json')"
                       v-if="activeAnimation"
                       :speed=".5"
                       style="height: inherit; width: inherit; opacity: .1"
                       :flex-growth="'None'"
                       :loop="false"
                       :delay="250"
                       :rendererSettings="{ preserveAspectRatio: 'xMidYMid meet' }" />
      <LottieAnimation :animationData="require('@/assets/animation-lottie/HeartAdd.json')"
                       v-if="activeAnimation"
                       :speed=".5"
                       style="height: inherit; width: inherit; opacity: 1"
                       :flex-growth="'None'"
                       class="heart-add-container"
                       :loop="true"
                       :delay="250"
                       :rendererSettings="{ preserveAspectRatio: 'xMidYMid meet' }" />
    </div>
  </div>
</template>

<script>
import BlockTitle from "@/components/entities/BlockTitle.vue";
import SliderScroll from "@/components/entities/SliderScroll.vue";
import CardServices from "@/components/entities/CardServices.vue";
import ButtonMain from "@/components/shared/ButtonMain.vue";
import SwitchTitle from "@/components/entities/SwitchTitle.vue";
import MainParagraph from "@/components/shared/text/MainParagraph.vue";
import SliderService from "@/components/entities/SliderService.vue";
import {mapActions} from "vuex";

export default {
  name: "ServiceScreen",
  components: {SliderService, MainParagraph, SwitchTitle, ButtonMain, CardServices, SliderScroll, BlockTitle},
  data() {
    return {
      buttonText: 'Создать уникальный бренд',
      activeAnimation: false,
      buttonInfo: {
        text: 'Все услуги',
        rout: '/',
        activeDark: false
      },
      lottieImg: 'heart.svg',
      mainParagraph: 'Комплексы услуг, решающие задачи в формате 360°\nОтвечаем на амбициозные задачи профессионально',
      mainTitle: 'Услуги',
      slideCount: 3,
      activeIndex: 0,
    }
  },
  // mounted() {
  //   this.checkSize();
  //   addEventListener('resize', this.checkSize)
  // },
  computed: {
    serviceInfo() {
      return this.$store.state.serviceInfo;
    },
    activeInfo() {
      return this.serviceInfo.find(item => item.id === this.activeIndex)
    }
  },
  methods: {
    ...mapActions(['ACTIVE_MODAL_CONTACT']),
    checkSize() {
      if(window.innerWidth <= 1600 && window > 769) {
        this.slideCount = 2.4
      } else if (window.innerWidth <= 769) {
        this.slideCount = 1.2
      } else {
        this.slideCount = 3
      }
    },
    openModal(){
      this.ACTIVE_MODAL_CONTACT(true)
    },
    changeActive(id) {
      this.activeIndex = id;
    }
  }
}
</script>

<style scoped lang="scss">
.service-screen-container {
  background-color: var(--color-sub-dark-color);
  position: relative;
  .service-screen-content {
    position: relative;
    z-index: 1;
  }

  .service-screen-content-text {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 100%;

    .text-info-container {
      height: fit-content;
      position: relative;
      background-color: rgba(22,23,27, .2);
      display: flex;
      flex-direction: column;

      .main-paragraph-container {
        font-weight: 300;
        color: #FDFDFD;
      }

      .list-container {
        display: flex;
        flex-direction: column;
      }
    }
  }

  .background-img-container {
    position: absolute;
    z-index: 0;
    top: 0;
    right: 34%;

    .heart-add-container {
      z-index: 1;
      top: 0;
      position: absolute;
    }

    & img {
      object-fit: contain;
    }
  }
}

@media (min-width: 3840px) and (min-width: 3025px){
  .service-screen-container {
    height: 1246px;
    .background-img-container {
      right: 33% !important;
    }
  }
}


@media (max-width: 3024px)  and (min-width: 1921px){
  .service-screen-container {
    height: 1246px;
    .service-screen-content {
      row-gap: 100px;
      .slider-scroll-container {
        height: 544px;
      }
    }

    .service-screen-content-text {
      .text-info-container {
        row-gap: 48px;
        max-width: 940px;
        padding: 48px;
        border-radius: 20px;

        .list-container {
          row-gap: 16px;
        }
      }
    }

    .background-img-container {
      top: 190px;
      max-width: 1245px;
      max-height: 781px;
    }
  }
}

@media (max-width: 2241px)  and (min-width: 1921px){
  .service-screen-container {
    .background-img-container {
      right: 30% !important;
    }
  }
}


@media (max-width: 1920px) and (min-width: 1601px){
  .service-screen-container {
    height: 936px;
    .service-screen-content {
      row-gap: 80px;
      .slider-scroll-container {
        height: 496px;
      }
    }

    .service-screen-content-text {
      .text-info-container {
        row-gap: 40px;
        max-width: 760px;
        padding: 40px;
        border-radius: 16px;

        .list-container {
          row-gap: 12px;
        }
      }
    }

    .background-img-container {
      top: 139px;
      right: 34%;
      max-width: 1018px;
      max-height: 639px;
    }
  }
}


@media (max-width: 1600px) {
  .service-screen-container {
    .service-screen-content {
      .slider-scroll-container {
        height: 362px;
      }
    }

    .service-screen-content-text {
      .text-info-container {
        row-gap: 26px;
        max-width: 678px;
        padding: 26px;
        border-radius: 12px;

        .main-paragraph-container {
          font-size: 18px;
        }


        .list-container {
          row-gap: 8px;
        }
      }
    }

    .background-img-container {
      right: 33%;
      top: 131px;
      max-width: 775px;
      max-height: 487px;
    }
  }
}
@media (max-height: 800px) {
  .service-screen-container {
    .service-screen-content {
      .slider-scroll-container {
        height: 334px;
      }
    }

    .service-screen-content-text {
      .text-info-container {
        row-gap: 20px;
        max-width: 610px;
        padding: 20px;
        border-radius: 8px;

        .main-paragraph-container {
          font-size: 16px;
        }


        .list-container {
          row-gap: 8px;
        }
      }
    }

    .background-img-container {
      top: 111px;
      right: 35%;
      max-width: 645px;
      max-height: 405px;
    }
  }
}

@media (max-width: 1400px) and (min-height: 801px) {
  .service-screen-container {
    .service-screen-content {
      .slider-scroll-container {
        height: 362px;
      }
    }

    .service-screen-content-text {
      .text-info-container {
        row-gap: 26px;
        max-width: 678px;
        padding: 26px;
        border-radius: 12px;

        .main-paragraph-container {
          font-size: 18px;
        }


        .list-container {
          row-gap: 8px;
        }
      }
    }

    .background-img-container {
      right: 33%;
      top: 131px;
      max-width: 775px;
      max-height: 487px;
    }
  }
}

@media (max-width: 1133px) {
  .service-screen-container {
    .service-screen-content {
      .slider-scroll-container {
        height: 334px;
      }
    }

    .service-screen-content-text {
      .text-info-container {
        row-gap: 20px;
        max-width: 610px;
        padding: 20px;
        border-radius: 8px;

        .main-paragraph-container {
          font-size: 16px;
        }

        .list-container {
          row-gap: 8px;
        }
      }
    }

    .background-img-container {
      top: 111px;
      right: 33%;
      max-width: 645px;
      max-height: 405px;
    }
  }
}

@media (max-width: 769px) {
  .service-screen-container {
    .service-screen-content {
      row-gap: 32px;
      .slider-scroll-container {
        height: 239px;
      }

    }

    .background-img-container {
      top: 68px;
      max-width: 339px;
      max-height: 334px;
      right: -15%;
    }
  }
}

//@media (max-width: 769px)  and (min-height: 720px) and (max-height: 1000px) {
//  .service-screen-container {
//    .service-screen-content {
//      row-gap: 32px;
//      padding: 32px 0;
//
//      .main-title-container
//
//      .slider-scroll-container {
//        height: 250px;
//      }
//    }
//
//    .background-img-container {
//      top: 32px;
//      max-width: 436px;
//      max-height: 438px;
//      right: -50px;
//    }
//  }
//}

@media (min-height: 600px) and (max-height: 630px){

  //8 plus
}

@media (max-width: 375px) {
  //se new
}

@media (max-width: 375px) and (min-height: 630px) {
  //x
}


@media (max-width: 320px) {
  //se old
}

@media (min-width: 3025px) {
  .service-screen-container {
    height: 1745px;
    .service-screen-content {
      row-gap: 160px;
      .slider-scroll-container {
        height: 774px;
      }
    }

    .service-screen-content-text {
      .text-info-container {
        row-gap: 80px;
        max-width: 1640px;
        padding: 80px;
        border-radius: 32px;

        .list-container {
          row-gap: 16px;
        }
      }
    }

    .background-img-container {
      max-width: 2238px;
      max-height: 1406px;
      top: 156px;
    }
  }
}

</style>