
export default {
    methods: {
        focusTrue() {
            this.focusParam = true;
        },
        focusFalse() {
            this.focusParam = false;
        },

        getUrlImg(picture) {
            return require('../assets/' + picture)
        },

        getUrlIcons(picture) {
            return require('../assets/icons/' + picture)
        },

        getUrlTeam(picture) {
            return require('../assets/team/' + picture)
        },

        getUrlProject(picture) {
            return require('../assets/project/' + picture)
        },

        getUrlPartners(picture) {
            return require('../assets/partners/' + picture)
        },

        getUrlLottie(picture) {
            return require('../assets/animation-lottie/' + picture)
        },

        getUrlLogo(picture) {
            return require('../assets/logo/' + picture)
        },

        getUrlBrand(picture) {
            return require('../assets/brand/' + picture)
        },
        getUrlStickers(picture) {
            return require('../assets/stickers/' + picture)
        },


        routToHomeWithScroll() {
                if(window.scrollY !== 0) {
                    this.$emit('scrollToOpacity')
                    window.scrollTo({
                        top: 0,
                        behavior: "smooth",
                    });
                    setTimeout(() => {
                        this.$router.push('/')
                    }, 500)
                } else {
                    this.$emit('scrollToOpacity')
                    this.$router.push('/')
                }

        },

        routTo(rout) {
            this.$router.push(rout)
        },
        scrollToTop() {
            if(this.$route.name === 'home') {
                window.scrollTo({
                    top: 0,
                    behavior: "smooth",
                });
            } else {
                this.routToHomeWithScroll();
            }
        },

        changeStateLottie() {
            if (!this.activeAnimation) {
                this.activeAnimation = true
            }
        }
    },


    computed: {
        activeMobile() {
            return this.$store.state.activeMobile;
        },
        activeTouch() {
            return this.$store.state.activeTouchAllow;
        },
    }
}