<template>
  <div>
    <main-header :active-fixed="true"
                 @changeStateMenu="changeStateMenu"
                 :active="$route.name !== 'home' || activeFixed"
                 :class="{'active' : $route.name !== 'home' || ( activeMobile || activeFixed && innerWidth >= 1300)}"/>

    <transition name="fade-opacity-lazy">
      <modal-consulting v-if="activeModalConsulting"
                        @closeModal="changeStateModal"/>
    </transition>
    <transition name="fade-opacity-lazy">
      <modal-video v-if="activeModalVideo"
                        @closeModal="changeStateModalVideo"/>
    </transition>
    <transition name="fade-opacity-lazy">
      <modal-result v-if="activeModalResult"
                    :active-text="'Наш клиент-менеджер свяжется с Вами и уточнит детали'"
                    :active-result="true"
                    :active-title="'Запрос успешно отправлен'"
                    @closeModal="changeStateModalResult"/>
    </transition>

    <transition name="fade-opacity-lazy">
      <modal-consulting v-if="activeModalContact"
                        :active-contact="true"
                        @closeModal="changeStateModalContact"/>
    </transition>

    <router-view v-slot="{ Component }">
      <transition :name="activeTransition" mode="out-in">
        <component :is="Component" />
      </transition>
    </router-view>

    <main-footer v-if="!activeMobile && $route.name === 'home'"/>
    <main-footer-mobile v-else-if="activeMobile && $route.name === 'home'"/>
  </div>

</template>

<script>

import MainFooter from "@/components/widgets/MainFooter.vue";
import {mapActions} from "vuex";
import MainFooterMobile from "@/components/widgets/MainFooterMobile.vue";
import MainHeader from "@/components/widgets/MainHeader.vue";
import ModalConsulting from "@/components/entities/ModalConsulting.vue";
import ModalResult from "@/components/entities/ModalResult.vue";
import ModalVideo from "@/components/entities/ModalVideo.vue";

export default {
  name: "MainLayout",
  components: {ModalVideo, ModalResult, ModalConsulting, MainHeader, MainFooterMobile, MainFooter},
  mounted() {
    this.checkSize();
    this.checkHeaderFixed();
    addEventListener('scroll', this.checkHeaderFixed)
    addEventListener('resize', this.checkSize)
  },
  unmounted() {
    removeEventListener('scroll', this.checkHeaderFixed)
    removeEventListener('resize', this.checkSize)
  },
  data() {
    return {
      activeMenu: false,
      activeFixed: false,
      innerWidth: 0
    }
  },

  methods: {
    ...mapActions(['ACTIVE_MOBILE', 'ACTIVE_TOUCH_ALLOW', 'ACTIVE_MODAL_CONSULTING', 'ACTIVE_MODAL_CONTACT', 'ACTIVE_MODAL_RESULT', 'ACTIVE_MODAL_VIDEO']),
    checkSize() {
      this.innerHeight = window.innerHeight;
      document.documentElement.style.setProperty('--vh', `${this.innerHeight * 0.01}px`);
      this.innerWidth = window.innerWidth;
      document.documentElement.style.setProperty('--vw', `${this.innerWidth * 0.01}px`);
      if(this.innerWidth <= 769) {
        this.ACTIVE_MOBILE(true)
      } else {
        this.ACTIVE_MOBILE(false)
      }
      if ('ontouchstart' in window || navigator.maxTouchPoints > 0) {
        this.ACTIVE_TOUCH_ALLOW(true)
      } else {
        this.ACTIVE_TOUCH_ALLOW(false)
      }
    },

    changeStateMenu(item) {
      this.activeMenu = item
      console.log(item)
    },

    checkHeaderFixed() {
      let scrollY = window.scrollY
      let activePX = this.checkPX(window.innerWidth);
      if ((scrollY + activePX) >= window.innerHeight) {
        this.activeFixed = true
      } else {
        this.activeFixed = false
      }
    },

    checkPX(width) {
      if(width >= 3025) {
        return 256
      } else if (width > 1920) {
        return 128
      } else if (width > 1600) {
        return 64
      } else if (width > 1300) {
        return 32
      } else {
        return 24
      }
    },

    changeStateModal() {
      this.ACTIVE_MODAL_CONSULTING(!this.activeModalConsulting)
    },
    changeStateModalContact() {
      this.ACTIVE_MODAL_CONTACT(!this.activeModalContact)
    },
    changeStateModalResult() {
      this.ACTIVE_MODAL_RESULT(!this.activeModalResult)
    },
    changeStateModalVideo() {
      this.ACTIVE_MODAL_VIDEO(!this.activeModalVideo)
    }
  },
  computed: {
    activeTransition() {
      if(!this.activeMobile || !this.activeMenu) {
        return 'fade-opacity-lazy'
      } else {
        return ''
      }
    },

    activeModalConsulting() {
      return this.$store.state.activeModalConsulting;
    },
    activeModalContact() {
      return this.$store.state.activeModalContact;
    },
    activeModalVideo() {
      return this.$store.state.activeModalVideo;
    },
    activeModalResult() {
      return this.$store.state.activeModalResult;
    }
  }
}
</script>

<style scoped lang="scss">
div {
  position: relative;
}
  .main-header-container {
    opacity: 0;

    &.active {
      opacity: 1;
    }
  }

</style>