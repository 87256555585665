<template>
  <div class="news-screen-container">
    <div class="news-screen-content main-container">
      <block-title :button-info="buttonInfo"
                   :active-button="true"
                   @actionButton="changeStateModal"
                   :main-paragraph="mainParagraph"
                   :main-title="mainTitle"/>

      <slider-scroll :active-info-length="newsInfo.length"
                     v-animateonscroll="{ enterClass: 'fade-in'}"
                     class="active-gradient"
                     :slides-per-view="slideCount"
                     :centered-slides="false"
                     :virtual-status="false"
                     :active-scroll-bar="true">
        <swiper-slide v-for="(item, index) in newsInfo" v-slot="{ isActive, isPrev }">
          <card-news :info-card="item" :class="{'is-active' : isActive,
          'is-last' : index === newsInfo.length - 1 && isActive,
          'is-last-prev' : isPrev && index === newsInfo.length - 2}"/>
        </swiper-slide>
      </slider-scroll>

<!--      <button-main-->
<!--          v-if="activeMobile"-->
<!--          :active-dark="buttonInfo.activeDark"-->
<!--          @click="routTo(buttonInfo.rout)">-->
<!--        {{ buttonInfo.text }}-->
<!--      </button-main>-->
    </div>

  </div>
</template>

<script>
import BlockTitle from "@/components/entities/BlockTitle.vue";
import CardNews from "@/components/entities/CardNews.vue";
import SliderScroll from "@/components/entities/SliderScroll.vue";
import ButtonMain from "@/components/shared/ButtonMain.vue";
import ModalConsulting from "@/components/entities/ModalConsulting.vue";
import {mapActions} from "vuex";

export default {
  name: "NewsScreen",
  components: {ModalConsulting, ButtonMain, SliderScroll, CardNews, BlockTitle},
  data() {
    return {
      buttonInfo: {
        text: 'Связаться с PR центром',
        activeDark: false
      },
      slideCount: 2,
      mainTitle: 'Новости'
    }
  },
  computed: {
    newsInfo() {
      return this.$store.state.newsInfo;
    },
    mainParagraph() {
      if(window.innerWidth >= 769) {
        return 'Участвуем в отраслевых конференциях, экспертных советах, научных и прикладных активностях\nГотовы предоставить квалифицированное мнение по профилям наших компетенций'
      } else {
        return 'Участвуем в отраслевых конференциях, экспертных советах, научных и прикладных активностях. Готовы предоставить квалифицированное мнение по профилям наших компетенций'
      }
    }
  },
  mounted() {
    this.checkSize();
    addEventListener('resize', this.checkSize)
  },
  unmounted() {
    removeEventListener('resize', this.checkSize)
  },
  methods: {
    ...mapActions(['ACTIVE_MODAL_CONSULTING']),
    checkSize() {
      if(window.innerWidth > 3840) {
        this.slideCount = 2.4
      } else if (window.innerWidth > 3024) {
        this.slideCount = 2.3
      } else if (window.innerWidth > 1921) {
        this.slideCount = 2.4
      } else if (window.innerWidth > 1600 && window.innerHeight <= 800) {
        this.slideCount = 1.8
      }  else if (window.innerWidth > 1600) {
        this.slideCount = 2.35
      }   else if (window.innerWidth > 1300) {
        this.slideCount = 1.95
      } else if ( window.innerWidth <= 769) {
        this.slideCount = 1.1
      }  else {
        this.slideCount = 2
      }
    },
    changeStateModal() {
      this.ACTIVE_MODAL_CONSULTING(true)
    }
  }
}
</script>

<style scoped lang="scss">
.news-screen-container {
  background-color: var(--color-sub-dark-color);
  position: relative;
  overflow: hidden;
}



@media (max-width: 3024px){

  .news-screen-content {
    .slider-scroll-container {
      height: 468px;
    }
  }
}


@media (max-width: 1920px) {
  .news-screen-container {
    .news-screen-content {
      row-gap: 100px;
      padding: 120px 0;

      .slider-scroll-container {
        height: 408px;
      }
    }
  }

}


@media (max-width: 1600px) {
  .news-screen-container {
    .news-screen-content {
      row-gap: 60px;
      padding: 100px 0;

      .slider-scroll-container {
        height: 378px;
      }
    }
  }
}
@media (max-height: 800px) {
  .news-screen-container {
    .news-screen-content {
      row-gap: 50px;
      padding: 80px 0;

      .slider-scroll-container {
        height: 386px;
      }
    }
  }
}

@media (max-width: 1400px) and (min-height: 801px) {
  .news-screen-container {
    .news-screen-content {
      row-gap: 60px;
      padding: 100px 0;

      .slider-scroll-container {
        height: 378px;
      }
    }
  }
}

@media (max-width: 1133px) {
  .news-screen-container {
     .news-screen-content {
       row-gap: 50px;
       padding: 80px 0;

       .slider-scroll-container {
         height: 386px;

       }
     }
   }
}

@media (max-width: 1050px) {
  .news-screen-container {
    .news-screen-content {
      padding: 80px 16px;
    }
  }
}

@media (max-width: 769px) {
  .news-screen-container {
    .news-screen-content {
      row-gap: 24px;
      padding: 32px 20px;

      .slider-scroll-container {
        height: 284px;

        .card-rp-container {
          opacity: .6;
          transition: all .6s ease;
          &.is-active {
            opacity: 1;
          }

          &.is-last {
            transform: translateX(-34px);
          }
          &.is-last-prev {
            transform: translateX(-34px);
          }
        }
      }
    }
  }
}

@media (max-width: 769px)  and (min-height: 720px) and (max-height: 1000px) {
  .news-screen-container {
      .news-screen-content {
        row-gap: 32px;
        padding: 48px 24px;
      .slider-scroll-container {
        height: 314px;

        .card-rp-container {
          &.is-last {
            transform: translateX(-38px);
          }
          &.is-last-prev {
            transform: translateX(-38px);
          }
        }
      }
    }
  }
}

@media (min-height: 600px) and (max-height: 630px){

  //8 plus
}

@media (max-width: 375px) {
  .news-screen-container {
    .news-screen-content {
      .slider-scroll-container {
        .card-rp-container {
          &.is-last {
            transform: translateX(-32px);
          }
          &.is-last-prev {
            transform: translateX(-32px);
          }
        }
      }
    }
  }
}

@media (max-width: 320px) {
  .news-screen-container {
    .news-screen-content {
      .slider-scroll-container {
        .card-rp-container {
          &.is-last {
            transform: translateX(-28px);
          }
          &.is-last-prev {
            transform: translateX(-28px);
          }
        }
      }
    }
  }
}

@media (min-width: 3025px) {
  .news-screen-container {

    .news-screen-content {
      row-gap: 160px;
      --padding-top-bottom: 192px;

      .slider-scroll-container {
        height: 888px;
      }
    }
  }
}

</style>