<template>
  <header class="header-container">
    <div class="logo-container" @click="routToHomeWithScroll">
      <img :src="getUrlLogo(logoImg)" alt="logo">
    </div>
    <line-header/>
  </header>
</template>

<script>
import LineHeader from "@/components/entities/sticker/LineHeader.vue";

export default {
  name: "SubHeader",
  components: {LineHeader},
  data() {
    return {
      logoImg: 'look.svg'
    }
  },
}
</script>

<style scoped lang="scss">
  .header-container {
    z-index: 900;
    align-items: center;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 100%;
    position: fixed;

    .logo-container {
      cursor: pointer;
      width: 100%;
      height: 100%;
      & img {
        object-fit: contain;
        width: 100%;
        height: 100%;
      }
    }
  }

  @media (max-width: 3024px){
    .header-container {
      height: 174px;
      padding: 0 63px 0 100px;

      .logo-container {
        max-width: 220px;
        max-height: 72px;
      }
    }
  }


  @media (max-width: 1920px) {
    .header-container {
      height: 148px;
      padding: 0 40px 0 85px;

      .logo-container {
        max-width: 190px;
        max-height: 64px;
      }
    }
  }


  @media (max-width: 1600px) {
    .header-container {
      height: 112px;
      padding: 0 32px 0 64px;

      .logo-container {
        max-width: 143px;
        max-height: 48px;
      }
    }
  }
  @media (max-height: 800px) {
    .header-container {
      height: 98px;
      padding: 0 25px 0 57px;

      .logo-container {
        max-width: 126px;
        max-height: 42px;
      }
    }
  }

  @media (max-width: 1400px) and (min-height: 801px) {
    .header-container {
      height: 112px;
      padding: 0 32px 0 64px;

      .logo-container {
        max-width: 143px;
        max-height: 48px;
      }
    }
  }

  @media (max-width: 1133px) {
    .header-container {
      height: 98px;
      padding: 0 25px 0 57px;

      .logo-container {
        max-width: 126px;
        max-height: 42px;
      }
    }
  }

  @media (max-width: 769px) {
    .header-container {
      height: 56px;
      padding: 0 16px;

      .logo-container {
        max-width: 61px;
        max-height: 21px;
      }
    }
  }

  @media (max-width: 769px)  and (min-height: 720px) and (max-height: 1000px) {
    //plus
  }

  @media (min-height: 600px) and (max-height: 630px){

    //8 plus
  }

  @media (max-width: 375px) {
    //se new
  }

  @media (max-width: 375px) and (min-height: 630px) {
    //x
  }


  @media (max-width: 320px) {
    //se old
  }

  @media (min-width: 3025px) {
    .header-container {
      height: 342px;
      padding: 0 146px 0 194px;

      .logo-container {
        max-width: 430px;
        max-height: 146px;
      }
    }
  }
</style>