<template>
    <div class="quiz-screen-container" v-if="activeBlock">
        <div class="quiz-screen-content">
          <div class="top-info-container">
            <main-paragraph v-if="!activeMobile">
              <transition name="fade-opacity-lazy" mode="out-in">
                <span style="position: relative" :key="activeQuestion">
                  {{ Number(id) + 1 }}
                </span>
              </transition>
               {{ textQuestion }}
            </main-paragraph>

            <div class="title-container" >
              <transition name="fade-opacity-lazy" mode="out-in">
              <main-title-quiz :key="activeQuestion" >
                {{ activeQuestion?.mainTitle }}
              </main-title-quiz>
              </transition>
              <main-paragraph v-if="!activeMobile">
                {{ subTitle }}
              </main-paragraph>
            </div>
          </div>

          <transition name="fade-opacity-lazy" mode="out-in">
            <list-quiz :key="activeQuestion"
                       :active-list="activeQuestion?.quizItem"/>
          </transition>
        </div>


      <div class="bottom-info-container" v-if="!activeMobile">
        <button-main :active-dark="true"
                     @click="nextQuestion"
                     :is-disabled="!activeQuestion?.activeAnswer">
          {{ textButton }}
        </button-main>

        <div class="arrow-button-container">
          <icon-arrow @click="lastQuestion" :class="{'not-active' : Number(id) === 0}" direction="left"/>
          <icon-arrow @click="nextQuestion" :class="{ 'disabled': !activeQuestion?.activeAnswer, 'not-active' : Number(id) === quizInfoLength}"
                      direction="right"/>
        </div>
      </div>

      <div class="bottom-info-container" v-else>
        <icon-arrow @click="lastQuestion" :class="{'not-active' : Number(id) === 0}" direction="left"/>

        <button-main :active-dark="true"
                     @click="nextQuestion"
                     :is-disabled="!activeQuestion.activeAnswer">
          {{ textButton }}
        </button-main>

        <icon-arrow @click="nextQuestion" :class="{ 'disabled': !activeQuestion?.activeAnswer, 'not-active' : Number(id) === quizInfoLength}"
                      direction="right"/>
      </div>
    </div>
</template>

<script>

import MainParagraph from "@/components/shared/text/MainParagraph.vue";
import MainTitleQuiz from "@/components/shared/text/MainTitleQuiz.vue";
import ItemQuizList from "@/components/shared/ItemQuizList.vue";
import {mapActions} from "vuex";
import ButtonMain from "@/components/shared/ButtonMain.vue";
import ListQuiz from "@/components/entities/ListQuiz.vue";
import IconArrow from "@/components/shared/IconArrow.vue";

export default {
  name: "QuizQuestionScreen",
  components: {IconArrow, ListQuiz, ButtonMain, ItemQuizList, MainTitleQuiz, MainParagraph},
  props: ['id'],
  data() {
    return {
      activeBlock: false,
      textButton: 'Ответить',
      textQuestion: 'вопрос',
      subTitle: 'Выберите один или несколько вариантов'
    }
  },
  computed: {
    activeQuestion() {
      return this.$store.state.quizModule.quizInfo.find(item => item.id === Number(this.id))
    },
    resultQuizAnswer() {
      return this.$store.state.quizModule.resultQuizAnswer
    },
    quizInfoLength() {
      return this.$store.state.quizModule.quizInfo.length - 1
    }
  },
  created() {
    this.checkQuestion();
  },
  mounted() {
    this.changeQuestion()
    this.activeBlock = true
  },
  watch: {
    id() {
      this.changeQuestion();
    }
  },
  methods: {
    ...mapActions('quizModule', ['ACTIVE_ANSWER', 'ACTIVE_QUESTION', 'ID_RESULT']),

    checkQuestion() {
      let id = Number(this.id)
      if(id >= this.resultQuizAnswer.length) {
        this.$router.push('/quiz/start')
      } else if(id !== 0) {
        for (let i = 0; i < id; i++) {
          if (this.resultQuizAnswer[i].answer === -1) {
            this.$router.push('/quiz/start')
          }
        }
      } else {
        this.activeBlock = true
      }
    },

    changeStatus(item) {
      this.ACTIVE_ANSWER({
        activeQuestion: this.activeQuestion,
        activeItem: item
      })
    },
    nextQuestion() {
      let id = Number(this.id)
      if(this.activeQuestion.activeAnswer) {
        if(id < this.quizInfoLength) {
          this.$router.push({name: 'quiz-loading', params: {id: id  + 1}})
        } else {
          this.ID_RESULT(true)
        }
      }
    },
    lastQuestion() {
      let id = Number(this.id)
      this.$router.push({name: 'quiz-loading', params: {id: id - 1}})
    },
    changeQuestion() {
      this.ACTIVE_QUESTION(Number(this.id))
    }
  }
}
</script>

<style scoped lang="scss">

  .quiz-screen-container {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100%;
    .quiz-screen-content {
      position: relative;
      display: flex;
      flex-direction: column;

      .top-info-container {
        display: flex;
        flex-direction: column;

        .title-container {
          position: relative;
          display: flex;
          flex-direction: column;

          .main-title-container {
            position: relative;
          }
        }
      }
    }
    .bottom-info-container {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;

      .arrow-button-container {
        display: flex;
        flex-direction: row;
      }
    }
  }

  .icon-arrow-container {
    transition: all .6s ease;
    opacity: 1;
    cursor: pointer;

    &.disabled {
      opacity: .6;
      cursor: default;
    }

    &.not-active {
      opacity: 0;
      cursor: default;
    }
  }


  @media (max-width: 3024px){
    .quiz-screen-container {
      .quiz-screen-content {
        row-gap: 80px;

        .top-info-container {
          row-gap: 80px;

          .title-container {
            row-gap: 10px;
          }
        }

      }

      .bottom-info-container {
        .arrow-button-container {
          column-gap: 50px;
        }
      }
    }
  }


  @media (max-width: 1920px) {
    .quiz-screen-container {
      .quiz-screen-content {
        row-gap: 60px;

        .top-info-container {
          row-gap: 60px;

          .title-container {
            row-gap: 14px;
          }
        }
      }
      .bottom-info-container {
        .arrow-button-container {
          column-gap: 22px;
        }
      }
    }
  }


  @media (max-width: 1600px) {
    .quiz-screen-container {
      .quiz-screen-content {
        row-gap: 40px;

        .top-info-container {
          row-gap: 40px;

          .title-container {
            row-gap: 8px;
          }
        }
      }
      .bottom-info-container {
        .arrow-button-container {
          column-gap: 30px;
        }
      }
    }
  }
  @media (max-height: 800px) {

    .main-paragraph-container {
      font-size: 16px;
    }
    .quiz-screen-container {
      .quiz-screen-content {
        row-gap: 40px;

        .top-info-container {
          row-gap: 40px;

          .title-container {
            row-gap: 6px;
          }
        }

      }

      .bottom-info-container {
        .arrow-button-container {
          column-gap: 30px;
        }
      }
    }
  }

  @media (max-width: 1400px) and (min-height: 801px) {
    .quiz-screen-container {
      .quiz-screen-content {
        row-gap: 40px;

        .top-info-container {
          row-gap: 40px;

          .title-container {
            row-gap: 8px;
          }
        }

      }

      .bottom-info-container {
        .arrow-button-container {
          column-gap: 30px;
        }
      }
    }
  }

  @media (max-width: 1133px) {
    .main-paragraph-container {
      font-size: 16px;
    }
    .quiz-screen-container {
      .quiz-screen-content {
        row-gap: 40px;

        .main-title-container {
          font-size: 24px;
        }

        .top-info-container {
          row-gap: 40px;

          .title-container {
            row-gap: 6px;
          }
        }
      }

      .bottom-info-container {
        .arrow-button-container {
          column-gap: 30px;
        }
      }
    }
  }

  @media (max-width: 769px) {
    .main-paragraph-container {
      font-size: 16px;
    }
    .quiz-screen-container {
      .quiz-screen-content {
        row-gap: 16px;

        .main-title-container {
          font-size: 22px;
        }
      }

      .bottom-info-container {
        padding-bottom: 0;
        align-self: center;
        max-width: 339px;
        width: 100%;
      }
    }
  }

  @media (max-width: 769px)  and (min-height: 720px) and (max-height: 1000px) {
    .main-paragraph-container {
      font-size: 16px;
    }
    .quiz-screen-container {
      .quiz-screen-content {
        row-gap: 20px;

        .main-title-container {
          font-size: 22px;
        }

        .top-info-container {
          row-gap: 40px;

          .title-container {
            row-gap: 6px;
          }
        }
      }

      .bottom-info-container {
        padding-bottom: 20px;
        align-self: center;
        max-width: 332px;
        width: 100%;
      }
    }
  }

  @media (min-height: 600px) and (max-height: 630px){

    //8 plus
  }

  @media (max-width: 375px) {
    //se new
  }

  @media (max-width: 375px) and (min-height: 630px) {
    //x
  }


  @media (max-width: 320px) {
    .main-paragraph-container {
      font-size: 16px;
    }
    .quiz-screen-container {
      .quiz-screen-content {
        row-gap: 12px;

        .main-title-container {
          font-size: 14px;
        }

        .top-info-container {
          row-gap: 40px;

          .title-container {
            row-gap: 6px;
          }
        }
      }

      .bottom-info-container {
        align-self: center;
        max-width: 332px;
        width: 100%;
      }
    }
  }

  @media (min-width: 3025px) {
    .quiz-screen-container {
      .quiz-screen-content {
        row-gap: 126px;

        .top-info-container {
          row-gap: 96px;

          .title-container {
            row-gap: 40px;
          }
        }
      }

      .bottom-info-container {
        .arrow-button-container {
          column-gap: 128px;
        }
      }
    }
  }
</style>