<template>
  <div class="circle-container" :class="{'is-necessary' : isNecessary,
                                'is-white' : isWhite,
                                'is-focus' : focused}"/>
</template>

<script>
export default {
  name: "CircleMiniBlock",
  props: {
    isNecessary: {
      type: Boolean,
      default: false
    },
    focused: {
      type: Boolean,
      default: false
    },
    isWhite: {
      type: Boolean,
      default: false
    }
  }
}
</script>

<style scoped lang="scss">
  .circle-container {
    border-radius: 50%;
    transition: background-color .6s ease, filter .6s ease;
    background-color: var(--color-sub-light-color);
    filter: blur(var(--active-blur));

    &.is-necessary {
      background-color: var(--color-main-accent);
    }

    &.is-focus {
      background-color: var(--color-main-visioners);
      filter: blur(0px);
    }
  }


@media (max-width: 3024px){
    .circle-container {
      min-width: 14px;
      max-width: 14px;
      height: 12px;

      &.active-textarea {
        margin-top: 7px;
      }
    }
}


@media (max-width: 1920px) {
    .circle-container {
      width: 14px;
      height: 14px;
      min-width: 14px;
      max-width: 14px;
      &.active-textarea {
        margin-top: 6px;
      }
    }
}

@media (max-width: 1600px) {
    .circle-container {
      width: 8px;
      height: 8px;
      min-width: 8px;
      max-width: 8px;
    }
}


@media (max-width: 769px) {
  .circle-container {
    width: 6px;
    height: 6px;
    min-width: 6px;
    max-width: 6px;
  }
}

@media (max-width: 769px)  and (min-height: 720px) and (max-height: 1000px) {
  //iPhone Plus
}
@media (min-height: 600px) and (max-height: 630px){
  //для экранов 8 плюс
}

@media (max-width: 375px){
  //iPhone 8
}


@media (max-width: 375px) and (min-height: 630px) {
  //iPhone X
}


@media (max-width: 320px) {
  .circle-container {
    width: 4px;
    height: 4px;
    min-width: 4px;
    max-width: 4px;
  }
}

@media (min-width: 3025px) {
    .circle-container {
      width: 22px;
      height: 22px;
      min-width: 22px;
      max-width: 22px;

      &.active-textarea {
        margin-top: 12px;
      }
    }
}
</style>