<template>
  <form class="block-contact-container" ref="formRef">
    <div class="block-contact-content">
      <div class="input-info-container">
        <div class="input-info-content"
             v-for="(item, index) in inputInfo"
             :key="index">
          <title-input>
            {{ item.mainTitle }}
          </title-input>
          <input-main v-for="itemInput in item.inputInfo"
                      @changeTextInput="changeTextInput"
                      :name-block="'input-info-container'"
                      :key="itemInput.id"
                      :info="itemInput"/>
        </div>
      </div>
      <div>
        <title-input>
          {{ textareaInfo.title }}
        </title-input>

        <input-main @changeTextInput="changeTextInput"
                    :length-text-area="500"
                    :name-block="'block-contact-content'"
                    :info="textareaInfo"/>
      </div>
    </div>

    <div class="actions-container">
      <button-main :active-dark="true"
                   @click="submitInfo"
                   :is-disabled="!isValid">
        {{ textButton }}
      </button-main>
      <text-control-input/>
    </div>

  </form>
</template>

<script>
import ButtonMain from "@/components/shared/ButtonMain.vue";
import InputMain from "@/components/shared/InputMain.vue";
import ButtonAgreement from "@/components/shared/ButtonAgreement.vue";
import * as emailjs from "@emailjs/browser";
import ButtonWithIcon from "@/components/shared/ButtonWithIcon.vue";
import {mapActions} from "vuex";
import TitleInput from "@/components/shared/text/TitleInput.vue";
import TextControlInput from "@/components/shared/text/TextControlInput.vue";

export default {
  name: "BlockContact",
  components: {TextControlInput, TitleInput, ButtonWithIcon, ButtonAgreement, InputMain, ButtonMain},
  data() {
    return {
      textButton: 'Получить бесплатную консультацию',
      inputInfo: [
        {
          mainTitle: 'Имя',
          inputInfo: [
            {
              id: 0,
              placeholder: 'Введите ваше имя',
              dataIndex: 'name',
              type: 'text'
            }
          ],
        },
        {
          mainTitle: 'E-mail',
          inputInfo: [
            {
              id: 1,
              placeholder: 'Введите почту',
              dataIndex: 'email',
              type: 'mail'
            }
          ],
        }
      ],
      textareaInfo: {
        id: 0,
        title: 'Опишите ваши бизнес идеи',
        placeholder: 'Например, онлайн-магазин уникальных подарков',
        dataIndex: 'message',
        type: 'textarea',
        isNecessary: false
      },
      isActiveAgreement: false,
      inputResult: {
        message: '',
        name: '',
        email: ''
      }
    }
  },

  computed: {
    isValid() {
      return this.inputResult.name.length
          && this.inputResult.email.length;
    }
  },

  methods: {
    ...mapActions(['ACTIVE_MODAL_RESULT']),
    changeStatusAgreement() {
      this.isActiveAgreement = !this.isActiveAgreement
    },
    changeTextInput(text, dataIndex) {
      this.inputResult[dataIndex] = text
    },

    submitInfo() {
      if(this.isValid) {
        emailjs.sendForm('service_6snhsmg', 'template_8d5citr', this.$refs.formRef, 'PtxY5p3h60AeBBTbV')
            .then((result) => {
              console.log('SUCCESS!', result.text);
              this.ACTIVE_MODAL_RESULT(true)
            }, (error) => {
              console.log('FAILED...', error.text);
            });
      }
    }
  }

}
</script>

<style scoped lang="scss">
  .block-contact-container {
    display: flex;
    flex-direction: column;
    row-gap: 64px;
    width: 100%;

    .block-contact-content {
      display: flex;
      flex-direction: column;
      width: 100%;


      .button-agreement-container {
        max-width: 100%;
      }

      .input-info-container {
        display: flex;
        flex-direction: column;
        width: 100%;

        .sub-title-container {
          font-weight: 100;
          color: #B6B6B6;
        }

        .input-info-content {
          display: flex;
          flex-direction: column;
          width: 100%;
        }
      }
    }

    .actions-container {
      .main-button-container {
        width: 100%;
      }
    }
  }


  @media (max-width: 3024px){
    .block-contact-container {
      row-gap: 64px;

      .block-contact-content {
        row-gap: 32px;

        .input-info-container {
          row-gap: 32px;
        }
      }
    }
  }


  @media (max-width: 1920px) {
      .block-contact-container {
        row-gap: 64px;

        .block-contact-content {
          row-gap: 32px;

          .input-info-container {
            row-gap: 32px;

            .sub-title-container {
              margin-left: 24px;
              font-size: 26.4px;
            }

          }
        }
    }
  }


  @media (max-width: 1600px) {
    .block-contact-container {
      row-gap: 52px;

      .block-contact-content {
        row-gap: 24px;

        .input-info-container {
          row-gap: 24px;

          .sub-title-container {
            margin-left: 34px;
            font-size: 22px;
          }
        }
      }
    }
  }


  @media (max-width: 769px) {
    .block-contact-container {
      align-items: center;
      row-gap: 12px;

      .block-contact-content {
        row-gap: 12px;

        .input-info-container {
          row-gap: 12px;

          .sub-title-container {
            font-weight: 300;
            margin-left: 0;
            font-size: 12px;
            color: #B6B6B6;
          }

        }
      }
    }
  }

  @media (max-width: 769px)  and (min-height: 720px) and (max-height: 1000px) {
    //plus
  }

  @media (min-height: 600px) and (max-height: 630px){

    //8 plus
  }

  @media (max-width: 375px) {
    //se new
  }

  @media (max-width: 375px) and (min-height: 630px) {
    //x
  }


  @media (max-width: 320px) {
    //se old
  }

  @media (min-width: 3025px) {
    .block-contact-container {
      row-gap: 72px;

      .block-contact-content {
        row-gap: 56px;

        .input-container {
        }

        .input-info-container {
          row-gap: 56px;

          .sub-title-container {
            margin-left: 38.5px;
            font-size: 54px;
          }

        }
      }
    }
  }

</style>