<template>
  <modal-frame-background>
    <modal-frame class="stickers"
                 @closeModalCross="closeModal"
                 :active-title="mainTitle">
      <div class="frame-modal-content">
        <p class="sub-title-container">
          {{ subTitle }}
        </p>
        <form ref="formRef" class="input-list-container">
          <main-input v-for="info in infoInput"
                      @changeTextInput="changeTextInput"
                      :key="info.id"
                      :info="info"
                      :name-block="'frame-modal-container'"/>
        </form>
        <transition name="fade-opacity-lazy" mode="out-in">
          <div class="policy-button-container" v-if="!activeBlockCode">
            <button-agreement @changeStatus="changeActiveButton"
                              class="italic"
                              :textInfo="policeText"
                              :is-active="isPolicyActive"/>
            <main-button  :active-big="false"
                          :is-disabled="!isValid"
                          :active-dark="true"
                         @click="submitInfo">
              {{ buttonTitle }}
            </main-button>
          </div>

          <div class="code-block-container" v-else>
            <div class="code-container">
              <main-input :info="infoInputCode"
                          class="not-circle"
                          :name-block="'code-container'"
                          @changeTextInput="changeCodeText"/>
              <p class="sub-paragraph-code-container">
                {{ codeTitle }}
              </p>
            </div>
            <div class="button-submit-container">
              <transition name="fade-opacity-lazy">
                <div class="timer-container" v-if="activeTimer">
                  <p class="sub-paragraph-code-container">
                    {{ codeTitleTwo }}
                  </p>
                  <timer @closeTimer="changeStateTime"/>
                </div>
              </transition>

              <main-button class="small"
                           :active-big="false"
                           :is-disabled="activeTimer"
                           :active-dark="true"
                           @click="submitCode">
                {{ buttonSubmit }}
              </main-button>
            </div>
          </div>
        </transition>
      </div>

    </modal-frame>
  </modal-frame-background>

</template>

<script>
// import ModalTitle from "@/components/test-component/shared/ModalTitle.vue";
import MainInput from "@/components/shared/InputMain.vue";
// import ButtonAnimationTitle from "@/components/test-component/shared/ButtonAnimationTitle.vue";
import MainButton from "@/components/shared/ButtonMain.vue";
import Timer from "@/components/entities/sticker/Timer.vue";
import {mapActions} from "vuex";
import ModalFrame from "@/components/entities/ModalFrame.vue";
import ModalFrameBackground from "@/components/entities/ModalFrameBackground.vue";
import ButtonAgreement from "@/components/shared/ButtonAgreement.vue";

export default {
  name: "ModalFeedback",
  components: {ButtonAgreement, ModalFrameBackground, ModalFrame, Timer, MainButton, MainInput},
  data() {
    return {
      activeBackground: false,
      activeModal: false,
      mainTitle: 'Для голосования необходимо\nпройти авторизацию',
      subTitle: 'Чтобы обеспечить честный подсчет голосов, просим заполнить данные формы. Мы направим письмо с ссылкой для подтверждения вашего выбора.',
      policeText: 'Даю согласие на обработку и хранение\nперсональных данных',
      isPolicyActive: false,
      codeTitle: 'Впишите здесь код из письма',
      codeTitleTwo: 'Отправить код повторно через',

      testCode: '0000',

      infoInput: [
        {
          id: 0,
          dataIndex: 'name',
          type: 'text',
          placeholder: 'ФИО',
          isNecessary: false
        },
        {
          id: 1,
          dataIndex: 'email',
          type: 'email',
          placeholder: 'Email',
          isNecessary: true
        },
      ],
      infoInputCode: {
        id: 0,
        dataIndex: 'code',
        type: 'text',
        placeholder: '',
        isNecessary: true
      },
      dataResult: {
        name: '',
        email: ''
      },
      buttonTitle: 'Получить код',
      buttonSubmit: 'Отправить',
      activeBlockCode: false,
      codeText: '',
      activeTimer: false,
    }
  },
  mounted() {
    this.openModal();
  },

  computed: {
    isValid() {
      return this.dataResult.email !== '' && this.isPolicyActive
    },
    disableButton() {
      return this.isValid && this.isPolicyActive;
    },
  },

  watch: {
    codeText() {
      this.checkCode();
    }
  },

  methods: {
    ...mapActions(['ACTIVE_STICKERS_RESULT']),
    checkCode() {
      if(this.codeText === this.testCode) {
        this.ACTIVE_STICKERS_RESULT(true)
        localStorage.setItem('activeResultSticker', 'true')
        this.closeModal();
      }
    },

    openModal() {
      setTimeout(() => {
        this.activeBackground = true
      }, 100)
      setTimeout(() => {
        this.activeModal = true
      }, 500)
    },

    changeStateTime() {
      this.activeTimer = !this.activeTimer
    },

    submitCode() {
      if(!this.activeTimer) {
        console.log('Код отправлен')
        this.changeStateTime();
      }
    },

    changeActiveButton() {
      this.isPolicyActive = !this.isPolicyActive
    },

    closeModal() {
      this.$emit('closeModal');
    },


    submitInfo() {
      if(this.isValid) {
        this.activeBlockCode = true
        this.activeTimer = true
      }
    },

    changeTextInput(text, dataIndex) {
      this.dataResult[dataIndex] = text
    },
    changeCodeText(text) {
      this.codeText = text
    }
  }
}
</script>

<style scoped lang="scss">

  .frame-modal-container {
    height: fit-content;
    border-color: rgba(113, 117, 127, .7);

    .main-title-container {
      text-align: center;
      white-space: pre-wrap;
    }

    .sub-title-container {
      text-align: center;
      line-height: 140%;
      color: var(--color-sub-light-color);
    }

    .input-container {
      &:last-child {
        margin-bottom: 0 !important;
      }
    }

    .main-button-container {
      align-self: center;
    }

    .policy-button-container {
      position: relative;
      display: flex;
      flex-direction: column;
    }

    .code-block-container {
      color: var(--color-sub-light-color) !important;
      .code-container {
        display: flex;
        flex-direction: row;
        align-items: center;
      }
      .button-submit-container {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;

        .timer-container {
          justify-content: space-between;
          display: flex;
          flex-direction: row;
        }
        .main-button-container {
          position: relative;
        }
      }
    }
  }

@media (max-width: 3024px) and (min-width: 1921px) {
    .frame-modal-container {
      max-width: 680px;
      border-radius: 32px;
      padding: 40px 40px 48px;
      border-width: 3px;
      row-gap: 28px;

      .sub-title-container {
        font-size: 20px;
        margin-bottom: 28px;
      }

      .input-list-container {
        margin-bottom: 28px;

        .input-container {
          margin-bottom: 12px;
        }
      }

      .policy-button-container {
        row-gap: 48px;
      }

      .code-block-container {
        .code-container {
          column-gap: 24px;
          font-size: 24px;
          margin-bottom: 42px;
          .input-container {
            max-width: 194px;
          }
        }
        .button-submit-container {
          column-gap: 8px;

          .timer-container {
            font-size: 20px;
            column-gap: 6px;
            width: 338px;
          }
        }
      }
    }
  }


@media (max-width: 1920px) and (min-width: 1601px) {
    .frame-modal-container {
      max-width: 564px;
      row-gap: 24px;
      border-radius: 28px;
      padding: 32px 32px 40px;
      border-width: 2px;

      .sub-title-container {
        font-size: 16px;
        margin-bottom: 24px;
      }

      .input-list-container {
        margin-bottom: 24px;

        .input-container {
          margin-bottom: 12px;
        }
      }

      .policy-button-container {
        row-gap: 32px;
      }

      .code-block-container {
        .code-container {
          column-gap: 20px;
          margin-bottom: 20px;
          font-size: 16px;
          .input-container {
            max-width: 164px;
          }
        }
        .button-submit-container {
          column-gap: 6px;

          .timer-container {
            font-size: 16px;
            column-gap: 5px;
            width: 302px;
          }
        }
      }
    }
  }

@media (max-width: 1600px) and (min-width: 770px) {
    .frame-modal-container {
      border-width: 2px;
      row-gap: 20px;
      max-width: 440px;
      border-radius: 20px;
      padding: 20px 20px 28px;

      .sub-title-container {
        font-size: 12px;
        margin-bottom: 20px;
      }
      .input-list-container {
        margin-bottom: 20px;

        .input-container {
          margin-bottom: 10px;
        }
      }

      .policy-button-container {
        row-gap: 24px;
      }

      .code-block-container {
        .code-container {
          column-gap: 14px;
          margin-bottom: 18px;
          font-size: 14px;
          .input-container {
            max-width: 122px;
          }
        }
        .button-submit-container {
          column-gap: 18px;

          .timer-container {
            column-gap: 10px;
            width: 214px;
            font-size: 12px;
          }
        }
      }
    }
}

@media (max-width: 769px) {
  .frame-modal-container {
    row-gap: 20px;
    max-width: 340px;
    border-width: 2px;
    border-radius: 20px;
    padding: 20px 20px 28px;

    .sub-title-container {
      font-size: 11px;
      margin-bottom: 20px;
    }
    .input-list-container {
      margin-bottom: 20px;

      .input-container {
        margin-bottom: 10px;
      }
    }

    .policy-button-container {
      row-gap: 24px;
    }

    .code-block-container {
      .code-container {
        column-gap: 14px;
        margin-bottom: 18px;
        font-size: 11px;
        .input-container {
          max-width: 122px;
        }
      }
      .button-submit-container {
        column-gap: 18px;

        .timer-container {
          flex-direction: column;
          align-items: center;
          column-gap: 10px;
          width: 214px;
          font-size: 10px;
        }
      }
    }
  }
}

@media (max-width: 769px)  and (min-height: 740px) and (max-height: 1000px) {
  .frame-modal-container {
    row-gap: 20px;
    max-width: 360px;
    border-radius: 20px;
    padding: 20px 20px 28px;

    .sub-title-container {
      font-size: 12px;
      margin-bottom: 20px;
    }
    .input-list-container {
      margin-bottom: 20px;

      .input-container {
        margin-bottom: 10px;
      }
    }

    .policy-button-container {
      row-gap: 24px;
    }

    .code-block-container {
      .code-container {
        column-gap: 14px;
        margin-bottom: 18px;
        font-size: 12px;
        .input-container {
          max-width: 122px;
        }
      }
      .button-submit-container {
        column-gap: 18px;

        .timer-container {
          column-gap: 10px;
          width: 214px;
          font-size: 11px;
        }
      }
    }
  }
}
@media (min-height: 600px) and (max-height: 630px){
  //для экранов 8 плюс
}

@media (max-width: 375px){
  //iPhone 8
}


@media (max-width: 375px) and (min-height: 630px) {
  //iPhone X
}


@media (max-width: 320px) {
  .frame-modal-container {
    row-gap: 20px;
    max-width: 290px;
    border-radius: 20px;
    padding: 16px 16px 24px;

    .sub-title-container {
      font-size: 10px;
      margin-bottom: 16px;
    }
    .input-list-container {
      margin-bottom: 16px;

      .input-container {
        margin-bottom: 8px;
      }
    }

    .policy-button-container {
      row-gap: 20px;
    }

    .code-block-container {
      .code-container {
        column-gap: 14px;
        margin-bottom: 18px;
        font-size: 10px;
        .input-container {
          max-width: 100px;
        }
      }
      .button-submit-container {
        column-gap: 18px;

        .timer-container {
          column-gap: 10px;
          width: 214px;
          font-size: 8px;
        }
      }
    }
  }
}

@media (min-width: 3025px) {
    .frame-modal-container {
      row-gap: 64px;
      max-width: 1204px;
      border-radius: 64px;
      padding: 52px 52px 80px;
      border-width: 5px;

      .sub-title-container {
        font-size: 36px;
        margin-bottom: 64px;
      }

      .input-list-container {
        margin-bottom: 64px;

        .input-container {
          margin-bottom: 32px;
        }
      }

      .policy-button-container {
        row-gap: 80px;
      }

      .code-block-container {
        .code-container {
          column-gap: 36px;
          font-size: 42px;
          margin-bottom: 72px;
          .input-container {
            max-width: 382px;
          }
        }
        .button-submit-container {
          column-gap: 15px;

          .timer-container {
            font-size: 34px;
            column-gap: 16px;
            width: 585px;
          }
        }
      }
    }
  }


</style>