<template>
  <p class="footer-main-title-container">
    <slot/>
  </p>
</template>

<script>
export default {
  name: "MainTitle"
}
</script>

<style scoped lang="scss">
.footer-main-title-container {
  color: #B6B6B6;
  line-height: 120%;
}


@media (max-width: 3024px){
  .footer-main-title-container {
    font-size: 32px;
  }
}


@media (max-width: 1920px) {
  .footer-main-title-container {
    font-size: 24px;
  }
}


@media (max-width: 1600px) {
  .footer-main-title-container {
    font-size: 22px;
  }
}


@media (max-width: 769px) {
  .footer-main-title-container {
    text-transform: inherit;
    font-size: 16px;
    color: #FDFDFD;
  }
}

@media (max-width: 769px)  and (min-height: 720px) and (max-height: 1000px) {
  //plus
}

@media (min-height: 600px) and (max-height: 630px){

  //8 plus
}

@media (max-width: 375px) {
  //se new
}

@media (max-width: 375px) and (min-height: 630px) {
  //x
}


@media (max-width: 320px) {
  //se old
}

@media (min-width: 3025px) {
  .footer-main-title-container {
    font-size: 42px;
  }
}

</style>