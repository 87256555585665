<template>
  <p class="sub-title-container">
    <slot/>
  </p>
</template>

<script>
export default {
  name: "SubTitle"
}
</script>

<style scoped lang="scss">
.sub-title-container {
  font-weight: 400;
  line-height: 150%;
  color: var(--color-main-light-color);
}


@media (max-width: 3024px){
  .sub-title-container {
    font-size: 40px;
  }
}


@media (max-width: 1920px) {
  .sub-title-container {
    font-size: 24px;
  }
}

@media (max-width: 1600px) {
  .sub-title-container {
    font-size: 20px;
  }
}

@media (max-width: 769px) {
  .sub-title-container {
    font-size: 16px;
  }
}

@media (min-height: 600px) and (max-height: 630px){

  //8 plus
}

@media (max-width: 375px) {
  //se new
}

@media (max-width: 375px) and (min-height: 630px) {
  //x
}


@media (max-width: 320px) {
  //se old
}

@media (min-width: 3025px) {
  .sub-title-container {
    font-size: 54px;
  }
}
</style>