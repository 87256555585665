<template>
  <div class="card-services-container">
    <div class="title-container">
      <div class="circle-container"></div>
      <p class="main-title-container">
        {{ infoCard.title }}
      </p>
    </div>


    <div class="card-services-content">
      <p class="main-paragraph-container description">
        {{ infoCard.description }}
      </p>

      <div class="list-info-container">
        <p class="main-paragraph-container mini"
           v-for="item in infoCard.info"
           :key="item.id">
          — {{ item.text }}
        </p>
      </div>

      <button-main class="card-mobile" @click="openModal">
        {{ infoCard.buttonText }}
      </button-main>
    </div>
  </div>
</template>

<script>
import ButtonMain from "@/components/shared/ButtonMain.vue";
import {mapActions} from "vuex";

export default {
  name: "CardServiceMobile",
  components: {ButtonMain},
  props: {
    infoCard: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      buttonText: 'Создать уникальный бренд'
    }
  },
  methods: {
    ...mapActions(['ACTIVE_MODAL_CONTACT']),
    openModal(){
      this.ACTIVE_MODAL_CONTACT(true)
    }
  }
}
</script>

<style scoped lang="scss">
  .card-services-container {
    display: flex;
    flex-direction: column;
    row-gap: 12px;
    color: #FDFDFD;

    .title-container {
      align-items: center;
      display: flex;
      flex-direction: row;
      column-gap: 5px;
      .circle-container {
        width: 5px;
        height: 5px;
        border-radius: 50%;
        background-color: var(--color-main-accent);
      }
    }

    .main-title-container {
      font-size: 20px;
      font-weight: 300;
    }

    .main-paragraph-container {
      font-size: 12px;
      font-weight: 300;
      line-height: 150%;
    }

    .main-button-container {
      padding: 7px 20px !important;
    }

    .card-services-content {
      row-gap: 12px;
      border-radius: 4px;
      padding: 12px;
      background-color: rgba(22,23,27,.2);
      display: flex;
      flex-direction: column;

      .list-info-container {
        display: flex;
        flex-direction: column;
        row-gap: 4px;
      }
    }
  }


</style>