import { createStore } from 'vuex'
import {quizModules} from "@/store/quiz";

export default createStore({
  state: {
    activeMobile: false,
    activeTouchAllow: false,
    activeModalConsulting: false,
    activeModalSticker: false,
    activeModalContact: false,
    activeModalResult: false,
    activeModalVideo: false,
    activeStickersResult: false,
    serviceInfo: [
      {
        id: 0,
        title: 'Брендинг',
        description: 'Создание стиля и брендов как для крупного бизнеса, так и для индивидуальных клиентов',
        buttonText: 'Создать уникальный бренд',
        info: [
          {
            id: 0,
            text: 'Бренд платформа'
          },
          {
            id: 1,
            text: 'Фирменный стиль'
          },
          {
            id: 2,
            text: 'HR-брендинг'
          },
          {
            id: 3,
            text: 'Личный бренд, имиджмейкинг, стайлинг'
          }
        ]
      },
      {
        id: 1,
        title: 'Дизайн',
        buttonText: 'Сформировать визуальную идентичность',
        description: 'Визуальная привлекательность, укрепляет бренд и коммуникацию с клиентами, формирует лояльность и доверие к бренду',
        info: [
          {
            id: 0,
            text: 'UI/UX'
          },
          {
            id: 1,
            text: 'Графический'
          },
          {
            id: 2,
            text: '3D'
          }
        ]
      },
      {
        id: 2,
        title: 'Разработка',
        buttonText: 'Запустить ваш проект',
        description: 'Кастомная и шаблонная разработка всегда с фокусом на ценность, результат, с учетом контекста задачи и идеальным чувством time2market',
        info: [
          {
            id: 0,
            text: 'Лендинги'
          },
          {
            id: 1,
            text: 'Сайты'
          },
          {
            id: 2,
            text: 'Порталы'
          }
        ]
      },
      {
        id: 3,
        title: 'Ивент продакшн',
        buttonText: 'Организовать ивент под ключ',
        description: 'Комплексные решения для организации конференций, форумов и бизнес-мероприятий, воплощающие в жизнь самые смелые концепции ',
        info: [
          {
            id: 0,
            text: 'Организация и проведение бизнес-мероприятий '
          },
          {
            id: 1,
            text: 'Дизайн и тех. сопровождение мероприятий'
          },
          {
            id: 2,
            text: 'Маркетинг и PR поддержка мероприятий'
          }
        ]
      },
      {
        id: 4,
        title: 'Производство контента',
        buttonText: 'Произвести контент на заказ',
        description: 'Контент, эффективно передаст ключевые сообщения бренда, увеличивая узнаваемость и вовлеченность аудитории',
        info: [
          {
            id: 0,
            text: 'Фото и видео production '
          },
          {
            id: 1,
            text: 'Motion-дизайн'
          },
          {
            id: 2,
            text: 'Showreel и рекламные ролики'
          }
        ]
      },
      {
        id: 5,
        title: 'Продвижение',
        buttonText: 'Продвинуть ваш бизнес',
        description: 'Комплекс работ для достижения конкретных маркетинговых и PR целей',
        info: [
          {
            id: 0,
            text: 'Контент-маркетинг'
          },
          {
            id: 1,
            text: 'SMM'
          },
          {
            id: 2,
            text: 'PR'
          },
          {
            id: 3,
            text: 'Digital-реклама'
          },
          {
            id: 3,
            text: 'Спецпроекты'
          }
        ]
      },
      {
        id: 6,
        title: 'Стратегический маркетинг',
        buttonText: 'Заказать маркетинг поддержку',
        description: 'Разработка или аудит активностей в рамках долгосрочных планов для достижения ваших бизнес-целей ',
        info: [
          {
            id: 0,
            text: 'Аудит маркетинговых активностей'
          },
          {
            id: 1,
            text: 'Исследования: Product/Market Fit, NPS, CustDev'
          },
          {
            id: 2,
            text: 'Позиционирование'
          },
          {
            id: 3,
            text: 'Коммуникационный план'
          }
        ]
      },
      {
        id: 7,
        title: 'Мерч и полиграфия',
        buttonText: 'Разработать продукцию со смыслом',
        description: 'Создание высококачественных и оригинальных продуктов с вашим брендом',
        info: [
          {
            id: 0,
            text: 'Моделинг и пошив корпоративной одежды'
          },
          {
            id: 1,
            text: 'Дизайн продукции и производство упаковки'
          },
          {
            id: 2,
            text: 'Создание 3D-мокапов продукции'
          }
        ]
      },

    ],
    newsInfo: [
      {
        id: 0,
        datePublish: "01.02.2024",
        mainTitle: "Маркетинг-бутик .look стал креативным партнером форума ВШЮА НИУ ВШЭ",
        description: "Предстоящий форум ВШЮА НИУ ВШЭ, посвященный юридическому менеджменту и консалтингу будет оформлен силами команды .look.",
        href: ["Новости", "Общество", "Наука", "Образование"],
        view: "356"
      },
      {
        id:1,
        datePublish: "28.11.2023",
        mainTitle: "Директор PR-функции на Конференции «Construction | Conf»",
        description: "28 ноября 2023 года в Центре Событий РБК прошла вторая ежегодная Всероссийская отраслевая конференция по вопросам строительного права и юридического менеджмента, в которой приняла участие директор PR-функции, лидер вертикали «PR и Маркетинг» Анна Растрыгина",
        href: ["Новости", "Общество", "Наука", "Образование"],
        view: "356"
      },
      {
        id: 2,
        datePublish: "04.09.2023",
        mainTitle: "Рестайлинг бренда и маскот – Helper.AI",
        description: "Функция «Цифровое искусство» и «PR» функция представили новый обновленный брендинг Группы компаний. Сохранив название и смыслы бренд стал более ярким, гибким и стал лучше отражать реализованные структурные изменениями внутри группы компаний, расширение деятельности и появление новых направлений бизнеса.",
        href: ["Пресс-релизы", "Бренд"],
        view: "356"
      },
      {
        id: 3,
        datePublish: "04.09.2023",
        mainTitle: "Директор PR-функции и суббренд .look на «Юридическом бизнес-форуме»",
        description: "30 августа 2023 года в залах Отеля Арарат Парк Хаятт прошел юридический бизнес-форум, в котором приняла участие директор PR-функции, лидер вертикали «PR и Маркетинг» Анна Растрыгина",
        href: ["Новости", "Общество", "Наука", "Образование"],
        view: "356"
      },
      {
        id: 4,
        datePublish: "22.05.2023",
        mainTitle: "Новый суббренд визионеров – .look",
        description: "Новый суббренд, объединил в себе неофициальную сторону бренда и будет отвечать за креативные решения и неофициальную PR коммуникацию Группы. Дополнительно, работая в формате B2B маркетинг-бутика будет оказывать профильные услуги полного цикла для сторонних компаний.",
        href: ["Пресс-релизы", "Суббренды"],
        view: "356"
      }
    ],
    partnersInfo: [
      {
        id: 0,
        mainTitle: "Construction | Conf",
        description: "Ежегодная Всероссийская отраслевая конференция в области строительного права и юридического менеджмента",
        img: "partners_4"
      },
      {
        id: 1,
        mainTitle: "КотПринт",
        description: "Комплексное производство полиграфической и сувенирной продукции любыми тиражами",
        img: "partners_2"
      },
      // {
      //   id: 2,
      //   mainTitle: "ВШЮА НИУ ВШЭ",
      //   description: "Высшая школа юриспруденции и администрирования Национального исследовательского университета «Высшая школа экономики»",
      //   img: "partners_1"
      // }
    ],
    projectInfo: [
      {
        id: 0,
        mainTitle: 'Юридическая фирма\nIM LEGAL',
        description: 'Создание бренда, дизайн логотипа, константы фирменного стиля, деловая документация, брендбук',
        img: 'legal'
      },
      {
        id: 1,
        mainTitle: 'ОАЭ крипто-биржа\nVALHALLA',
        description: 'Дизайн логотипа, интерфейс торгового терминала и личного кабинета',
        img: 'valhalla'
      },
      {
        id: 2,
        mainTitle: 'Московская областная\nколлегия адвокатов',
        description: 'Рестайлинг, разработка логотипа, брендбука, фирменного стиля и деловой документации',
        img: 'advocate'
      },
      {
        id: 3,
        mainTitle: 'Международная группа\nкомпаний «visioners»',
        description: 'Рестайлинг, дизайн-система, константы фирменного стиля, фотостиль, деловая документация, брендбуки',
        img: 'visioners'
      }
    ]
  },
  getters: {
  },
  mutations: {
    SET_ACTIVE_MOBILE(state, payload) {
      state.activeMobile = payload
    },
    SET_ACTIVE_TOUCH_ALLOW(state, payload) {
      state.activeTouchAllow = payload
    },
    SET_ACTIVE_MODAL_CONSULTING(state, payload) {
      state.activeModalConsulting = payload
    },
    SET_ACTIVE_MODAL_STICKER(state, payload) {
      state.activeModalStickers = payload
    },
    SET_ACTIVE_MODAL_CONTACT(state, payload) {
      state.activeModalContact = payload
    },
    SET_ACTIVE_MODAL_VIDEO(state, payload) {
      state.activeModalVideo = payload
    },

    SET_ACTIVE_STICKERS_RESULT(state, payload) {
      state.activeStickersResult = payload
    },

    SET_ACTIVE_MODAL_RESULT(state, payload) {
      state.activeModalResult = payload
    },
  },
  actions: {
    ACTIVE_MOBILE: (context, payload) => {
      context.commit('SET_ACTIVE_MOBILE', payload)
    },
    ACTIVE_TOUCH_ALLOW: (context, payload) => {
      context.commit('SET_ACTIVE_TOUCH_ALLOW', payload)
    },
    ACTIVE_MODAL_CONSULTING: (context, payload) => {
      context.commit('SET_ACTIVE_MODAL_CONSULTING', payload)
    },
    ACTIVE_MODAL_STICKER: (context, payload) => {
      context.commit('SET_ACTIVE_MODAL_STICKER', payload)
    },

    ACTIVE_STICKERS_RESULT: (context, payload) => {
      context.commit('SET_ACTIVE_STICKERS_RESULT', payload)
    },
    ACTIVE_MODAL_CONTACT: (context, payload) => {
      context.commit('SET_ACTIVE_MODAL_CONTACT', payload)
    },
    ACTIVE_MODAL_RESULT: (context, payload) => {
      context.commit('SET_ACTIVE_MODAL_RESULT', payload)
    },
    ACTIVE_MODAL_VIDEO: (context, payload) => {
      context.commit('SET_ACTIVE_MODAL_VIDEO', payload)
    },
  },
  modules: {
    quizModule: quizModules
  }
})
