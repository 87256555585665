<template>
  <modal-result :active-text="activeTextResult.resultText"
                :active-result="activeResult"
                :active-title="activeTextResult.resultTitle">
    <modal-frame @closeModalCross="closeModal"
                 ref="formRef"
                 :active-title="mainTitle">
      <form ref="formRef">
        <div class="modal-frame-input-container">
        <div class="modal-frame-input-content"
             :class="item.url"
             v-for="item in consultingModal.inputInfo">
          <title-input>
            {{ item.mainTitle }}
          </title-input>
          <input-main :info="itemInput"
                      :active-modal="true"
                      :key="itemInput.id"
                      :name-block="item.url"
                      v-for="itemInput in item.inputInfo"
                      @changeTextInput="changeTextInput"
          />
        </div>
      </div>

        <div class="modal-frame-textarea-content">
          <title-input>
            {{ textareaInfo.title }}
          </title-input>
          <input-main :info="textareaInfo"
                      :active-modal="true"
                      :length-text-area="500"
                      :name-block="'modal-frame-textarea-content'"/>
        </div>

        <div class="modal-frame-button-container">
          <button-main @click="submitInfo" :is-disabled="!isValid">
            {{ buttonText }}
          </button-main>
          <text-control-input/>
        </div>
      </form>

    </modal-frame>
  </modal-result>
</template>

<script>
import ModalFrameBackground from "@/components/entities/ModalFrameBackground.vue";
import ModalFrame from "@/components/entities/ModalFrame.vue";
import ButtonAgreement from "@/components/shared/ButtonAgreement.vue";
import ButtonWithIcon from "@/components/shared/ButtonWithIcon.vue";
import MainParagraph from "@/components/shared/text/MainParagraph.vue";
import InputMain from "@/components/shared/InputMain.vue";
import ModalResult from "@/components/entities/ModalResult.vue";
import * as emailjs from "@emailjs/browser";
import ButtonMain from "@/components/shared/ButtonMain.vue";
import TitleInput from "@/components/shared/text/TitleInput.vue";
import TextControlInput from "@/components/shared/text/TextControlInput.vue";

export default {
  name: "ModalConsulting",
  props: {
    activeContact: {
      type: Boolean,
      default: false
    },
  },

  components: {
    TextControlInput,
    TitleInput,
    ButtonMain,
    ModalResult,
    InputMain, MainParagraph, ButtonWithIcon, ButtonAgreement, ModalFrame, ModalFrameBackground},

  data() {
    return {
      consultingModal: {
      "mainTitle": "Связаться с нами",
          "resultTitle": "Запрос успешно отправлен",
          "resultText": "Наш клиент-менеджер свяжется с Вами и уточнит детали",

          "mainTitleRP": "Оставить заявку в PR-центр",
          "resultTitleRP": "Запрос успешно отправлен",
          "resultTextRP": "Представитель PR-центра свяжется с Вами и уточнит детали",
      "inputInfo": [
        {
          "id": 0,
          "url": "name",
          "mainTitle": "Имя",
          "inputInfo": [{
            "id": 0,
            "placeholder": "Введите ваше имя",
            "dataIndex": "name",
            "type": "text",
            "isNecessary": true
          }]
        },
        {
          "id": 1,
          "url": "contacts",
          "mainTitle": "E-mail",
          "inputInfo": [
            {
              "id": 0,
              "placeholder": "Введите почту",
              "dataIndex": "email",
              "type": "mail",
              "isNecessary": true
            }
          ]
        }
      ]
      },
      stateAgreementButton: false,
      resultInput: {
        name: '',
        email: '',
        message: ''
      },
      activeResult: false
    }
  },

  computed:{
    textareaInfo() {
      return {
        id: 0,
        title: this.activeContact ? 'Опишите ваши бизнес идеи' : 'Опишите ваш запрос',
        placeholder: this.activeContact ? 'Например, онлайн-магазин уникальных подарков' : 'Например, хочу получить ваше экспертное заключение',
        dataIndex: "message",
        type: "textarea",
        isNecessary: false
      }
    },
    buttonText() {
      return this.activeContact ? 'Получить бесплатную консультацию' : 'Отправить'
    },
    isValid() {
      return this.resultInput.name.length
          && this.resultInput.email.length
    },
    mainTitle() {
      return this.activeContact ? this.consultingModal.mainTitle : this.consultingModal.mainTitleRP

    },
    activeTextResult() {
      if(this.activeContact) {
        return {
          resultText: this.consultingModal.resultText,
          resultTitle: this.consultingModal.resultTitle
        }
      } else {
        return {
          resultText: this.consultingModal.resultTextRP,
          resultTitle: this.consultingModal.resultTitleRP
        }
      }
    }
  },

  methods: {
    closeModal() {
      this.$emit('closeModal')
    },

    changeStateAgreementButton() {
      this.stateAgreementButton = !this.stateAgreementButton
      console.log(this.stateAgreementButton)
    },

    changeTextInput(item, dataIndex) {
      this.resultInput[dataIndex] = item
    },
    submitInfo() {
      if(this.isValid) {
        emailjs.sendForm('service_6snhsmg', 'template_8d5citr', this.$refs.formRef, 'PtxY5p3h60AeBBTbV')
            .then((result) => {
              this.activeResult = true
              console.log('SUCCESS!', result.text);
            }, (error) => {
              console.log('FAILED...', error.text);
            });
      }
    }
  }
}
</script>

<style scoped lang="scss">
  .frame-modal-container {
    .modal-frame-button-container  {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      .main-button-container {
        width: 100%;
      }
    }

    & form {
      display: inherit;
      height: inherit;
      width: inherit;
      row-gap: inherit;
      flex-direction: inherit;
    }

    .modal-frame-input-container {
      display: flex;
      flex-direction: column;
      width: 100%;
      .modal-frame-input-content {
        display: flex;
        flex-direction: column;
        flex-basis: 33.33%;
      }
    }
  }

  @media (max-width: 3024px){
    .frame-modal-container {
      max-width: 918px;


      .modal-frame-input-container {
        row-gap: 32px;
      }
    }
  }


  @media (max-width: 1920px) {
    .frame-modal-container {
      max-width: 856px;

      .modal-frame-input-container {
        row-gap: 32px;
      }
    }
  }

  @media (max-width: 1600px) {
    .frame-modal-container {
      max-width: 644px;

      .modal-frame-input-container {
        row-gap: 24px;
      }
    }
  }
  @media (max-height: 800px) {
    .frame-modal-container {
      max-width: 444px;

      .modal-frame-input-container {
        row-gap: 24px;
      }
    }
  }

  @media (max-width: 1400px) and (min-height: 801px) {
    .frame-modal-container {
      max-width: 644px;

      .modal-frame-input-container {
        row-gap: 24px;
      }
    }
  }

  @media (max-width: 1133px) {
    .frame-modal-container {
      max-width: 444px;

      .modal-frame-input-container {
        row-gap: 24px;
      }
    }
  }
  @media (max-width: 769px) {
    .frame-modal-container {
      padding: 24px;
      justify-content: space-between;
      border-width: 0;
      border-radius: 0;
      max-width: 100%;
      height: 100%;

      .sub-paragraph-container {
        font-size: 12px;
      }

      .modal-frame-textarea-content {
        height: 100%;
        display: flex;
        flex-direction: column;
      }

      .modal-frame-button-container  {
        flex-direction: column;
        .sub-button-container {
          max-width: 100%;
        }
      }

      .modal-frame-input-container {
        flex-direction: column;
        row-gap: 12px;

        .modal-frame-input-content {
          .sub-paragraph-container {
            font-size: 12px;
          }
        }
      }
    }
  }

  @media (max-width: 769px)  and (min-height: 720px) and (max-height: 1000px) {
    .frame-modal-container {

      .modal-frame-input-container {
        row-gap: 12px;
      }
    }
  }
  @media (min-height: 600px) and (max-height: 630px){
    //для экранов 8 плюс
  }

  @media (max-width: 375px){
    //iPhone 8
  }


  @media (max-width: 375px) and (min-height: 630px) {
    //iPhone X
  }


  @media (max-width: 320px) {
    .input-container {
      padding: 8px;
      max-height: 24px;
    }

    .frame-modal-container {
      padding: 10px;

      .sub-paragraph-container {
        font-size: 10px;
      }


      .modal-frame-button-container  {
        row-gap: 10px;
      }

      .modal-frame-input-container {
        flex-direction: column;
        row-gap: 10px;

        .modal-frame-input-content {

          .main-paragraph-container {
            font-size: 10px;
          }
        }
      }
    }
  }

  @media (min-width: 3025px) {

    .frame-modal-container {
      max-width: 1414px;

      .modal-frame-input-container {
        row-gap: 56px;
      }
    }
  }

</style>