<template>
  <div class="slider-container">
    <div class="top-slider-container active-gradient">
      <swiper @slide-change="onSwiperMain"
              @swiper="changeSettingSwiper"
              class="swiper service"
              :modules="modules"
              :breakpoints="
            {
              0: {
                slidesPerView: 1,
                slidesOffsetBefore: 0,
                spaceBetween: 20,
                allowTouchMove: true,
                scrollbar: {
                  hide: false,
                  enabled: true
                }
              },
              330: {
                slidesPerView: 1.07,
                slidesOffsetBefore: 0,
                spaceBetween: 20,
                allowTouchMove: true,
                scrollbar: {
                  hide: false,
                  enabled: true
                }
              },
              380: {
                slidesPerView: 1.12,
                slidesOffsetBefore: 0,
                spaceBetween: 20,
                allowTouchMove: true,
                scrollbar: {
                  hide: false,
                  enabled: true
                }
              },
              400: {
                slidesPerView: 1.2,
                slidesOffsetBefore: 0,
                spaceBetween: 24,
                allowTouchMove: true,
                scrollbar: {
                  hide: false,
                  enabled: true
                }
              },
            }"
              :scrollbar="{
                  draggable: true,
                  dragClass: 'swiper-scrollbar-drag-custom'
             }"
              :speed="800"
              direction="horizontal">
        <swiper-slide class="custom-slide" v-for="(item, index) in infoService" v-slot="{ isActive, isPrev }">
          <card-service-mobile :class="{'is-active' : isActive,
          'is-last' : index === infoService.length - 1 && isActive,
          'is-last-prev' : isPrev && index === infoService.length - 2}"
                               :info-card="item"/>
        </swiper-slide>>
      </swiper>
    </div>
  </div>
</template>

<script>
import {Scrollbar} from "swiper";
import BlockPagination from "@/components/entities/BlockPagination.vue";
import CardServiceMobile from "@/components/entities/CardServiceMobile.vue";

export default {
  name: "SliderService",
  components: {CardServiceMobile, BlockPagination,},
  props: {
    infoService: {
      type: Array,
      required: true
    }
  },
  data() {
    return {
      scrollSwiper: '',
      activeIndex: 0,
      modules: [Scrollbar]
    }
  },

  methods: {
    onSwiperMain(swiper) {
      this.activeIndex = swiper.activeIndex;
    },

    changeSettingSwiper(swiper) {
      this.scrollSwiper = swiper
    },
  }
}
</script>

<style scoped lang="scss">

.slider-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  height: 100%;

  .top-slider-container {
    display: flex;
    flex-direction: row;
    align-items: center;
    width: 100%;
    height: 100%;
    position: relative;

    .swiper {
      padding-bottom: 14px;
      height: 250px;
      max-width: 100%;
      overflow: visible;
      width: 100%;
    }

    .card-services-container {
      position: relative;
      transition: all 1s ease;
      z-index: 10;
      height: 100%;
      opacity: .5;

      &.is-active {
        opacity: 1;
        z-index: 12;
      }
    }
  }
}


@media (max-width: 769px) {

  .slider-container {
    .top-slider-container {
      .swiper {
        padding-bottom: 14px;
        height: 286px
      }

      .card-services-container {
        &.is-last {
          transform: translateX(-40px);
        }
        &.is-last-prev {
          transform: translateX(-40px);
        }
      }
    }
  }
}


@media (max-width: 769px)  and (min-height: 720px) and (max-height: 1000px) {

  .slider-container {
    .top-slider-container {
      .swiper {
        padding-bottom: 14px;
        height: 286px
      }

      .card-services-container {
        &.is-last {
          transform: translateX(-68px);
        }
        &.is-last-prev {
          transform: translateX(-68px);
        }
      }
    }
  }
}

@media (min-height: 600px) and (max-height: 630px){
  .slider-container {
    .top-slider-container {
      .card-services-container {
        &.is-last {
          transform: translateX(-68px);
        }
        &.is-last-prev {
          transform: translateX(-68px);
        }
      }
    }
  }
}

@media (max-width: 375px) {
  .slider-container {
    .top-slider-container {
      .card-services-container {
        &.is-last {
          transform: translateX(-24px);
        }
        &.is-last-prev {
          transform: translateX(-24px);
        }
      }
    }
  }
}

@media (max-width: 320px) {

  .slider-container {
    .top-slider-container {
      .swiper {
        padding-bottom: 14px;
        height: 265px;
      }

      .card-services-container {
        &.is-last {
          transform: translateX(0);
        }
        &.is-last-prev {
          transform: translateX(0);
        }
      }
    }
  }
}

</style>