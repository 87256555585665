<template>
  <footer class="main-footer-container">
    <div class="main-footer-content main-container">
      
      <div class="left-info-container">
        <div class="left-top-container">
          <div class="logo-container" @click="scrollToTop">
            <img :src="'look.svg'" alt="logo">
          </div>

          <div class="left-center-info-container">
            <main-paragraph v-for="(item, index) in titleInfo" :key="index">
              {{ item }}
            </main-paragraph>
          </div>
        </div>


        <main-paragraph>
          {{ watermarkInfo }}
        </main-paragraph>
      </div>
      
<!--      <div class="center-info-container">-->
<!--        <div class="title-info-container">-->
<!--          <main-title>-->
<!--            {{ infoNum.mainTitle }}-->
<!--          </main-title>-->

<!--          <main-paragraph v-for="(item, index) in infoNum.info"-->
<!--                          :key="index">-->
<!--            {{ item }}-->
<!--          </main-paragraph>-->
<!--        </div>-->

<!--        <div class="title-info-container visioners">-->
<!--          <div class="visioners-info-container">-->
<!--            <p v-for="(item, index) in sliceEl"-->
<!--               :key="index">-->
<!--              <span v-if="item !== 'visioners'">-->
<!--                {{ item }}-->
<!--              </span>-->
<!--              <div v-else class="img-container">-->
<!--                <img :src="getUrlLogo('visioners-grey.svg')" alt="">-->
<!--              </div>-->
<!--            </p>-->
<!--          </div>-->

<!--          <main-paragraph v-for="(item, index) in visionersInfo.info"-->
<!--                          :key="index">-->
<!--            {{ item }}-->
<!--          </main-paragraph>-->
<!--        </div>-->
<!--      </div>-->
      
      <div class="right-info-container">
        <div class="title-info-container">
          <main-title>
            {{ officeInfo.mainTitle }}
          </main-title>

          <main-paragraph>
            {{ officeInfo.info }}
          </main-paragraph>
        </div>

        <div class="button-info-container">
          <main-paragraph v-for="item in buttonInfo"
                          :key="item.id">
            <span v-if="item.type !== 'mailto'">
              {{ item.text }}
            </span>
            <a v-else :href="item.type + ':' + item.text">
              {{ item.text }}
            </a>
          </main-paragraph>
        </div>
      </div>
    </div>
  </footer>
</template>

<script>
import MainTitle from "@/components/shared/footer/MainTitle.vue";
import MainParagraph from "@/components/shared/footer/MainParagraph.vue";

export default {
  name: "MainFooter",
  components: {MainParagraph, MainTitle},
  data() {
    return {
      titleInfo: ['Маркетинг-бутик', 'Представляя стиль — создаем бренды'],
      watermarkInfo: '© 2014 — 2024 HoldCo «visioners»',
      infoNum: {
        mainTitle: 'ООО «Визионерс КРЕАТИВ»',
        info: ['ИНН: 7709638741', 'ОГРН: 1057748748562']
      },
      visionersInfo: {
        mainTitle: 'Входит в ГК visioners',
        info: ['АО «Группа компаний «Визионерс»', 'ИНН: 9709102369']
      },
      officeInfo: {
        mainTitle: 'Офис',
        info: 'Россия, Москва, 105064,\nул. Земляной Вал, д.9'
      },

      buttonInfo: [
        {
          id: 0,
          text: 'info@visioners.tech',
          type: 'mailto'
        },
        {
          id: 1,
          text: 'Сообщить об ошибке на сайте',
          type: '/'
        },
        {
          id: 2,
          text: 'Политика обработки персональных данных',
          type: '/'
        }
      ]
    }
  },
  computed: {
    sliceEl() {
      return this.visionersInfo.mainTitle.split(' ')
    }
  }
}
</script>

<style scoped lang="scss">

.title-info-container {
  display: flex;
  flex-direction: column;

  .visioners-info-container {
    display: flex;
    flex-direction: row;
    color: #B6B6B6;
    align-items: baseline;

    .img-container {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 100%;
      height: 100%;

      & img {
        width: 100%;
        height: 100%;
      }
    }
  }

  &.visioners {
    .footer-main-paragraph-container {
      margin-bottom: 0;
    }
  }

  .footer-main-paragraph-container {

    &:last-child {
      margin-bottom: 0;
    }
  }
}

  .main-footer-container {
    background-color: var(--color-sub-dark-color);
    width: 100%;

    .main-footer-content {
      height: 100%;
      max-width: var(--max-width);
      display: flex;
      flex-direction: row;
      align-items: stretch;
      margin:  0 auto;
      
      .left-info-container {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        .left-top-container {
          display: flex;
          flex-direction: column;
        }

        .left-center-info-container {
          display: flex;
          flex-direction: column;
        }

        .logo-container {
          display: flex;
          align-items: center;
          justify-content: center;
          cursor: pointer;
          width: 100%;
          height: 100%;

          & img {
            width: 100%;
            height: 100%;
          }
        }
      }

      .center-info-container {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: stretch;
      }

      .right-info-container {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: stretch;

        .button-info-container {
          display: flex;
          flex-direction: column;

          .footer-main-paragraph-container {
            opacity: .6;
            transition: opacity .6s ease;
            cursor: pointer;

            & a {
              color: var(--color-sub-light-color);
            }

            &:hover {
              opacity: 1;
            }
          }
        }
      }
    }
  }



@media (max-width: 3024px){
  .title-info-container {

    .visioners-info-container {
      column-gap: 8px;
      font-size: 28px;

      .img-container {
        max-width: 114px;
        max-height: 19px;
      }
    }

    &.visioners {
      row-gap: 20px;
    }

    .footer-main-title-container {
      margin-bottom: 24px;
    }

    .footer-main-paragraph-container {
      margin-bottom: 20px;
    }
  }

  .main-footer-container {
    height: 592px;

    .main-footer-content {
      padding: 96px 0;

      .left-info-container {


        .left-top-container {
          row-gap: 48px;
        }

        .left-center-info-container {
          row-gap: 6px;
        }

        .logo-container {
          max-width: 135px;
          max-height: 48px;
        }
      }

      .right-info-container {

        .button-info-container {
          row-gap: 18px;
        }
      }
    }
  }
}


@media (max-width: 1920px) {

  .title-info-container {

    .visioners-info-container {
      column-gap: 4px;
      font-size: 16px;

      .img-container {
        max-width: 66px;
        max-height: 11px;
      }
    }

    &.visioners {
      row-gap: 12px;
    }

    .footer-main-title-container {
      margin-bottom: 24px;
    }

    .footer-main-paragraph-container {
      margin-bottom: 14px;
    }
  }

  .main-footer-container {
    height: 394px;

    .main-footer-content {
      padding: 64px 0;

      .left-info-container {

        .left-top-container {
          row-gap: 32px;
        }
        .left-center-info-container {
          row-gap: 6px;
        }

        .logo-container {
          max-width: 93px;
          max-height: 34px;
        }
      }

      .right-info-container {

        .button-info-container {
          row-gap: 16px;
        }
      }
    }
  }
}


@media (max-width: 1600px) {
  .title-info-container {

    .visioners-info-container {
      column-gap: 4px;
      font-size: 16px;

      .img-container {
        max-width: 66px;
        max-height: 13px;
      }
    }

    &.visioners {
      row-gap: 12px;
    }

    .footer-main-title-container {
      margin-bottom: 18px;
    }

    .footer-main-paragraph-container {
      margin-bottom: 12px;
    }
  }

  .main-footer-container {
    height: 378px;

    .main-footer-content {
      padding: 56px 0;

      .left-info-container {

        .left-top-container {
          row-gap: 24px;
        }
        .left-center-info-container {
          row-gap: 6px;
        }

        .logo-container {
          max-width: 77px;
          max-height: 26px;
        }
      }

      .right-info-container {
        .button-info-container {
          row-gap: 12px;
        }
      }
    }
  }
}

@media (max-height: 800px) {
  .title-info-container {

    .visioners-info-container {
      column-gap: 4px;
      font-size: 16px;

      .img-container {
        max-width: 66px;
        max-height: 13px;
      }
    }

    &.visioners {
      row-gap: 12px;
    }

    .footer-main-title-container {
      margin-bottom: 18px;
    }

    .footer-main-paragraph-container {
      margin-bottom: 12px;
      line-height: 120%;
    }
  }

  .main-footer-container {
    height: 362px;

    .main-footer-content {
      padding: 48px 0;

      .left-info-container {

        .left-top-container {
          row-gap: 24px;
        }
        .left-center-info-container {
          row-gap: 6px;
        }

        .logo-container {
          max-width: 77px;
          max-height: 26px;
        }
      }

      .right-info-container {
        .button-info-container {
          row-gap: 12px;
        }
      }
    }
  }
}

@media (max-width: 1400px) and (min-height: 801px) {
  .title-info-container {

    .visioners-info-container {
      column-gap: 4px;
      font-size: 16px;

      .img-container {
        max-width: 66px;
        max-height: 13px;
      }
    }

    &.visioners {
      row-gap: 12px;
    }

    .footer-main-title-container {
      margin-bottom: 18px;
    }

    .footer-main-paragraph-container {
      margin-bottom: 12px;
    }
  }

  .main-footer-container {
    height: 378px;

    .main-footer-content {
      padding: 56px 0;

      .left-info-container {

        .left-top-container {
          row-gap: 24px;
        }
        .left-center-info-container {
          row-gap: 6px;
        }

        .logo-container {
          max-width: 77px;
          max-height: 26px;
        }
      }

      .right-info-container {
        .button-info-container {
          row-gap: 12px;
        }
      }
    }
  }
}

@media (max-width: 1133px) {
  .title-info-container {

    .visioners-info-container {
      column-gap: 4px;
      font-size: 16px;

      .img-container {
        max-width: 66px;
        max-height: 13px;
      }
    }

    &.visioners {
      row-gap: 12px;
    }

    .footer-main-title-container {
      margin-bottom: 18px;
    }

    .footer-main-paragraph-container {
      margin-bottom: 12px;
      line-height: 120%;
    }
  }

  .main-footer-container {
    height: 362px;

    .main-footer-content {
      padding: 48px 0;

      .left-info-container {

        .left-top-container {
          row-gap: 24px;
        }
        .left-center-info-container {
          row-gap: 6px;
        }

        .logo-container {
          max-width: 77px;
          max-height: 26px;
        }
      }

      .right-info-container {
        .button-info-container {
          row-gap: 12px;
        }
      }
    }
  }
}

@media (max-width: 1050px) {
  .main-footer-container {
    .main-footer-content {
      padding: 48px 16px;
    }
  }
}

@media (max-width: 769px) {
  //14
}

@media (max-width: 769px)  and (min-height: 720px) and (max-height: 1000px) {
  //plus
}

@media (min-height: 600px) and (max-height: 630px){

  //8 plus
}

@media (max-width: 375px) {
  //se new
}

@media (max-width: 375px) and (min-height: 630px) {
  //x
}


@media (max-width: 320px) {
  //se old
}

@media (min-width: 3025px) {

  .title-info-container {

    .visioners-info-container {
      column-gap: 6px;
      font-size: 32px;

      .img-container {
        max-width: 122px;
        max-height: 34px;
      }
    }

    &.visioners {
      row-gap: 20px;
    }

    .footer-main-title-container {
      margin-bottom: 24px;
    }

    .footer-main-paragraph-container {
      margin-bottom: 20px;
    }
  }

  .main-footer-container {
    height: 702px;

    .main-footer-content {
      padding: 128px 0;

      .left-info-container {

        .left-top-container {
          row-gap: 54px;
        }

        .left-center-info-container {
          row-gap: 6px;
        }

        .logo-container {
          max-width: 154px;
          max-height: 52px;
        }
      }

      .right-info-container {

        .button-info-container {
          row-gap: 16px;
        }
      }
    }
  }
}

</style>