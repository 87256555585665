<template>
  <scroll-active class="menu-content"
                 :click-to-scroll="false"
                 :offset="offset"
                 @itemchanged="onItemChanged">
    <a class="scrollactive-item"
       :href="'#section-' + item.id"
       :data-section-selector="getBlock(item.id)"
       v-for="item in menuInfo"
       :key="item.id"
       @click.prevent="scrollTo(item.id)"
       @click.passive="clearScrollChanged">
      <main-paragraph v-if="!activeMobile" :class="{'active' : activeIndex === item.id}">
        {{ item.text }}
      </main-paragraph>
      <button-with-arrow v-else>
        {{ item.text }}
      </button-with-arrow>
    </a>
  </scroll-active>
</template>

<script>
import ButtonWithArrow from "@/components/shared/ButtonWithArrow.vue";
import MainParagraph from "@/components/shared/text/MainParagraph.vue";
import scrollActive from "vue-scrollactive/src/scrollactive.vue";

export default {
  name: "MenuItem",
  components: {MainParagraph, ButtonWithArrow, scrollActive},
  props: {
    menuInfo: {
      type: Array,
      required: true
    },
    offset: {
      type: Number,
      default: 20
    },
    activeIndex: {
      type: Number
    }
  },

  data() {
    return {
      prevTimer: null
    }
  },

  methods: {
    onItemChanged(event, currentItem) {
      this.$emit('onItemChanged', event, currentItem)
    },

    scrollTo(id) {
      this.$emit('scrollTo', id)
    },

    getBlock(id) {
      return '#section-'.concat(id);
    },

    clearScrollChanged() {
      if (this.prevTimer !== null) {
        clearTimeout(this.prevTimer);
      }
    },

  }
}
</script>

<style scoped lang="scss">
.menu-content {
  display: flex;
  flex-direction: row;
}

.main-paragraph-container {
  color: white;
  opacity: .5;
  cursor: pointer;
  transition: opacity .6s ease;

  &.active {
    opacity: 1;
  }

  &:hover {
    opacity: 1;
  }
}

@media (max-width: 3024px) and (min-width: 1921px) {
    .menu-content {
      column-gap: 56px;
    }
}


@media (max-width: 1920px) {
    .menu-content {
      column-gap: 50px;
    }
}


@media (max-width: 1600px) {
    .menu-content {
      column-gap: 48px;
    }
}

@media (max-height: 800px) {
    .menu-content {
      column-gap: 48px;

      .main-paragraph-container {
        font-size: 16px !important;
      }
    }
}

@media (max-width: 1400px) and (min-height: 801px) {
    .menu-content {
      column-gap: 48px;
    }
}

@media (max-width: 1133px) {
    .menu-content {
      column-gap: 48px;
     .main-paragraph-container {
        font-size: 16px !important;
      }
    }
}

@media (max-width: 769px) {
  .menu-content {
    flex-direction: column;
    width: 100%;
  }
}

@media (max-width: 320px) {
  .menu-content {
    flex-direction: column;
    width: 100%;
  }
}

@media (min-width: 3025px) {
    .menu-content {
      column-gap: 72px;
    }
}

</style>