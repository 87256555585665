<template>
  <div class="card-team-container" ref="cardTeam" :class="{'not-active' : !isActive, 'is-active-description' : activeCard}">
    <div class="top-info-container" @click="changeState">
      <div class="img-container">
        <img :src="getUrlTeam(cardInfo.img)" alt="team">
      </div>
      <transition :name="activeAnimation.transition">
        <div class="description-info-container" :class="activeAnimation.type" v-if="activeCard">
          <div class="description-info-content">
            <p class="main-paragraph-container main"
               :key="index"
               v-for="(item, index) in cardInfo.description">
              {{ item }}
            </p>
          </div>

          <p class="main-paragraph-container sub">
            {{ cardInfo.title }}
          </p>
        </div>
      </transition>

    </div>

    <div class="bottom-info-container">
         <main-title>
               {{ cardInfo.name }}
         </main-title>
         <p class="sub-paragraph-container">
           {{ cardInfo.position }}
         </p>
    </div>
  </div>
</template>

<script>
import MainTitle from "@/components/shared/text/Card/MainTitle.vue";

export default {
  name: "CardTeam",
  components: {MainTitle},
  props: {
    isClickActive: {
      required: true
    },
    cardInfo: {
      type: Object,
      required: true
    },
    activeIndex: {
      type: Number,
      required: true
    },
    isActive: {
      type: Boolean,
      required: true
    },
    isNext: {
      type: Boolean,
      required: true
    },
  },
  data() {
    return {
      blockAnimation: false,
      activeCard: false,
      activeAnimation: {
        transition: 'fade-translate-x-card-revert',
        type: 'right'
      }
    }
  },
  mounted() {
    this.changeState();
  },

  watch: {
    isActive() {
      this.changeState();
    }
  } ,
  methods: {
    changeState() {
      if(this.isActive) {
        setTimeout(() => {
          this.activeCard = this.isActive
        }, 500)
      } else {
          this.activeCard = this.isActive
      }
    }
  },
}
</script>

<style scoped lang="scss">
  .card-team-container {
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;
    transition: all .6s ease;

    &:hover {
      .bottom-info-container {
        .sub-paragraph-container {
          color: var(--color-main-accent)
        }
      }
    }
    .top-info-container {
      cursor: pointer;
    }

    &.is-active-description {
      .bottom-info-container {
        .sub-paragraph-container {
          color: var(--color-main-accent)
        }
      }
    }

      .top-info-container {
        display: flex;
        position: relative;
        height: 100%;

        .img-container {
          height: 100%;
          min-height: inherit;
          max-height: inherit;
          overflow: hidden;
          position: relative;
          width: 100%;
          z-index: 3;
          & img {
            min-height: inherit;
            max-height: inherit;
            width: 100%;
            height: 100%;
          }
        }


        .description-info-container {
          height: fit-content;
          width: 100%;
          background-color: var(--color-sub-dark-color);
          position: absolute;
          display: flex;
          flex-direction: column;
          z-index: 2;

          .description-info-content {
            display: flex;
            flex-direction: column;
          }

          .main-paragraph-container {
            color: var(--color-card-light-color);
            white-space: pre-wrap;
            font-weight: 300;
            &.main {
              font-weight: 400;
              line-height: 150%;
              color: #FDFDFD;
            }
          }

        }
      }

    .bottom-info-container {
      display: flex;
      flex-direction: column;
      text-align: center;

      .main-title-container {
        transition: font-size .6s ease;
        //text-transform: uppercase;
      }

      .sub-paragraph-container {
        transition: all .6s ease;
        color: var(--color-card-light-color);
      }
    }
  }


  @media (max-width: 3840px) and (min-width: 3025px){
    .card-team-container {
      .top-info-container {
        .description-info-container {
          right: -855px !important;
        }
      }
    }
  }

  @media (max-width: 3024px){
    .card-team-container {
      row-gap: 22px;
      max-width: 460px;

      &.not-active {

        .top-info-container {
          .img-container {
            border-radius: 24px;
          }
        }

        .bottom-info-container {
          .main-title-container {
            font-size: 36px !important;
          }
          .sub-paragraph-container {
            font-size: 32px !important;
          }
        }
      }

      .top-info-container {
        max-height: 638px;
        min-height: 638px;

        .img-container {
          border-radius: 20px;
        }


        .description-info-container {
          max-width: 532px;
          min-width: 532px;
          border-radius: 20px;
          row-gap: 40px;
          bottom: -100px;
          padding: 40px 40px 40px 80px;
          right: -486px;

          .main-paragraph-container {
            font-size: 20px;
          }

          .description-info-content {
            row-gap: 32px;
          }
        }
      }

      .bottom-info-container {
        row-gap: 6px;
        .main-title-container {
          font-size: 32px;
        }

        .sub-paragraph-container {
          font-size: 28px;
        }
      }
    }
  }

  @media (max-width: 2240px){
    .card-team-container {
      .top-info-container {
        .description-info-container {
          right: -492px;
        }
      }
    }
  }

  @media (max-width: 1920px) {
    .card-team-container {
      row-gap: 20px;
      max-width: 370px;


      &.not-active {
        max-width: 334px;
        min-width: 334px;

        .bottom-info-container {

          .main-title-container {
            font-size: 28px !important;
          }
          .sub-paragraph-container {
            font-size: 23px !important;
          }
        }
      }

      .top-info-container {
        max-height: 500px;
        min-height: 500px;

        .img-container {
          border-radius: 20px;
        }


        .description-info-container {
          max-width: 430px;
          min-width: 430px;
          border-radius: 16px;
          row-gap: 32px;
          padding: 32px 32px 32px 64px;
          right: -398px;
          bottom: -74px;

          .description-info-content {
            row-gap: 24px;
          }

          .main-paragraph-container {
            font-size: 16px;
          }

        }
      }

      .bottom-info-container {
        row-gap: 6px;
        .main-title-container {
          font-size: 24px;
        }

        .sub-paragraph-container {
          font-size: 16px;
        }
      }
    }

  }


  @media (max-width: 1600px) {
    .card-team-container {
      row-gap: 14px;
      max-width: 280px;

      &.not-active {
        max-width: 235px;
        min-width: 235px;

        .top-info-container {
          .img-container {
            border-radius: 16px;
          }
        }

        .bottom-info-container {

          .main-title-container {
            font-size: 22px !important;
          }

          .sub-paragraph-container {
            font-size: 16px !important;
          }
        }
      }

      .top-info-container {
        max-height: 380px;
        min-height: 380px;

        .img-container {
          border-radius: 12px;
        }


        .description-info-container {
          max-width: 328px;
          min-width: 328px;
          border-radius: 12px;
          row-gap: 24px;
          padding: 24px 24px 24px 48px;
          right: -304px;
          bottom: -64px;

          .description-info-content {
            row-gap: 24px;
          }

          .main-paragraph-container {
            font-size: 12px;
          }
        }
      }

      .bottom-info-container {
        row-gap: 6px;

        .main-title-container {
          font-size: 20px;
        }
        .sub-paragraph-container {
          font-size: 14px;
        }
      }
    }
  }
  @media (max-height: 800px) {
    .card-team-container {
      row-gap: 8px;
      max-width: 250px;

      &.not-active {
        max-width: 210px;
        min-width: 210px;

        .top-info-container {
          .img-container {
            border-radius: 10px;
          }
        }

        .bottom-info-container {
          .main-title-container {
            font-size: 20px !important;
          }
          .sub-paragraph-container {
            font-size: 16px !important;
          }
        }
      }

      .top-info-container {
        max-height: 340px;
        min-height: 340px;

        .img-container {
          border-radius: 8px;
        }


        .description-info-container {
          max-width: 288px;
          min-width: 288px;
          border-radius: 8px;
          row-gap: 16px;
          padding: 16px 16px 16px 32px;
          right: -272px;
          bottom: -53px;

          .description-info-content {
            row-gap: 16px;
          }

          .main-paragraph-container {
            font-size: 10px;
          }
        }
      }

      .bottom-info-container {
        row-gap: 6px;
        .main-title-container {
          font-size: 18px;
        }
        .sub-paragraph-container {
          font-size: 14px;
        }
      }
    }
  }

  @media (max-width: 1400px) and (min-height: 801px) {
    .card-team-container {
      row-gap: 14px;
      max-width: 280px;

      &.not-active {
        max-width: 235px;
        min-width: 235px;

        .top-info-container {
          .img-container {
            border-radius: 16px;
          }
        }

        .bottom-info-container {

          .main-title-container {
            font-size: 22px !important;
          }

          .sub-paragraph-container {
            font-size: 16px !important;
          }
        }
      }

      .top-info-container {
        max-height: 380px;
        min-height: 380px;

        .img-container {
          border-radius: 12px;
        }


        .description-info-container {
          max-width: 328px;
          min-width: 328px;
          border-radius: 12px;
          row-gap: 24px;
          padding: 24px 24px 24px 48px;
          right: -304px;
          bottom: -64px;

          .description-info-content {
            row-gap: 24px;
          }

          .main-paragraph-container {
            font-size: 12px;
          }
        }
      }

      .bottom-info-container {
        row-gap: 6px;

        .main-title-container {
          font-size: 20px;
        }
        .sub-paragraph-container {
          font-size: 14px;
        }
      }
    }
  }

  @media (max-width: 1133px) {
    .card-team-container {
      row-gap: 8px;
      max-width: 250px;

      &.not-active {
        max-width: 210px;
        min-width: 210px;

        .top-info-container {
          .img-container {
            border-radius: 10px;
          }
        }

        .bottom-info-container {
          .main-title-container {
            font-size: 20px !important;
          }
          .sub-paragraph-container {
            font-size: 16px !important;
          }
        }
      }

      .top-info-container {
        max-height: 340px;
        min-height: 340px;

        .img-container {
          border-radius: 8px;
        }


        .description-info-container {
          max-width: 288px;
          min-width: 288px;
          border-radius: 8px;
          row-gap: 16px;
          padding: 16px 16px 16px 32px;
          right: -272px;
          bottom: -53px;

          .description-info-content {
            row-gap: 16px;
          }

          .main-paragraph-container {
            font-size: 10px;
          }
        }
      }

      .bottom-info-container {
        row-gap: 6px;
        .main-title-container {
          font-size: 18px;
        }
        .sub-paragraph-container {
          font-size: 14px;
        }
      }
    }
  }

  @media (max-width: 769px) {
    //14
  }

  @media (max-width: 769px)  and (min-height: 720px) and (max-height: 1000px) {
    //plus
  }

  @media (min-height: 600px) and (max-height: 630px){

    //8 plus
  }

  @media (max-width: 375px) {
    //se new
  }

  @media (max-width: 375px) and (min-height: 630px) {
    //x
  }


  @media (max-width: 320px) {
    //se old
  }

  @media (min-width: 3025px) {
    .card-team-container {
      row-gap: 24px;
      max-width: 800px;

      &.not-active {
        max-width: 740px;
        min-width: 740px;
        .top-info-container {
          .img-container {
            border-radius: 42px;
          }
        }

        .bottom-info-container {
          .main-title-container {
            font-size: 56px !important;
          }
          .sub-paragraph-container {
            font-size: 48px !important;
          }
        }
      }

      .top-info-container {
        max-height: 1030px;
        min-height: 1030px;

        .img-container {
          border-radius: 32px;
        }


        .description-info-container {
          max-width: 928px;
          min-width: 928px;
          border-radius: 32px;
          row-gap: 64px;
          bottom: -137px;
          padding: 64px 64px 64px 96px;
          right: -876px;


          .description-info-content {
            row-gap: 32px;
          }

          .main-paragraph-container {
            font-size: 28px;
          }

        }
      }

      .bottom-info-container {
        row-gap: 8px;

        .main-title-container {
          font-size: 46px;
        }

        .sub-paragraph-container {
          font-size: 42px;
        }
      }
    }
  }

</style>