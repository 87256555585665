<template>
  <p class="main-title-container" :class="{'active-big' : activeBig}">
    <slot/>
  </p>
</template>

<script>
export default {
  name: "MainTitle",
  props: {
    activeBig: {
      type: Boolean,
      default: false
    }
  }
}
</script>

<style scoped lang="scss">
.main-title-container {
  font-weight: 400;
  line-height: 120%;
  color: var(--color-main-light-color);

  &.active-big {
    line-height: normal;
  }
}


@media (max-width: 3024px) and (min-width: 1921px){
  .main-title-container {
    font-size: 24px;

    &.active-big {
      font-size: 32px;
    }
  }
}


@media (max-width: 1920px) {
  .main-title-container {
    font-size: 24px;
  }
}


@media (max-width: 1600px) {
  .main-title-container {
    font-size: 22px;
  }
}


@media (max-width: 769px) {
  .main-title-container {
    font-size: 16px;

    &.active-big {
      font-size: 18px;
    }
  }
}

@media (max-width: 769px)  and (min-height: 720px) and (max-height: 1000px) {
  //plus
}

@media (min-height: 600px) and (max-height: 630px){

  //8 plus
}

@media (max-width: 375px) {
  //se new
}

@media (max-width: 375px) and (min-height: 630px) {
  //x
}


@media (max-width: 320px) {
  //se old
}

@media (min-width: 3025px) {
  .main-title-container {
    font-size: 54px;
  }
}
</style>